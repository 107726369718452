import { validatePresence, validateNumber } from 'ember-changeset-validations/validators'

const messageForEmptyField = 'validation.empty'
const messageForFieldsLessThanZero = 'validation.filed-has-to-be-greater-than-zero'
const validatePresenceOptions = {
  presence: true,
  message: messageForEmptyField
}
export default {
  title: validatePresence(validatePresenceOptions),
  main_artist: validatePresence(validatePresenceOptions),
  side: [
    validatePresence(validatePresenceOptions),
    validateNumber({ gt: 0, message: messageForFieldsLessThanZero })
  ],
  number_on_side: [
    validatePresence(validatePresenceOptions),
    validateNumber({ gt: 0, message: messageForFieldsLessThanZero })
  ]
}
