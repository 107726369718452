import Component from '@glimmer/component'
import { action } from '@ember/object'
import { inject as service } from '@ember/service'

export default class DiscographyDashlet extends Component {
  @service router

  get total() {
    if (!this.args.total) {
      return 0
    }
    return this.args.total
  }
  @action
  createRelease() {
    this.router.transitionTo('user.new-release')
  }
  @action
  viewDiscography() {
    if (this.args.type === 'group') {
      this.router.transitionTo('user.discography.groups')
    } else if (this.args.type === 'recording') {
      this.router.transitionTo('user.discography.recordings')
    } else if (this.args.type === 'performer') {
      this.router.transitionTo('user.clients.performers')
    } else if (this.args.type === 'producer') {
      this.router.transitionTo('user.clients.producers')
    } else {
      this.router.transitionTo('user.discography.albums')
    }
  }
}
