import Controller from '@ember/controller'
import LocaleMixin from 'min-side/mixins/locale'
import { inject as service } from '@ember/service'
import { action, computed } from '@ember/object'
import { tracked } from '@glimmer/tracking'
import { extractProblems } from 'min-side/utils/error-helpers'
import { isNorwayCountryId } from 'min-side/helpers/countries'
import createGramoMembershipApplication from 'min-side/graphql/mutations/registration'
import ENV from 'min-side/config/environment'
import { validOrganizationNumber } from '../../utils/validators'

export default class RegisterController extends Controller.extend(LocaleMixin) {
  @service intl
  @service apollo
  @tracked orgNumberError
  @tracked unexpectedError
  @tracked graphqlErrors
  @tracked domainErrors
  @tracked editedFields = {}
  @tracked country = null
  @tracked wantsRegistrantCode = false
  @tracked application = {
    firstName: '',
    lastName: '',
    email: '',
    mobile: '',
    gender: '',
    personalNumber: '',
    country: null,
    profiles: {},
    orgNumber: '',
    companyName: '',
    role: '',
    registrantCodeLabel: null
  }
  @tracked step = null

  profiles = [
    {
      title: 'registration.profiles.performer',
      value: 'PERFORMER'
    },
    {
      title: 'registration.profiles.producer',
      value: 'PERSONAL_PRODUCER'
    },
    {
      title: 'registration.profiles.producer-company',
      value: 'PRODUCER_COMPANY'
    }
  ]

  @computed(
    'application',
    'application.{firstName,lastName,email,mobile,gender,country,personalNumber,dob}',
    'application.country.id',
    'application.orgInfo.{orgNumber,companyName,role}',
    'wantsRegistrantCode'
  )
  get disableUserSubmit() {
    if (!this.get('application.firstName')) {
      return true
    }
    if (!this.get('application.lastName')) {
      return true
    }
    if (!this.get('application.email')) {
      return true
    }
    if (!this.get('application.mobile')) {
      return true
    }
    if (!this.get('application.gender')) {
      return true
    }
    if (!this.get('application.country.id')) {
      return true
    }
    if (isNorwayCountryId(this.get('application.country.id'))) {
      if (!this.get('application.personalNumber')) {
        return true
      }
    } else if (!this.get('application.dob')) {
      return true
    }
    return false
  }

  @computed('application', 'application.orgNumber', 'application.profiles.PRODUCER_COMPANY')
  get validateOrgNumber() {
    if (this.get('application.profiles.PRODUCER_COMPANY')) {
      if (!this.get('application.orgNumber')) {
        this.set('orgNumberError', this.intl.t('validation.missing-org-number'))
        return false
      } else if (!validOrganizationNumber(this.get('application.orgNumber'))) {
        this.set('orgNumberError', this.intl.t('validation.invalid-org-number'))
        return false
      }
    }
    return true
  }

  @computed(
    'application',
    'application.registrantCodeLabel',
    'application.{token,orgNumber,role,companyName}',
    'selectedProfiles',
    'wantsRegistrantCode'
  )
  get disableProfilesSubmit() {
    if (!this.get('application.token')) {
      return true
    }
    if (this.get('wantsRegistrantCode')) {
      if (!this.get('application.registrantCodeLabel')) {
        return true
      }
    }

    if (this.get('selectedProfiles').length === 0) {
      return true
    }
    if (this.get('application.profiles.PRODUCER_COMPANY')) {
      if (!this.get('application.orgNumber')) {
        return true
      }
      if (!this.get('application.role')) {
        return true
      }
      if (!this.get('application.companyName')) {
        return true
      }
    }
    return false
  }

  @computed('application.profiles.PRODUCER_COMPANY', 'application.orgNumber')
  get orgNumber() {
    if (this.get('application.profiles.PRODUCER_COMPANY')) {
      return this.get('application.orgNumber')
    }
  }

  @computed('application.profiles.PRODUCER_COMPANY', 'application.companyName')
  get companyName() {
    if (this.get('application.profiles.PRODUCER_COMPANY')) {
      return this.get('application.companyName')
    }
  }

  @computed('application.profiles.PRODUCER_COMPANY', 'application.role')
  get role() {
    if (this.get('application.profiles.PRODUCER_COMPANY')) {
      return this.get('application.role')
    }
  }

  @computed('application.profiles.PERSONAL_PRODUCER', 'application.profiles.PRODUCER_COMPANY')
  get canRequestRegistrantCode() {
    return (
      this.get('application.profiles.PERSONAL_PRODUCER') ||
      this.get('application.profiles.PRODUCER_COMPANY')
    )
  }

  @computed('canRequestRegistrantCode', 'wantsRegistrantCode', 'application.registrantCodeLabel')
  get registrantCodeLabel() {
    if (this.get('canRequestRegistrantCode') && this.get('wantsRegistrantCode')) {
      return this.get('application.registrantCodeLabel')
    }
  }

  hasGraphQlError(field) {
    return (this.get('graphqlErrors') || []).some(error => {
      const problems = extractProblems(error)
      return problems.filter(p => {
        return p.path.includes(field)
      }).length
    })
  }

  getDomainErrors(field) {
    return (this.get('domainErrors') || []).filter(e => {
      return e.key === field
    })
  }

  getErrors(field, invalidString) {
    if (this.get(`editedFields.${field}`)) {
      return []
    }
    if (this.hasGraphQlError(field)) {
      return [{ message: invalidString }]
    }

    return this.getDomainErrors(field)
  }

  @computed('application.profiles.{PERFORMER,PERSONAL_PRODUCER,PRODUCER_COMPANY}')
  get selectedProfiles() {
    return Object.entries(this.get('application.profiles'))
      .map(pair => {
        if (pair[1]) {
          return pair[0]
        }
        return null
      })
      .filter(value => value)
  }

  @computed('graphqlErrors', 'domainErrors')
  get hasFormErrors() {
    return this.get('graphqlErrors.length') || this.get('domainErrors.length')
  }

  @computed('editedFields.social_security_number', 'graphqlErrors', 'domainErrors')
  get ssnErrors() {
    return this.getErrors('social_security_number', this.intl.t('validation.invalid-ssn'))
  }

  @computed('editedFields.born_on', 'graphqlErrors', 'domainErrors')
  get dobErrors() {
    return this.getErrors('born_on', this.intl.t('validation.invalid-date'))
  }

  @computed('editedFields.email', 'graphqlErrors', 'domainErrors')
  get emailErrors() {
    return this.getErrors('email', this.intl.t('validation.invalid-email'))
  }

  @computed('editedFields.organization_number', 'graphqlErrors', 'domainErrors', 'orgNumberError')
  get orgNumberErrors() {
    if (this.get('orgNumberError')) {
      return [{ message: this.get('orgNumberError') }]
    }
    return this.getErrors('organization_number', this.intl.t('validation.invalid-org-number'))
  }

  @action
  onFocus(field) {
    this.set(`editedFields.${field}`, true)
  }
  @action
  onPersonalNumberInput() {
    const maxLength = 11
    let personalNumber = this.get('application.personalNumber').replace(/[^0-9]/g, '')
    personalNumber = personalNumber.substring(0, maxLength)

    this.set('application.personalNumber', personalNumber)
  }

  @action
  changeCountry(args) {
    if (args && args.id) {
      this.set('application.country', args)
      if (isNorwayCountryId(args.id)) {
        this.set('dobMethod', 'number')
        this.set('application.dob', null)
      } else {
        this.set('dobMethod', 'date')
        this.set('application.personalNumber', null)
      }
    } else {
      this.set('application.country', null)
      this.set('dobMethod', null)
    }
  }

  @action
  changeProfiles(profile) {
    this.set(`application.profiles.${profile}`, !this.get(`application.profiles.${profile}`))
  }

  @action
  toggleWantsRegistrantCode() {
    this.set('wantsRegistrantCode', !this.get('wantsRegistrantCode'))
  }

  @action
  changeGender(gender) {
    this.set('application.gender', gender)
  }

  @action
  changeMobile(mobile) {
    this.set('application.mobile', mobile)
  }

  @action
  resetErrors() {
    this.set('orgNumberError', '')
  }

  @action
  nextStep() {
    this.set('step', 'profiles')
  }

  @action
  async submit() {
    if (!this.get('validateOrgNumber')) {
      return
    }
    this.set('editedFields', {})
    const application = this.get('application')
    const orgNumber = this.get('orgNumber')
    const companyName = this.get('companyName')
    const role = this.get('role')
    const registrantCodeLabel = this.get('registrantCodeLabel')
    const locale = this.get('intl.primaryLocale')
    const variables = {
      input: {
        first_name: application.firstName || null,
        last_name: application.lastName || null,
        gender: application.gender || null,
        citizenship: {
          country: application.country.id
        },
        socialSecurityNumber: application.personalNumber || null,
        bornOn: application.dob || null,
        token: application.token || null,
        contactInformation: {
          email: application.email || null,
          mobile: application.mobile || null
        },
        organization_number: orgNumber || null,
        company_name: companyName || null,
        role: role || null,
        registrantCodeLabel: registrantCodeLabel || null,
        profiles: this.get('selectedProfiles')
      }
    }
    try {
      const response = await this.apollo.mutate({
        mutation: createGramoMembershipApplication,
        variables,
        context: {
          uri: `${ENV.apollo.publicApiURL}?locale=${locale}`
        }
      })
      if (response && response.create_gramo_membership_application) {
        const data = response.create_gramo_membership_application
        if (data.gramo_application) {
          this.set('reference', data.gramo_application.reference_number)
          this.set('step', 'submitted')
        } else {
          // FIXME: No matter the error, the end user is always sent to the first page.
          // Perhaps there is a way to detect where the error is and send them to the relevant step?
          this.set('domainErrors', data.errors)
          this.set('step', null)
        }
      } else {
        this.set('unexpectedError', true)
      }
    } catch (e) {
      this.set('graphqlErrors', e.errors)
      this.set('step', null)
    }
  }

  @action
  onCaptchaResolved(reCaptchaResponse) {
    this.set('application.token', reCaptchaResponse)
  }

  @action
  onCaptchaExpired() {
    this.set('application.token', null)
  }
}
