import Route from '@ember/routing/route'
import { inject as service } from '@ember/service'
import UnauthenticatedRouteMixin from 'ember-simple-auth/mixins/unauthenticated-route-mixin'
import query from 'min-side/graphql/queries/countries'
import ENV from 'min-side/config/environment'

export default class RegisterRoute extends Route.extend(UnauthenticatedRouteMixin) {
  @service intl
  @service apollo
  @service ajaxRequests

  model() {
    const locale = this.get('intl.primaryLocale')
    return this.apollo.query({
      query,
      fetchPolicy: 'network-only',
      context: {
        uri: `${ENV.apollo.publicApiURL}?locale=${locale}`
      }
    })
  }
}
