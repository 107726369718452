import Controller from '@ember/controller'
import { computed, get } from '@ember/object'
import { reads } from '@ember/object/computed'
import { getGramoMembership } from 'min-side/helpers/memberships'
import { tracked } from '@glimmer/tracking'
import { dropTask } from 'ember-concurrency-decorators'
import { inject as service } from '@ember/service'
import updatePerformerProfileMutation from 'min-side/graphql/mutations/profile/update-performer-profile'
import updateDefaultMinsideProfile from 'min-side/graphql/mutations/profile/default-profile'
import { formatErrors } from 'min-side/utils/changeset-helpers'
import { queryManager } from 'ember-apollo-client'
import {
  getErrorsFromArrayOfChangesets,
  mapChangesetErrors
} from 'min-side/utils/changeset-helpers'
import Promise from 'rsvp'
import Changeset from 'ember-changeset'
import lookupValidator from 'ember-changeset-validations'
import AlternativeArtistNameValidations from 'min-side/validations/profile/alternative-artist-name'
import InstrumentValidations from 'min-side/validations/profile/instrument'
import PerformerValidations from 'min-side/validations/profile/performer'

export default class ProfileInfoProfileController extends Controller {
  @tracked changesets = {}

  @queryManager apollo
  @service intl
  @service profileInfo

  @reads('profileInfo.currentProfileName')
  profileType

  @reads('model.user.person.performer')
  performer

  @reads('performer.society_memberships')
  societyMemberships

  @reads('performer.federation_memberships')
  federationMemberships

  @reads('profileInfo.activeProfile.displayName')
  activeProfileDisplayName

  @computed('societyMemberships')
  get membership() {
    return getGramoMembership(this.societyMemberships)
  }

  @computed('profileType', 'intl.locale')
  get pageTitle() {
    let type = this.profileType

    if (type !== 'contact') {
      return this.intl.t('profiles.profile', {
        profileType: this.intl.t(`profiles.${type}`)
      })
    }

    if (this.profileInfo.currentProfileType === 'producer_company') {
      type = 'producer'
    }
    return `${this.intl.t(`profiles.${type}`)}: ${this.activeProfileDisplayName}`
  }

  setupPerformerInfoChangeset(model) {
    const performer = get(model, 'user.person.performer')
    this.set(
      'changesets.performerChangeset',
      this.constructor.setupPerformerSectionChangesetAndReturnIt(performer)
    )
  }

  setupDefaultProfile(model) {
    this.set(
      'changesets.defaultprofileChangeset',
      this.constructor.setupDefaultProfileChangesetAndReturnIt(model)
    )
  }

  @dropTask
  *onProfileInfoValidate(changesets) {
    try {
      const profileInfo = changesets.performerChangeset
      // We do not care to validate the default profile changeset if profile info changeset is not initiated.
      if (!profileInfo) {
        return []
      }
      const instruments = profileInfo.get('instruments')
      const alternativeNames = profileInfo.get('alternative_artist_names')

      yield Promise.all(instruments.map(i => i.validate()))
      yield Promise.all(alternativeNames.map(an => an.validate()))
      yield profileInfo.validate()

      const isValid = this.constructor.isChangesetValid(profileInfo)

      if (!isValid) {
        const validationErrors = this.constructor.collectChangesetErrors(profileInfo)
        const intlErrors = validationErrors.map(({ attribute, message }) => ({
          attribute,
          message: this.intl.t(message)
        }))

        return intlErrors
      }

      return []
    } catch (error) {
      throw error
    }
  }

  @dropTask
  *onProfileInfoSubmit(changesets) {
    const profileInfoChangeset = changesets.performerChangeset
    const defaultProfileToggleChangeset = changesets.defaultprofileChangeset
    try {
      if (profileInfoChangeset) {
        const profileSubmissionResult = this.profileInfoSubmit.perform(profileInfoChangeset)

        if (profileSubmissionResult && profileSubmissionResult.errors) {
          return formatErrors(profileSubmissionResult.errors)
        }
      }

      const defaultProfileSubmissionResult = this.defaultProfileToggleSubmit.perform(
        defaultProfileToggleChangeset
      )
      if (defaultProfileSubmissionResult && defaultProfileSubmissionResult.errors) {
        return formatErrors(defaultProfileSubmissionResult.errors)
      }

      return []
    } catch (error) {
      throw error
    }
  }

  @dropTask
  *profileInfoSubmit(changeset) {
    try {
      const profileInfo = changeset
      const instruments = profileInfo.get('instruments')
      const alternativeNames = profileInfo.get('alternative_artist_names')

      const variables = {
        input: {
          ...this.constructor.extractChangesetData(profileInfo),
          id: get(this, 'model.user.person.performer.id')
        }
      }

      const res = yield get(this, 'apollo').mutate(
        { mutation: updatePerformerProfileMutation, variables },
        'public_update_performer_profile'
      )

      if (!res || !res.errors) {
        instruments.forEach(i => i.save())
        alternativeNames.forEach(an => an.save())
        profileInfo.save()
      }

      return res
    } catch (error) {
      throw error
    }
  }

  @dropTask
  *defaultProfileToggleSubmit(changeset) {
    try {
      const variables = {
        input: {
          default_min_side_profile: changeset.get('default_min_side_profile'),
          default_min_side_organization_id: changeset.get('default_min_side_organization_id'),
          id: changeset.get('id')
        }
      }
      const res = yield this.apollo.mutate({
        variables,
        mutation: updateDefaultMinsideProfile
      })
      if (!res || !res.errors) {
        changeset.save()
      }
      return res
    } catch (error) {
      throw error
    }
  }

  static setupDefaultProfileChangesetAndReturnIt(model) {
    const {
      user: { person }
    } = model
    return new Changeset({
      default_min_side_profile: person.default_min_side_profile,
      default_min_side_organization_id: person.default_min_side_organization_id,
      id: person.id
    })
  }

  static setupPerformerSectionChangesetAndReturnIt(performer) {
    const { artist_name, main_instrument } = performer
    const altArtistsChangeset = performer.alternative_artist_names.map(artist => {
      return new Changeset(
        artist,
        lookupValidator(AlternativeArtistNameValidations),
        AlternativeArtistNameValidations
      )
    })
    const instrumentsChangeset = performer.instruments.map(instrument => {
      return new Changeset(
        instrument,
        lookupValidator(InstrumentValidations),
        InstrumentValidations
      )
    })

    return new Changeset(
      {
        artist_name,
        main_instrument,
        alternative_artist_names: altArtistsChangeset,
        instruments: instrumentsChangeset
      },
      lookupValidator(PerformerValidations),
      PerformerValidations
    )
  }

  static extractChangesetData(changeset) {
    const profileInfo = changeset
    const altArtistNames = profileInfo.get('alternative_artist_names')
    const instruments = profileInfo.get('instruments')

    const profileInfoData = {
      main_instrument: profileInfo.get('main_instrument'),
      artist_name: profileInfo.get('artist_name')
    }

    const { main_instrument, artist_name } = profileInfoData

    const altArtistNamesData = [
      ...altArtistNames.map(alt => {
        return {
          artist_name: alt.get('artist_name'),
          description: alt.get('description')
        }
      })
    ]

    const instrumentsData = [
      ...instruments.map(instr => {
        return instr.get('id')
      })
    ]

    return {
      main_instrument: (main_instrument && main_instrument.id) || null,
      artist_name,
      alternative_artist_names: altArtistNamesData,
      instruments: instrumentsData
    }
  }

  static collectChangesetErrors(profileInfo) {
    const alternativeNames = profileInfo.get('alternative_artist_names')
    const instruments = profileInfo.get('instruments')

    const profileInfoErrors = profileInfo.get('errors')
    const altNamesErrors = getErrorsFromArrayOfChangesets(
      'alternative_artist_names.',
      alternativeNames
    )
    const instrumentsErrors = getErrorsFromArrayOfChangesets('instruments.', instruments)
    return mapChangesetErrors('', [...profileInfoErrors, ...altNamesErrors, ...instrumentsErrors])
  }

  static isChangesetValid(profileInfo) {
    const instruments = profileInfo.get('instruments')
    const alternativeNames = profileInfo.get('alternative_artist_names')

    const isProfileInfoValid = profileInfo.get('isValid')
    const isInstrumentsValid = instruments.every(i => i.get('isValid'))
    const isAlternativeNamesValid = alternativeNames.every(an => an.get('isValid'))

    return isProfileInfoValid && isInstrumentsValid && isAlternativeNamesValid
  }
}
