import Component from '@ember/component'
import { inject } from '@ember/service'
import { computed } from '@ember/object'
import { equal, readOnly } from '@ember/object/computed'
import { getWithDefault } from '@ember/object'
import { LEDGER_ENTRY_TYPES } from 'min-side/constants/ledger-entries'
import { PROFILE_TYPES } from 'min-side/constants/profile'
import { isEmpty } from '@ember/utils'
import AdaptOwnerLedgerToOwnerName from 'echo-ember-common/utils/adapt-owner-ledger-to-owner-name'

const {
  PAYMENT_ENTRY,
  PAYMENT_DEBT_TRANSFER_ENTRY,
  FOREIGN_REMUNERATION_ENTRY,
  ADMINISTRATION_FEE_ENTRY,
  INHERITANCE_ENTRY,
  LEDGER_ENTRY,
  REMUNERATION_ENTRY
} = LEDGER_ENTRY_TYPES

const { INHERITANCE_PARTY } = PROFILE_TYPES

const accountNumberFromBankAccount = account => {
  if (!account) {
    return ''
  }
  return (
    account.norwegian_account_number ||
    account.international_account_number ||
    account.account_number
  )
}

export default Component.extend({
  tagName: '',
  supportsDataTestProperties: true,
  intl: inject(),
  profile: inject(),
  router: inject(),

  isInheritanceParty: equal('profile.activeProfile.profile', INHERITANCE_PARTY),

  isDiffEntry: computed('entry.snapshot.type', function () {
    const entryType = this.get('entry.snapshot.type')
    return entryType === 'CORRECTION' || entryType === 'ADDITIONAL'
  }),
  isInflictedByLegacySystem: readOnly('entry.snapshot.inflicted_by_legacy_system'),
  isPaymentEntry: computed('entry.typename', function () {
    return [PAYMENT_ENTRY, PAYMENT_DEBT_TRANSFER_ENTRY].includes(this.get('entry.typename'))
  }),
  isForeignPaymentEntry: equal('entry.typename', FOREIGN_REMUNERATION_ENTRY),
  isInheritanceEntry: equal('entry.typename', INHERITANCE_ENTRY),

  paymentEntryTransferComment: computed(
    'bankAccount',
    'defaultTransferComment',
    'intl.locale',
    'isInheritanceParty',
    'isPaidToBankAccount',
    'isPaidToFederation',
    function () {
      const account = accountNumberFromBankAccount(this.get('entry.bank_account'))
      if (this.isPaidToBankAccount) {
        if (account) {
          return this.intl.t('ledger.overview.recipient', { account })
        }
        return this.intl.t('ledger.overview.paid-to-details-missing')
      }

      const creditorsNames = this.get('entry.creditors.edges')
        .map(creditors => {
          if (creditors.node.owner) {
            return AdaptOwnerLedgerToOwnerName(creditors.node.owner)
          }
          return undefined
        })
        .join(', ')
      if (creditorsNames) {
        return this.intl.t('ledger.overview.transferred_to_third_party', {
          third_party_name: creditorsNames
        })
      }
      return this.defaultTransferComment
    }
  ),

  paymentDebtEntryTransferComment: computed(
    'bankAccount',
    'defaultTransferComment',
    'intl.locale',
    'isInheritanceParty',
    'isPaidToBankAccount',
    'isPaidToFederation',
    function () {
      const debitorNames = this.get('entry.debitors.edges')
        .map(debitors => {
          if (debitors.node.owner) {
            return AdaptOwnerLedgerToOwnerName(debitors.node.owner)
          }
          return undefined
        })
        .join(', ')
      if (debitorNames) {
        return this.intl.t('ledger.overview.from', {
          debitor: debitorNames
        })
      }
      return this.defaultTransferComment
    }
  ),

  transferComment: computed(
    'administrationFeeEntryTransferComment',
    'defaultTransferComment',
    'entry.typename',
    'inheritanceEntryTransferComment',
    'paymentEntryTransferComment',
    'paymentDebtEntryTransferComment',
    function () {
      switch (this.get('entry.typename')) {
        case INHERITANCE_ENTRY:
          return this.inheritanceEntryTransferComment

        case PAYMENT_ENTRY:
          return this.paymentEntryTransferComment

        case PAYMENT_DEBT_TRANSFER_ENTRY:
          return this.paymentDebtEntryTransferComment

        case ADMINISTRATION_FEE_ENTRY:
          return this.administrationFeeEntryTransferComment

        default:
          return this.defaultTransferComment
      }
    }
  ),

  inheritanceEntryTransferComment: computed('intl.locale', 'entry.description', function () {
    let testatorName = ''
    if (this.get('entry.debitors.edges') && this.get('entry.debitors.edges.length')) {
      testatorName = this.get('entry.debitors.edges')
        .map(({ node }) => {
          const {
            owner: { person = { first_name: '', last_name: '' } }
          } = node
          return `${person.first_name} ${person.last_name}`
        })
        .join(', ')
        .toUpperCase()
    }
    return isEmpty(testatorName)
      ? ''
      : this.intl.t('ledger.overview.from', { debitor: testatorName })
  }),

  administrationFeeEntryTransferComment: computed('intl.locale', 'isInheritanceParty', function () {
    const gramo = 'GRAMO'
    return this.isInheritanceParty ? this.intl.t('ledger.overview.to', { creditor: gramo }) : gramo
  }),

  defaultTransferComment: computed('intl.locale', 'entry.typename', function () {
    const debitorsTypes = [LEDGER_ENTRY, REMUNERATION_ENTRY]
    const entryKey = debitorsTypes.includes(this.get('entry.typename')) ? 'debitors' : 'creditors'
    return getWithDefault(this, `${entryKey}.edges`, [])
      .reduce((acc, creditor) => {
        const owner = creditor.node.owner
        if (owner.name) {
          return acc.concat(owner.name)
        }
        if (owner.artist_name) {
          return acc.concat(owner.artist_name)
        }
        if (owner.creditor) {
          return acc.concat(owner.creditor.name)
        }
        return acc
      }, [])
      .join(', ')
  }),
  isPaidToBankAccount: computed(function () {
    if (this.isPaymentEntry) {
      const creditors = this.get('entry.creditors.edges')
      return creditors && creditors.length && creditors.get('firstObject.node.sector') === 'BANK'
    }
    return false
  }),

  link: computed('entry.typename', 'isDiffEntry', function () {
    const isCorrectionEntry = this.isDiffEntry

    switch (this.get('entry.typename')) {
      case LEDGER_ENTRY:
        return 'user.ledger.entry'
      case PAYMENT_ENTRY:
      case PAYMENT_DEBT_TRANSFER_ENTRY:
        return 'user.ledger.payment'
      case REMUNERATION_ENTRY:
        if (this.isInflictedByLegacySystem) {
          return null
        }

        return isCorrectionEntry
          ? 'user.ledger.remuneration.by-recording-diff'
          : 'user.ledger.remuneration.by-recording'
      case INHERITANCE_ENTRY:
        return 'user.ledger.inheritance'
      default:
        return null
    }
  }),

  amount: computed('entry.amount', function () {
    const amount = this.get('entry.amount')
    const typename = this.get('entry.typename')
    switch (typename) {
      case PAYMENT_ENTRY:
      case PAYMENT_DEBT_TRANSFER_ENTRY:
        return -parseFloat(amount)

      default:
        return amount
    }
  }),
  actions: {
    redirectToLedgerEntry(link, id, params) {
      if (link) {
        this.router.transitionTo(link, id, { queryParams: params })
      }
    }
  }
})
