import Component from '@glimmer/component'
import { action } from '@ember/object'
import { inject as service } from '@ember/service'

export default class PaymentsDashlet extends Component {
  @service router

  @action
  goToLedger() {
    this.router.transitionTo('user.ledger')
  }
}
