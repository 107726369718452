import Component from '@glimmer/component'
import { action, computed } from '@ember/object'
import { rollbackPropsExcept } from 'min-side/utils/changeset-helpers'

export default class ProfileSectionBankInfoComponent extends Component {
  accountTypes = ['blank', 'norwegian', 'iban', 'bic-swift', 'american']

  get data() {
    return this.args.changeset
  }

  get accountType() {
    return this.data.get('accountType')
  }

  set accountType(value) {
    return this.data.set('accountType', value)
  }

  get bic() {
    return this.data.get('bic')
  }

  set bic(value) {
    return this.data.set('bic', value)
  }

  get bankName() {
    return this.data.get('bank_name')
  }

  set bankName(value) {
    return this.data.set('bank_name', value)
  }

  get bankCode() {
    return this.data.get('bank_code')
  }

  set bankCode(value) {
    return this.data.set('bank_code', value)
  }

  get errorPath() {
    return this.args.errorPath || 'bank_account'
  }

  @computed('args.errors.length')
  get nestedErrorsObject() {
    return {
      errors: this.args.errors
    }
  }

  @action onAccountTypeChange(accountType) {
    this.accountType = accountType
    rollbackPropsExcept(this.data, ['accountType'])
  }
}
