import Component from '@ember/component'
import { computed, get } from '@ember/object'

export default Component.extend({
  'data-test-edit-release-tracks-table': true,
  tagName: 'div',
  classNames: [],
  classNameBindings: ['disabled::table--tracks--edit'],
  disabled: true,
  number: computed('index', function () {
    return get(this, 'index') + 1
  }),
  actions: {
    remove() {
      this.removeSide(this.index)
    }
  }
})
