import { computed } from '@ember/object'
import { alias } from '@ember/object/computed'
import Component from '@ember/component'

export default Component.extend({
  tagName: 'tr',
  description: alias('position.description'),
  name: computed('position.person.{first_name,last_name}', function () {
    const firstName = this.get('position.person.first_name')
    const lastName = this.get('position.person.last_name')
    return [firstName, lastName].compact().join(' ')
  }),
  email: alias('position.person.contact_information.email'),
  mobile: alias('position.person.contact_information.mobile')
})
