import Helper from '@ember/component/helper'

// The problem is that the state of the claim recording can in theory be
// updated by Gramo, before the claim has been completed.
// We mask the rejected state until the claim is completed.
export function claimRecordingState([claimRecordingState, claimState]) {
  if (claimRecordingState !== 'REJECTED') {
    return claimRecordingState
  }

  if (claimState === 'REQUESTED_CHANGES' || claimState === 'COMPLETED') {
    return 'REJECTED'
  }

  return 'SUBMITTED'
}

export default Helper.helper(claimRecordingState)
