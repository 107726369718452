import Component from '@ember/component'
import Changeset from 'ember-changeset'
import AlternativeCatalogueNumberValidations from 'min-side/validations/new-release/alternative-catalog-number'
import lookupValidator from 'ember-changeset-validations'
import { inject } from '@ember/service'
import EmberObject from '@ember/object'

export default Component.extend({
  intl: inject(),

  init(...args) {
    this._super(...args)

    this.changeset = new Changeset(
      this.altCatNumber,
      lookupValidator(AlternativeCatalogueNumberValidations),
      AlternativeCatalogueNumberValidations
    )

    this._addAlternativeCatalogueNumberChangesetToParent(this.changeset)
    this._validateChangeSet(this.changeset)
  },

  actions: {
    async submit(changeset) {
      this._validateChangeSet(changeset)
    }
  },

  async _validateChangeSet(changeset) {
    await changeset.validate()
    if (!changeset.get('isValid')) {
      const errors = this._mapValidationErrors(changeset.get('errors'))
      this.setProperties({
        errors,
        showErrors: false
      })
      this._addErrorToParent()
    }
  },

  _mapValidationErrors(changesetErrors) {
    const errors = []
    changesetErrors.forEach(error => {
      errors.push(
        EmberObject.create({
          attribute: error.key,
          message: this.intl.t('validation.empty')
        })
      )
    })
    return { errors }
  },

  _addErrorToParent() {
    this.addAlternativeCatalogueNumberErrorToParent(this.alternativeCatalogueNumberIndex)
  },

  _addAlternativeCatalogueNumberChangesetToParent(changeset) {
    this.addAlternativeCatalogueNumberChangesetToParent(changeset)
  }
})
