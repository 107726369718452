import Component from '@ember/component'
import { reads } from '@ember/object/computed'
import { computed, get } from '@ember/object'
import moment from 'moment'
import { inject } from '@ember/service'

export default Component.extend({
  'data-test-testators-overview': true,
  tagName: 'section',
  router: inject(),
  testatorsList: reads('inheritanceParty.inherits_from'),

  overviewCard: computed('testatorsList', function () {
    return {
      count: get(this, 'testatorsList').filter(t => this._isValid(t.ends_on)).length,
      headerTranslationKey: 'sectors.testator',
      icon: 'performer',
      link: 'user.testators'
    }
  }),

  _isValid(date) {
    if (date === null) {
      return true
    }
    return moment(date).isAfter(moment())
  },

  actions: {
    viewRights() {
      this.router.transitionTo('user.testators')
    }
  }
})
