import Component from '@glimmer/component'
import { action } from '@ember/object'

export default class Dashlet extends Component {
  get clickable() {
    if (this.args.action) {
      return true
    }
    return false
  }
  @action
  click(event) {
    if (this.clickable) {
      event.preventDefault()
      this.args.action()
    }
  }
}
