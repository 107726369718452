import Controller from '@ember/controller'
import { action, get, getProperties } from '@ember/object'
import { tracked } from '@glimmer/tracking'
import { dropTask } from 'ember-concurrency-decorators'
import { inject as service } from '@ember/service'
import mutation from 'min-side/graphql/mutations/personal-information'
import { queryManager } from 'ember-apollo-client'
import { formatErrors } from 'min-side/utils/changeset-helpers'
import {
  getErrorsFromArrayOfChangesets,
  mapChangesetErrors
} from 'min-side/utils/changeset-helpers'
import PersonalInfoValidations, {
  defaultPersonalInfoObject
} from 'min-side/validations/profile/personal-information'
import Changeset from 'ember-changeset'
import lookupValidator from 'ember-changeset-validations'
import PreviousNameValidations from 'min-side/validations/profile/previous-name'
import CitizenshipValidations from 'min-side/validations/profile/citizenship'
import ResidencyValidations from 'min-side/validations/profile/residency'

export default class ProfileInfoPersonalController extends Controller {
  @tracked changeset = null

  @queryManager apollo
  @service intl
  @service profileInfo

  setupChangeset(model) {
    const personData = get(model, 'user.person')
    this.changeset = this.constructor.setUpChangesetAndReturnIt(personData)
  }

  @dropTask
  *onPersonalInfoValidate(changeset) {
    try {
      const personalInfo = changeset
      const previousNames = personalInfo.get('previous_names')
      const citizenships = personalInfo.get('citizenships')
      const residencies = personalInfo.get('residencies')

      yield Promise.all(previousNames.map(pn => pn.validate()))
      yield Promise.all(citizenships.map(c => c.validate()))
      yield Promise.all(residencies.map(r => r.validate()))
      yield personalInfo.validate()

      const isValid = this.constructor.isChangesetValid(personalInfo)

      if (!isValid) {
        const validationErrors = this.constructor.collectChangesetError(personalInfo)
        const intlErrors = validationErrors.map(({ attribute, message }) => ({
          attribute,
          message: this.intl.t(message)
        }))

        return intlErrors
      }
      return []
    } catch (error) {
      throw error
    }
  }

  @dropTask
  *onPersonalInfoSubmit(changeset) {
    try {
      const previousNames = changeset.get('previous_names')
      const citizenships = changeset.get('citizenships')
      const residencies = changeset.get('residencies')
      const variables = {
        input: {
          ...this.constructor.extractChangesetData(changeset),
          id: this.profileInfo.maskId
        },
        include_performer: this.profileInfo.includePerformer,
        include_producer: this.profileInfo.includeProducer
      }

      const res = yield this.apollo.mutate({ mutation, variables }, 'update_personal_information')

      if (res && res.errors) {
        const formattedErrors = formatErrors(res.errors)

        return formattedErrors
      }

      previousNames.forEach(pn => pn.save())
      citizenships.forEach(c => c.save())
      residencies.forEach(r => r.save())
      changeset.save()

      return []
    } catch (error) {
      throw error
    }
  }

  @action
  onPersonalInfoCancel(changeset) {
    const previousNames = changeset.get('previous_names')
    const residencies = changeset.get('residencies')
    const citizenships = changeset.get('citizenships')

    changeset.rollback()

    previousNames.forEach(pn => pn.rollback())
    residencies.forEach(r => r.rollback())
    citizenships.forEach(c => c.rollback())
  }

  static setUpChangesetAndReturnIt(data) {
    const {
      previous_names = [],
      first_name = '',
      last_name = '',
      gender = 'other',
      born_on = null,
      social_security_number = '',
      citizenships = [],
      residencies = []
    } = data

    const personalInfo = {
      ...defaultPersonalInfoObject,
      previous_names: this.setUpPreviousNamesChangesets(previous_names),
      first_name,
      last_name,
      gender,
      born_on,
      social_security_number,
      citizenships: this.setUpCitizenshipsChangesets(citizenships),
      residencies: this.setUpResidenciesChangesets(residencies)
    }

    return new Changeset(
      personalInfo,
      lookupValidator(PersonalInfoValidations),
      PersonalInfoValidations
    )
  }

  /**
   * @param previous_names
   * @returns {Changeset[]}
   */
  static setUpPreviousNamesChangesets(previous_names = []) {
    return previous_names.map(
      ({ description, name }) =>
        new Changeset(
          { description, name },
          lookupValidator(PreviousNameValidations),
          PreviousNameValidations
        )
    )
  }

  /**
   * @param citizenships
   * @returns {Changeset[]}
   */
  static setUpCitizenshipsChangesets(citizenships = []) {
    return citizenships.map(
      citizenship =>
        new Changeset(
          {
            country: null,
            from: '',
            to: '',
            ...citizenship
          },
          lookupValidator(CitizenshipValidations),
          CitizenshipValidations
        )
    )
  }

  /**
   * @param residencies
   * @returns {Changeset[]}
   */
  static setUpResidenciesChangesets(residencies = []) {
    return residencies.map(
      residency =>
        new Changeset(
          {
            country: null,
            from: '',
            to: '',
            ...residency
          },
          lookupValidator(ResidencyValidations),
          ResidencyValidations
        )
    )
  }

  /**
   * @param changset
   * @returns bool
   */
  static isChangesetValid(changset) {
    const isPreviousNamesValid = changset.get('previous_names').every(pn => pn.get('isValid'))
    const isCitizenshipsValid = changset.get('citizenships').every(c => c.get('isValid'))
    const isResidenciesValid = changset.get('residencies').every(r => r.get('isValid'))
    const isPersonalInfoValid = changset.get('isValid')

    return isPreviousNamesValid && isCitizenshipsValid && isResidenciesValid && isPersonalInfoValid
  }

  static extractChangesetData(changeset) {
    const previousNames = changeset.get('previous_names')
    const citizenships = changeset.get('citizenships')
    const residencies = changeset.get('residencies')

    return {
      ...getProperties(changeset, [
        'social_security_number',
        'born_on',
        'gender',
        'first_name',
        'last_name'
      ]),
      previous_names: previousNames.map(pn => ({ ...pn.get('data'), ...pn.get('change') })),
      citizenships: citizenships.map(c => {
        return {
          country: c.get('country.id'),
          from: c.get('from'),
          to: c.get('to')
        }
      }),
      residencies: residencies.map(r => {
        return {
          country: r.get('country.id'),
          from: r.get('from'),
          to: r.get('to')
        }
      })
    }
  }

  /**
   * Collects all the errors and maps them to a proper format
   * @param changeset
   * @returns {*}
   */
  static collectChangesetError(changeset) {
    const previousNames = changeset.get('previous_names')
    const citizenships = changeset.get('citizenships')
    const residencies = changeset.get('residencies')

    const personalInfoErrors = changeset.get('errors')
    const previousNamesErrors = getErrorsFromArrayOfChangesets('previous_names.', previousNames)
    const citizenshipsErrors = getErrorsFromArrayOfChangesets('citizenships.', citizenships)
    const resideciesErrors = getErrorsFromArrayOfChangesets('residencies.', residencies)
    return mapChangesetErrors('', [
      ...personalInfoErrors,
      ...previousNamesErrors,
      ...citizenshipsErrors,
      ...resideciesErrors
    ])
  }
}
