import Route from '@ember/routing/route'
import query from 'min-side/graphql/queries/personal-information'
import { inject as service } from '@ember/service'
import { queryManager } from 'ember-apollo-client'

export default class ProfileInfoRoute extends Route {
  @queryManager apollo

  @service profileInfo

  beforeModel({ targetName }) {
    const { getFirstAvailableLinkForTheCurrentActiveProfile } = this.controllerFor(
      'user.profile-info'
    )

    if (targetName === 'user.profile-info.index') {
      if (getFirstAvailableLinkForTheCurrentActiveProfile) {
        this.transitionTo(getFirstAvailableLinkForTheCurrentActiveProfile.route)
      } else {
        // TODO[Sergiu] come up with a better error message.
        throw new Error('The profile you are currently using has no routes that is allowed to see')
      }
    }
  }

  // noinspection JSCheckFunctionSignatures
  model() {
    const variables = {
      person_id: this.profileInfo.maskId,
      include_performer: this.profileInfo.includePerformer,
      include_producer: this.profileInfo.includeProducer
    }
    return this.apollo.watchQuery({ query, variables })
  }
}
