import { isEmpty } from '@ember/utils'
import EmberObject, { get } from '@ember/object'
import { run, cancel, later } from '@ember/runloop'
import { Promise, resolve } from 'rsvp'
import Base from 'ember-simple-auth-token/authenticators/jwt'

const MILLISECONDS_IN_SECONDS = 1000

/**
 * Extending the JWT authenticator from lib as our server is
 * not 100% compatible with the lib. And the lib isn't 100% configurable.
 *
 * In the future we should probably fork the repo since there doesn't seem
 * to be any activity recently.
 *
 * https://github.com/jpadilla/ember-simple-auth-token
 *
 * DUPLICATION echo-common-front-end-candidate
 */
export default Base.extend({
  /**
   * Execute a refresh request to the server.
   *
   * Overridden to insert the token as a request header.
   */
  refreshAccessToken(token) {
    this.headers.Authorization = `Bearer ${token}`

    return this._super(token)
  },

  /**
   * Execute authenticate request to server
   *
   * Overridden to insert the token as a request header and return
   * the raw xhr object instead of only response json or text
   */
  authenticate(credentials, headers = {}) {
    headers.Authorization = `Bearer ${credentials.token}`
    delete credentials.token

    return new Promise((resolve, reject) => {
      const data = this.getAuthenticateData(credentials)

      this.makeRequest(this.serverTokenEndpoint, data, headers).then(
        response => {
          run(() => {
            try {
              const sessionData = this.handleAuthResponse(response.json)
              resolve(sessionData)
            } catch (error) {
              reject(error)
            }
          })
        },
        xhr => {
          run(() => {
            reject(xhr)
          })
        }
      )
    })
  },
  /**
   * Returns authenticate data to the server when authenticate() is called.
   *
   * Default behaviour is an identification and password field sent to the server.
   * Overridden we provde only the SMS code to server + token in request header
   *
   * @param  {object} credentials   Object containing token
   * @return {object}               Object with credentials transformed
   *                                to the data the server expects
   */
  getAuthenticateData(credentials) {
    return { data: { attributes: { code: credentials.code } } }
  },

  /**
   * Returns the data to be stored in session, from the response data from server on auth/refresh
   *
   * Overridden to expose the user data directly on the session.
   *
   * @param  {object} response The response data from the server
   * @return {object}          The object we'll persist in the session
   */
  getResponseData(response) {
    const token = get(response, this.tokenPropertyName)
    const tokenData = this.getTokenData(token)

    response.user = tokenData.data.user
    return response
  },

  /**
   * Adds support for non ascii char in web token, thus use our own getJWTPayload method.
   *
   * NOTE FIXED IN PULL REQUEST
   * https://github.com/jpadilla/ember-simple-auth-token/pull/124
   */
  // getTokenData(token) {
  //   return getJWTPayload(token)
  // },

  /**
   * Override trigger, capture the sessionDataUpdated, and transform the response data
   *
   * NOTE FIXED IN PULL REQUEST
   * https://github.com/jpadilla/ember-simple-auth-token/pull/124
   */
  trigger(name, ...args) {
    if (name === 'sessionDataUpdated') {
      return this._super(name, this.getResponseData(args[0]))
    }

    return this._super(name, ...args)
  },
  /**
   Schedules session invalidation at the time token expires.

   @method scheduleAccessTokenExpiration
   @private
   */
  scheduleAccessTokenExpiration(expiresAt) {
    const now = this.getCurrentTime()
    const wait = Math.max((expiresAt - now) * MILLISECONDS_IN_SECONDS, 0)

    if (!isEmpty(expiresAt)) {
      cancel(this._tokenExpirationTimeout)
      delete this._tokenExpirationTimeout
      this._tokenExpirationTimeout = later(this, this.handleAccessTokenExpiration, wait)
    }
  },
  /**
   Restores the session from a set of session properties.
   It will return a resolving promise if one of two conditions is met:
   1) Both `data.token` and `data.expiresAt` are non-empty and `expiresAt`
   is greater than the calculated `now`.
   2) If `data.token` is non-empty and the decoded token has a key for
   `tokenExpireName`.
   If `refreshAccessTokens` is true, `scheduleAccessTokenRefresh` will
   be called and an automatic token refresh will be initiated.
   @method restore
   @param {Object} data The data to restore the session from
   @return {Ember.RSVP.Promise} A promise that when it resolves results
   in the session being authenticated
   */
  restore(data) {
    const dataObject = EmberObject.create(data)

    return new Promise((resolve, reject) => {
      const now = this.getCurrentTime()
      const token = dataObject.get(this.tokenPropertyName)
      const refreshToken = dataObject.get(this.refreshTokenPropertyName)
      let expiresAt = dataObject.get(this.tokenExpireName)

      if (isEmpty(token)) {
        return reject(new Error('empty token'))
      }

      if (isEmpty(expiresAt)) {
        // Fetch the expire time from the token data since `expiresAt`
        // wasn't included in the data object that was passed in.
        const tokenData = this.getTokenData(token)

        expiresAt = tokenData[this.tokenExpireName]
        if (isEmpty(expiresAt)) {
          return resolve(data)
        }
      }
      if (expiresAt > now) {
        const wait = (expiresAt - now - this.refreshLeeway) * MILLISECONDS_IN_SECONDS

        this.scheduleAccessTokenExpiration(expiresAt)

        if (wait > 0) {
          if (this.refreshAccessTokens) {
            this.scheduleAccessTokenRefresh(dataObject.get(this.tokenExpireName), refreshToken)
          }
          resolve(data)
        } else if (this.refreshAccessTokens) {
          resolve(this.refreshAccessToken(refreshToken))
        } else {
          reject(new Error('unable to refresh token'))
        }
      } else if (this.refreshAccessTokens) {
        // the token might not be expired,
        // we can't test this on the client so attempt to refresh the token.
        // If the server rejects the token the user session will be invalidated
        resolve(this.refreshAccessToken(refreshToken))
      } else {
        reject(new Error('token is expired'))
      }
    })
  },
  invalidate() {
    cancel(this._refreshTokenTimeout)
    delete this._refreshTokenTimeout
    cancel(this._tokenExpirationTimeout)
    delete this._tokenExpirationTimeout
    return new resolve()
  },

  /**
   Handles access token expiration. After token expiration the session will
   be invalidated and the sessionInvalidated provided by ember-simple-auth
   will be triggered.

   @method handleAccessTokenExpiration
   @private
   */
  handleAccessTokenExpiration() {
    return this.invalidate().then(() => {
      this.trigger('sessionDataInvalidated')
    })
  },
  handleAuthResponse(response) {
    const token = get(response, this.tokenPropertyName)
    const tokenData = this.getTokenData(token)
    const expiresAt = get(tokenData, this.tokenExpireName)
    this.scheduleAccessTokenExpiration(expiresAt)
    response.user = tokenData.data.user

    return this._super(response)
  }
})
