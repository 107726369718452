import Route from '@ember/routing/route'
import { inject } from '@ember/service'
import query from 'min-side/graphql/queries/discography/groups'
import { queryManager } from 'ember-apollo-client'

export default Route.extend({
  apollo: queryManager(),
  profile: inject(),
  model() {
    const impersonatedUserId = this.get('profile.impersonatedUserId')
    const variables = impersonatedUserId ? { person_id: impersonatedUserId } : null
    return this.apollo.watchQuery({ query, variables })
  }
})
