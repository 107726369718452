import Component from '@glimmer/component'
import { tracked } from '@glimmer/tracking'
import { action } from '@ember/object'
import { dropTask } from 'ember-concurrency-decorators'
import { inject as service } from '@ember/service'

export default class ProfileSectionComponent extends Component {
  isErrorsObjectProvided = false

  @tracked isControlsDisabled = true
  @tracked localErrors = []

  @service flashMessages
  @service intl

  /*
   * If you don't provide component with errors object
   * we assume that parent component doesn't care about the error state
   * and handle this logic locally.
   */
  constructor(...args) {
    super(...args)

    this.isErrorsObjectProvided = 'errors' in this.args
  }

  get data() {
    return this.args.data || undefined
  }

  get editControls() {
    return 'editControls' in this.args ? this.args.editControls : true
  }

  get errors() {
    return 'errors' in this.args ? this.args.errors : this.localErrors
  }
  set errors(value) {
    this.localErrors = value
  }

  @dropTask
  *saveProfileSectionTask() {
    try {
      const { onValidate, onSubmit } = this.args

      const validationErrors = yield onValidate(this.data)
      if (validationErrors && validationErrors.length) {
        return this.onError(validationErrors)
      }

      const submitErrors = yield onSubmit(this.data)
      if (submitErrors && submitErrors.length) {
        return this.onError(submitErrors)
      }

      return this.onSuccess()
    } catch (error) {
      console.error(error)
      return this.onFail()
    }
  }

  onError(errors) {
    if (!this.isErrorsObjectProvided) {
      this.localErrors = errors
    }
    return this.flashMessages.error(this.intl.t('flash.save.invalid'))
  }

  onSuccess() {
    if (!this.isErrorsObjectProvided) {
      this.localErrors = []
    }
    this.isControlsDisabled = true
    return this.flashMessages.success(this.intl.t('flash.save.success'))
  }

  onFail() {
    if (!this.isErrorsObjectProvided) {
      this.localErrors = []
    }
    this.flashMessages.error(this.intl.t('flash.save.failed'))
  }

  @action
  onCancel() {
    this.saveProfileSectionTask.cancelAll()
    this.isControlsDisabled = true

    if (!this.isErrorsObjectProvided) {
      this.localErrors = []
    }

    /*
     * For most simple cases we just need to rollback changes on the changeset
     * and that's exactly what we're doing if onCancel handler is not provided
     */
    return this.args.onCancel
      ? this.args.onCancel(this.data)
      : this.data && this.data.rollback && this.data.rollback()
  }
}
