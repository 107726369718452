const sectorsForUserTypes = {
  contact: 'PERFORMER',
  agency: 'PERFORMER',
  producer_company: 'PRODUCER'
}

export const isPerformer = activeProfile => activeProfile.profile === 'performer'
export const isProducer = activeProfile => activeProfile.profile === 'personal-producer'

export const isOrganisation = activeProfile => activeProfile.profile === 'contact'

export const isInheritor = activeProfile => activeProfile.profile === 'inheritance-party'

export const isAgency = activeProfile =>
  activeProfile.profile === 'contact' && activeProfile.type === 'agency'

export const sectorForUser = activeProfile => {
  if (isPerformer(activeProfile)) return 'PERFORMER'
  if (isProducer(activeProfile)) return 'PRODUCER'
  if (isInheritor(activeProfile)) return 'INHERITOR'
  return sectorsForUserTypes[activeProfile.type] || 'PERFORMER'
}
