import Ember from 'ember'
import moment from 'moment'

const DEFAULT_FRACTION_DIGITS = 2

/**
 * Handlebar wrapper for {@link Number#toLocaleString}
 *
 * Usage:
 * {{to-locale-string 3.145 minimumFractionDigits=1}}
 * => "3,1"
 * {{to-locale-string 3.145 style='currency' currency='NOK' minimumFractionDigits=2}}
 * => "kr 3,15"
 * {{to-locale-string 1600.56 withFractionCap=1000}}
 * => "1600"
 *
 */
export function toLocaleString([number, ..._rest], options) {
  if (isNaN(number)) {
    return number
  }

  const float = parseFloat(number)
  const defaultOptions = {}
  if ('withFractionCap' in options && float >= options.withFractionCap) {
    defaultOptions.maximumFractionDigits = 0
  } else {
    defaultOptions.minimumFractionDigits = DEFAULT_FRACTION_DIGITS
  }

  return float.toLocaleString(moment.locale(), Ember.assign(defaultOptions, options))
}

export default Ember.Helper.helper(toLocaleString)
