import Controller from '@ember/controller'
import { inject } from '@ember/service'
import { computed, get } from '@ember/object'
import { reads, not, and, empty } from '@ember/object/computed'

export default Controller.extend({
  profile: inject(),
  session: inject(),
  features: inject(),

  isAgencyAgreementActive: reads('profile.isAgencyAgreementActive'),
  impersonatingUser: reads('profile.impersonatedUserId'),
  notImpersonatingUser: not('impersonatingUser'),
  isAlive: empty('model.person.yearOfDeath'),
  isDead: not('isAlive'),
  hasInactiveProfilesWeCanSwitchTo: computed('isAlive', 'profile.inactiveProfiles', function () {
    if (!this.isAlive) return false

    const inactiveSelectableProfiles = this.profile.inactiveProfiles.filter(
      p => !p.isAgencyAgreementActive
    )

    return inactiveSelectableProfiles.length > 0
  }),
  inactiveUser: reads('profile.inactiveUser'),
  isAccessRestricted: and('notImpersonatingUser', 'inactiveUser'),

  // add class to style the menu differently for the new menu. removed this when the feature is enabled globally
  pullClass: computed('this.features.newMenu', function () {
    if (this.features.get('newMenu')) {
      return 'new-menu'
    }
    return ''
  }),

  errorBox: computed('isDead', function () {
    const deadPersonError = {
      headerTranslationKey: 'profile-dashboard.errors.dead_person_header',
      contentTranslationKey: 'profile-dashboard.errors.dead_person_description'
    }

    const activeAgencyAgreementError = {
      headerTranslationKey: 'profile-dashboard.errors.profile_with_agency_header',
      contentTranslationKey: 'profile-dashboard.errors.profile_with_agency_description'
    }
    return this.isDead ? deadPersonError : activeAgencyAgreementError
  }),

  errorBoxButton: computed('hasInactiveProfilesWeCanSwitchTo', function () {
    const cancelButton = {
      action: 'switchProfile',
      style: 'secondary',
      translationKey: 'cancel'
    }

    const logoutButton = {
      action: 'logout',
      style: 'primary',
      translationKey: 'main-menu.logout'
    }

    return get(this, 'hasInactiveProfilesWeCanSwitchTo') ? cancelButton : logoutButton
  })
})
