import Component from '@glimmer/component'
import { tracked } from '@glimmer/tracking'
import { action, computed } from '@ember/object'
import Ember from 'ember'

/**
 * This where taken from bootstrap, since we are using bootstrap's grid system in this app, you can read
 * more here https://getbootstrap.com/docs/4.0/layout/grid/#grid-tiers
 * @type {{MD: number, SM: number, XS: number, LG: number}}
 */
export const BREAK_POINTS = {
  XS: 0,
  SM: 480,
  MD: 768,
  LG: 1024
}

export default class extends Component {
  @tracked windowInnerWidth = window.innerWidth

  get breakPoints() {
    return BREAK_POINTS
  }

  @computed('windowInnerWidth')
  get isMobileBreakPoint() {
    return this.windowInnerWidth <= BREAK_POINTS.MD
  }

  @computed('windowInnerWidth')
  get isDesktopBreakPoint() {
    return this.windowInnerWidth > BREAK_POINTS.MD
  }

  @action
  registerListener() {
    window.addEventListener('resize', this.handleResize)
  }

  @action
  unregisterListener() {
    window.removeEventListener('resize', this.handleResize)
  }

  @action
  handleResize(e) {
    if (Ember.testing) {
      this.windowInnerWidth = e.innerWidth
    } else {
      this.windowInnerWidth = e.target.innerWidth
    }
  }
}
