import Route from '@ember/routing/route'
import { inject } from '@ember/service'
import { queryManager } from 'ember-apollo-client'
import query from 'min-side/graphql/queries/edit-release/release-by-id'
import { prepareReleaseObject } from 'min-side/extractors/edit-release/info'
import { prepareTracks } from 'min-side/extractors/edit-release/tracks'

export default Route.extend({
  apollo: queryManager(),
  queryParams: inject(),
  pageTitle: inject(),

  model({ id }) {
    const variables = { id }
    return this.apollo.query({ query, variables, fetchPolicy: 'network-only' }).then(model => {
      this.pageTitle.setVariables({ title: model.release.title })
      return prepareReleaseObject({ ...model, release: { ...prepareTracks(model.release), id } })
    })
  },
  setupController(controller, model) {
    controller.setProperties({
      release: model.release,
      genres: model.genres,
      instruments: model.instruments
    })
  },
  actions: {
    willTransition() {
      this.refresh()
    }
  }
})
