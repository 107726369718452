import Component from '@glimmer/component'
import { tracked } from '@glimmer/tracking'
import { action } from '@ember/object'

export const availableInIconSet = [
  'pencil',
  'language',
  'logout',
  'calendar',
  'account',
  'address',
  'album-label',
  'album',
  'producer',
  'profile',
  'artist',
  'performer',
  'recording',
  'notes',
  'arrow-back',
  'carret_down',
  'checkmark',
  'close',
  'down',
  'info',
  'menu',
  'switch',
  'lock',
  'show',
  'altok2',
  'no2',
  'nerd',
  'yay2',
  'altok',
  'yay',
  'bank',
  'paperplane',
  'no',
  'quill',
  'pagination-previous',
  'pagination-next'
]

export default class Icon extends Component {
  @tracked type = null

  get availableAsIconInFont() {
    return availableInIconSet.includes(this.args.type)
  }

  /**
   * We are replacing image with svg element in order to be able to
   * style the svg with css.
   *
   * @param {DOM element} image
   */
  @action
  async replaceWithSvg(image) {
    try {
      const data = await fetch(image.src)
      const parser = new DOMParser()
      const svg = parser.parseFromString(await data.text(), 'image/svg+xml').querySelector('svg')

      if (!svg) {
        throw new Error(`Failed to load SVG from img. Type was: '${this.type}'`)
      }

      svg.id = image.id
      svg.classList = image.classList

      image.parentNode.replaceChild(svg, image)
    } catch (error) {
      // eslint-disable-next-line no-undef
      const logLevel = Ember.testing ? 'warn' : 'error'
      console[logLevel](error)
    }
  }
}
