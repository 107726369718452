import Component from '@ember/component'
import { get, computed } from '@ember/object'
import moment from 'moment'

export default Component.extend({
  tagName: '',
  supportsDataTestProperties: true,

  formattedDuration: computed('recording.duration_in_seconds', function () {
    const durationInSeconds = get(this, 'recording.duration_in_seconds')

    return moment
      .utc(moment.duration(durationInSeconds, 'seconds').asMilliseconds())
      .format('HH:mm:ss')
  }),

  actions: {
    addRecording() {
      this.addRecordingAction(this.recording)
    }
  }
})
