/**
 * Maps a keyboard browser event to a constant more human readable
 *
 * @param  {Event}          event   The event
 * @return {String | false} Either a String, like 'arrowUp' or false if keyCode was unkown
 */
export function mapToKey(event) {
  let key =
    {
      8: 'backspace',
      9: 'tab',
      13: 'enter',
      27: 'esc',
      38: 'arrowUp',
      40: 'arrowDown'
    }[event.keyCode] || false
  if (key) {
    const cmd = event.metaKey ? 'cmd-' : ''
    const alt = event.altKey ? 'alt-' : ''
    const ctrl = event.ctrlKey ? 'ctrl-' : ''
    const shift = event.shiftKey ? 'shift-' : ''
    key = `${cmd}${alt}${ctrl}${shift}${key}`
  }
  return key
}
