import isEmpty from 'lodash/isEmpty'

const preparePerformance = performer => {
  return {
    performer_id: performer.get('id'),
    role_codes: [performer.get('role_code')],
    instrument_code: performer.get('instrument_code')
  }
}

export const preparePerformancesInput = performers => {
  return performers.map(performer => {
    return preparePerformance(performer)
  })
}

export const getPerformancesChangeRequest = performancesChangeRequests => {
  if (isEmpty(performancesChangeRequests.edges)) return []
  return performancesChangeRequests.edges.map(edge => edge.node.changes[0])
}
