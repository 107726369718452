import Component from '@ember/component'
import { inject } from '@ember/service'

export default Component.extend({
  'data-test-dashboard-common-others-content': true,
  isPerformer: false,
  isContact: false,
  isPersonalProducer: false,
  isProducerCompany: false,
  discographyModel: null,
  features: inject(),
  router: inject(),

  // eslint-disable-next-line no-empty-function
  globalSearchAction() {}
})
