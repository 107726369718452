import Component from '@ember/component'
import { computed } from '@ember/object'
import { rangeWithDots, SEPARATOR_CONTENT } from 'min-side/helpers/range-with-dots'

export default Component.extend({
  'data-test-numeric-pagination': true,
  tagName: 'nav',
  classNames: ['text--right'],
  rangeWithDotsDelta: 1,

  numPages: computed('perPage', 'totalItemsCount', function () {
    return Math.ceil(this.totalItemsCount / this.perPage)
  }),
  rangeWithDots: computed('numPages', 'currentPage', 'rangeWithDotsDelta', function () {
    return rangeWithDots([this.currentPage, this.numPages, this.rangeWithDotsDelta])
  }),

  actions: {
    onClick(targetPage) {
      if (targetPage === SEPARATOR_CONTENT) return false
      if (targetPage === this.currentPage) return false
      this.onPageClick(targetPage)
    }
  }
})
