import LocalPhoneInput from 'ember-phone-input/components/phone-input'

/**
 * This is necessary for adding the disabled attribute to attribute bindings allowing us to disabled
 * phone input.
 * If this PR (https://github.com/qonto/ember-phone-input/pull/144) get's merged we could get rid of this local
 * extension.
 */
export default LocalPhoneInput.extend({
  attributeBindings: ['type', 'disabled'],
  classNames: ['form__input', 'form__input--block']
})
