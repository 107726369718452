import Ember from 'ember'
import Component from '@ember/component'
import { reads } from '@ember/object/computed'
import { inject } from '@ember/service'
import lookupValidator from 'ember-changeset-validations'
import RecordingInformationValidations from 'min-side/validations/edit-recording/recording-information'
import MainArtistSuggestionQuery from 'min-side/graphql/queries/edit-recording/main-artist-suggestions'
import SearchRecordLabelsQuery from 'min-side/graphql/queries/edit-recording/search-record-labels'
import UpdateRecordingInfoMutation from 'min-side/graphql/mutations/edit-recording/update-recording-information'
import CountriesQuery from 'min-side/graphql/queries/countries'
import Changeset from 'ember-changeset'
import { task, timeout } from 'ember-concurrency'
import { queryManager } from 'ember-apollo-client'
import RemapValidationErrors from 'min-side/mixins/remap-validation-errors'
import moment from 'moment'
import { computed } from '@ember/object'
import { prepareRecordingInputType } from 'min-side/extractors/edit-recording/info'
import { DEBOUNCE_MS } from 'min-side/constants/time'
import EmberObject from '@ember/object'
import ErrorFormatter from 'min-side/mixins/error-formater'

export default Component.extend(RemapValidationErrors, ErrorFormatter, {
  tagName: 'section',
  elementId: 'recording-info',
  'data-test-edit-recording-info': true,
  intl: inject(),
  apollo: queryManager(),

  disabled: true,
  countries: reads('fetchCountriesTask.lastSuccessful.value'),
  changeset: null,

  init(...args) {
    this._super(...args)
    this.changeset = new Changeset(
      this.recording,
      lookupValidator(RecordingInformationValidations),
      RecordingInformationValidations
    )

    this.setProperties({
      errors: { errors: [] }
    })
    if (!Ember.testing) {
      this.fetchCountriesTask.perform()
    }
  },

  producedInCountry: computed('changeset.change.produced_in', 'recording.produced_in', function () {
    return this.get('changeset.change.produced_in') || this.get('recording.produced_in')
  }),

  recordedInCountry: computed('changeset.recorded_in', 'recording.recorded_in', function () {
    return this.get('changeset.recorded_in') || this.get('recording.recorded_in')
  }),

  selectedLabel: computed('changeset.record_label', 'recording.record_label', function () {
    return { name: this.get('changeset.record_label') }
  }),

  rejectEmptyAlternativeTitles() {
    this.set(
      'changeset.alternative_titles',
      this.get('changeset.alternative_titles').filter(t => t.title)
    )
  },

  actions: {
    durationChanged(value) {
      this.set('changeset.duration_hms', value)
      this.set('changeset.duration_in_seconds', moment.duration(`00:${value}`).asSeconds())
    },

    async save() {
      this.rejectEmptyAlternativeTitles()
      await this.changeset.validate()

      if (this.get('changeset.isValid')) {
        await this.changeset.save()
        const recording = prepareRecordingInputType(this.recording)
        this.getWithDefault('onSave', function () {
          // No idea why this is here. Seems like it is used in a test.
          // By why, if I'm correct, do we have code like this to please
          // a test.
        })(recording)
        const variables = {
          input: {
            recording,
            id: this.get('recording.id')
          }
        }

        try {
          const {
            update_recording_information: { errors }
          } = await this.apollo.mutate({
            variables,
            mutation: UpdateRecordingInfoMutation
          })
          if (errors) {
            this.get('flashMessages').error(this.get('intl').t('flash.save.invalid'))
            this.set('errors.errors', this.formatErrors(errors))
          } else {
            this.flashMessages.success(this.intl.t('flash.save.success'))
            this.set('disabled', true)
          }
        } catch (e) {
          this.flashMessages.error(this.intl.t('flash.save.failed'))
        }
      } else {
        this._mapValidationErrors()
        this.flashMessages.error(this.intl.t('flash.save.invalid'))
      }
    },

    edit() {
      this.set('disabled', false)
    },

    rollback() {
      this.set('errors.errors', null)
      this.rollbackAction(this.changeset)
    },

    updateSearchableField(fieldName, { name }) {
      this.changeset.set(fieldName, name)
    },

    updateLabel(recordLabel) {
      const name = recordLabel && recordLabel.name
      this.changeset.set('record_label', name)
    },

    changeDuration(value) {
      this.set('changeset.duration_in_seconds', value)
    },

    changeRecordingDates(input, value) {
      const changeset = this.changeset
      changeset.set(input, moment(value[0]).format('YYYY-MM-DD'))
    },

    cancelSearchResult() {
      this.fetchArtistsCollectionTask.cancelAll()
    }
  },

  fetchArtistsCollectionTask: task(function* (query) {
    yield timeout(DEBOUNCE_MS)
    const { main_artist_name_suggestions } = yield this.apollo.watchQuery({
      query: MainArtistSuggestionQuery,
      variables: { query }
    })

    return main_artist_name_suggestions.map(suggestion => ({
      name: suggestion
    }))
  })
    .maxConcurrency(1)
    .restartable(),

  fetchRecordLabelsTask: task(function* (query) {
    yield timeout(DEBOUNCE_MS)
    const { search_record_labels } = yield this.apollo.watchQuery({
      query: SearchRecordLabelsQuery,
      variables: { query }
    })
    return (search_record_labels.edges || []).map(edge => edge.node)
  })
    .maxConcurrency(1)
    .restartable(),

  fetchCountriesTask: task(function* () {
    const { countries } = yield this.apollo.watchQuery({
      query: CountriesQuery
    })

    return countries
  }),

  async rollbackAction(changeset) {
    changeset.rollback()
    this.setProperties({
      disabled: true
    })
  },

  _mapValidationErrors() {
    const errors = []
    this.get('changeset.errors').forEach(error => {
      errors.push(
        EmberObject.extend({
          attribute: error.key,
          message: this.intl.t('validation.empty')
        }).create()
      )
    })
    this.set('errors', { errors })
  }
})
