import Component from '@glimmer/component'
import { inject as service } from '@ember/service'
import { action } from '@ember/object'
import { tracked } from '@glimmer/tracking'

export default class DocumentsDashlet extends Component {
  @service documentSigning

  @tracked
  loading = false

  @action
  async sign(party, person) {
    this.loading = true
    await this.documentSigning.sign(party, person.id)
    this.loading = false
  }
}
