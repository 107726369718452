import Component from '@ember/component'
import { computed } from '@ember/object'

export default Component.extend({
  tagName: 'table',
  classNames: ['table', 'table--fluid', 'table--list', 'table--four', 'no-pull'],
  'data-test-edit-recording-ownership-file-list': true,

  translationKeys: computed(() => [
    'edit_recording.ownership.file_list.filename',
    'edit_recording.ownership.file_list.date',
    'edit_recording.ownership.file_list.comment'
  ])
})
