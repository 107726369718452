export default {
  "discography-switcher": "_discography-switcher_k446op",
  "prefix": "_prefix_k446op",
  "switcher-link": "_switcher-link_k446op",
  "icon": "_icon_k446op",
  "my-discography": "_my-discography_k446op",
  "gramo-discography": "_gramo-discography_k446op",
  "tabs-navigation": "_tabs-navigation_k446op",
  "tabs-navigation-bar": "_tabs-navigation-bar_k446op",
  "tabs-navigation-text": "_tabs-navigation-text_k446op"
};
