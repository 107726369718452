import Controller from '@ember/controller'
import { inject } from '@ember/service'
import EmberObject from '@ember/object'
import { computed } from '@ember/object'
import CreateClaimRecording from 'min-side/graphql/mutations/claims/create_claim_recordings'
import UpdateClaimRecording from 'min-side/graphql/mutations/claims/update_claim_recording'
import { sectorForUser } from 'min-side/helpers/user-types'

export default Controller.extend({
  profile: inject(),
  session: inject(),
  features: inject(),
  intl: inject(),
  apollo: inject(),
  router: inject(),

  errors: [],

  step: 'release',

  queryParams: ['single', 'existing'],
  single: false,
  existing: '',

  sector: computed('profile.activeProfile', function () {
    return sectorForUser(this.get('profile.activeProfile'))
  }),

  showRemoveRecording: computed('model.recordings.length', function () {
    return this.get('model.recordings.length') > 1
  }),
  showRemovePerformance: computed('model.performances.length', function () {
    return this.get('model.performances.length') > 1
  }),
  showBackButton: computed('step', function () {
    // the back button starts at recordings for single tracks
    if (this.get('single') && this.get('activeStep') === 'recordings') {
      return false
    }
    return this.get('step') !== 'release'
  }),

  activeStep: computed('step', function () {
    if (this.get('single')) {
      if (this.get('step') === 'release') {
        return 'recordings'
      }
    }
    return this.get('step')
  }),

  addRecording() {
    this.model.recordings.pushObject(
      EmberObject.create({
        release_year: this.get('model.release.release_year'),
        title: '',
        isrc: '',
        errors: []
      })
    )
  },

  addPerformance() {
    this.model.performances.pushObject(
      EmberObject.create({
        performer: null,
        role_code: null,
        errors: []
      })
    )
  },

  addError(attribute, message) {
    this.errors.pushObject(EmberObject.create({ attribute, message }))
  },

  validatePerformances(performances) {
    let hasErrors = false

    performances.forEach((performance, index) => {
      performance.set('errors', [])
      // the performer component expects the attribute to be a string with the index of the performance
      if (!performance.role_code) {
        performance.errors.pushObject({
          attribute: `performances/${index}/role_code`,
          message: this.intl.t('discography.register.errors.role-code')
        })
        hasErrors = true
      }
      if (!performance.performer_name) {
        performance.errors.pushObject({
          attribute: 'performer_required',
          message: this.intl.t('discography.register.errors.performer-name')
        })
        hasErrors = true
      }
    })

    return hasErrors
  },

  validateOwnership(ownership) {
    let hasErrors = false

    ownership.set('errors', [])

    if (!ownership.share) {
      ownership.errors.pushObject({
        attribute: 'share',
        message: this.intl.t('discography.register.errors.role-code')
      })
      hasErrors = true
    }
    if (!ownership.producer_name) {
      ownership.errors.pushObject({
        attribute: 'performer_required',
        message: this.intl.t('discography.register.errors.performer-name')
      })
      hasErrors = true
    }

    return hasErrors
  },

  actions: {
    addRecording() {
      this.addRecording()
    },
    removeRecording(recording) {
      if (this.model.recordings.length > 1) {
        this.model.recordings.removeObject(recording)
      }
    },
    addPerformance() {
      this.addPerformance()
    },
    removePerformance(performance) {
      if (this.model.performances.length > 1) {
        this.model.performances.removeObject(performance)
      }
    },
    addOwnership() {
      this.addOwnership()
    },
    removeOwnership(ownership) {
      if (this.model.ownerships.length > 1) {
        this.model.ownerships.removeObject(ownership)
      }
    },
    async save() {
      this.set('errors', [])
      // call the mutation and handle errors
      // if there are no errors, redirect to the registered page
      const performances = this.get('model.performances')?.map(performance => {
        return {
          performer_id: performance.performer_id,
          performer_name: performance.performer_name,
          role_code: performance.role_code
        }
      })
      const ownerships = [
        {
          start_date: this.get('model.ownership.start_date'),
          producer_name: this.get('model.ownership.producer_name'),
          producer_id: this.get('model.ownership.producer_id'),
          share: parseInt(this.get('model.ownership.share'), 10)
        }
      ]

      const recordings = this.get('model.recordings').map(recording => {
        const recordingData = {
          title: recording.title,
          isrc: recording.isrc,
          main_artist: recording.main_artist,
          release_year: parseInt(recording.release_year, 10),
          album: this.get('model.release.title'),
          label_name: this.get('model.release.label_name'),
          catalog_number: this.get('model.release.catalog_number')
        }

        if (this.get('sector') === 'PERFORMER') {
          recordingData.performances = performances
        }
        if (this.get('sector') === 'PRODUCER') {
          recordingData.ownerships = ownerships
        }

        return recordingData
      })

      let variables = {}
      if (this.get('existing')) {
        // when editing a registration we always have just one recording
        const recording = this.get('model.recordings.firstObject')

        const recordingData = {
          title: recording.title,
          isrc: recording.isrc,
          main_artist: recording.main_artist,
          release_year: parseInt(recording.release_year, 10),
          album: this.get('model.release.title'),
          label_name: this.get('model.release.label_name'),
          catalog_number: this.get('model.release.catalog_number')
        }

        if (this.get('sector') === 'PERFORMER') {
          recordingData.performances = performances
        }
        if (this.get('sector') === 'PRODUCER') {
          recordingData.ownerships = ownerships
        }

        variables = {
          input: {
            id: this.get('existing'),
            claim_recording: recordingData
          }
        }
      } else {
        variables = {
          input: {
            claim_recordings: recordings,
            sector: sectorForUser(this.get('profile.activeProfile')),
            owner_id: this.get('profile.activeProfile.id')
          }
        }
      }

      try {
        let response = null
        let errors = null
        if (this.get('existing')) {
          response = await this.apollo.mutate({
            variables,
            mutation: UpdateClaimRecording
          })
          errors = response.update_claim_recording.errors
        } else {
          response = await this.apollo.mutate({
            variables,
            mutation: CreateClaimRecording
          })
          errors = response.create_claim_recordings.errors
        }

        // if there are no errors redirect to the registered recordings page
        if (errors) {
          this.get('flashMessages').error(this.get('intl').t('flash.save.failed'))
        } else {
          this.get('flashMessages').success(this.get('intl').t('flash.save.success'))
          this.set('step', 'release') // set state back to release because ember rememebers the previous state
          this.get('router').transitionTo('user.recordings')
        }
      } catch (_e) {
        this.get('flashMessages').error(this.get('intl').t('flash.save.failed'))
      }
    },
    next() {
      if (this.get('activeStep') === 'release') {
        this.set('errors', [])
        if (!this.get('model.release.title')) {
          this.addError('title', this.intl.t('discography.register.errors.title'))
          return
        }
        if (!this.get('model.release.release_year')) {
          this.addError('release_year', this.intl.t('discography.register.errors.release-year'))
          return
        } else if (!this.get('model.release.release_year').match(/^[0-9]{4}$/i)) {
          this.addError(
            'release_year',
            this.intl.t('discography.register.errors.release-year-invalid')
          )
          return
        }
        this.get('model.recordings').forEach(recording => {
          if (!recording.release_year && this.get('model.release.release_year')) {
            recording.set('release_year', this.get('model.release.release_year'))
          }
        })
        this.set('step', 'recordings')
      } else if (this.get('activeStep') === 'recordings') {
        // validate recordings
        let hasErrors = false
        this.get('model.recordings').forEach(recording => {
          recording.set('errors', [])
          if (!recording.title) {
            recording.errors.pushObject({
              attribute: 'title',
              message: this.intl.t('discography.register.errors.title')
            })
            hasErrors = true
          }
          if (!recording.isrc) {
            recording.errors.pushObject({
              attribute: 'isrc',
              message: this.intl.t('discography.register.errors.isrc')
            })
            hasErrors = true
          } else if (!recording.isrc.trim().match(/^[A-Z]{2}[A-Z0-9]{3}[0-9]{7}$/i)) {
            recording.errors.pushObject({
              attribute: 'isrc',
              message: this.intl.t('discography.register.errors.isrc-invalid')
            })
            hasErrors = true
          }
          if (!recording.main_artist) {
            recording.errors.pushObject({
              attribute: 'main_artist',
              message: this.intl.t('discography.register.errors.main-artist')
            })
            hasErrors = true
          }
          if (!recording.release_year) {
            recording.errors.pushObject({
              attribute: 'release_year',
              message: this.intl.t('discography.register.errors.release-year')
            })
            hasErrors = true
          } else if (!recording.release_year.match(/^[0-9]{4}$/i)) {
            recording.errors.pushObject({
              attribute: 'release_year',
              message: this.intl.t('discography.register.errors.release-year-invalid')
            })
            hasErrors = true
          }
        })
        if (hasErrors) {
          return
        }

        this.set('step', 'contributions')
      } else if (this.get('activeStep') === 'contributions') {
        let hasErrors = false

        if (this.get('sector') === 'PERFORMER') {
          hasErrors = this.validatePerformances(this.get('model.performances'))
        }

        if (this.get('sector') === 'PRODUCER') {
          hasErrors = this.validateOwnership(this.get('model.ownership'))
        }

        if (hasErrors) {
          return
        }
        this.set('step', 'review')
      }
    },

    back() {
      if (this.get('activeStep') === 'recordings') {
        this.set('step', 'release')
      } else if (this.get('activeStep') === 'contributions') {
        this.set('step', 'recordings')
      } else if (this.get('activeStep') === 'review') {
        this.set('step', 'contributions')
      }
    }
  }
})
