define("min-side/services/css-modules-active-route", ["exports", "ember-css-modules-active-route/services/css-modules-active-route"], function (_exports, _cssModulesActiveRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _cssModulesActiveRoute.default;
    }
  });
});
