import ENV from 'min-side/config/environment'
import { urlFor } from 'min-side/utils/url-helpers'
import { camelize } from '@ember/string'

async function profilesWithEchoFrontUrls(signedIn) {
  const profiles = await signedIn.profiles()
  const personId = (await signedIn.person()).id

  const out = {}

  profiles.forEach(profile => {
    let profileName = camelize(profile.toLowerCase())

    if (profileName === 'inheritanceParty') profileName = 'inheritance' // Well, thats annoying!

    out[`${profileName}Profile`] = urlFor(
      `discography/people/${personId}?profile=${profileName}`,
      'echo',
      ENV.frontend
    )
  })

  return out
}

async function organizationsWithEchoFrontUrls(signedIn) {
  const organizations = await signedIn.organizations()
  const organizationProfileToCompanyType = {
    PRODUCER: 'producer-company',
    AGENCY: 'agency'
  }

  return organizations.map(({ id, name, profile }) => {
    const companyType = organizationProfileToCompanyType[profile]

    const echo_url = companyType
      ? urlFor(`discography/organizations/${companyType}/${id}`, 'echo', ENV.frontend)
      : null

    return {
      name,
      id,
      echo_url,
      companyType
    }
  })
}

export default async function signedInToIntercomData(signedIn) {
  const { id, name, email, mobile } = await signedIn.person()
  const { createdAt, intercomUserHash } = await signedIn.user()
  const companies = await organizationsWithEchoFrontUrls(signedIn)
  const profileUrls = await profilesWithEchoFrontUrls(signedIn)

  return {
    id,
    name,
    email,
    companies,
    hash: intercomUserHash,
    ...profileUrls,
    phone: mobile,
    createdAt: createdAt.unix()
  }
}
