import Mixin from '@ember/object/mixin'
import { NEW_RELEASE_FORM_STEPS } from 'min-side/constants/new-release-steps'
import { computed } from '@ember/object'
import { reads } from '@ember/object/computed'
import EmberObject from '@ember/object'

const {
  RELEASE_INFO_STEP,
  RECORDINGS_STEP,
  OWNERSHIP_STEP,
  PERFORMERS_STEP,
  REVIEW_STEP
} = NEW_RELEASE_FORM_STEPS

export default Mixin.create({
  currentStep: RELEASE_INFO_STEP,
  nextStep: RELEASE_INFO_STEP,

  showReleaseInformation: reads('steps.showReleaseInformation'),
  showRecordings: reads('steps.showRecordings'),
  showOwnership: reads('steps.showOwnership'),
  showPerformers: reads('steps.showPerformers'),
  showReview: reads('steps.showReview'),

  formEditStatus: computed(() => {
    return [
      EmberObject.create({ step: RELEASE_INFO_STEP, editing: false }),
      EmberObject.create({ step: RECORDINGS_STEP, editing: false }),
      EmberObject.create({ step: PERFORMERS_STEP, editing: false })
    ]
  }),

  isEditing: computed('formEditStatus.@each.editing', function () {
    return this.formEditStatus.some(step => step.get('editing'))
  }),

  isCompleted: computed('nextStep', 'currentStep', function () {
    if (this.nextStep > this.currentStep) {
      return true
    }
    return false
  }),

  steps: computed('currentStep', function () {
    switch (this.currentStep) {
      case RELEASE_INFO_STEP:
        return {
          showReleaseInformation: true,
          showRecordings: false,
          showOwnership: false,
          showPerformers: false,
          showReview: false
        }
      case RECORDINGS_STEP:
        return {
          showReleaseInformation: false,
          showRecordings: true,
          showOwnership: false,
          showPerformers: false,
          showReview: false
        }
      case OWNERSHIP_STEP:
        return {
          showReleaseInformation: false,
          showRecordings: false,
          showOwnership: true,
          showPerformers: false,
          showReview: false
        }
      case PERFORMERS_STEP:
        return {
          showReleaseInformation: false,
          showRecordings: false,
          showOwnership: false,
          showPerformers: true,
          showReview: false
        }
      case REVIEW_STEP:
        return {
          showReleaseInformation: false,
          showRecordings: false,
          showOwnership: false,
          showPerformers: false,
          showReview: true
        }
      default:
        return {
          showReleaseInformation: true,
          showRecordings: false,
          showOwnership: false,
          showPerformers: false,
          showReview: false
        }
    }
  }),

  wizardSteps: computed('currentStep', function () {
    return [
      {
        id: 'user.new-release',
        name: 'molecules.release_form.release.name',
        active: this.nextStep >= RELEASE_INFO_STEP,
        complete: false
      },
      {
        id: 'user.new-release',
        name: 'molecules.release_form.recordings.name',
        active: this.nextStep >= RECORDINGS_STEP,
        complete: false
      },
      {
        id: 'user.new-release',
        name: 'molecules.release_form.ownership.name',
        active: this.nextStep >= OWNERSHIP_STEP,
        complete: false
      },
      {
        id: 'user.new-release',
        name: 'molecules.release_form.performers.name',
        active: this.nextStep >= PERFORMERS_STEP,
        complete: false
      },
      {
        id: 'user.new-release',
        name: 'molecules.release_form.review.name',
        active: this.nextStep >= REVIEW_STEP,
        complete: false
      }
    ]
  }),

  actions: {
    stepForward() {
      this.set('currentStep', this.currentStep + 1)
      this.set('nextStep', this.currentStep)
    },

    stepBack() {
      this.set('currentStep', this.currentStep - 1)
    },

    goto(step, index) {
      if (step.active) {
        this.set('currentStep', index + 1)
      }
    },

    updateFormEditStatus(formStep, editStatus) {
      const stepStatus = this.formEditStatus.find(editStatus => editStatus.step === formStep)
      stepStatus.set('editing', editStatus)
    }
  }
})
