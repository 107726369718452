import Component from '@ember/component'
import { alias, and, not, filter } from '@ember/object/computed'
import { computed, get, getWithDefault } from '@ember/object'
import { inject as service } from '@ember/service'

export default Component.extend({
  'data-test-dashboard-common': true,

  profile: service(),
  onboarding: service(),
  features: service(),
  router: service(),

  person: alias('model.user.person'),

  personName: alias('model.user.person.first_name'),

  missingPerformerBankAccount: computed(
    'person.performer.society_memberships.@each.bank_account',
    function () {
      if (!this.get('person.performer')) {
        return false
      }
      const societyMemberships = this.get('person.performer.society_memberships')
      if (societyMemberships.find(sm => sm.bank_account && sm.active)) {
        return false
      }
      return true
    }
  ),

  missingProducerBankAccount: computed(
    'person.personal_producer.society_memberships.@each.bank_account',
    function () {
      if (!this.get('person.personal_producer')) {
        return false
      }
      const societyMemberships = this.get('person.personal_producer.society_memberships')
      if (societyMemberships.find(sm => sm.bank_account && sm.active)) {
        return false
      }
      return true
    }
  ),

  powerAttorneyParties: filter(
    'person.electronic_signature_parties.edges.@each.node.document_type',
    function (edge) {
      return edge.node.document_type === 'POWER_ATTORNEY'
    }
  ),

  membershipParties: filter(
    'person.electronic_signature_parties.edges.@each.node.document_type',
    function (edge) {
      return edge.node.document_type === 'MEMBERSHIP'
    }
  ),

  organization: computed('person.contact.positions', 'profile.activeProfile.id', function () {
    const organization = getWithDefault(this, 'person.contact.positions', []).find(
      company => get(company, 'organization.id') === get(this, 'profile.activeProfile.id')
    )

    return organization
  }),

  agency: computed('organization', function () {
    const organization = this.get('organization')

    if (!organization) return {}
    return get(organization, 'organization.agency')
  }),

  producer_company: computed('organization', function () {
    const organization = this.get('organization')

    if (!organization) return {}
    return get(organization, 'organization.producer_company')
  }),

  notImpersonating: not('profile.activeProfile.impersonating'),
  hasNotConfirmedDetails: computed(
    'organization',
    'person.details_last_confirmed',
    'agency.details_last_confirmed',
    'producer_company.details_last_confirmed',
    function () {
      if (this.get('organization')) {
        return (
          !this.get('agency.details_last_confirmed') &&
          !this.get('producer_company.details_last_confirmed')
        )
      }
      return !this.get('person.details_last_confirmed')
    }
  ),
  showOnboarding: and('onboarding.showOnboarding', 'hasNotConfirmedDetails', 'notImpersonating'),
  actions: {
    viewDiscography() {
      this.router.transitionTo('user.discography.albums')
    },
    dismissOnboarding() {
      this.onboarding.dismissOnboarding()
    }
  }
})
