import ProfileInfoRouteBankAndMembership from 'min-side/abstracts/routes/profile-info-route-bank-and-membership'
import { getQueryByProfileType } from 'min-side/graphql/helpers/profile-info/queries'
import producerCompanyQuery from 'min-side/graphql/queries/profile/producer-company'
import agencyQuery from 'min-side/graphql/queries/profile/agency'

export default class ProfileInfoBankRoute extends ProfileInfoRouteBankAndMembership {
  async model() {
    if (this.currentProfile === 'contact') {
      if (this.currentProfileType === 'agency') {
        return await this.modelForAgency()
      }
      return await this.modelForContact()
    }

    const impersonatedUserId = this.profileInfo.maskId
    const variables = impersonatedUserId ? { person_id: impersonatedUserId } : null

    const query = getQueryByProfileType(this.currentProfile)
    const model = await this.apollo.watchQuery({ query, variables, fetchPolicy: 'network-only' })

    return this.getCurrentActiveProfileBankAccount(model)
  }

  async modelForAgency() {
    const id = this.profileInfo.activeProfile.id
    const variables = { id }
    const model = await this.apollo.watchQuery({
      query: agencyQuery,
      variables,
      fetchPolicy: 'network-only'
    })

    return this.getCurrentActiveProfileBankAccount(model)
  }

  async modelForContact() {
    const id = this.profileInfo.activeProfile.id
    const variables = { id }

    const model = await this.apollo.watchQuery({
      query: producerCompanyQuery,
      variables,
      fetchPolicy: 'network-only'
    })
    return this.getCurrentActiveProfileBankAccount(model)
  }

  setupController(controller, model) {
    super.setupController(controller, model)
    controller.setupChangeset(model)
  }
}
