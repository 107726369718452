import { inject as service } from '@ember/service'
import Route from '@ember/routing/route'
import { urlFor } from 'min-side/utils/url-helpers'
import { get } from '@ember/object'
import UnauthenticatedRouteMixin from 'ember-simple-auth/mixins/unauthenticated-route-mixin'

const PASSWORD_MINIMUM_LENGTH = 6

export default Route.extend(UnauthenticatedRouteMixin, {
  intl: service(),
  ajaxRequests: service(),
  verify() {
    let valid = true
    const errors = []
    if (!(this.currentModel.password === this.currentModel.passwordConfirmation)) {
      errors.push({
        attribute: 'passwordConfirmation',
        message: this.intl.t('validation.passwords-not-match')
      })
      valid = false
    }
    if (!(this.currentModel.password.length >= PASSWORD_MINIMUM_LENGTH)) {
      errors.push({
        attribute: 'password',
        message: this.intl.t('validation.password-short')
      })
      valid = false
    }
    this.set('currentModel.errors', errors)
    return valid
  },
  model() {
    return {
      errors: []
    }
  },
  actions: {
    cancel() {
      this.transitionTo('login')
    },
    submit() {
      if (!this.verify()) {
        return
      }
      const data = {
        id: this.controller.user_id,
        token: this.controller.token,
        password: this.currentModel.password,
        locale: get(this, 'intl.primaryLocale')
      }
      const ajaxRequests = this.get('ajaxRequests')

      return ajaxRequests
        .postRequest(urlFor('admin/users/reset-password'), data)
        .then(res => {
          if (!res.ok) throw res

          this.flashMessages.success(this.intl.t('flash.save.password-set'))
          this.transitionTo('login')
        })
        .catch(() => {
          const msg = `${this.intl.t('flash.save.invalid')}
            ${this.intl.t('flash.save.session-expired')}`
          this.flashMessages.add({
            message: msg,
            type: 'error',
            sticky: true,
            componentName: 'components/flash-message-with-link',
            linkContent: `${this.intl.t('flash.save.send-new-password')}`,
            linkTo: 'forgot-password'
          })
        })
    }
  }
})
