import { computed } from '@ember/object'
import Component from '@ember/component'

/**
 * Renders default HTML structure wrapping a form field.
 *
 * Supports rendering model-error, if any model is given.
 */
export default Component.extend({
  tagName: '',
  supportsDataTestProperties: true,

  /**
   * The model
   *
   * The model is optional and is passed to other components
   * like model-error. If not given model error isn't rendered at all.
   *
   * @type {EmberData Model}
   */
  model: null,

  /**
   * The attribute name
   *
   * @type {String}
   */
  attribute: null,

  /**
   * Should we render errors for attribute?
   *
   * @type {Boolean}
   */
  showErrors: true,

  /**
   * Should we render label element with block modifier?
   *
   * @type {Boolean}
   */
  block: false,

  /**
   * Should we render label element with required asterisk?
   *
   * @type {Boolean}
   */
  required: false,

  /**
   * Should the label be hidden on desktop?
   *
   * @type {Boolean}
   */
  hiddenLabel: false,

  /**
   * Custom error label class
   *
   * @type {String}
   */
  errorLabelClass: '',

  /**
   * Class name bindings for the input element
   */
  classNameBindingsForInput: computed(
    'inputClass',
    'block',
    'inputWidth',
    'inputDate',
    function () {
      const classes = ['form__input']
      const inputClass = this.inputClass
      const inputWidth = this.inputWidth
      const inputDate = this.inputDate

      if (inputClass) {
        classes.push(inputClass)
      }
      if (this.block) {
        classes.push('form__input--block')
      }
      if (inputWidth) {
        classes.push(`form__input--${inputWidth}`)
      }
      if (inputDate) {
        classes.push('form__input--date')
      }

      return classes.join(' ')
    }
  ),

  /**
   * Class name bindings for the label element
   */
  classNameBindingsForLabel: computed('block', 'errors.length', function () {
    const classes = []

    if (this.block) {
      classes.push('form__label--block')
    }
    if (this.required) {
      classes.push('form__label--required')
    }
    if (this.hiddenLabel) {
      classes.push('form__label--hidden')
    }

    return classes.join(' ')
  })
})
