export const profileNameInModel = profile => {
  if (!profile || !profile.replace) {
    return null
  }
  switch (profile.replace('_', '-')) {
    case 'performer':
      return 'performer'
    case 'personal-producer':
      return 'personal_producer'
    case 'contact':
      return 'contact'
    case 'inheritance-party':
      return 'personal_inheritance_party'
    default:
      return profile.replace('-', '_')
  }
}

export const toProfileEnum = profile => {
  switch (profile.replace('-', '_')) {
    case 'personal_inheritance_party':
      return 'INHERITANCE_PARTY'
    default:
      return profile.toUpperCase().replace('-', '_')
  }
}

export const filterProfiles = profiles => {
  return profiles.filter(p => {
    return !['garnishment_debtor', 'user'].includes(p.toLowerCase())
  })
}
