import Component from '@ember/component'
import { computed } from '@ember/object'

export default Component.extend({
  'data-test-file-list-trigger': true,

  filesAmount: computed('files', function () {
    return this.files ? this.files.length : '0'
  })
})
