import Controller from '@ember/controller'
import { computed, get, getWithDefault } from '@ember/object'
import { alias } from '@ember/object/computed'
import { inject as service } from '@ember/service'
import { filterProfiles, profileNameInModel } from 'min-side/helpers/profiles'
import { dasherize } from '@ember/string'
import moment from 'moment'
import { action } from '@ember/object'

const root = 'user.profile-info'

export default class ProfileInfoController extends Controller {
  @service router
  @service intl
  @service profileInfo

  @alias('model.user.person') person
  @alias('router.currentRouteName') currentRoute

  @computed('intl.locale')
  get rawProfileLinks() {
    return [
      {
        groupName: this.intl.t('profile-info.left-side-menu.categories.personal'),
        options: [
          {
            link: {
              title: this.intl.t('personal-information.personal-info'),
              description: this.intl.t('profile-info.headers.personal-info-description'),
              route: `${root}.personal`,
              icon: 'icon-account'
            },
            canAccess: [
              'performer',
              'personal-producer',
              'inheritance-party',
              'contact:producer_company',
              'contact:agency'
            ]
          },
          {
            link: {
              title: this.intl.t('personal-information.contact-info'),
              description: this.intl.t('profile-info.headers.contact-info-description'),
              route: `${root}.contact`,
              icon: 'icon-contact'
            },
            canAccess: [
              'performer',
              'personal-producer',
              'inheritance-party',
              'contact:producer_company',
              'contact:agency'
            ]
          },
          {
            link: {
              title: this.intl.t('personal-information.tax-reports'),
              description: this.intl.t('profile-info.headers.tax-reports-description'),
              route: `${root}.tax-reports`,
              icon: 'icon-tax'
            },
            canAccess: [
              'performer',
              'personal-producer',
              'inheritance-party',
              'contact:producer_company',
              'contact:agency'
            ]
          },
          {
            link: {
              title: this.intl.t('profile-info.headers.profile'),
              description: this.intl.t('profile-info.headers.profile-description'),
              route: `${root}.profile`,
              icon: 'icon-profile'
            },
            canAccess: [
              'performer',
              'personal-producer',
              'inheritance-party',
              'contact:producer_company',
              'contact:agency'
            ]
          },
          {
            link: {
              title: this.intl.t('profile-info.headers.bank-info'),
              description: this.intl.t('profile-info.headers.bank-info-description'),
              route: `${root}.bank`,
              icon: 'icon-bank-details'
            },
            canAccess: ['performer', 'personal-producer', 'inheritance-party']
          },
          {
            link: {
              title: this.intl.t('profile-info.headers.documents-info'),
              description: this.intl.t('profile-info.headers.documents-description'),
              route: `${root}.documents`,
              icon: 'icon-folder'
            },
            canAccess: ['performer', 'personal-producer', 'contact:producer_company']
          }
        ]
      },
      {
        groupName: this.intl.t('profile-info.left-side-menu.categories.organization'),
        options: [
          {
            link: {
              title: this.intl.t('profile-info.headers.organization-info'),
              description: this.intl.t('profile-info.headers.organization-info-description'),
              route: `${root}.org-info`,
              icon: 'icon-membership'
            },
            canAccess: ['contact:producer_company', 'contact:agency']
          },
          {
            link: {
              title: this.intl.t('profile-info.headers.bank-info'),
              description: this.intl.t('profile-info.headers.bank-info-description'),
              route: `${root}.bank`,
              icon: 'icon-bank-details'
            },
            canAccess: ['contact:producer_company', 'contact:agency']
          },
          {
            link: {
              title: this.intl.t('profile-info.headers.membership-info'),
              description: this.intl.t('profile-info.headers.membership-info-description'),
              route: `${root}.membership`,
              icon: 'icon-membership'
            },
            canAccess: [
              'performer',
              'personal-producer',
              'inheritance-party',
              'contact:producer_company'
            ]
          },
          {
            link: {
              title: this.intl.t('profile-info.headers.registrant-codes-info'),
              description: this.intl.t('profile-info.headers.registrant-codes-description'),
              route: `${root}.registrant-codes`,
              icon: 'icon-registrant-code'
            },
            canAccess: ['personal-producer', 'contact:producer_company']
          }
        ]
      }
    ]
  }

  @computed('profileInfo.currentProfileName')
  get shouldRenderCategoryName() {
    return this.profileInfo.currentProfileName === 'contact'
  }

  @computed('currentRouteNameWithoutLoadingRoute')
  get currentSelectedMenuItem() {
    const findFunction = navItem => navItem.route === this.currentRouteNameWithoutLoadingRoute
    return this.profileLinks.flatMap(item => item.options).find(findFunction)
  }

  @computed('router.currentRouteName')
  get currentRouteNameWithoutLoadingRoute() {
    let currentRoute = this.router.currentRouteName
    const loadingPartInRouteName = '_loading'
    if (currentRoute.includes(loadingPartInRouteName)) {
      currentRoute = currentRoute.substring(0, currentRoute.length - loadingPartInRouteName.length)
    }
    return currentRoute
  }

  @computed('profileInfo.currentProfileName', 'profileInfo.currentProfileType')
  get profileLinks() {
    // profile name for contact looks like "contact:producer_company" or "contact:agency"
    const profileName = this.profileInfo.currentProfileType
      ? `${this.profileInfo.currentProfileName}:${this.profileInfo.currentProfileType}`
      : this.profileInfo.currentProfileName

    return this.rawProfileLinks.map(category => {
      return {
        ...category,
        options: category.options
          .filter(({ canAccess }) => canAccess.includes(profileName))
          .map(({ link }) => link)
      }
    })
  }

  @computed('profileInfo.currentProfileName', 'profileInfo.currentProfileType')
  get getFirstAvailableLinkForTheCurrentActiveProfile() {
    return this.profileLinks[0].options[0]
  }

  @computed('person.{first_name,last_name}')
  get name() {
    const { first_name, last_name } = this.person
    return `${first_name} ${last_name}`
  }

  @computed('profileInfo.activeProfile')
  get groups() {
    const groups = get(this, 'person.performer.group_memberships') || []
    return groups.map(({ group }) => group.name).join(', ')
  }

  @computed('person.profiles', 'intl.locale')
  get profiles() {
    return filterProfiles(get(this, 'person.profiles'))
      .map(profile => this.intl.t(`profiles.${dasherize(profile)}`))
      .join(', ')
  }

  @computed(
    'person.{contact,performer,personal_producer,personal_inheritance_party}.gramo_membership_number'
  )
  get gramoMembershipNumber() {
    const activeProfile = this.profileInfo.currentProfileName
    if (!activeProfile) return false
    return get(this, `person.${profileNameInModel(activeProfile)}.gramo_membership_number`) || ' - '
  }

  @computed('person.{born_on,current_citizenships}', 'gramoMembershipNumber', 'intl.locale')
  get metaFields() {
    if (!this.gramoMembershipNumber) return []

    const membershipNumber = this.gramoMembershipNumber

    const bornOn = get(this, 'person.born_on')
      ? moment(get(this, 'person.born_on')).format('L')
      : ' - '

    const currentCitizenships =
      getWithDefault(this, 'person.current_citizenships', [])
        .map(({ country }) => country.name)
        .join(', ') || ' - '

    return [
      {
        title: this.intl.t('personal-information.membership-number'),
        value: membershipNumber
      },
      {
        title: this.intl.t('personal-information.born-on'),
        value: bornOn
      },
      {
        title: this.intl.t('personal-information.citizenship'),
        value: currentCitizenships
      }
    ]
  }

  @action
  select(selected) {
    this.router.transitionTo(selected)
  }
}
