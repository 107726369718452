import Controller from '@ember/controller'
import { action } from '@ember/object'
export default class IndexLoadingController extends Controller {
  @action
  setParam() {
    return null
  }

  @action
  clearRangeParams() {
    return null
  }
}
