import Component from '@ember/component'
import { computed } from '@ember/object'
import { reads } from '@ember/object/computed'
import { inject } from '@ember/service'
import { PROFILE_TYPES } from 'min-side/constants/profile'

export default Component.extend({
  profile: inject(),
  router: inject(),

  tagName: 'tr',

  testator: reads('entry.ledger.owner'),

  amount: computed('entry.amount', function () {
    return Math.abs(this.get('entry.amount'))
  }),

  name: computed('testator.person', function () {
    const testatorPerson = this.get('testator.person')
    return `${testatorPerson.first_name} ${testatorPerson.last_name}`
  }),

  sectorTranslationKey: computed('testator.typename', function () {
    return this.get('testator.typename') === 'Performer' ? 'sectors.performer' : 'sectors.producer'
  }),

  actions: {
    async impersonate() {
      const {
        id: personId,
        person: { first_name: firstName, last_name: lastName },
        typename
      } = this.testator

      const testatorType =
        typename === 'PersonalProducer' ? PROFILE_TYPES.PERSONAL_PRODUCER : PROFILE_TYPES.PERFORMER

      await this.profile.impersonate(personId, firstName, lastName, testatorType)
      this.router.transitionTo('user.ledger')
    }
  }
})
