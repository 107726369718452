import { get, getWithDefault } from '@ember/object'

const _getRawDebitorsForLedgerEntry = ledgerEntry => {
  const debitors = getWithDefault(ledgerEntry, 'debitors.edges', []).map(edge => get(edge, 'node'))

  return debitors || []
}

const _getOffsetEntryForPerson = (ledgerEntry, personId) => {
  const offsetEntry = getWithDefault(ledgerEntry, 'offset_entries.edges', []).find(
    ledgerEntry => get(ledgerEntry, 'node.ledger.owner.id') === personId
  )

  return offsetEntry || {}
}

const getAgreementsForPrincipal = (principal, correctPosition, agreementType) => {
  const agreements = getWithDefault(
    correctPosition,
    `organization.agency.${agreementType}_agency_agreements`,
    []
  )
  const agreement = agreements.findBy('principal_id', principal.id)
  return Object.assign({}, principal, { agreement })
}

export const getCorrectPosition = (payload, agencyId) => {
  const positions = get(payload, 'user.person.contact.positions').filter(obj =>
    get(obj, 'organization.agency')
  )
  if (!positions.length) return null

  const correctPosition = positions.filter(pos => get(pos, 'organization.agency.id') === agencyId)

  if (!correctPosition.length) return null
  return correctPosition[0]
}

export const getPageInfo = (payload, agencyId, agreementType) => {
  const position = getCorrectPosition(payload, agencyId)
  if (!position) {
    return {}
  }
  return get(position, `organization.agency.clients_${agreementType}s.pageInfo`)
}

export const getTotalAmount = (payload, agencyId, agreementType) => {
  const position = getCorrectPosition(payload, agencyId)
  if (!position) {
    return {}
  }
  return get(position, `organization.agency.clients_${agreementType}s.total`)
}

export const getAgreementsFragment = (payload, agencyId, agreementType) => {
  const correctPosition = getCorrectPosition(payload, agencyId)
  if (!correctPosition) return null

  const personEdges = getWithDefault(
    correctPosition,
    `organization.agency.clients_${agreementType}s.edges`,
    []
  )

  return personEdges
    .map(edge => get(edge, 'node'))
    .map(principal => getAgreementsForPrincipal(principal, correctPosition, agreementType))
}

export const getLedgerEntry = (payload, id) => {
  const entry = getWithDefault(payload, 'ledger.entries', []).find(
    entry => get(entry, 'typename') === 'LedgerEntry' && get(entry, 'id') === id
  )

  return entry || []
}

export const getDebitorsForLedgerEntry = (payload, id) => {
  const entry = getLedgerEntry(payload, id)
  const debitors = _getRawDebitorsForLedgerEntry(entry)
  return debitors.map(debitor => {
    const debitorId = debitor.owner.id || debitor.owner.person.id
    const offsetEntry = _getOffsetEntryForPerson(entry, debitorId)
    return { ...debitor, amount: Math.abs(offsetEntry.node.amount) }
  })
}
