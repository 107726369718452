import ENV from 'min-side/config/environment'

export { pathFor, toQueryString } from 'echo-ember-common/utils/url-helpers'

import { urlFor as urlForFromCommon } from 'echo-ember-common/utils/url-helpers'

// Wrap common one, as this was the fastes way of adding some default, like
// url to backend from ENV.
export function urlFor(path, backendName = 'echo', backends = ENV.backend) {
  return urlForFromCommon(path, backendName, backends)
}
