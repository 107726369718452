export default {
  "dashlet": "_dashlet_1tb249",
  "icon": "_icon_1tb249",
  "dashlet-content": "_dashlet-content_1tb249",
  "no-link": "_no-link_1tb249",
  "clickable": "_clickable_1tb249",
  "release": "_release_1tb249",
  "recording": "_recording_1tb249",
  "playtime": "_playtime_1tb249",
  "group": "_group_1tb249",
  "performer": "_performer_1tb249",
  "producer": "_producer_1tb249",
  "payments": "_payments_1tb249",
  "isrc": "_isrc_1tb249"
};
