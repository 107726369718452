import Component from '@ember/component'
import { queryManager } from 'ember-apollo-client'
import searchRecordingsTask from 'min-side/tasks/search-recordings'
import tSearchRecordings from 'min-side/tasks/search-recordings-by-isrc'

export default Component.extend({
  'data-test-add-new-track-form': true,
  apollo: queryManager(),
  actions: {
    startAddingRecording() {
      this.set('track.addingRecording', true)
    },
    cancelProcess() {
      this.set('track.addingRecording', false)
    }
  },
  searchRecordingsTask,
  tSearchRecordings
})
