import { readOnly, equal } from '@ember/object/computed'
import Route from '@ember/routing/route'
import query from 'min-side/graphql/queries/ledger/remuneration-diff'
import { queryManager } from 'ember-apollo-client'
import { inject } from '@ember/service'
import EmberObject, { getProperties } from '@ember/object'

export default Route.extend({
  apollo: queryManager(),
  profile: inject(),

  profileName: readOnly('profile.activeProfile.profile'),
  includePerformer: equal('profileName', 'performer'),
  includeProducer: equal('profileName', 'personal-producer'),

  async model() {
    const { id } = this.paramsFor('user.ledger.remuneration')
    const { recording_id } = this.paramsFor('user.ledger.remuneration.recording-diff')
    let ids = {}
    if (this.maskId) {
      ids = {
        person_id: this.maskId,
        rightsholder_id: this.maskId
      }
    } else {
      ids = await this._resolveNecessaryIdsForQuery()
    }
    const variables = {
      id,
      recording_id,
      aggregate_on_channels: true,
      ...ids,
      include_performer: this.includePerformer,
      include_producer: this.includeProducer
    }
    return EmberObject.extend({ ...(await this.apollo.watchQuery({ query, variables })) }).create()
  },

  async _resolveNecessaryIdsForQuery() {
    const profile = await this.profile
    const {
      activeProfile: { id: rightsholder_id },
      _person: { id: person_id }
    } = getProperties(profile, ['activeProfile', '_person'])
    return { rightsholder_id, person_id }
  },

  setupController(controller, model) {
    this._super(controller, model)

    const id = this.paramsFor('user.ledger.remuneration').id
    controller.set('ledgerId', id)
  }
})
