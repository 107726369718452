import Component from '@ember/component'
import { equal } from '@ember/object/computed'
import { inject } from '@ember/service'
import { NEW_RELEASE_FORM_STEPS } from 'min-side/constants/new-release-steps'

const { OWNERSHIP_STEP } = NEW_RELEASE_FORM_STEPS

export default Component.extend({
  'data-test-release-form-ownership': true,
  tagName: 'section',
  eventBus: inject(),

  isCurrentStep: equal('currentStep', OWNERSHIP_STEP),

  actions: {
    save() {
      this.saveOwnershipsAction(this.get('recordLabel.shares') || [])
      this.stepForwardAction()
    },

    cancel() {
      this.eventBus.publish('stepBackToRecordings')
      this.stepBackAction()
    }
  }
})
