import Route from '@ember/routing/route'
import { inject } from '@ember/service'
import AuthenticatedRouteMixin from 'ember-simple-auth/mixins/authenticated-route-mixin'

export default Route.extend(AuthenticatedRouteMixin, {
  profile: inject(),
  logout: inject(),

  async model() {
    /* In theory it should be impossible for echo users to now login to this
     * site, however on the off chance they do, rather than crash, we can
     * clear up that weird state by logging them out
     */
    await this.profile.loadActiveProfile()
    const profiles = this.profile.selectableProfileObjects
    if (profiles.length < 1) {
      await this.logout.logout()
    }

    return { profiles }
  },

  actions: {
    async chooseProfile(profile) {
      await this.profile.switchTo(profile)
      this.transitionTo('user')
    }
  }
})
