export default {
  "statusboard": "_statusboard_113vtd",
  "help": "_help_113vtd",
  "search": "_search_113vtd",
  "box-list--numbered": "_box-list--numbered_113vtd",
  "list__header": "_list__header_113vtd",
  "col-xs-7": "_col-xs-7_113vtd",
  "form__label": "_form__label_113vtd",
  "radio-group": "_radio-group_113vtd"
};
