import Component from '@ember/component'
import { computed } from '@ember/object'
import { alias } from '@ember/object/computed'

export default Component.extend({
  tagName: 'form',

  societies: alias('membership.territory_mandate.societies'),
  type: 'DEFAULT VALUE',
  territoryMandateTypes: [
    {
      name: 'choose-mandate',
      value: 'DEFAULT VALUE'
    },
    {
      name: 'regional',
      value: 'REGIONAL'
    },
    {
      name: 'world-wide',
      value: 'WORLDWIDE'
    }
  ],
  addMandateExceptionText: computed('type', function () {
    return this.type === 'REGIONAL'
      ? 'molecules.membership-info.add-territory'
      : 'molecules.membership-info.except-territory'
  }),
  actions: {
    updateTerritoryMandateType(type) {
      this.set('membership.territory_mandate.type', type)
    },
    addMandateException() {
      this.societies.pushObject({})
    },
    removeMandateException(index) {
      this.societies.removeAt(index)
    }
  }
})
