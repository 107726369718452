import Component from '@ember/component'
import EmberObject, { computed, set } from '@ember/object'
import { or, reads, notEmpty } from '@ember/object/computed'
import Changeset from 'ember-changeset'
import PerformerValidations from 'min-side/validations/edit-recording/performer'
import lookupValidator from 'ember-changeset-validations'
import { inject } from '@ember/service'
import { A } from '@ember/array'
import Ember from 'ember'
import tSearchPerformers from 'min-side/tasks/search-performers'
import { queryManager } from 'ember-apollo-client'
import RemapValidationErrors from 'min-side/mixins/remap-validation-errors'
import { mapPerformer } from 'min-side/extractors/performance'
import CreatePerformancesChangeRequestsMutation from 'min-side/graphql/mutations/edit-recording/create-performances-change-requests'
import { preparePerformancesInput } from 'min-side/extractors/edit-recording/performances'
import { task } from 'ember-concurrency'
import { getPerformancesChangeRequest } from 'min-side/extractors/edit-recording/performances'
import { PROFILE_TYPES_MAP, PROFILE_TYPES } from 'min-side/constants/profile'
import { resolve } from 'rsvp'

export default Component.extend(RemapValidationErrors, {
  tagName: 'section',
  'data-test-edit-recording-performers': true,
  apollo: queryManager(),
  intl: inject(),
  queryParams: inject(),
  profile: inject(),

  performersCount: reads('performers.length'),

  showAddNewPerformer: computed(
    'disabled',
    'showSearchBox',
    'hasPendingChangeRequest',
    function () {
      return !this.hasPendingChangeRequest && !this.disabled && !this.showSearchBox
    }
  ),

  isChanged: or('isAttributeChanged', 'isAmountChanged'),

  isAttributeChanged: computed('performers.@each.isDirty', function () {
    return this.performers.any(p => p.get('isDirty'))
  }),

  isAmountChanged: computed('performers.length', function () {
    return this.performers.length !== this.performancesData.length
  }),

  performerIds: computed('performers.[]', function () {
    return this.performers.mapBy('id')
  }),

  showChangeRequest: notEmpty('changeRequest'),

  init(...args) {
    this._super(...args)

    this.setProperties({
      disabled: true,
      searchQuery: null,
      showSearchBox: false,
      showGeneralValidationError: false,
      hasPendingChangeRequest: this.get('performancesChangeRequests.has_pending'),
      changeRequest: getPerformancesChangeRequest(this.performancesChangeRequests)
    })

    this.initPerformersState()
  },

  initPerformersState() {
    this.set('performers', A())
    this.set('selectedInstruments', A())

    this.performancesData.forEach(performance => {
      const performer = mapPerformer(performance)
      performer.new = false
      performer.mapped_errors = EmberObject.create({ errors: [] })

      const performerChangeset = new Changeset(
        performer,
        lookupValidator(PerformerValidations),
        PerformerValidations
      )

      const code = performer.instrument_code
      const instrument = this.instruments.findBy('id', code)

      this.performers.addObject(performerChangeset)
      this.selectedInstruments.pushObject(instrument)
    })
  },

  addPropertiesToPerformer(performer) {
    set(performer, 'new', true)
    set(performer, 'role_code', null)
    set(performer, 'instrument_code', null)
    set(performer, 'mapped_errors', EmberObject.create({ errors: [] }))
    return performer
  },

  rejectNewPerformers() {
    const performers = this.performers.filterBy('new', false)
    this.set('performers', performers)
  },

  validate() {
    this.performers.forEach(p => p.validate())
  },

  isInvalid() {
    return this.performers.any(p => p.get('isInvalid'))
  },

  saveChanges() {
    this.performers.forEach(performer => {
      performer.set('new', false)
      performer.save()
    })
  },

  resetForm() {
    this.setProperties({
      disabled: true,
      showSearchBox: false,
      showGeneralValidationError: false
    })
    this.resetSearchResults()
  },

  resetSearchResults() {
    this.set('searchQuery', null)
    if (this.get('tSearchPerformers.last')) this.set('tSearchPerformers.last.value', null)
  },

  revertSelectedInstruments() {
    const instruments = this.performers.map(performer => {
      const code = performer.get('instrument_code')
      return this.instruments.find(instrument => instrument.id === code)
    })
    this.set('selectedInstruments', instruments)
  },

  saveAction() {
    this.validate()

    if (this.isInvalid()) {
      this._mapValidationErrors()
      this.flashMessages.error(this.intl.t('flash.save.invalid'))
      return false
    }
    this.saveChanges()
    this.tSavePerformers.perform()
    return true
  },

  tSavePerformers: task(function* () {
    const changes = preparePerformancesInput(this.performers)
    const variables = {
      input: {
        changes,
        recording_id: this.recordingId,
        requester_person_profile: PROFILE_TYPES_MAP[this.get('profile.activeProfile.profile')],
        requester_person_id: this._requesterPersonId()
      }
    }

    try {
      const {
        create_performances_change_requests: { errors, change_requests: changeRequests }
      } = yield this.apollo.mutate({
        variables,
        mutation: CreatePerformancesChangeRequestsMutation
      })
      if (errors) {
        this.get('flashMessages').error(this.get('intl').t('flash.save.invalid'))
        this.set('errors.errors', errors)
      } else {
        this.resetForm()
        if (changeRequests.length > 0) this.set('hasPendingChangeRequest', true)
        this.flashMessages.success(this.intl.t('flash.save.success'))
      }
    } catch (e) {
      this.flashMessages.error(this.intl.t('flash.save.failed'))
    }
  }),

  actions: {
    async save() {
      if (!this.isChanged) return this.resetForm()

      if (!Ember.testing && !confirm(this.intl.t('edit_recording.performers.confirmation'))) {
        return
      }
      this.saveAction()
      return resolve(true)
    },

    rollback() {
      this.initPerformersState()
      this.resetForm()
    },

    edit() {
      this.set('disabled', false)
    },

    toggleSearch() {
      this.set('showSearchBox', !this.get('showSearchBox'))
    },

    removePerformer(index) {
      this.performers.removeAt(index)
      this.selectedInstruments.removeAt(index)
    },

    addPerformer(performer) {
      const performerChangeset = new Changeset(
        this.addPropertiesToPerformer(performer),
        lookupValidator(PerformerValidations),
        PerformerValidations
      )

      this.performers.addObject(performerChangeset)
      this.selectedInstruments.addObject({})
      this.resetSearchResults()
      this.set('showSearchBox', false)
    }
  },

  _mapValidationErrors() {
    this.performers.forEach(changeset => {
      const errors = []
      changeset.get('errors').forEach(error => {
        errors.push(
          EmberObject.create({
            attribute: error.key,
            message: this.intl.t('validation.empty')
          })
        )
      })
      changeset.get('mapped_errors').set('errors', errors)
    })
  },
  _requesterPersonId() {
    if (this.get('profile.activeProfile.profile') === PROFILE_TYPES.CONTACT) {
      return this.get('profile._person.user.person.id')
    }
    return this.get('profile.activeProfile.id')
  },

  tSearchPerformers
})
