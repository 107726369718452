import Component from '@glimmer/component'
import { action, computed } from '@ember/object'
import { inject as service } from '@ember/service'
import { isPresent } from '@ember/utils'

export default class DefaultProfileToggle extends Component {
  @service profileInfo

  get data() {
    return this.args.changeset
  }

  get model() {
    return this.args.model
  }

  get disabled() {
    return this.args.disabled
  }

  @computed(
    'profileInfo.activeProfile',
    'data.{default_min_side_profile,default_min_side_organization_id,id}'
  )
  get checked() {
    let defaultProfile = this.data.get('default_min_side_profile')
    const activeProfile = this.profileInfo.activeProfile
    if (isPresent(defaultProfile)) defaultProfile = defaultProfile.replace('_', '-')
    if (isPresent(activeProfile.profile)) {
      if (defaultProfile !== 'CONTACT') {
        return defaultProfile === activeProfile.profile.toUpperCase()
      }

      const defaultOrganization = this.data.get('default_min_side_organization_id')
      return (
        defaultProfile === activeProfile.profile.toUpperCase() &&
        defaultOrganization === activeProfile.id
      )
    }
  }

  set checked(value) {
    if (value === false) {
      this._setWhenToggleOffForCurrentProfile()
    } else {
      this._setWhenToggleOnForCurrentProfile()
    }
  }

  _setWhenToggleOffForCurrentProfile() {
    this._setDataInChangeset(null, null, this.model.user.person.id)
  }

  _setWhenToggleOnForCurrentProfile() {
    const activeProfile = this.profileInfo.activeProfile
    const currentUser = this.model.user.person
    const profile = activeProfile.profile.replace('-', '_').toUpperCase()
    let organizationId = null

    if (activeProfile.profile === 'contact') {
      organizationId = activeProfile.id
    }
    this._setDataInChangeset(profile, organizationId, currentUser.id)
  }

  _setDataInChangeset(defaultMinSideProfile, defaultMinSideOrganizationId, id) {
    this.data.set('default_min_side_profile', defaultMinSideProfile)
    this.data.set('default_min_side_organization_id', defaultMinSideOrganizationId)
    this.data.set('id', id)
  }

  @action
  toggle() {
    if (!this.disabled) {
      this.checked = !this.checked
    }
  }
}
