import Component from '@ember/component'
import { computed } from '@ember/object'
import { equal } from '@ember/object/computed'
import { inject } from '@ember/service'

export default Component.extend({
  tagName: 'div',
  classNames: 'row',
  'data-test-ownership-share': true,
  intl: inject(),
  hasError: false,

  labels: computed('withLabels', 'intl.locale', function () {
    const intl = this.intl
    return this.withLabels
      ? {
          producerName: intl.t('molecules.ownership_share.producer_name'),
          country: intl.t('molecules.ownership_share.producer_country'),
          share: intl.t('molecules.ownership_share.share')
        }
      : {
          producerName: '',
          country: '',
          share: ''
        }
  }),

  withLabels: equal('index', 0),

  actions: {
    removeShare() {
      this.removeShareAction(this.index)
    }
  }
})
