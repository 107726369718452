import Component from '@ember/component'
import Changeset from 'ember-changeset'
import { inject } from '@ember/service'
import { set, get, computed } from '@ember/object'
import EmberObject from '@ember/object'
import { task } from 'ember-concurrency'
import ReleaseInformationValidations from 'min-side/validations/release-information'
import lookupValidator from 'ember-changeset-validations'
import { cleanReleaseProperties, prepareReleaseInput } from 'min-side/extractors/edit-release/info'
import ErrorFormatter from 'min-side/mixins/error-formater'

import {
  EMPTY_ALTERNATIVE_CATALOGUE_NUMBER_OBJECT,
  EMPTY_ALTERNATIVE_CATALOGUE_NUMBER_ERROR_OBJECT
} from 'min-side/constants/empty-alternative-catalogue-defaults'

export default Component.extend(ErrorFormatter, {
  'data-test-edit-release-info': true,
  tagName: 'section',
  elementId: 'release-info',
  intl: inject(),
  disabled: true,
  init(...args) {
    this._super(...args)
    this.changeset = new Changeset(
      {
        ...this.release,
        record_label: {
          name: this.get('release.record_label')
        }
      },
      lookupValidator(ReleaseInformationValidations),
      ReleaseInformationValidations
    )
    this.setProperties({
      alternativeCatalogueNumberErrors: [],
      alternativeCatalogNumbersChangesets: [],
      errors: { errors: [] }
    })
  },

  genresCollection: computed('genres', function () {
    return [
      { id: '', english_name: 'Select genre', norwegian_name: 'Velg sjanger' },
      ...this.genres
    ]
  }),

  formats: computed(() => [
    { value: '', translationKey: 'molecules.format-relations.select-format' },
    { value: 'Album', translationKey: 'album.form.release_info.formats.album' },
    { value: 'EP', translationKey: 'album.form.release_info.formats.ep' },
    { value: 'Compilation', translationKey: 'album.form.release_info.formats.compilation' },
    { value: 'Single', translationKey: 'album.form.release_info.formats.single' }
  ]),

  genreLabelPath: computed('intl.locale', function () {
    return this.get('intl.locale') === 'en' ? 'english_name' : 'norwegian_name'
  }),

  selectedLabel: computed('changeset.record_label', 'release.record_label', function () {
    return this.get('changeset.record_label')
  }),

  selectedGenre: computed('changeset.genre', 'release.genre', function () {
    return this.get('changeset.genre')
  }),

  hasAlternativeCatalogueNumberError: computed(
    'alternativeCatalogueNumberErrors.@each.{isError}',
    function () {
      return this.alternativeCatalogueNumberErrors.some(error => error.isError === true)
    }
  ),

  submitTask: task(function* (changeset) {
    yield changeset.validate()
    if (changeset.get('isValid') && !this.hasAlternativeCatalogueNumberError) {
      yield changeset.save()
      this.alternativeCatalogNumbersChangesets.forEach(changeset => changeset.save())
      const releaseID = get(changeset, 'id')
      const inputRelease = prepareReleaseInput(cleanReleaseProperties(changeset))
      try {
        const {
          update_release: { errors }
        } = yield this.tSaveReleaseInfo.perform(inputRelease, releaseID)

        if (errors) {
          this.get('flashMessages').error(this.get('intl').t('flash.save.invalid'))
          this.set('errors.errors', this.formatErrors(errors))
        } else {
          this.flashMessages.success(this.intl.t('flash.save.success'))
          this.setProperties({
            disabled: true
          })
        }
      } catch (errors) {
        this.flashMessages.error(this.intl.t('flash.save.failed'))
      }
    } else {
      const errors = this._mapValidationErrors(changeset.get('errors'))
      this.setProperties({
        errors,
        showChildrenErrors: true
      })
      this.flashMessages.error(this.intl.t('flash.save.invalid'))
    }
  }),
  actions: {
    edit() {
      this._resetAlternativeCatalogueNumberErrors()
      this._savePreviousCatalogNumbersState()
      set(this, 'disabled', false)
    },

    rollback() {
      this.set('errors.errors', null)
      this.rollBackAction(this.changeset)
    },

    updateSearchableField(fieldName, { name }) {
      this.changeset.set(fieldName, name)
    },

    updateLabel(recordingLabel) {
      this.changeset.set('record_label', recordingLabel)
    },

    addAlternativeCatalogueNumber() {
      this.changeset
        .get('alternative_catalog_numbers')
        .pushObject(EmberObject.create(EMPTY_ALTERNATIVE_CATALOGUE_NUMBER_OBJECT))
      this.alternativeCatalogueNumberErrors.pushObject(
        EmberObject.create(EMPTY_ALTERNATIVE_CATALOGUE_NUMBER_ERROR_OBJECT)
      )
    },

    removeAlternativeCatalogueNumber(object, index) {
      this.changeset.get('alternative_catalog_numbers').removeObject(object)
      this.alternativeCatalogueNumberErrors.removeAt(index)
      this.alternativeCatalogNumbersChangesets.removeAt(index)
    },

    addAlternativeCatalogueNumberError(index) {
      const alternativeCatalogueNumberError = this.alternativeCatalogueNumberErrors.objectAt(index)
      alternativeCatalogueNumberError.set('isError', true)
      this.set('showChildrenErrors', true)
    },

    addAlternativeCatalogueNumberChangeset(changeset) {
      this.alternativeCatalogNumbersChangesets.pushObject(changeset)
      this.alternativeCatalogueNumberErrors.pushObject(
        EmberObject.create(EMPTY_ALTERNATIVE_CATALOGUE_NUMBER_ERROR_OBJECT)
      )
    },

    clearAlternativeCatalogueNumberError(index) {
      const alternativeCatalogueNumberError = this.alternativeCatalogueNumberErrors.objectAt(index)
      alternativeCatalogueNumberError.set('isError', false)
    },

    updateGenre(genre) {
      this.changeset.set('genre', genre)
    },

    cancelSearchResult() {
      this.tFetchArtistsCollectionTask.cancelAll()
    }
  },
  _savePreviousCatalogNumbersState() {
    this.setProperties({
      previousAlternativeCatalogNumbers: [...this.changeset.get('alternative_catalog_numbers')],
      previousAlternativeCatalogNumbersChangesets: [...this.alternativeCatalogNumbersChangesets],
      previousAlternativeCatalogueNumberErrors: [...this.alternativeCatalogueNumberErrors]
    })
  },

  _restorePreviousCatalogNumbersState() {
    if (!this.previousAlternativeCatalogNumbersChangesets) return

    this.changeset.set('alternative_catalog_numbers', this.previousAlternativeCatalogNumbers)
    this.setProperties({
      alternativeCatalogNumbersChangesets: [...this.previousAlternativeCatalogNumbersChangesets],
      alternativeCatalogueNumberErrors: [...this.previousAlternativeCatalogueNumberErrors]
    })
  },

  _resetAlternativeCatalogueNumberErrors() {
    this.alternativeCatalogueNumberErrors.forEach(error => error.set('isError', false))
  },

  async rollBackAction(changeset) {
    changeset.rollback()
    this._restorePreviousCatalogNumbersState()
    this.alternativeCatalogNumbersChangesets.forEach(changeset => changeset.rollback())
    this.setProperties({
      disabled: true
    })
  },

  _mapValidationErrors(changesetErrors) {
    const errors = []
    changesetErrors.forEach(error => {
      errors.push(
        EmberObject.create({
          attribute: error.key,
          message: this.intl.t('validation.empty')
        })
      )
    })
    return { errors }
  }
})
