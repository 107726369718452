import Route from '@ember/routing/route'
import fetch_file_url_query from 'min-side/graphql/queries/file-download-url'
import deleteFileMutation from 'min-side/graphql/mutations/delete-file'
import { queryManager } from 'ember-apollo-client'
import { inject } from '@ember/service'
import { task } from 'ember-concurrency'

export default Route.extend({
  apollo: queryManager(),
  profile: inject(),

  actions: {
    fetchFileUrlAction(id) {
      return this.tFetchFileUrl.perform(id)
    }
  },

  tDeleteFile: task(function* (id) {
    const variables = { input: { id } }
    return yield this.apollo.mutate({ mutation: deleteFileMutation, variables })
  }),

  tFetchFileUrl: task(function* (id) {
    const variables = { id }
    return yield this.apollo.watchQuery({ query: fetch_file_url_query, variables })
  })
})
