import Route from '@ember/routing/route'
import { inject as service } from '@ember/service'
import { queryManager } from 'ember-apollo-client'
import { get } from '@ember/object'
import { reads } from '@ember/object/computed'
import { getQueryByProfileType } from 'min-side/graphql/helpers/profile-info/queries'

import producerCompanyQuery from 'min-side/graphql/queries/profile/producer-company'
import { getActiveOrganization } from 'min-side/helpers/organization'
import { PROFILE_TYPES_MAP } from 'min-side/constants/profile'

export default class ProfileInfoDocumentsRoute extends Route {
  @queryManager apollo
  @service profile
  @service profileInfo

  @reads('profile.activeProfile.profile')
  profileType

  async model() {
    if (this.profileType === 'contact') {
      return await this.modelForContact()
    }
    const impersonatedUserId = get(this, 'profile.impersonatedUserId')
    const variables = impersonatedUserId ? { person_id: impersonatedUserId } : null

    const query = getQueryByProfileType(this.profileType)

    const response = await this.apollo.watchQuery({ query, variables, fetchPolicy: 'network-only' })
    return {
      document_parties: get(
        response,
        `user.person.${
          PROFILE_TYPES_MAP[this.get('profile.activeProfile.profile')]
        }.electronic_signature_parties.edges`
      ),
      person_id: get(response, 'user.person.id')
    }
  }

  async modelForContact() {
    const id = this.get('profile.activeProfile.id')
    const variables = { id }

    const response = await this.apollo.watchQuery({
      query: producerCompanyQuery,
      variables,
      fetchPolicy: 'network-only'
    })

    // get active org id, then use that to filter the list of documents on the contact profile
    const company_id = getActiveOrganization(response.user.person.contact.positions, id).id
    const parties = get(response, `user.person.contact.electronic_signature_parties.edges`)
    return {
      document_parties: parties.filter(party => {
        return party.node.company && party.node.company.id === company_id
      }),
      person_id: get(response, 'user.person.id')
    }
  }
}
