import Component from '@ember/component'
import { computed } from '@ember/object'
import { inject } from '@ember/service'

export default Component.extend({
  profile: inject(),
  custom: false,
  shouldShowCustom: computed('params.period', 'custom', function () {
    return this.custom || this.get('params.period') === 'custom'
  }),
  actions: {
    periodChanged(value) {
      this.set('custom', value === 'custom')
      if (!value) {
        this.clearRangeParams()
        return this.onPeriodChange(null)
      }

      if (['last_year', 'last_four_years'].includes(value)) {
        this.clearRangeParams()
      }

      return this.onPeriodChange(value)
    },
    onChangeDateFrom(value) {
      this.onDateFromChange(value)
    },
    onDateUntilChange(value) {
      this.onDateUntilChange(value)
    }
  }
})
