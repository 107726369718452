import { get } from '@ember/object'

const connectionPath = 'user.person.personal_inheritance_party.inheritances_from_persons'

export const extractPageInfo = response => get(response, `${connectionPath}.pageInfo`)

export const extractTestators = response => {
  const personalInheritanceEdges = get(response, `${connectionPath}.edges`)
  return personalInheritanceEdges.map(edge => get(edge, 'node'))
}
