import Component from '@ember/component'
import { computed } from '@ember/object'
import { alias } from '@ember/object/computed'

/**
 * This component creates a windowed paging functionality on top of relay connections.
 *
 * DUPLICATION echo-common-front-end-candidate
 */
export default Component.extend({
  'data-test-cursor-pager': true,
  pageSize: 10,
  tagName: 'nav',
  classNames: ['text--right'],
  hasNumericPagination: true,

  endCursorPosition: computed('pageInfo.endCursor', function () {
    const endCursor = this.get('pageInfo.endCursor') || btoa('1')
    return atob(endCursor)
  }),

  startCursorPosition: computed('pageInfo.startCursor', function () {
    const startCursor = this.get('pageInfo.startCursor') || btoa('1')
    return atob(startCursor)
  }),

  hasPreviousPage: alias('pageInfo.hasPreviousPage'),

  hasNextPage: alias('pageInfo.hasNextPage'),

  hasNoPages: computed('hasPreviousPage', 'hasNextPage', function () {
    return !(this.get('hasPreviousPage') || this.get('hasNextPage'))
  }),

  currentPage: computed('endCursorPosition', 'pageSize', function () {
    return Math.ceil(this.get('endCursorPosition') / this.get('pageSize'))
  }),

  actions: {
    previous() {
      if (this.get('hasPreviousPage')) {
        const after = this.get('startCursorPosition') - this.get('pageSize') - 1
        this.get('onPageChange')(this.get('pageSize'), after <= 1 ? null : btoa(after))
      }
    },
    next() {
      if (this.get('hasNextPage')) {
        this.get('onPageChange')(this.get('pageSize'), this.get('pageInfo.endCursor'))
      }
    },
    onPageClick(newPage) {
      const endCursor = (parseInt(newPage, 10) - 1) * this.get('pageSize')
      this.onPageChange(this.get('pageSize'), endCursor <= 0 ? null : btoa(endCursor))
    }
  }
})
