import { computed } from '@ember/object'
import Component from '@ember/component'
import { inject } from '@ember/service'

export default Component.extend({
  features: inject(),

  amount: computed('payment.amount', function () {
    return -parseFloat(this.get('payment.amount'))
  })
})
