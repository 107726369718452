export default {
  "title": "_title_1yno6y",
  "artist": "_artist_1yno6y",
  "content": "_content_1yno6y",
  "controls": "_controls_1yno6y",
  "close": "_close_1yno6y",
  "navigation": "_navigation_1yno6y",
  "file-controls": "_file-controls_1yno6y",
  "recording": "_recording_1yno6y",
  "recording-form": "_recording-form_1yno6y",
  "ownership": "_ownership_1yno6y",
  "file-list": "_file-list_1yno6y",
  "file-list--row": "_file-list--row_1yno6y",
  "new-performance": "_new-performance_1yno6y",
  "new-performance-controls": "_new-performance-controls_1yno6y",
  "active": "_active_1yno6y"
};
