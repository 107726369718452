import Component from '@glimmer/component'

class UserAvatar extends Component {
  get avatarName() {
    if (!this.args.person || !this.args.person.name) return ''

    const names = this.args.person.name.split(' ')
    let initials = ''
    if (names[0]) {
      initials += names[0][0]
    }
    if (names.length > 1) {
      initials += names[names.length - 1][0]
    }
    return initials
  }
}

export default UserAvatar
