import Controller from '@ember/controller'
import { alias } from '@ember/object/computed'
import { inject as injectController } from '@ember/controller'

export default Controller.extend({
  discographyController: injectController('user.discography'),
  query: alias('discographyController.query'),
  tabs: alias('discographyController.tabs'),
  recordingsTotal: alias('discographyController.recordingsTotal'),
  albumsTotal: alias('discographyController.albumsTotal'),
  groupsTotal: alias('discographyController.groupsTotal')
})
