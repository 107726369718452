import Controller from '@ember/controller'
import { tracked } from '@glimmer/tracking'
import { getJWTPayload } from 'echo-ember-common/utils/jtw-helpers'
import { tempErrorMsg, i18nBoundTo } from 'min-side/utils/error-helpers'
import { urlFor } from 'min-side/utils/url-helpers'
import { inject as service } from '@ember/service'
import { computed, action } from '@ember/object'
import { Headers } from 'fetch'
import { queryManager } from 'ember-apollo-client'
import {
  HTTP_UNAUTHORIZED,
  HTTP_UNPROCESSABLE,
  HTTP_SERVICE_UNAVAILABLE
} from 'min-side/constants/http-status-codes'

export default class VerifyController extends Controller.extend() {
  queryParams = ['token', 'preconfiguredSMS', 'mobileType']
  preconfiguredSMS = 0
  @tracked disabled = false
  @tracked loading = false

  @service session
  @service intl
  @service ajaxRequests

  @queryManager apollo

  @computed('mobileType')
  get mobile() {
    const payload = getJWTPayload(this.token)
    if (this.mobileType === 'alternative') {
      return payload.data.user.alternative_mobile
    }
    return payload.data.user.mobile
  }

  errorMessage(status) {
    switch (status) {
      case HTTP_UNAUTHORIZED:
        return this.intl.t('verify.errors.timeout')
      case HTTP_UNPROCESSABLE:
        return this.intl.t('verify.errors.failed')
      case HTTP_SERVICE_UNAVAILABLE:
        return this.intl.t('flash.http_codes.service_unavailable')
      default:
        return this.intl.t('flash.http_codes.other')
    }
  }

  @action
  async verify() {
    this.disabled = true
    this.loading = true
    try {
      await this.session.authenticate('authenticator:jwt', this.getProperties('token', 'code'))
    } catch (err) {
      this.flashMessages.error(this.errorMessage(err.status))
      this.disabled = false
      this.loading = false
    } finally {
      this.code = ''
      // don't stop loading here, loading stops in the route when we have finally transitioned away
    }
  }

  @action
  async reidentify() {
    const token = this.token
    const dataToBeSent = {
      data: { attributes: { mobile: this.mobileType } },
      locale: this.intl.primaryLocale
    }
    try {
      const response = await this.ajaxRequests.postRequest(
        urlFor('auth/reidentify'),
        dataToBeSent,
        new Headers({
          Authorization: `Bearer ${token}`
        })
      )

      if (!response.ok) throw response

      const { data } = await response.json()
      this.token = data.attributes.token
      this.flashMessages.success(this.intl.t('verify.reidentify-codeWasSent'))
    } catch (error) {
      const errorData = await error.json()
      let msg

      if (error.status === HTTP_UNAUTHORIZED) {
        msg = this.intl.t('verify.errors.reidentify-failed')
      } else {
        msg = tempErrorMsg(
          this.intl.t('verify.errors.reidentify-failed'),
          (errorData && errorData.errors) || [],
          i18nBoundTo(this.intl, 'model.user')
        )
      }

      this.flashMessages.error(msg)
    }
  }
}
