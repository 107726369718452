import Route from '@ember/routing/route'
import { inject } from '@ember/service'
import { queryManager } from 'ember-apollo-client'
import query from 'min-side/graphql/queries/edit-recording/recording-by-isrc-or-id'

export default Route.extend({
  apollo: queryManager(),
  queryParams: inject(),
  pageTitle: inject(),

  beforeModel(transition) {
    this._super()
    if (transition.to.params.id !== undefined) {
      this.queryParams.add(transition.to.params.id)
    }
  },

  model({ isrc_or_id }) {
    const variables = { isrc_or_id }

    return this.apollo.query({ query, variables, fetchPolicy: 'network-only' }).then(model => {
      this.pageTitle.setVariables({ title: model.recording_by_isrc_or_id.title })
      return model
    })
  },

  activate() {
    this._super()
    if (document.getElementsByTagName('main')[0]) {
      document.getElementsByTagName('main')[0].scrollTo(0, 0)
    }
  },

  deactivate() {
    this._super()
    this.queryParams.empty()
  },
  actions: {
    willTransition() {
      this.refresh()
    }
  }
})
