import Component from '@glimmer/component'
import { inject as service } from '@ember/service'

export default class MostPlayedDashlet extends Component {
  @service profile

  get showShareSize() {
    return this.profile.current.meta.profileName !== 'performer'
  }
}
