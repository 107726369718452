import Component from '@ember/component'
import { inject } from '@ember/service'
import { action } from '@ember/object'
import { alias } from '@ember/object/computed'

export default class SidebarLinks extends Component {
  @inject router

  classNames = ['nav--secondary']
  tagName = 'nav'

  @alias('router.currentRouteName') currentRoute

  @action
  select(route) {
    this.router.transitionTo(route)
  }
}
