import Component from '@ember/component'
import { or } from '@ember/object/computed'

export default Component.extend({
  'data-test-save-controls': true,
  defaultCancelTranslationKey: 'cancel',
  defaultSaveTranslationKey: 'submit',
  classNames: ['form__section--action', 'form__section--flex'],

  cancelKeyTranslation: or('cancelTranslationKey', 'defaultCancelTranslationKey'),
  saveKeyTranslation: or('saveTranslationKey', 'defaultSaveTranslationKey')
})
