import CurrentProfileMixin from 'min-side/mixins/current-profile'
import Controller, { inject as injectController } from '@ember/controller'
import { computed } from '@ember/object'
import { inject } from '@ember/service'
import { alias, oneWay } from '@ember/object/computed'

export default Controller.extend(CurrentProfileMixin, {
  queryParams: ['q'],
  q: null,
  query: oneWay('q'),
  after: null,
  profile: inject(),
  albumsController: injectController('user/discography/albums/index'),
  recordingsController: injectController('user/discography/recordings/index'),
  tabs: computed('model', 'profile.activeProfile', function () {
    switch (this.get('profile.activeProfile.profile')) {
      case 'performer':
        return [
          {
            name: 'releases',
            total: this.albumsTotal,
            link: 'albums'
          },
          {
            name: 'recordings',
            total: this.recordingsTotal,
            link: 'recordings'
          },
          {
            name: 'groups',
            total: this.groupsTotal,
            link: 'groups'
          }
        ]
      case 'contact':
      case 'personal-producer':
        return [
          {
            name: 'releases',
            total: this.albumsTotal,
            link: 'albums'
          },
          {
            name: 'recordings',
            total: this.recordingsTotal,
            link: 'recordings'
          }
        ]
      default:
        return []
    }
  }),
  recordingsTotal: alias('currentProfile.recordings.total'),
  albumsTotal: alias('currentProfile.releases.total'),
  groupsTotal: computed('currentProfile', function () {
    const q = this.getWithDefault('q') || ''
    const query = q.toLocaleLowerCase()
    return this.getWithDefault('model.user.person.performer.group_memberships', []).filter(
      groupMembership => {
        return (
          groupMembership.group.name.toLocaleLowerCase().includes(query) ||
          groupMembership.group.nationality.name.toLocaleLowerCase().includes(query)
        )
      }
    ).length
  }),
  actions: {
    updateQuery() {
      this.setProperties({
        q: this.query,
        after: null
      })

      this.albumsController.set('after', null)
      this.recordingsController.set('after', null)
    }
  }
})
