import Service from '@ember/service'
import Evented from '@ember/object/evented'

export default Service.extend(Evented, {
  publish(...args) {
    return this.trigger(...args)
  },
  subscribe(...args) {
    return this.on(...args)
  },
  unsubscribe(...args) {
    return this.off(...args)
  }
})
