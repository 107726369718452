import Controller from '@ember/controller'
import { computed } from '@ember/object'
import CurrentProfileMixin from 'min-side/mixins/current-profile'
import { getCurrentOrganization } from 'min-side/helpers/contact'
import { inject } from '@ember/service'

export default Controller.extend(CurrentProfileMixin, {
  profile: inject(),

  queryParams: ['q', 'property', 'direction', 'after'],
  q: null,
  property: null,
  direction: null,
  after: null,
  first: 25,

  recordings: computed('model.user.person', function () {
    const currentProfile = this.profile.current

    if (currentProfile.meta.companyType === 'producerCompany') {
      const organization = getCurrentOrganization(
        this.get('profile.activeProfile'),
        this.model.user.person
      )
      return organization.producer_company.members_recordings
    }
    return this.model.user.person.sector.members_recordings
  }),
  withTotalPlaytime: computed('profile', function () {
    const currentProfile = this.profile.current

    return currentProfile.meta.companyType !== 'producerCompany'
  }),

  actions: {
    onPageChange(_first, after) {
      this.set('after', after)
    },
    async search(query) {
      this.set('after', null)
      this.set('q', query)
    }
  }
})
