import Component from '@ember/component'
import { computed } from '@ember/object'
import { inject } from '@ember/service'
import { formatAlbum } from 'min-side/extractors/recording'
import { cleanRecordingResponse } from 'min-side/extractors/edit-recording/info'

const translationNamespace = 'recording.form'

export default Component.extend({
  'data-test-edit-recording-view': true,
  intl: inject(),
  flashMessages: inject(),

  albumText: computed('recording.tracks.[]', 'intl.locale', function () {
    const { title, more } = formatAlbum(this.get('recording.tracks'))
    const translationKey = this._getAlbumTranslationKey(more)

    return this.intl.t(`recording.header.${translationKey}`, {
      firstTitle: title,
      numMore: more
    })
  }),
  trackCount: computed('recording.tracks.[]', function () {
    return this.get('recording.tracks').length
  }),
  performerCount: computed('recording.performances.[]', function () {
    return this.get('recording.performances').length
  }),
  /**
   * Clean the recording response and prepare it for recording info
   */
  cleanRecordingResponse: computed('recording', function () {
    return cleanRecordingResponse(this.recording)
  }),

  sidebarLinks: computed('currentStep', function () {
    return [
      {
        id: 'user.edit-recording.info',
        name: `${translationNamespace}.recording_info.header`,
        active: false
      },
      {
        id: 'user.edit-recording.ownership',
        name: `${translationNamespace}.ownership.header`,
        active: false
      },
      {
        id: 'user.edit-recording.performers',
        name: `${translationNamespace}.performers.header`,
        active: false,
        counter: this.performerCount
      },
      {
        id: 'user.edit-recording.releases',
        name: `${translationNamespace}.releases.header`,
        active: false,
        counter: this.trackCount
      }
    ]
  }),

  _getAlbumTranslationKey(amtItems) {
    if (amtItems === 1) return 'album_text_one_more'
    return amtItems >= 1 ? 'album_text_multiple' : 'album_text'
  }
})
