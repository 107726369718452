import Component from '@ember/component'
import { inject } from '@ember/service'
import { computed, getWithDefault, get } from '@ember/object'
import { task, timeout } from 'ember-concurrency'
import moment from 'moment'
import MainArtistSuggestionQuery from 'min-side/graphql/queries/edit-recording/main-artist-suggestions'
import labelsQuery from 'min-side/graphql/queries/new-release/labels'
import UpdateRelease from 'min-side/graphql/mutations/edit-release/update-release'
import { DEBOUNCE_MS } from 'min-side/constants/time'
import { queryManager } from 'ember-apollo-client'

const DEFAULT_VALUE = '-'
const MAX_CONCURRENCY = 1

export default Component.extend({
  'data-test-edit-release-view': true,
  intl: inject(),
  apollo: queryManager(),

  numberOfTracks: computed('release.tracks.[]', function () {
    return getWithDefault(this, 'release.tracks.length', DEFAULT_VALUE)
  }),

  performersCount: computed('release.tracks.[]', function () {
    return getWithDefault(this, 'release.tracks.0.node.performances.length', DEFAULT_VALUE)
  }),
  releaseYear: computed('release.release_date', function () {
    const releaseDate = get(this, 'release.release_date')
    return releaseDate ? moment(releaseDate).format('YYYY') : DEFAULT_VALUE
  }),

  recordLabel: computed('release.record_label', function () {
    const record_label = get(this, 'release.record_label')
    return record_label && typeof record_label === 'object'
      ? get(record_label, 'name')
      : record_label
  }),

  sidebarLinks: computed('currentStep', 'release.tracks.[]', function () {
    return [
      {
        id: 'user.edit-release.info',
        name: 'edit_release.info.section_title'
      },
      {
        id: 'user.edit-release.tracks',
        name: 'edit_release.tracks.section_title',
        counter: this.numberOfTracks
      }
    ]
  }),
  tFetchLabelsCollectionTask: task(function* (query) {
    yield timeout(DEBOUNCE_MS)
    const variables = { query }
    const labels = yield this.apollo.watchQuery({ query: labelsQuery, variables })
    return labels.search_record_labels.edges.map(edge => edge.node)
  }).restartable(),

  tFetchArtistsCollectionTask: task(function* (query) {
    yield timeout(DEBOUNCE_MS)
    const { main_artist_name_suggestions } = yield this.apollo.watchQuery({
      query: MainArtistSuggestionQuery,
      variables: { query }
    })
    return main_artist_name_suggestions.map(suggestion => ({
      name: suggestion
    }))
  })
    .maxConcurrency(MAX_CONCURRENCY)
    .restartable(),

  tSaveReleaseInfo: task(function* (changesets, id) {
    const variables = {
      input: {
        id,
        release: changesets
      }
    }
    return this.apollo.mutate({
      variables,
      mutation: UpdateRelease
    })
  })
    .enqueue()
    .maxConcurrency(MAX_CONCURRENCY)
})
