import Controller, { inject as injectController } from '@ember/controller'
import { inject } from '@ember/service'
import { readOnly } from '@ember/object/computed'

export default Controller.extend({
  profile: inject(),
  childController: injectController('user.ledger.remuneration.recording.index'),
  recordingEdge: readOnly('childController.recordingEdge'),
  subSubHeader: readOnly('childController.subSubHeader'),
  metaFields: readOnly('childController.metaFields'),
  renumerationId: readOnly('childController.renumerationId')
})
