import Controller from '@ember/controller'
import { computed } from '@ember/object'
import { extractTestators, extractPageInfo } from 'min-side/extractors/testator'

export default Controller.extend({
  queryParams: ['q', 'after'],
  q: null,
  after: null,
  testators: computed('model', function () {
    return extractTestators(this.model)
  }),

  pageInfo: computed(
    'model.user.person.personal_inheritance_party.inheritances_from_persons.pageInfo',
    function () {
      return extractPageInfo(this.model)
    }
  ),

  actions: {
    /**
     * Make this action return a promise by making use of the async syntax (an async function always return a promise),
     * this is needed since the search-input uses {{components/async-button}} component which needs the action to return a
     * promise to function properly.
     * @param q
     * @returns {Promise<null|*>}
     */
    async onSearch(q) {
      if (!q || q === '') return this.set('q', null)
      this.setProperties({ q, after: null })
      return null
    },

    onPageChange(_, after) {
      this.set('after', after)
    }
  }
})
