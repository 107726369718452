import Route from '@ember/routing/route'
import { inject } from '@ember/service'
import { isPresent } from '@ember/utils'
import query from 'min-side/graphql/queries/discography/releases'
import { queryManager } from 'ember-apollo-client'

export default Route.extend({
  apollo: queryManager(),
  profile: inject(),
  queryParams: {
    after: { refreshModel: true }
  },
  renderTemplate(controller, model) {
    this._super(controller, model)

    this.render('user.discography.albums.pagination', {
      outlet: 'pagination',
      controller: this.controllerFor('user.discography.albums.index')
    })
  },
  model({ after }) {
    const { q } = this.paramsFor('user.discography')
    const { property, direction } = this.paramsFor('user.discography.albums')
    const first = this.controllerFor('user.discography.albums.index').get('first')
    const order = []
    if (property && direction) {
      order.push({ property, direction })
    }

    const maskId = this.get('profile.impersonatedUserId')
    const profileName = this.get('profile.activeProfile.profile')
    let includePerformer = true
    let includeProducer = true

    if (maskId !== null) {
      includePerformer = profileName === 'performer'
      includeProducer = profileName === 'personal-producer'
    }

    const variables = {
      query: isPresent(q) ? q : null,
      first,
      after: isPresent(after) ? after : null,
      person_id: this.get('profile.impersonatedUserId'),
      include_performer: includePerformer,
      include_producer: includeProducer,
      order
    }

    return this.apollo.query({ query, variables, fetchPolicy: 'network-only' })
  },

  setupController(controller, model, transition) {
    this._super(controller, model)
    controller.set('query', transition.to.queryParams.q)
  }
})
