import Component from '@ember/component'
import { inject } from '@ember/service'
import { queryManager } from 'ember-apollo-client'
import { computed } from '@ember/object'

export default Component.extend({
  intl: inject(),
  profile: inject(),
  tagName: '',
  apollo: queryManager(),

  showFileUpload: false,

  showOwnership: computed('recording.typename', function () {
    return this.get('recording.typename') === 'ProducerClaimRecording'
  }),
  showPerformance: computed('recording.typename', function () {
    return this.get('recording.typename') === 'PerformerClaimRecording'
  }),

  actions: {
    addFileToClaimRecording(claim_file) {
      const claim_files = this.get('recording.claim_files')
      claim_files.pushObject(claim_file)
      this.set('recording.claim_files', claim_files)
    },
    addFileToClaim(claim_file) {
      this.get('claim_files').pushObject(claim_file)
    },
    removeFileFromClaimRecording(claim_file_id, claim_recording_id) {
      const claimFiles = this.get('recording.claim_files')
      const updated = claimFiles.filter(file => {
        return file.id !== claim_file_id
      })
      this.get('removeFile')(claim_file_id, claim_recording_id)
      this.set('recording.claim_files', updated)
    },

    addNewFile() {
      this.set('fileType', 'new')
      this.set('showFileUpload', true)
    },
    addExistingFile() {
      this.set('fileType', 'existing')
      this.set('showFileUpload', true)
    },
    closeDialog() {
      this.set('showFileUpload', false)
    },
    addPerformance() {
      this.get('recording.performances').pushObject({})
    },
    removePerformance(performnace) {
      this.get('recording.performances').removeObject(performnace)
    }
  }
})
