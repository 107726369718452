import { helper as buildHelper } from '@ember/component/helper'
import { getWithDefault, get } from '@ember/object'

/**
 * NOTE
 *
 * I'm a bit unsure if this entryName function is correct.
 * I *think* a better solution to this is as follows:
 *
 * https://github.com/gramo-org/echo-front/blob/060c9b1286967edf22acfe4d2f1d7cb56cbce354/app/pods/components/accounting/ledger-entries/entry-details/template.hbs#L1-L14
 *
 * Don't let view-layer care as much as to which type of line we have. As a good
 * default we can render bank info if we have that. If we don't, try render
 * creditors if we have that. If we don't, try render debitors.
 *
 * .. And we are done.
 *
 * Let it be up to the data-layer (GraphQL query) to express which, or both, of
 * the sides we want to get.
 *
 * I didn't do anything in the implementation now, as I don't think min side
 * has ledgers for other than rightsholders atm.
 */
export function entryName([entry]) {
  if (get(entry, 'typename') === 'LedgerAdministrationFeeEntry') {
    return 'GRAMO'
  }
  const debitorsTypes = ['LedgerEntry', 'LedgerRemunerationEntry']
  const entryKey = debitorsTypes.includes(get(entry, 'typename')) ? 'debitors' : 'creditors'
  return getWithDefault(entry, `${entryKey}.edges`, [])
    .reduce((acc, creditor) => {
      const owner = creditor.node.owner
      if (owner.name) {
        return acc.concat(owner.name)
      }
      if (owner.artist_name) {
        return acc.concat(owner.artist_name)
      }
      if (owner.creditor) {
        return acc.concat(owner.creditor.name)
      }
      return acc
    }, [])
    .join(', ')
}

export default buildHelper(entryName)
