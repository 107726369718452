import Component from '@ember/component'
import { computed } from '@ember/object'

export default Component.extend({
  tagName: 'table',
  classNames: ['table table--fluid table--list table--five'],
  'data-test-search-performers-list': true,

  translationKeys: computed(() => [
    'search_performers_box.name',
    'search_performers_box.artist_name',
    'search_performers_box.birth_year',
    'search_performers_box.instrument',
    'search_performers_box.country'
  ])
})
