import { readOnly, equal } from '@ember/object/computed'
import Route from '@ember/routing/route'
import query from 'min-side/graphql/queries/ledger/remuneration-diff'
import { queryManager } from 'ember-apollo-client'
import { inject } from '@ember/service'
import EmberObject from '@ember/object'

export default Route.extend({
  apollo: queryManager(),
  profile: inject(),

  profileName: readOnly('profile.activeProfile.profile'),
  includePerformer: equal('profileName', 'performer'),
  includeProducer: equal('profileName', 'personal-producer'),
  renderTemplate(controller, model) {
    this._super(controller, model)

    this.render('user.ledger.remuneration.header-remuneration-entry-description', {
      outlet: 'remuneration-description',
      controller: this.controllerFor('user.ledger.remuneration.by-recording-diff.index')
    })
    this.render('user.ledger.remuneration.download', {
      outlet: 'ledger-tabs-controls',
      into: 'user.ledger.remuneration.by-recording-diff',
      controller: this.controllerFor('user.ledger.remuneration.by-recording-diff.index')
    })
  },

  async model() {
    const { id } = this.paramsFor('user.ledger.remuneration')
    const maskId = this.get('profile.impersonatedUserId')

    const variables = {
      id,
      rightsholder_id: this.get('profile.activeProfile.id'),
      person_id: maskId,
      include_performer: this.includePerformer,
      include_producer: this.includeProducer
    }
    const rawModel = await this.apollo.query({ query, variables })
    return EmberObject.extend({ ...rawModel }).create()
  }
})
