import Route from '@ember/routing/route'
import { inject } from '@ember/service'
import inheritanceQuery from 'min-side/graphql/queries/ledger/inheritance'

import { queryManager } from 'ember-apollo-client'

export default Route.extend({
  profile: inject(),
  apollo: queryManager(),

  model({ id }) {
    const maskId = this.get('profile.impersonatedUserId')
    const profileName = this.get('profile.activeProfile.profile')
    let includePerformer = true
    let includeProducer = true

    if (maskId !== null) {
      includePerformer = profileName === 'performer'
      includeProducer = profileName === 'personal-producer'
    }

    const variables = {
      id,
      person_id: maskId,
      include_performer: includePerformer,
      include_producer: includeProducer
    }

    return this.apollo.query({ query: inheritanceQuery, variables })
  }
})
