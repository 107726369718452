import { schedule } from '@ember/runloop'
import { isBlank } from '@ember/utils'
import TypeaheadTrigger from 'ember-power-select-typeahead/components/power-select-typeahead/trigger'
import { debounce } from '@ember/runloop'

const DEBOUNCE_TIME = 300
export default TypeaheadTrigger.extend({
  /* overwriting the original ember-power-select-typeahead-trigger method
    allows to set a new value on the input when no result found in the existing list
  */

  didReceiveAttrs(...rest) {
    this._super(...rest)
    const oldSelect = this.oldSelect
    const newSelect = this.set('oldSelect', this.select)
    if (!oldSelect) {
      return
    }
    const input = document.querySelector(
      `#ember-power-select-typeahead-input-${newSelect.uniqueId}`
    )

    debounce(() => {
      if (input && input.value !== this.getSelectedAsText() && newSelect.searchText) {
        schedule('actions', null, newSelect.actions.select, { name: input.value })
      }
    }, DEBOUNCE_TIME)
    /*
     * We need to update the input field with value of the selected option whenever we're closing
     * the select box.
     */
    if (oldSelect.isOpen && !newSelect.isOpen && newSelect.searchText) {
      const newText = this.getSelectedAsText()
      if (input.value !== newText) {
        input.value = newText
      }
      this.set('text', newText)
    }

    if (newSelect.lastSearchedText !== oldSelect.lastSearchedText) {
      if (isBlank(newSelect.lastSearchedText)) {
        schedule('actions', null, newSelect.actions.close, null, true)
      } else {
        schedule('actions', null, newSelect.actions.open)
      }
    }

    if (oldSelect.selected !== newSelect.selected) {
      this.set('text', this.getSelectedAsText())
    }
  }
})
