import { inject as service } from '@ember/service'
import BaseGlobalSearchResultsRoute from 'min-side/abstracts/routes/global-search-results-route'
import { getRecordings } from 'min-side/extractors/global-search'
import query from 'min-side/graphql/queries/global-search/recordings'

export default class GlobalSearchResultsRecordingsIndexRoute extends BaseGlobalSearchResultsRoute {
  queryParams = []
  @service profile
  renderTemplate(controller, model) {
    super.renderTemplate(controller, model)

    this.render('user.global-search-results.pagination', {
      outlet: 'pagination',
      into: 'user.global-search-results.recordings',
      controller
    })
  }
  async model() {
    const parentQueryParams = this.paramsFor('user.global-search-results')
    const recordingsParams = this.paramsFor('user.global-search-results.recordings')
    const variables = {
      ...parentQueryParams,
      ...recordingsParams,
      order_by: this._orderByFromUserOrDefault(recordingsParams),
      rightsholder_id: this.profile.current.meta.id,
      sector: this.profile.current.meta.sector
    }

    return getRecordings(await this.apollo.query({ query, variables, fetchPolicy: 'network-only' }))
  }
}
