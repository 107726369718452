import Route from '@ember/routing/route'
import { action } from '@ember/object'
import { urlFor } from 'min-side/utils/url-helpers'
import { tempErrorMsg, i18nBoundTo } from 'min-side/utils/error-helpers'
import { inject as service } from '@ember/service'
import { Headers } from 'fetch'
import { HTTP_UNASSIGNED, HTTP_UNAUTHORIZED } from 'min-side/constants/http-status-codes'

export default class LoginChoseMobileRoute extends Route {
  @service intl
  @service ajaxRequests

  @action
  async loginNextStep() {
    const controller = this.controllerFor('login-choose-mobile')
    const token = controller.token
    const dataToBeSent = {
      data: {
        attributes: {
          mobile: controller.mobile
        }
      },
      locale: this.intl.primaryLocale
    }

    try {
      const response = await this.ajaxRequests.postRequest(
        urlFor('auth/reidentify'),
        dataToBeSent,
        new Headers({
          Authorization: `Bearer ${token}`
        })
      )

      if (!response.ok) throw response

      const { data } = await response.json()

      const preconfiguredSMS = response.status === HTTP_UNASSIGNED ? 1 : 0
      this.transitionTo('verify', {
        queryParams: {
          token: data.attributes.token,
          mobileType: controller.mobile,
          preconfiguredSMS
        }
      })
    } catch (error) {
      const errorData = await error.json()

      let msg = [this.intl.t('login.failed'), this.intl.t('login.backend-is-down')].join('. ')

      if (errorData && errorData.errors && errorData.errors.length) {
        msg = tempErrorMsg(
          this.intl.t('login.failed'),
          errorData.errors,
          i18nBoundTo(this.intl, 'model.user')
        )
      }

      this.flashMessages.error(msg)

      if (error.status === HTTP_UNAUTHORIZED) {
        return this.transitionTo('inactive')
      }
    }
  }
}
