import Component from '@glimmer/component'
import { inject as service } from '@ember/service'

import merge from 'lodash/merge'
import 'chartjs-plugin-datalabels'

import colors from 'min-side/generated-files/generated-colors'

const colorVariables = colors['custom-properties']

const xAxesConfig = {
  ticks: {
    padding: 10,
    fontColor: colorVariables['--almost-grey'],
    beginAtZero: true
  },
  gridLines: {
    drawTicks: false,
    drawBorder: false,
    display: false,
    color: colorVariables['--almost-grey'],
    zeroLineColor: colorVariables['--almost-grey']
  }
}
const yAxesConfig = {
  ticks: {
    padding: 10,
    fontColor: colorVariables['--almost-grey'],
    beginAtZero: true
  },
  gridLines: {
    drawTicks: false,
    drawBorder: false,
    color: colorVariables['--very-dark-grey'],
    zeroLineColor: colorVariables['--very-dark-grey']
  }
}

const defaultOptions = {
  scales: {
    xAxes: [xAxesConfig],
    yAxes: [yAxesConfig]
  },
  legend: {
    display: false
  },
  plugins: {
    datalabels: {
      display: false,
      color: colorVariables['--text-black'],
      font: {
        size: 15,
        weight: 'bold'
      },
      textAlign: 'center',
      align: 'bottom',
      anchor: 'end'
    }
  }
}

const defaultData = {
  datasets: [
    {
      backgroundColor: colorVariables['--lime-green']
    }
  ]
}

export default class Chart extends Component {
  @service router

  get graphOptions() {
    return merge({}, defaultOptions, this.args.options)
  }

  get graphData() {
    return merge({}, defaultData, this.args.data)
  }
}
