import Service from '@ember/service'
import { inject as service } from '@ember/service'
import { computed, get } from '@ember/object'

const { localStorage } = window

export default Service.extend({
  profile: service(),
  showOnboarding: computed('profile.activeProfile.profile', {
    get() {
      if (this.__showOnboarding) {
        return this.__showOnboarding
      }
      return !this._getProfileOnboarded(get(this, 'profile.activeProfile.profile'))
    },
    set(key, value) {
      this.__showOnboarding = value
      return value
    }
  }),

  dismissOnboarding() {
    this.set('showOnboarding', false)
    this._setProfileOnboarded(get(this, 'profile.activeProfile.profile'), true)
  },

  reset() {
    localStorage.removeItem('profileOnboarded')
  },

  flash(msg) {
    get(this, 'flashMessages').success(msg)
  },

  _getProfileOnboarded(profile) {
    const settings = JSON.parse(localStorage.getItem('profileOnboarded') || null)
    return settings ? settings[profile] : null
  },

  _setProfileOnboarded(profile, setting) {
    const settings = JSON.parse(localStorage.getItem('profileOnboarded') || null) || {}
    settings[profile] = setting
    localStorage.setItem('profileOnboarded', JSON.stringify(settings))
  }
})
