import BaseGlobalSearchControllerController from 'min-side/abstracts/controllers/global-search-results-controller'

export default class GlobalSearchResultsReleasesController extends BaseGlobalSearchControllerController {
  get headerTranslationKeys() {
    return [
      {
        key: 'tite',
        sortApiKey: 'TITLE',
        translationKey: 'global_search_releases.title'
      },
      {
        key: 'mainArtist',
        sortApiKey: 'MAIN_ARTIST',
        translationKey: 'global_search_releases.main_artist'
      },
      {
        key: 'released',
        sortApiKey: 'RELEASE_DATE',
        translationKey: 'global_search_releases.published',
        textRight: true
      },
      {
        key: 'recordLabel',
        sortApiKey: 'LABEL_NAME',
        translationKey: 'global_search_releases.label'
      },
      {
        key: 'catalogNumber',
        sortApiKey: 'CATALOG_NUMBER',
        translationKey: 'global_search_releases.catalog_number',
        textRight: true
      },
      {
        key: 'format',
        sortApiKey: 'FORMAT',
        translationKey: 'global_search_releases.format'
      }
    ]
  }
}
