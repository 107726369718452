import Route from '@ember/routing/route'
import LocaleMixin from 'min-side/mixins/locale'

export default Route.extend(LocaleMixin, {
  beforeModel(transition) {
    const locale = transition.to.params.locale

    if (['en', 'nb'].includes(locale)) {
      this.setLocale(locale)
      this.transitionTo('/')
      return
    }
    this.transitionTo('not-found', locale)
  }
})
