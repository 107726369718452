import Controller from '@ember/controller'
import { inject } from '@ember/service'
import { computed } from '@ember/object'
import { profileNameInModel } from 'min-side/helpers/profiles'

export default Controller.extend({
  queryParams: ['sector'],
  profile: inject(),

  ledger: computed('model', function () {
    const activeProfile = this.get('profile.activeProfile.profile')
    if (!activeProfile) {
      return {}
    }
    if (activeProfile === 'contact') return this.get('model.ledger')
    return this.get(`model.user.person.${profileNameInModel(activeProfile)}.ledger`)
  }),

  payment: computed('ledger', function () {
    const ledger = this.ledger
    return ledger.entries[0]
  })
})
