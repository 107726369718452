import BaseGlobalSearchControllerController from 'min-side/abstracts/controllers/global-search-results-controller'
import { computed } from '@ember/object'

export default class GlobalSearchResultsRecordingsController extends BaseGlobalSearchControllerController {
  @computed('parentController.include_gramo_registry')
  get headerTranslationKeys() {
    const tabs = [
      {
        key: 'title',
        sortApiKey: 'TITLE',
        translationKey: 'global_search_recordings.title'
      },
      {
        key: 'mainArtist',
        sortApiKey: 'MAIN_ARTIST',
        translationKey: 'global_search_recordings.main_artist'
      },
      {
        key: 'released',
        sortApiKey: 'RELEASE_DATE',
        translationKey: 'global_search_recordings.published',
        textRight: true
      },
      {
        key: 'recordLabel',
        sortApiKey: 'LABEL_NAME',
        translationKey: 'global_search_recordings.label'
      },
      {
        key: 'isrc',
        sortApiKey: 'ISRC',
        translationKey: 'global_search_recordings.isrc'
      },
      {
        key: 'durationInSeconds',
        sortApiKey: 'DURATION_IN_SECONDS',
        translationKey: 'global_search_recordings.duration',
        textRight: true
      },
      {
        key: 'roleCode',
        sortApiKey: 'ROLE_CODES',
        translationKey: 'global_search_recordings.role_code'
      }
    ]

    if (this.parentController.include_gramo_registry) {
      return tabs.filter(tab => {
        return tab.key !== 'roleCode'
      })
    }

    return tabs
  }
}
