export const getBankInfo = bankInfo => {
  switch (bankInfo.accountType) {
    case 'norwegian':
      return {
        norwegian_bank_account: {
          account_number: bankInfo.norwegian_account_number || '',
          currency: bankInfo.currency
        }
      }
    case 'iban':
      return {
        iban_bank_account: {
          account_number: bankInfo.international_account_number || '',
          currency: bankInfo.currency
        }
      }
    case 'bic-swift':
      return {
        bic_bank_account: {
          bic: bankInfo.bic,
          account_number: bankInfo.account_number || '',
          currency: bankInfo.currency
        }
      }
    case 'american':
      return {
        american_bank_account: {
          account_number: bankInfo.account_number || '',
          bank_name: bankInfo.bank_name,
          bank_code: bankInfo.bank_code,
          currency: bankInfo.currency
        }
      }
    default:
      return null
  }
}

export const serializeBankAccount = account => {
  if (account && account.__typename) {
    switch (account.__typename) {
      case 'NorwegianBankAccount':
        if (account.norwegian_account_number)
          account.account_number = account.norwegian_account_number
        delete account.norwegian_account_number
        break
      case 'IBANBankAccount':
        if (account.international_account_number)
          account.account_number = account.international_account_number
        delete account.international_account_number
    }
  }
}
