import Mixin from '@ember/object/mixin'
import { inject } from '@ember/service'
import { computed, get } from '@ember/object'
import { profileNameInModel } from 'min-side/helpers/profiles'

export default Mixin.create({
  profile: inject(),
  currentProfile: computed('model', 'profile.activeProfile', function () {
    const activeProfile = this.get('profile.activeProfile.profile')
    const id = this.get('profile.activeProfile.id')
    if (!activeProfile) return []
    const profileName = profileNameInModel(activeProfile)
    switch (profileName) {
      case 'contact': {
        const positions = this.getWithDefault('model.user.person.contact.positions', []).find(
          position => position.organization.id === id
        )
        return get(positions || {}, 'organization.producer_company')
      }
      default:
        return this.get(`model.user.person.${profileNameInModel(activeProfile)}`)
    }
  })
})
