import Route from '@ember/routing/route'
import { inject } from '@ember/service'
import UnauthenticatedRouteMixin from 'ember-simple-auth/mixins/unauthenticated-route-mixin'

const PASSWORD_MINIMUM_LENGTH = 6

export default Route.extend(UnauthenticatedRouteMixin, {
  intl: inject(),
  invitation: inject(),
  session: inject(),

  beforeModel(transition) {
    this.set('invitationToken', transition.to.params.invitation_token)
    this.get('invitation.verifyInvitationTokenTask').perform(this.invitationToken)
  },

  model() {
    return {
      errors: [],
      email: '',
      password: '',
      passwordConfirmation: ''
    }
  },

  actions: {
    cancelAction() {
      this.transitionTo('login')
    },
    submitAction() {
      if (!this._validate()) {
        return
      }
      this.get('invitation.submitTask').perform(
        this.invitationToken,
        this.currentModel.email,
        this.currentModel.password
      )
    }
  },

  _validate() {
    let valid = true
    const errors = []

    if (!(this.currentModel.password === this.currentModel.passwordConfirmation)) {
      errors.push({
        attribute: 'passwordConfirmation',
        message: this.intl.t('validation.passwords-not-match')
      })
      valid = false
    }

    if (this.currentModel.password.length < PASSWORD_MINIMUM_LENGTH) {
      errors.push({
        attribute: 'password',
        message: this.intl.t('validation.password-short')
      })
      valid = false
    }

    if (!this.currentModel.email.includes('@')) {
      errors.push({
        attribute: 'email',
        message: this.intl.t('validation.invalid-email')
      })
      valid = false
    }

    this.set('currentModel.errors', errors)
    return valid
  }
})
