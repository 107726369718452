import Route from '@ember/routing/route'
import { inject } from '@ember/service'
import { isPresent } from '@ember/utils'
import performerQuery from 'min-side/graphql/queries/members-recordings-performer'
import producerQuery from 'min-side/graphql/queries/members-recordings-personal-producer'
import { queryManager } from 'ember-apollo-client'
import { profileNameInModel } from 'min-side/helpers/profiles'

export default Route.extend({
  apollo: queryManager(),
  profile: inject(),
  router: inject(),
  features: inject(),

  queryParams: {
    q: { refreshModel: true },
    property: { refreshModel: true },
    direction: { refreshModel: true },
    after: { refreshModel: true },
    first: { refreshModel: true }
  },

  beforeModel() {
    if (!this.features.get('discography_rework')) {
      this.router.transitionTo('')
    }
  },

  model({ q, property, direction, after, first }) {
    const order = []
    if (property && direction) {
      order.push({ property, direction })
    }

    let query

    switch (profileNameInModel(this.get('profile.activeProfile.profile'))) {
      case 'performer':
        query = performerQuery
        break
      case 'personal_producer':
        query = producerQuery
        break
    }

    const variables = {
      query: isPresent(q) ? q : null,
      order,
      after,
      first,
      played: true
    }

    this.controllerFor('user.played-recordings').set('loading', true)

    return this.apollo.query({ query, variables, fetchPolicy: 'network-only' }).then(response => {
      this.controllerFor('user.played-recordings').set('loading', false)
      return response
    })
  },

  actions: {
    onOrderByChange(property, direction) {
      this.transitionTo({ queryParams: { property, direction, after: null } })
    }
  }
})
