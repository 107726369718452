import Helper from '@ember/component/helper'
import moment from 'moment'
import { TIME_VALUES } from 'min-side/constants/time'

const { MILISECONDS_IN_SECOND } = TIME_VALUES

export function durationInMinutes(seconds) {
  return moment(MILISECONDS_IN_SECOND * seconds).format('mm:ss')
}

export default Helper.helper(durationInMinutes)
