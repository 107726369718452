import Component from '@ember/component'
import { equal, or, reads } from '@ember/object/computed'

export default Component.extend({
  tagName: '',
  supportsDataTestProperties: true,

  isRecording: equal('recording.typename', 'Recording'),

  infoDisabled: or('disabled', 'isRecording'),
  trackDisabled: reads('disabled'),

  showTrackInputs: true,
  actions: {
    changeDuration(duration) {
      this.set('recording.duration_in_seconds', duration)
    }
  }
})
