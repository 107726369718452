import Component from '@ember/component'
import { assert } from '@ember/debug'
import { isPresent } from '@ember/utils'

export default Component.extend({
  justShowEdit: false /* To only show the edit link in the header section */,
  justShowSave: false /* To only show the save/cancel buttons in a footer section */,
  actionsClassNames: 'form__section--action form__section--flex',

  /**
   * Adds the default wrapper class of 'section-header__action'.
   * Should be set to 'false' when the above properties are set to 'true'
   */
  inHeader: true,

  classNameBindings: ['inHeader:section-header__action'],

  init(...args) {
    this._super(...args)

    assert(
      'Component `edit-controls` must have `enableInputsAction` closure action passed',
      isPresent(this.enableInputsAction)
    )

    assert(
      'Component `edit-controls` must have `restoreOriginalValuesAction` closure action passed',
      isPresent(this.restoreOriginalValuesAction)
    )

    assert(
      'Component `edit-controls` must have `saveAction` closure action passed',
      isPresent(this.saveAction)
    )
  },

  actions: {
    enableInputs(e) {
      this.enableInputsAction()
      e.preventDefault()
    }
  }
})
