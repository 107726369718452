import moment from 'moment'

export const checkIfAgencyAgreementActive = agencyAgreement => {
  const isBeforeEndData =
    agencyAgreement.ends_on === null ? true : moment().isBefore(agencyAgreement.ends_on)
  const isAfterStartDate = moment().isAfter(agencyAgreement.starts_on)

  return isAfterStartDate && isBeforeEndData
}

export const extractProfilesWithoutActiveAgencyAgreements = profiles => {
  return profiles.filter(profile => {
    const agencyAgreementsExist =
      profile.agencyAgreements !== undefined && profile.agencyAgreements.length !== 0
    let isAgencyAgreementInactive

    if (agencyAgreementsExist) {
      const lastAgencyAgreement = profile.agencyAgreements.slice(-1)[0]
      isAgencyAgreementInactive = !checkIfAgencyAgreementActive(lastAgencyAgreement)
    }
    return (
      isAgencyAgreementInactive ||
      profile.agencyAgreements === undefined ||
      profile.agencyAgreements.length === 0
    )
  })
}
