import performerQuery from 'min-side/graphql/queries/profile/performer'
import personalProducerQuery from 'min-side/graphql/queries/profile/personal-producer'
import heirQuery from 'min-side/graphql/queries/profile/heir'
import { PROFILE_TYPES } from 'min-side/constants/profile'

export const getQueryByProfileType = profileType => {
  const { PERSONAL_PRODUCER, PERFORMER, INHERITANCE_PARTY } = PROFILE_TYPES
  switch (profileType) {
    case PERSONAL_PRODUCER:
      return personalProducerQuery
    case PERFORMER:
      return performerQuery
    case INHERITANCE_PARTY:
      return heirQuery
    default:
      throw new Error(`No query for profile type "${profileType}" found.`)
  }
}
