import Route from '@ember/routing/route'
import query from 'min-side/graphql/queries/album'
import { queryManager } from 'ember-apollo-client'

const magicNumber = 578

export default Route.extend({
  apollo: queryManager,
  model() {
    return this.apollo.watchQuery({ query }).then(model => {
      model.set('main_artist', 'God Forbid')
      model.set('title', 'Constitution of Treason')
      model.set('genre', 'Metal')
      model.set('format', 'CD')
      model.set('label', 'EMI')
      model.set('recording_country', magicNumber)
      model.set('producing_country', magicNumber)
      model.set('release_date', '2007-12-12')
      model.set('sides', [
        [
          {
            title: 'Take on Me',
            main_artist: 'A-HA',
            duration: 190,
            ISRC: 'USJT19900027'
          },
          {
            title: 'Hunting High and Low',
            main_artist: 'A-HA',
            duration: 190,
            ISRC: 'USJT19900031'
          }
        ],
        [
          {
            title: 'Take on Me',
            main_artist: 'A-HA',
            duration: 190,
            ISRC: 'USJT19900030'
          },
          {
            title: 'Hunting High and Low',
            main_artist: 'A-HA',
            duration: 190,
            ISRC: 'USJT19900027'
          }
        ]
      ])
      model.set('performers', [{ name: 'John Doe' }, { name: 'Joan Doe' }])
      return model
    })
  }
})
