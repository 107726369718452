import Component from '@ember/component'
import { computed } from '@ember/object'
import { set } from '@ember/object'
import { equal, mapBy } from '@ember/object/computed'
import EmberObject from '@ember/object'
import Changeset from 'ember-changeset'
import PerformerValidations from 'min-side/validations/new-release/performer'
import lookupValidator from 'ember-changeset-validations'
import { inject } from '@ember/service'
import { NEW_RELEASE_FORM_STEPS } from 'min-side/constants/new-release-steps'
import { resolve } from 'rsvp'

const { PERFORMERS_STEP } = NEW_RELEASE_FORM_STEPS

export default Component.extend({
  'data-test-release-form-performers': true,
  tagName: 'section',
  intl: inject(),
  eventBus: inject(),

  init(...args) {
    this._super(...args)

    this.setProperties({
      selectedInstruments: [],
      disabled: false,
      showSearchBox: false
    })

    this.eventBus.subscribe('stepBackToOwnership', this, '_enableInputs')
  },

  willDestroyElement(...args) {
    this._super(...args)
    this.eventBus.unsubscribe('stepBackToReleaseInformation', this, '_enableInputs')
  },

  isCurrentStep: equal('currentStep', PERFORMERS_STEP),

  showAddNewPerformer: computed('performersData.[]', 'showSearchBox', 'disabled', function () {
    return !this.showSearchBox && (!this.disabled || this.performersData.length === 0)
  }),

  alreadySelectedPerformersIds: mapBy('performersData', 'id'),

  addPropertiesToPerformer(performer) {
    performer.saved = false
    set(performer, 'role_code', null)
    /**
     * If performer does have an main instrument, we then preset the instrument_code
     */
    if (this.__performerHasMainInstrument(performer)) {
      set(performer, 'instrument_code', performer.performer.main_instrument.code)
    }
    // performer.role_code = null
    set(performer, 'mapped_errors', EmberObject.create({ errors: [] }))
    // performer.mapped_errors = EmberObject.create({ errors: [] })
    return performer
  },

  validate() {
    this.performersData.forEach(changeset => {
      changeset.validate()
    })
  },

  isInvalid() {
    return this.performersData.any(changeset => changeset.get('isInvalid'))
  },

  saveChanges() {
    this.performersData.forEach(changeset => {
      changeset.set('saved', true)
      changeset.save()
    })
  },

  rollbackChanges() {
    // debugger
    const performers = this.performersData.filter(changeset => changeset.get('saved'))
    performers.forEach(changeset => {
      changeset.rollback()
    })
    this.set('performersData', performers)
  },

  submitAction() {
    this.validate()

    if (this.isInvalid()) {
      this.mapValidationErrors()
    } else {
      this.saveChanges()
      this.savePerformersAction(this.performersData)
      this.disableForm()
    }
  },

  rollbackAction() {
    this.rollbackChanges()
    this.revertSelectedInstruments()
    this.disableForm()
  },

  _enableInputs() {
    this.set('disabled', false)
    this.updateFormEditStatusAction(PERFORMERS_STEP, true)
  },

  revertSelectedInstruments() {
    const instruments = this.performersData.map(performer => {
      const code = performer.get('instrument_code')
      return this.instruments.find(instrument => instrument.id === code)
    })
    this.set('selectedInstruments', instruments)
  },

  mapValidationErrors() {
    this.performersData.forEach(changeset => {
      const errors = []
      changeset.get('errors').forEach(error => {
        errors.push(
          EmberObject.extend({
            attribute: error.key,
            message: this.intl.t('validation.empty')
          }).create()
        )
      })
      changeset.get('mapped_errors').set('errors', errors)
    })
  },

  disableForm() {
    this.setProperties({
      disabled: true,
      showSearchBox: false
    })
    this.updateFormEditStatusAction(PERFORMERS_STEP, false)
  },

  clearPerformers() {
    this.set('performersData', [])
    this.set('selectedInstruments', [])
  },

  actions: {
    submit() {
      this.submitAction()
      if (!this.isInvalid()) this.stepForwardAction()
    },

    async save() {
      this.submitAction()
      resolve(true)
    },

    rollback() {
      this.rollbackAction()
    },

    stepBack() {
      this.rollbackAction()
      this.clearPerformers()
      this.stepBackAction()
    },

    edit() {
      this._enableInputs()
    },

    toggleSearch() {
      this.set('disabled', false)
      this.toggleProperty('showSearchBox')
    },

    addPerformer(performer) {
      const performerChangeset = new Changeset(
        this.addPropertiesToPerformer(performer),
        lookupValidator(PerformerValidations),
        PerformerValidations
      )

      this.performersData.addObject(performerChangeset)

      /**
       * We preselect the main instrument if the artist has one
       */
      if (this.__performerHasMainInstrument(performer)) {
        this.selectedInstruments.addObject({
          name: performer.performer.main_instrument.name,
          id: performer.performer.main_instrument.code
        })
      }

      this.set('searchPerformersTask.last.value', null)

      this.toggleProperty('showSearchBox')

      if (!this.afterFirstSave && this.disabled) {
        this.disableForm()
      }
    },

    removePerformer(index) {
      this.performersData.removeAt(index)
      this.selectedInstruments.removeAt(index)
    }
  },

  __performerHasMainInstrument(performer) {
    return (
      performer.performer &&
      performer.performer.main_instrument &&
      performer.performer.main_instrument.code
    )
  }
})
