import { mapBy, sort, reads } from '@ember/object/computed'

import RemunerationController from '../../controller'

export default RemunerationController.extend({
  broadcasterEdges: reads('remunerationEntry.snapshot.diff.edges'),

  groupedByChannel: mapBy('broadcasterEdges', 'node'),

  sorting: ['channel.broadcaster.name', 'channel.name'],
  sortedNodes: sort('groupedByChannel', 'sorting'),
  snapshotId: reads('remunerationEntry.snapshot.id'),
  groupBy: 'channel'
})
