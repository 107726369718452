import Component from '@glimmer/component'
import { inject as service } from '@ember/service'
import { action } from '@ember/object'
import { urlFor } from 'min-side/utils/url-helpers'
import { dateRangeInputFromPeriod } from 'min-side/utils/date-ranges'

const HTTP_NOT_IMPLEMENTED = 501
const download_types = {
  ledger: {
    required: ['ownerId', 'sector', 'period'],
    url: urlFor('files/export/ledger')
  },
  distribution_area_channels: {
    required: ['ownerId', 'sector', 'distributionAreaId', 'year'],
    url: urlFor('files/export/distribution_area_channels')
  },
  discography: {
    required: ['ownerId', 'sector'],
    url: urlFor('files/export/discography')
  },
  distribution: {
    required: ['ownerId', 'sector', 'snapshotId', 'groupBy'],
    url: urlFor('files/export/distribution')
  },
  payment: {
    required: ['statementId'],
    url: urlFor('/files/payment-statement')
  }
}

export default class DownloadButton extends Component {
  @service session
  @service flashMessages
  @service ajaxRequests
  @service intl
  @service features

  constructor(...args) {
    super(...args)
    // check the arguments are present when the component is rendered
    this.validateDownloadType(this.args)
  }

  // check the arguments against the download type and return the parameters for the download type
  validateDownloadType(args) {
    if (!args.type) {
      throw new Error('Download button must have a type')
    }
    const download_type = download_types[args.type]
    if (!download_type) {
      throw new Error('Invalid download button type')
    }

    const params = {}
    for (const required of download_type.required) {
      if (!args[required]) {
        throw new Error(`Missing '${required}' argument for download button type`)
      }
      params[required.decamelize()] = args[required]
    }

    // calculate the date range if given a period string
    if (params.period) {
      const range = dateRangeInputFromPeriod(params.period, args.from, args.to)
      if (!range) {
        throw new Error(`Invalid period for download button`)
      }
      params.from = range.from
      params.to = range.to
      delete params.period
    }

    return { url: download_type.url, params }
  }

  @action
  async download(
    message = this.intl.t('download_alert'),
    limit_message = this.intl.t('download_limit_error')
  ) {
    const download_type = this.validateDownloadType(this.args)
    this.flashMessages.success(message)
    const response = await this.ajaxRequests.getRequest(
      download_type.url,
      download_type.params,
      undefined,
      true
    )

    if (!response.ok) {
      this.flashMessages.clearMessages()
      if (response.status === HTTP_NOT_IMPLEMENTED) {
        this.flashMessages.error(limit_message)
      } else {
        this.flashMessages.error(this.intl.t('flash.http_codes.other'))
      }
      return
    }

    const blob = await response.blob()
    if (window.navigator && window.navigator.msSaveBlob) {
      window.navigator.msSaveBlob(blob, `${this.args.type}.xlsx`)
    } else {
      const a = document.createElement('a')
      a.setAttribute('href', window.URL.createObjectURL(blob))
      a.setAttribute('download', this.args.type)
      a.click()
    }
  }
}
