import { warn } from '@ember/debug'
import { computed, getWithDefault } from '@ember/object'
import FlashMessage from 'ember-cli-flash/components/flash-message'

export default FlashMessage.extend({
  'data-test-flash-message': true,

  alertType: computed('flash.type', {
    get() {
      const flashType = getWithDefault(this, 'flash.type', '')
      return `flash-message flash-message--${flashType}`
    },

    set() {
      warn('`alertType` is read only')

      return this
    }
  }),
  actions: {
    abort() {
      this.trigger('destroyMessage')
      this.onAbort()
    }
  }
})
