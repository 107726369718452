export default {
  "data": {
    "__schema": {
      "types": [
        {
          "kind": "INPUT_OBJECT",
          "name": "AddClaimRecordingToClaimFileInput",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "AddClaimRecordingToClaimFilePayload",
          "possibleTypes": null
        },
        {
          "kind": "INPUT_OBJECT",
          "name": "AddFileToClaimInput",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "AddFileToClaimPayload",
          "possibleTypes": null
        },
        {
          "kind": "INPUT_OBJECT",
          "name": "AddPlaybacksToClaimInput",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "AddPlaybacksToClaimPayload",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "Address",
          "possibleTypes": null
        },
        {
          "kind": "INPUT_OBJECT",
          "name": "AddressInput",
          "possibleTypes": null
        },
        {
          "kind": "UNION",
          "name": "AddressUnion",
          "possibleTypes": [
            {
              "name": "Address"
            },
            {
              "name": "InternationalAddress"
            }
          ]
        },
        {
          "kind": "OBJECT",
          "name": "Agency",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "AgencyAgreement",
          "possibleTypes": null
        },
        {
          "kind": "ENUM",
          "name": "AgencyAgreementPrincipalType",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "AgencyStatement",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "AlternativeArtistName",
          "possibleTypes": null
        },
        {
          "kind": "INPUT_OBJECT",
          "name": "AlternativeArtistNameInput",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "AlternativeCatalogNumber",
          "possibleTypes": null
        },
        {
          "kind": "INPUT_OBJECT",
          "name": "AlternativeCatalogNumberInput",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "AlternativeIsrc",
          "possibleTypes": null
        },
        {
          "kind": "INPUT_OBJECT",
          "name": "AlternativeIsrcInput",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "AlternativeName",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "AlternativeTitle",
          "possibleTypes": null
        },
        {
          "kind": "INPUT_OBJECT",
          "name": "AlternativeTitleInput",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "AmericanBankAccount",
          "possibleTypes": null
        },
        {
          "kind": "INPUT_OBJECT",
          "name": "AmericanBankAccountInput",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "AnnualIncomeStatement",
          "possibleTypes": null
        },
        {
          "kind": "UNION",
          "name": "AsPersonOrCompanyUnion",
          "possibleTypes": [
            {
              "name": "Person"
            },
            {
              "name": "ProducerCompany"
            }
          ]
        },
        {
          "kind": "UNION",
          "name": "AsPublicPersonOrPublicCompanyUnion",
          "possibleTypes": [
            {
              "name": "PublicPerson"
            },
            {
              "name": "PublicProducerCompany"
            }
          ]
        },
        {
          "kind": "INPUT_OBJECT",
          "name": "AssignElectronicSignatureDocumentInput",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "AssignElectronicSignatureDocumentPayload",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "BICBankAccount",
          "possibleTypes": null
        },
        {
          "kind": "INPUT_OBJECT",
          "name": "BICBankAccountInput",
          "possibleTypes": null
        },
        {
          "kind": "UNION",
          "name": "BankAccount",
          "possibleTypes": [
            {
              "name": "AmericanBankAccount"
            },
            {
              "name": "BICBankAccount"
            },
            {
              "name": "IBANBankAccount"
            },
            {
              "name": "NorwegianBankAccount"
            }
          ]
        },
        {
          "kind": "SCALAR",
          "name": "BankRoutingTransferNumber",
          "possibleTypes": null
        },
        {
          "kind": "SCALAR",
          "name": "Boolean",
          "possibleTypes": null
        },
        {
          "kind": "ENUM",
          "name": "BroadcastMedium",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "Broadcaster",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "BrregConnectionError",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "BrregOrganization",
          "possibleTypes": null
        },
        {
          "kind": "UNION",
          "name": "BrregOrganizationResult",
          "possibleTypes": [
            {
              "name": "BrregConnectionError"
            },
            {
              "name": "BrregOrganization"
            }
          ]
        },
        {
          "kind": "SCALAR",
          "name": "BusinessIdentifierCodeType",
          "possibleTypes": null
        },
        {
          "kind": "INTERFACE",
          "name": "ChangeRequestInterface",
          "possibleTypes": [
            {
              "name": "OwnershipsChangeRequest"
            },
            {
              "name": "PerformancesChangeRequest"
            }
          ]
        },
        {
          "kind": "OBJECT",
          "name": "Channel",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "ChannelGroupsType",
          "possibleTypes": null
        },
        {
          "kind": "ENUM",
          "name": "ChannelReportingType",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "Citizenship",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "Claim",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "ClaimEdge",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "ClaimFile",
          "possibleTypes": null
        },
        {
          "kind": "INTERFACE",
          "name": "ClaimRecordingInterface",
          "possibleTypes": [
            {
              "name": "PerformerClaimRecording"
            },
            {
              "name": "ProducerClaimRecording"
            }
          ]
        },
        {
          "kind": "OBJECT",
          "name": "ClaimRecordingPerformance",
          "possibleTypes": null
        },
        {
          "kind": "INPUT_OBJECT",
          "name": "ClaimRecordingPerformanceInput",
          "possibleTypes": null
        },
        {
          "kind": "INPUT_OBJECT",
          "name": "ClaimRecordingPlaybackInput",
          "possibleTypes": null
        },
        {
          "kind": "ENUM",
          "name": "ClaimRecordingStates",
          "possibleTypes": null
        },
        {
          "kind": "ENUM",
          "name": "ClaimSector",
          "possibleTypes": null
        },
        {
          "kind": "ENUM",
          "name": "ClaimStates",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "ClaimsConnectionType",
          "possibleTypes": null
        },
        {
          "kind": "SCALAR",
          "name": "CoercibleInteger",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "CommissionDetailsStatement",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "CommissionDistribution",
          "possibleTypes": null
        },
        {
          "kind": "ENUM",
          "name": "CommissionDistributionState",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "CompanyContactParty",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "Composer",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "ComposerConnectionWithTotalType",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "ComposerEdge",
          "possibleTypes": null
        },
        {
          "kind": "INPUT_OBJECT",
          "name": "ComposerSearchResultOrderInput",
          "possibleTypes": null
        },
        {
          "kind": "ENUM",
          "name": "ComposerSortableFields",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "ComposersRecordingsConnectionType",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "Contact",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "ContactInformation",
          "possibleTypes": null
        },
        {
          "kind": "INPUT_OBJECT",
          "name": "ContactInformationInput",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "Contributor",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "Country",
          "possibleTypes": null
        },
        {
          "kind": "INPUT_OBJECT",
          "name": "CountryRelationWithFromAndToDatesInput",
          "possibleTypes": null
        },
        {
          "kind": "INPUT_OBJECT",
          "name": "CreateOwnershipsChangeRequestInput",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "CreateOwnershipsChangeRequestPayload",
          "possibleTypes": null
        },
        {
          "kind": "INPUT_OBJECT",
          "name": "CreatePerformancesChangeRequestsInput",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "CreatePerformancesChangeRequestsPayload",
          "possibleTypes": null
        },
        {
          "kind": "INPUT_OBJECT",
          "name": "CreateRecordingForReleaseInput",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "CreateRecordingForReleasePayload",
          "possibleTypes": null
        },
        {
          "kind": "INPUT_OBJECT",
          "name": "CreateRecordingFromDraftInput",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "CreateRecordingFromDraftPayload",
          "possibleTypes": null
        },
        {
          "kind": "INPUT_OBJECT",
          "name": "CreateReleaseWithRecordingsInput",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "CreateReleaseWithRecordingsPayload",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "Currency",
          "possibleTypes": null
        },
        {
          "kind": "SCALAR",
          "name": "CurrencyCodeType",
          "possibleTypes": null
        },
        {
          "kind": "SCALAR",
          "name": "CurrencyExchangeRate",
          "possibleTypes": null
        },
        {
          "kind": "SCALAR",
          "name": "Date",
          "possibleTypes": null
        },
        {
          "kind": "INPUT_OBJECT",
          "name": "DateRangeInput",
          "possibleTypes": null
        },
        {
          "kind": "INPUT_OBJECT",
          "name": "DeleteClaimFileInput",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "DeleteClaimFilePayload",
          "possibleTypes": null
        },
        {
          "kind": "INPUT_OBJECT",
          "name": "DeleteFileInput",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "DeleteFilePayload",
          "possibleTypes": null
        },
        {
          "kind": "ENUM",
          "name": "DeleteFileResult",
          "possibleTypes": null
        },
        {
          "kind": "ENUM",
          "name": "Direction",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "Distribution",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "DistributionArea",
          "possibleTypes": null
        },
        {
          "kind": "ENUM",
          "name": "DistributionAreaMixedType",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "DistributionAreaYear",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "DistributionChannelRates",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "DistributionProfile",
          "possibleTypes": null
        },
        {
          "kind": "ENUM",
          "name": "DistributionProfileProtectionDateType",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "DistributionRates",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "DistributionSnapshot",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "DistributionSnapshotChannelPlaytimes",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "DistributionSnapshotChannelPoints",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "DistributionSnapshotDiff",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "DistributionSnapshotDiffConnection",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "DistributionSnapshotDiffEdge",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "DistributionSnapshotPlaytimes",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "DistributionSnapshotPoints",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "DistributionSnapshotRemunerationDetailsByChannelConnection",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "DistributionSnapshotRemunerationDetailsByChannelEdge",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "DistributionSnapshotRemunerationDetailsConnection",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "DistributionSnapshotRemunerationDetailsEdgeType",
          "possibleTypes": null
        },
        {
          "kind": "ENUM",
          "name": "DistributionSnapshotState",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "DistributionSnapshotStats",
          "possibleTypes": null
        },
        {
          "kind": "ENUM",
          "name": "DistributionSnapshotType",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "ElectronicSignatureDocument",
          "possibleTypes": null
        },
        {
          "kind": "ENUM",
          "name": "ElectronicSignatureDocumentStates",
          "possibleTypes": null
        },
        {
          "kind": "ENUM",
          "name": "ElectronicSignatureDocumentTypes",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "ElectronicSignaturePartiesConnectionType",
          "possibleTypes": null
        },
        {
          "kind": "INTERFACE",
          "name": "ElectronicSignaturePartyInterface",
          "possibleTypes": [
            {
              "name": "CompanyContactParty"
            },
            {
              "name": "PersonWithProfileParty"
            }
          ]
        },
        {
          "kind": "OBJECT",
          "name": "ElectronicSignaturePartyInterfaceEdge",
          "possibleTypes": null
        },
        {
          "kind": "INTERFACE",
          "name": "ElectronicSignaturePartyOwnerInterface",
          "possibleTypes": [
            {
              "name": "Contact"
            },
            {
              "name": "Performer"
            },
            {
              "name": "Person"
            },
            {
              "name": "PersonalProducer"
            }
          ]
        },
        {
          "kind": "SCALAR",
          "name": "Email",
          "possibleTypes": null
        },
        {
          "kind": "ENUM",
          "name": "EnabledState",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "Error",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "ExternalRecording",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "Feature",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "Federation",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "FederationMembership",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "FederationStatement",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "File",
          "possibleTypes": null
        },
        {
          "kind": "INPUT_OBJECT",
          "name": "FileInput",
          "possibleTypes": null
        },
        {
          "kind": "SCALAR",
          "name": "Float",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "ForeignPerformerRemunerationImport",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "ForeignPerformerRemunerationImportInvalidStatement",
          "possibleTypes": null
        },
        {
          "kind": "ENUM",
          "name": "ForeignPerformerRemunerationImportInvalidStatementReason",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "ForeignProducerRemunerationImport",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "ForeignProducerRemunerationImportInvalidStatement",
          "possibleTypes": null
        },
        {
          "kind": "ENUM",
          "name": "ForeignProducerRemunerationImportInvalidStatementReason",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "ForeignRemunerationEntryConnectionType",
          "possibleTypes": null
        },
        {
          "kind": "INTERFACE",
          "name": "ForeignRemunerationEntryInterface",
          "possibleTypes": [
            {
              "name": "ForeignRemunerationPerformerEntry"
            },
            {
              "name": "ForeignRemunerationProducerEntry"
            },
            {
              "name": "ForeignRemunerationRecordingEntry"
            }
          ]
        },
        {
          "kind": "OBJECT",
          "name": "ForeignRemunerationEntryInterfaceEdge",
          "possibleTypes": null
        },
        {
          "kind": "ENUM",
          "name": "ForeignRemunerationImportAbortedReason",
          "possibleTypes": null
        },
        {
          "kind": "INTERFACE",
          "name": "ForeignRemunerationImportInterface",
          "possibleTypes": [
            {
              "name": "ForeignPerformerRemunerationImport"
            },
            {
              "name": "ForeignProducerRemunerationImport"
            }
          ]
        },
        {
          "kind": "INTERFACE",
          "name": "ForeignRemunerationImportInvalidStatementInterface",
          "possibleTypes": [
            {
              "name": "ForeignPerformerRemunerationImportInvalidStatement"
            },
            {
              "name": "ForeignProducerRemunerationImportInvalidStatement"
            }
          ]
        },
        {
          "kind": "ENUM",
          "name": "ForeignRemunerationImportState",
          "possibleTypes": null
        },
        {
          "kind": "ENUM",
          "name": "ForeignRemunerationImportTargetType",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "ForeignRemunerationPerformerEntry",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "ForeignRemunerationProducerEntry",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "ForeignRemunerationRecordingEntry",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "ForeignRemunerationSnapshotGroup",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "ForeignRemunerationSnapshotStatement",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "Garnishment",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "GarnishmentCreditor",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "GarnishmentDebtor",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "GarnishmentStatement",
          "possibleTypes": null
        },
        {
          "kind": "ENUM",
          "name": "GarnishmentType",
          "possibleTypes": null
        },
        {
          "kind": "ENUM",
          "name": "Gender",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "Genre",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "GramoMembership",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "Group",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "GroupConnectionWithTotalType",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "GroupEdge",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "GroupMembership",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "IBANBankAccount",
          "possibleTypes": null
        },
        {
          "kind": "INPUT_OBJECT",
          "name": "IBANBankAccountInput",
          "possibleTypes": null
        },
        {
          "kind": "SCALAR",
          "name": "ID",
          "possibleTypes": null
        },
        {
          "kind": "SCALAR",
          "name": "IPN",
          "possibleTypes": null
        },
        {
          "kind": "SCALAR",
          "name": "ISRC",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "Inheritance",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "InheritanceConnectionWithTotalType",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "InheritanceEdge",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "InheritorOrganization",
          "possibleTypes": null
        },
        {
          "kind": "UNION",
          "name": "InheritorUnion",
          "possibleTypes": [
            {
              "name": "InheritorOrganization"
            },
            {
              "name": "Person"
            }
          ]
        },
        {
          "kind": "OBJECT",
          "name": "Instrument",
          "possibleTypes": null
        },
        {
          "kind": "SCALAR",
          "name": "InstrumentCode",
          "possibleTypes": null
        },
        {
          "kind": "SCALAR",
          "name": "Int",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "InternationalAddress",
          "possibleTypes": null
        },
        {
          "kind": "SCALAR",
          "name": "InternationalBankAccountNumberType",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "IsrcExpressRegistration",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "JournalStatement",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "Ledger",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "LedgerAdministrationFeeEntry",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "LedgerCommissionEntry",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "LedgerCorrectionEntry",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "LedgerDistributionAreaChannel",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "LedgerDistributionAreaSummary",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "LedgerEdge",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "LedgerEntry",
          "possibleTypes": null
        },
        {
          "kind": "INTERFACE",
          "name": "LedgerEntryInterface",
          "possibleTypes": [
            {
              "name": "LedgerAdministrationFeeEntry"
            },
            {
              "name": "LedgerCommissionEntry"
            },
            {
              "name": "LedgerCorrectionEntry"
            },
            {
              "name": "LedgerEntry"
            },
            {
              "name": "LedgerExpiredRemunerationEntry"
            },
            {
              "name": "LedgerForeignRemunerationEntry"
            },
            {
              "name": "LedgerGarnishmentEntry"
            },
            {
              "name": "LedgerInheritanceEntry"
            },
            {
              "name": "LedgerLegacyInformationEntry"
            },
            {
              "name": "LedgerLegacyInformationForeignRemunerationEntry"
            },
            {
              "name": "LedgerManualTransferEntry"
            },
            {
              "name": "LedgerPaymentDebtTransferEntry"
            },
            {
              "name": "LedgerPaymentDebtTransferPartEntry"
            },
            {
              "name": "LedgerPaymentEntry"
            },
            {
              "name": "LedgerPaymentPartEntry"
            },
            {
              "name": "LedgerRemunerationEntry"
            },
            {
              "name": "LedgerReturnEntry"
            },
            {
              "name": "LedgerReturnPartEntry"
            },
            {
              "name": "LedgerSupplementalRemunerationEntry"
            }
          ]
        },
        {
          "kind": "OBJECT",
          "name": "LedgerEntryInterfaceConnection",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "LedgerEntryInterfaceEdge",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "LedgerEntryRelationConnection",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "LedgerExpiredRemunerationEntry",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "LedgerForeignRemunerationEntry",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "LedgerGarnishmentEntry",
          "possibleTypes": null
        },
        {
          "kind": "ENUM",
          "name": "LedgerGroup",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "LedgerInheritanceEntry",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "LedgerLegacyInformationEntry",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "LedgerLegacyInformationForeignRemunerationEntry",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "LedgerManualTransferEntry",
          "possibleTypes": null
        },
        {
          "kind": "UNION",
          "name": "LedgerOwnerUnion",
          "possibleTypes": [
            {
              "name": "Agency"
            },
            {
              "name": "Federation"
            },
            {
              "name": "Garnishment"
            },
            {
              "name": "GarnishmentCreditor"
            },
            {
              "name": "Group"
            },
            {
              "name": "InheritorOrganization"
            },
            {
              "name": "Party"
            },
            {
              "name": "Performer"
            },
            {
              "name": "PersonalInheritanceParty"
            },
            {
              "name": "PersonalProducer"
            },
            {
              "name": "ProducerCompany"
            },
            {
              "name": "Society"
            }
          ]
        },
        {
          "kind": "OBJECT",
          "name": "LedgerPayment",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "LedgerPaymentDebtTransferEntry",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "LedgerPaymentDebtTransferPartEntry",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "LedgerPaymentEdge",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "LedgerPaymentEntry",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "LedgerPaymentPartEntry",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "LedgerPaymentsConnection",
          "possibleTypes": null
        },
        {
          "kind": "ENUM",
          "name": "LedgerReasonToHoldPayment",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "LedgerRemunerationEntry",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "LedgerRemunerationYear",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "LedgerReturnEntry",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "LedgerReturnPartEntry",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "LedgerSupplementalRemunerationEntry",
          "possibleTypes": null
        },
        {
          "kind": "ENUM",
          "name": "LocaleEnumType",
          "possibleTypes": null
        },
        {
          "kind": "SCALAR",
          "name": "MoneyAmount",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "Mutation",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "NorwegianBankAccount",
          "possibleTypes": null
        },
        {
          "kind": "INPUT_OBJECT",
          "name": "NorwegianBankAccountInput",
          "possibleTypes": null
        },
        {
          "kind": "SCALAR",
          "name": "NorwegianBankAccountNumber",
          "possibleTypes": null
        },
        {
          "kind": "SCALAR",
          "name": "NorwegianSocialSecurityNumber",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "Organization",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "OrganizationConnectionWithTotalType",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "OrganizationEdge",
          "possibleTypes": null
        },
        {
          "kind": "INTERFACE",
          "name": "OrganizationInterface",
          "possibleTypes": [
            {
              "name": "Agency"
            },
            {
              "name": "Federation"
            },
            {
              "name": "GarnishmentCreditor"
            },
            {
              "name": "InheritorOrganization"
            },
            {
              "name": "Organization"
            },
            {
              "name": "ProducerCompany"
            },
            {
              "name": "PublicProducerCompany"
            },
            {
              "name": "Society"
            }
          ]
        },
        {
          "kind": "ENUM",
          "name": "OrganizationProfileEnum",
          "possibleTypes": null
        },
        {
          "kind": "UNION",
          "name": "OwnerUnion",
          "possibleTypes": [
            {
              "name": "Agency"
            },
            {
              "name": "Federation"
            },
            {
              "name": "Garnishment"
            },
            {
              "name": "GarnishmentCreditor"
            },
            {
              "name": "InheritorOrganization"
            },
            {
              "name": "Party"
            },
            {
              "name": "Person"
            },
            {
              "name": "ProducerCompany"
            },
            {
              "name": "Society"
            }
          ]
        },
        {
          "kind": "OBJECT",
          "name": "Ownership",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "OwnershipClaimRecording",
          "possibleTypes": null
        },
        {
          "kind": "INPUT_OBJECT",
          "name": "OwnershipClaimRecordingInput",
          "possibleTypes": null
        },
        {
          "kind": "INPUT_OBJECT",
          "name": "OwnershipInput",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "OwnershipShare",
          "possibleTypes": null
        },
        {
          "kind": "INPUT_OBJECT",
          "name": "OwnershipShareInput",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "OwnershipsChange",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "OwnershipsChangeRequest",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "OwnershipsChangeRequestEdge",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "OwnershipsChangeRequestsConnectionType",
          "possibleTypes": null
        },
        {
          "kind": "INPUT_OBJECT",
          "name": "OwnershipsChangesetInput",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "PageInfo",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "Party",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "Performance",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "PerformanceAllocation",
          "possibleTypes": null
        },
        {
          "kind": "INPUT_OBJECT",
          "name": "PerformanceAllocationAddInput",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "PerformanceChange",
          "possibleTypes": null
        },
        {
          "kind": "INPUT_OBJECT",
          "name": "PerformanceInput",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "PerformancesChange",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "PerformancesChangeRequest",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "PerformancesChangeRequestEdge",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "PerformancesChangeRequestsConnectionType",
          "possibleTypes": null
        },
        {
          "kind": "INPUT_OBJECT",
          "name": "PerformancesChangesetInput",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "Performer",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "PerformerClaimRecording",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "PerformerGroupMembershipType",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "PerformerRecordingsConnectionType",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "PerformerRecordingsEdgeType",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "PerformerReleasesConnectionType",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "Person",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "PersonConnectionWithTotalType",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "PersonEdge",
          "possibleTypes": null
        },
        {
          "kind": "ENUM",
          "name": "PersonIncomeTypeEnum",
          "possibleTypes": null
        },
        {
          "kind": "ENUM",
          "name": "PersonProfileEnum",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "PersonWithProfileParty",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "PersonalInheritanceParty",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "PersonalProducer",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "Position",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "PreventDeletePersonReason",
          "possibleTypes": null
        },
        {
          "kind": "ENUM",
          "name": "PreventDeletePersonReasonCode",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "PreventDeleteRecordingReason",
          "possibleTypes": null
        },
        {
          "kind": "ENUM",
          "name": "PreventDeleteRecordingReasonCode",
          "possibleTypes": null
        },
        {
          "kind": "INPUT_OBJECT",
          "name": "PreviousNameInput",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "Producer",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "ProducerClaimRecording",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "ProducerCompany",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "ProducerEdge",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "ProducerRecordLabelConnectionType",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "ProducerRecordingsConnectionType",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "ProducerRecordingsEdgeType",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "ProducerReleasesConnectionType",
          "possibleTypes": null
        },
        {
          "kind": "ENUM",
          "name": "ProducerTypeEnum",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "ProtectionLaw",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "ProtectionLawGroup",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "ProtectionLawGroupMember",
          "possibleTypes": null
        },
        {
          "kind": "UNION",
          "name": "ProtectionLawOwner",
          "possibleTypes": [
            {
              "name": "Country"
            },
            {
              "name": "ProtectionLawGroup"
            }
          ]
        },
        {
          "kind": "ENUM",
          "name": "ProtectionLawOwnerTypeEnum",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "ProtectionLawRevision",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "PublicFile",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "PublicPerformer",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "PublicPerson",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "PublicPersonConnectionWithTotalType",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "PublicPersonEdge",
          "possibleTypes": null
        },
        {
          "kind": "ENUM",
          "name": "PublicPersonProfileEnum",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "PublicPersonalProducer",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "PublicPlayback",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "PublicProducerCompany",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "PublicProducerConnectionWithTotalType",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "PublicSearchRecordingConnection",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "PublicSearchReleaseConnection",
          "possibleTypes": null
        },
        {
          "kind": "INPUT_OBJECT",
          "name": "PublicUpdatePerformerProfileInput",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "PublicUpdatePerformerProfilePayload",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "Query",
          "possibleTypes": null
        },
        {
          "kind": "SCALAR",
          "name": "Rational",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "RecordLabel",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "RecordLabelConnectionWithTotalType",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "RecordLabelEdge",
          "possibleTypes": null
        },
        {
          "kind": "INPUT_OBJECT",
          "name": "RecordLabelSearchResultOrderInput",
          "possibleTypes": null
        },
        {
          "kind": "ENUM",
          "name": "RecordLabelSortableFields",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "Recording",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "RecordingConnectionWithTotalType",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "RecordingDraft",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "RecordingDuplicateConnectionWithTotalType",
          "possibleTypes": null
        },
        {
          "kind": "ENUM",
          "name": "RecordingDuplicateType",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "RecordingDuplicationsEdgeType",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "RecordingEdge",
          "possibleTypes": null
        },
        {
          "kind": "INPUT_OBJECT",
          "name": "RecordingFromDraftInput",
          "possibleTypes": null
        },
        {
          "kind": "INPUT_OBJECT",
          "name": "RecordingInput",
          "possibleTypes": null
        },
        {
          "kind": "INTERFACE",
          "name": "RecordingInterface",
          "possibleTypes": [
            {
              "name": "Recording"
            },
            {
              "name": "RecordingDraft"
            }
          ]
        },
        {
          "kind": "INPUT_OBJECT",
          "name": "RecordingSearchResultOrderInput",
          "possibleTypes": null
        },
        {
          "kind": "ENUM",
          "name": "RecordingSortableFields",
          "possibleTypes": null
        },
        {
          "kind": "SCALAR",
          "name": "RegistrantCode",
          "possibleTypes": null
        },
        {
          "kind": "UNION",
          "name": "RegistrantCodeOwner",
          "possibleTypes": [
            {
              "name": "Producer"
            },
            {
              "name": "RecordLabel"
            }
          ]
        },
        {
          "kind": "OBJECT",
          "name": "RegistrantCodeWithMetadata",
          "possibleTypes": null
        },
        {
          "kind": "INTERFACE",
          "name": "RegistrantCodesOwnerInterface",
          "possibleTypes": [
            {
              "name": "PersonalProducer"
            },
            {
              "name": "ProducerCompany"
            },
            {
              "name": "RecordLabel"
            }
          ]
        },
        {
          "kind": "OBJECT",
          "name": "Release",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "ReleaseDraft",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "ReleaseEdge",
          "possibleTypes": null
        },
        {
          "kind": "ENUM",
          "name": "ReleaseFormat",
          "possibleTypes": null
        },
        {
          "kind": "INPUT_OBJECT",
          "name": "ReleaseInput",
          "possibleTypes": null
        },
        {
          "kind": "INTERFACE",
          "name": "ReleaseInterface",
          "possibleTypes": [
            {
              "name": "Release"
            },
            {
              "name": "ReleaseDraft"
            }
          ]
        },
        {
          "kind": "INPUT_OBJECT",
          "name": "ReleaseSearchResultOrderInput",
          "possibleTypes": null
        },
        {
          "kind": "ENUM",
          "name": "ReleaseSortableFields",
          "possibleTypes": null
        },
        {
          "kind": "INPUT_OBJECT",
          "name": "ReleaseTracksInput",
          "possibleTypes": null
        },
        {
          "kind": "INPUT_OBJECT",
          "name": "RemovePlaybackFromClaimInput",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "RemovePlaybackFromClaimPayload",
          "possibleTypes": null
        },
        {
          "kind": "INPUT_OBJECT",
          "name": "RemoveRecordingFromClaimFileInput",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "RemoveRecordingFromClaimFilePayload",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "Residency",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "ReturnStatement",
          "possibleTypes": null
        },
        {
          "kind": "UNION",
          "name": "RightsholderWithGroupUnion",
          "possibleTypes": [
            {
              "name": "Group"
            },
            {
              "name": "Performer"
            },
            {
              "name": "PersonalProducer"
            },
            {
              "name": "ProducerCompany"
            }
          ]
        },
        {
          "kind": "ENUM",
          "name": "RoleCode",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "RoleCodeRuleSet",
          "possibleTypes": null
        },
        {
          "kind": "ENUM",
          "name": "Sector",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "SectorStats",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "Society",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "SocietyMembership",
          "possibleTypes": null
        },
        {
          "kind": "INTERFACE",
          "name": "SocietyMembershipInterface",
          "possibleTypes": [
            {
              "name": "GramoMembership"
            },
            {
              "name": "SocietyMembership"
            }
          ]
        },
        {
          "kind": "OBJECT",
          "name": "SocietyWithPerformersStatement",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "SocietyWithProducersStatement",
          "possibleTypes": null
        },
        {
          "kind": "ENUM",
          "name": "Source",
          "possibleTypes": null
        },
        {
          "kind": "INTERFACE",
          "name": "Statement",
          "possibleTypes": [
            {
              "name": "AgencyStatement"
            },
            {
              "name": "CommissionDetailsStatement"
            },
            {
              "name": "FederationStatement"
            },
            {
              "name": "GarnishmentStatement"
            },
            {
              "name": "JournalStatement"
            },
            {
              "name": "ReturnStatement"
            },
            {
              "name": "SocietyWithPerformersStatement"
            },
            {
              "name": "SocietyWithProducersStatement"
            }
          ]
        },
        {
          "kind": "SCALAR",
          "name": "String",
          "possibleTypes": null
        },
        {
          "kind": "SCALAR",
          "name": "StrippedStringType",
          "possibleTypes": null
        },
        {
          "kind": "INPUT_OBJECT",
          "name": "SubmitClaimInput",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "SubmitClaimPayload",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "TerritoryMandate",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "TerritoryMandateSociety",
          "possibleTypes": null
        },
        {
          "kind": "ENUM",
          "name": "TerritoryMandateTypeEnum",
          "possibleTypes": null
        },
        {
          "kind": "SCALAR",
          "name": "Timestamp",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "TrackType",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "TrackTypeEdge",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "TracksConnectionType",
          "possibleTypes": null
        },
        {
          "kind": "SCALAR",
          "name": "URL",
          "possibleTypes": null
        },
        {
          "kind": "SCALAR",
          "name": "UUID",
          "possibleTypes": null
        },
        {
          "kind": "INPUT_OBJECT",
          "name": "UpdateBankAccountInfoInput",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "UpdateBankAccountInfoPayload",
          "possibleTypes": null
        },
        {
          "kind": "INPUT_OBJECT",
          "name": "UpdateDefaultMinsideProfileInput",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "UpdateDefaultMinsideProfilePayload",
          "possibleTypes": null
        },
        {
          "kind": "INPUT_OBJECT",
          "name": "UpdateLocaleOnUserInput",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "UpdateLocaleOnUserPayload",
          "possibleTypes": null
        },
        {
          "kind": "INPUT_OBJECT",
          "name": "UpdateOnboardingInfoInput",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "UpdateOnboardingInfoPayload",
          "possibleTypes": null
        },
        {
          "kind": "INPUT_OBJECT",
          "name": "UpdateOrganizationContactInfoInput",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "UpdateOrganizationContactInfoPayload",
          "possibleTypes": null
        },
        {
          "kind": "INPUT_OBJECT",
          "name": "UpdateOrganizationMembershipInfoInput",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "UpdateOrganizationMembershipInfoPayload",
          "possibleTypes": null
        },
        {
          "kind": "INPUT_OBJECT",
          "name": "UpdatePersonalInformationInput",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "UpdatePersonalInformationPayload",
          "possibleTypes": null
        },
        {
          "kind": "INPUT_OBJECT",
          "name": "UpdatePlaybackOnClaimInput",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "UpdatePlaybackOnClaimPayload",
          "possibleTypes": null
        },
        {
          "kind": "INPUT_OBJECT",
          "name": "UpdateRecordingInformationInput",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "UpdateRecordingInformationPayload",
          "possibleTypes": null
        },
        {
          "kind": "INPUT_OBJECT",
          "name": "UpdateRecordingInput",
          "possibleTypes": null
        },
        {
          "kind": "INPUT_OBJECT",
          "name": "UpdateReleaseInput",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "UpdateReleasePayload",
          "possibleTypes": null
        },
        {
          "kind": "INPUT_OBJECT",
          "name": "UpdateReleaseTracksInput",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "UpdateReleaseTracksPayload",
          "possibleTypes": null
        },
        {
          "kind": "SCALAR",
          "name": "UploadedFile",
          "possibleTypes": null
        },
        {
          "kind": "INPUT_OBJECT",
          "name": "UpsertAgencyInput",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "UpsertAgencyPayload",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "User",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "UserProfile",
          "possibleTypes": null
        },
        {
          "kind": "SCALAR",
          "name": "Year",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "__Directive",
          "possibleTypes": null
        },
        {
          "kind": "ENUM",
          "name": "__DirectiveLocation",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "__EnumValue",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "__Field",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "__InputValue",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "__Schema",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "__Type",
          "possibleTypes": null
        },
        {
          "kind": "ENUM",
          "name": "__TypeKind",
          "possibleTypes": null
        }
      ]
    }
  }
}
;