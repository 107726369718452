import { getProperties, get } from '@ember/object'
import omit from 'lodash/omit'

const RECORDING_ATTRIBUTES = [
  'title',
  'main_artist',
  'duration_in_seconds',
  'release_date',
  'isrc',
  'record_label',
  'id'
]

const TRACK_ATTRIBUTES = ['side', 'title', 'number_on_side', 'isrc', 'recording_id']

const cleanUpRecording = recording => getProperties(recording, RECORDING_ATTRIBUTES)

const OMITTED_TRACK_ATTRIBUTES = ['recording']

const prepareTracks = release => {
  const processedTracks = get(release, 'tracks.edges').map(track => {
    if (get(track, 'node.recording')) {
      const title = get(track, 'node.title')
        ? get(track, 'node.title')
        : get(track, 'node.recording.title')
      const isrc = get(track, 'node.isrc')
        ? get(track, 'node.isrc')
        : get(track, 'node.recording.isrc')
      return {
        node: {
          ...omit(get(track, 'node'), OMITTED_TRACK_ATTRIBUTES),
          ...omit(get(track, 'node.recording'), ['id', 'title', 'isrc']),
          recording_id: get(track, 'node.recording.id'),
          title,
          isrc,
          addingRecording: false
        }
      }
    }
    if (get(track, 'node.side') && get(track, 'node.number')) {
      return {
        node: {
          ...getProperties(get(track, 'node'), ['side', 'number']),
          addingRecording: false
        }
      }
    }

    return track
  })
  return { ...release, tracks: processedTracks }
}
const getRecordings = recordings => {
  const {
    search_recordings: { edges }
  } = recordings

  if (!edges.length) return []

  return edges.map(recording => recording.node)
}

const prepareTrackForInput = track => {
  const newTrack = { ...get(track, 'node'), number_on_side: get(track, 'node.number') }
  return getProperties(newTrack, TRACK_ATTRIBUTES)
}

export { cleanUpRecording, getRecordings, prepareTracks, prepareTrackForInput }
