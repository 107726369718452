import Component from '@ember/component'
import { computed } from '@ember/object'
import { inject } from '@ember/service'

export default Component.extend({
  tagName: '',
  profile: inject(),

  claim_recording: computed('recording.claim_recordings.[]', function () {
    return this.get('recording.claim_recordings').find(
      cr => cr.state !== 'COMPLETED' && cr.state !== 'APPROVED'
    )
  }),

  state: computed('claim_recording.state', 'recording.recording.id', 'myPerformance', function () {
    // if there is connecte claim then it might not be in echo
    // if they have a performance or ownership then it is an "update" claim
    // if there are playbacks connected then it is a new claim
    // if there are not connected playbacks then it is a registration
    if (this.get('claim_recording.state')) {
      if (this.get('myPerformance') || this.get('myOwnership')) {
        return 'update'
      } else if (this.get('claim_recording.playbacks.length')) {
        return 'new'
      }
      return 'registered'
    }
    return ''
  }),

  myPerformance: computed('recording.recording.performances.[]', function () {
    if (this.get('recording.recording.performances')) {
      return this.get('recording.recording.performances').find(
        p => p.public_performer.id === this.get('profile.activeProfile.id')
      )
    }
  }),

  myOwnership: computed('recording.recording.ownerships.[]', function () {
    if (this.get('recording.recording.ownerships')) {
      return this.get('recording.recording.ownerships').find(
        o =>
          o.shares.find(s => s.producer.id === this.get('profile.activeProfile.id')) &&
          o.active === true
      )
    }
  }),

  unconfirmed: computed('state', 'recording.status', function () {
    if (this.get('state') === 'registered' || this.get('state') === 'new') {
      return true
    }
    return false
  })
})
