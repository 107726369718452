import Component from '@glimmer/component'
import { action } from '@ember/object'
import { inject } from '@ember/service'

export default class GlobalSearchResultsReleasesTableRow extends Component {
  @inject router
  @action
  redirectToRelease() {
    this.router.transitionTo('user.edit-release', this.args.release.id)
  }
}
