import Component from '@ember/component'
import { inject } from '@ember/service'
import { get, getWithDefault } from '@ember/object'
import { reads, equal } from '@ember/object/computed'
import { task } from 'ember-concurrency'
import { queryManager } from 'ember-apollo-client'
import { extractProblems } from 'min-side/utils/error-helpers'
import CreateRecordingForRelease from 'min-side/graphql/mutations/edit-release/create-recording-for-release'

const MAX_CONCURRENCY = 1
export default Component.extend({
  'data-test-search-recordings-in-track': true,
  showResults: false,
  showAddRecording: false,
  foundRecordings: reads('searchRecordingsTask.last.value'),
  noRecordingsFound: equal('foundRecordings.length', 0),
  tryAgainButtonTranslationKey: 'edit_release.tracks.cancel',
  useThisButtonTranslationKey: 'edit_release.tracks.create_recording',
  showCancelButton: reads('search.candidates.length'),
  intl: inject(),
  apollo: queryManager(),
  init(...args) {
    this._super(...args)
    this.set('search', [])
    this.set('recordings', [])
  },
  actions: {
    async search(query) {
      await this.searchRecordingsTask.perform(query)
      this.set('showResults', true)
    },
    togglShowAddRecording() {
      this.set('showAddRecording', true)
    },
    removeSearch() {
      if (!this.get('createRecordingTask.isRunning')) {
        this.set('showAddRecording', false)
      }
    },
    addRecordingMiddleware(recording) {
      if (get(recording, 'typename') === 'NewRecording') {
        this.createRecordingTask.perform(recording)
      } else {
        this.addRecordingAction(recording)
      }
    }
  },
  createRecordingTask: task(function* (recording) {
    const variables = {
      input: {
        recording: {
          isrc: get(recording, 'isrc'),
          title: getWithDefault(recording, 'title', ''),
          duration_in_seconds: getWithDefault(recording, 'duration_in_seconds', null),
          main_artist: getWithDefault(recording, 'main_artist', null)
        }
      }
    }
    try {
      const {
        create_recording_for_release: { errors, recording: newRecording }
      } = yield this.apollo.mutate({
        variables,
        mutation: CreateRecordingForRelease
      })
      if (errors) {
        this.flashMessages.error(this.intl.t('flash.save.invalid'))
        this.set('search', {
          isrc: get(recording, 'isrc'),
          candidates: [
            { ...recording, mappedErrors: { errors: this._parseAndMapServerErrors(errors) } }
          ]
        })
      } else {
        this.addRecordingAction({ ...recording, id: newRecording.id })
        this.set('showAddRecording', false)
      }
    } catch (e) {
      this.flashMessages.error(this.intl.t('flash.save.invalid'))
      const mappedErrors = {}
      if (e.hasOwnProperty('errors')) {
        const { errors } = e
        mappedErrors.errors = this._parseAndMapApolloErrors(errors)
      }
      this.set('search', {
        isrc: get(recording, 'isrc'),
        candidates: [{ ...recording, mappedErrors }]
      })
    }
  })
    .enqueue()
    .maxConcurrency(MAX_CONCURRENCY),

  _parseAndMapApolloErrors(errors) {
    const mappedErrors = errors.map(error => {
      const problems = extractProblems(error)
      if (!problems) return false
      return problems.map(problem => {
        return {
          message: this.intl.t('validation.empty'),
          attribute: problem.path[1]
        }
      })
    })
    return [].concat(...mappedErrors)
  },
  _parseAndMapServerErrors(errorsToParse) {
    return errorsToParse.map(error => {
      return {
        message: this.intl.t('validation.empty'),
        attribute: error.key
      }
    })
  }
})
