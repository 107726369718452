import Component from '@ember/component'
import { set } from '@ember/object'

export default Component.extend({
  'data-test-edit-recording-ownership-file-upload': true,

  actions: {
    changeFile(event) {
      const file = event.target.files[0]
      set(this.attachment, 'file', file)
      set(this.attachment, 'name', file.name)
      set(this.attachment, 'uploaded_at', new Date().toISOString())
    }
  }
})
