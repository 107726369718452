import Component from '@ember/component'
import { computed, defineProperty, get, set } from '@ember/object'
import { isChangeset } from 'validated-changeset'
import { assert } from '@ember/debug'

/**
 * Renders an input bound to a model's attribute.
 *
 * @see form-field-wrapper for more attributes you can provide to this component
 */
export default Component.extend({
  tagName: '',
  supportsDataTestProperties: true,

  /**
   * The model we'll read our attribute's value from
   *
   * Maybe, if we had a more complete form addon we could
   * have read this value from some parent element.
   *
   * @type {EmberData Model}
   */
  model: null,

  /**
   * Optional errors object
   * which will be used instead of model
   * if provided
   *
   * @type {Object}
   * @param {Array} errors
   */
  errors: null,

  /**
   * The attribute name
   *
   * @type {String}
   */
  attribute: null,

  /**
   * The error path
   *
   * @type {String}
   */
  errorPath: '',

  /**
   * The type of the input
   *
   * @type {String}
   */
  type: 'text',

  /**
   * Optional hint rendered next to field's label
   *
   * @type {String}
   */

  labelHint: null,

  /**
   * Should we render errors for attribute?
   *
   * @type {Boolean}
   */
  showErrors: true,

  /**
   * Should we render label element with block modifier?
   *
   * @type {Boolean}
   */
  block: false,

  /**
   * Should we render label element with required asterisk?
   *
   * @type {Boolean}
   */
  required: false,

  disabled: false,

  /**
   * The focus property works in tandem with custom functionality for
   * focusing on an input element defined in initializers/add-focus-to-text-field.js
   */
  focus: false,

  errorLabelClass: '',

  /**
   * In order to dynamically set CP key
   * we have to define property on init
   *
   * In order to track changeset changes (which can be passed instead of plain model)
   * we need to use .get and .set methods on changeset
   * CP is able to track changeset changes only if
   * it's observing _content and _changes properties on the changeset
   */
  init(...args) {
    this._super(...args)

    assert('You must provide "attribute" to model-input component', this.attribute)
    assert('You must provide "model" to model-input component', this.model)
    assert(
      'You must use `.` as a delimiter for model-input attribute.',
      !~this.attribute.indexOf('/')
    )

    defineProperty(
      this,
      'value',
      computed(`model.${this.attribute}`, 'model.{_content,_changes}', {
        get() {
          const model = get(this, 'model')
          return isChangeset(model)
            ? model.get(this.attribute)
            : get(this, `model.${this.attribute}`)
        },
        set(_, value) {
          const model = get(this, 'model')
          // eslint-disable-next-line no-unused-expressions
          isChangeset(model)
            ? model.set(this.attribute, value)
            : set(this, `model.${this.attribute}`, value)
          return value
        }
      })
    )
  }
})
