import Component from '@ember/component'
import RSVP from 'rsvp'
import { inject } from '@ember/service'
import { computed, set } from '@ember/object'
import { getGramoMembership } from 'min-side/helpers/memberships'
import { getCurrentOrganization, getOrganizationGramoMembership } from 'min-side/helpers/contact'
import { isForeignAddress } from 'min-side/helpers/contact-information'
import { isNorwayCountryId } from 'min-side/helpers/countries'
import HandlesSaveWithFlashMixin from 'echo-ember-common/mixins/handles-save-with-flash'
import { isPresent } from '@ember/utils'
import { pickBy } from 'lodash'
import { validPostalCode } from 'min-side/utils/validators'
import { next } from '@ember/runloop'

export default Component.extend(HandlesSaveWithFlashMixin, {
  'data-test-gramo-onboarding': true,
  intl: inject(),
  profile: inject(),
  firstPage: true,
  didReceiveAttrs() {
    /**
     * So ... We had tests related to "Acceptance | Onboarding" which was failing randomly.
     * As of writing I have no clue to why it happens. I managed to reproduce it locally too,
     * but only a few times.
     *
     * I have also no clue to why the didReceiveAttrs does all of this stuff to the very deep data structure.
     * This component is a couple of forms which a couple of fields for contact information and bank information.
     * How incredibly complex is it possible to make it? I think this is an example of that. Why not just pass in
     * Simple, almost non-nested data representing the form fields? Remember to look at the computed property
     * contactInformation in this component too, to get the picture :-(
     */
    next(() => {
      if (this.isDestroying || this.isDestroyed) return

      if (!this.get('model.user.person.contact_information')) {
        this.set('model.user.person.contact_information', {})
      }
      if (!this.get('contactInformation.address.content')) {
        this.set('contactInformation.address.content', '')
      }
      if (this.gramoMembership && !this.get('gramoMembership.bank_account')) {
        this.set('gramoMembership.bank_account', {})
      }
      if (this.get('profile.activeProfile.profile') === 'contact') {
        this.set('model.hasForeignAddress', isForeignAddress(this.contactInformation))
      } else {
        this.set(
          'model.hasForeignAddress',
          isForeignAddress(this.get('model.user.person.contact_information'))
        )
      }
    })
  },

  removeOtherAddressType() {
    if (this.get('model.hasForeignAddress')) {
      const newAddress = this.get('contactInformation.address.content')
      if (isPresent(newAddress)) {
        this.set('contactInformation.international_address', newAddress)
        delete this.contactInformation.address
      }
    } else {
      delete this.get('contactInformation.address').content
    }
  },

  verify() {
    const account = this.firstPage
      ? pickBy(this.get('contactInformation.address'), (value, key) => key !== 'care_of')
      : this.bankAccount
    const postal_code = this.get('contactInformation.address.postal_code')
    const errors = []
    for (const key in account) {
      if (!account[key]) {
        errors.push({
          attribute: this.firstPage ? `address/${key}` : key,
          message: this.intl.t(`onboarding.errors.${key}`)
        })
      }
    }
    if (postal_code && !validPostalCode(postal_code)) {
      errors.push({
        attribute: 'address/postal_code',
        message: this.intl.t('onboarding.errors.invalid_postal_code')
      })
    }
    if (errors.length) return RSVP.reject({ validationErrors: errors })
    return RSVP.resolve(errors)
  },

  setBankAccount(value) {
    this.set('bankAccount', this.accountTypeFields[value])
    this.set('model.user.person.inputAccountType', value)
    this.set('accountType', value)
  },

  setOrgAddress() {
    if (this.hasForeignAddress && !this.get('currentOrganization.contact_information.address')) {
      this.set('currentOrganization.contact_information.address', { content: '' })
    }
  },

  disableNorwegianContactInfoInput: computed(
    'profile.activeProfile',
    'currentOrganization',
    function () {
      return this.get('profile.activeProfile.profile') === 'contact'
        ? isNorwayCountryId(this.currentOrganization.country.numeric)
        : false
    }
  ),

  hasForeignAddress: computed('profile.activeProfile', 'currentOrganization', function () {
    return this.get('model.hasForeignAddress')
  }),

  contactInformation: computed('profile.activeProfile', 'model.errors', {
    get() {
      if (!this.get('model.user.person.contact_information.address')) {
        return {
          address: {
            care_of: '',
            street: '',
            postal_code: '',
            postal_area: ''
          }
        }
      }
      if (this.get('profile.isContact')) {
        this.set('currentOrganization.contact_information.errors', [])
        this.setOrgAddress()
        return this.get('currentOrganization.contact_information')
      }
      this.set('model.user.person.contact_information.errors', [])
      return this.get('model.user.person.contact_information')
    },
    set(key, val) {
      const contactInfo = val
      contactInfo.errors = []
      if (this.get('profile.isContact')) {
        this.set('currentOrganization.contact_information', contactInfo)
      } else {
        this.set('model.user.person.contact_information', contactInfo)
      }
      return contactInfo
    }
  }),

  gramoMembership: computed('profile.activeProfile.{type,profile}', 'model.errors', function () {
    if (!this.get('profile.activeProfile.profile')) {
      return null
    }
    const profile = this.get('profile.activeProfile.profile').replace('-', '_')
    let membership
    if (profile === 'contact') {
      membership = getOrganizationGramoMembership(
        this.get('profile.activeProfile'),
        this.get('model.user.person')
      )
    } else if (profile === 'inheritance_party') {
      membership = getGramoMembership(
        this.get('model.user.person.personal_inheritance_party.society_memberships')
      )
    } else {
      membership = getGramoMembership(this.get(`model.user.person.${profile}.society_memberships`))
    }
    return membership || null
  }),

  bankAccount: computed('profile.activeProfile', 'model.errors', 'gramoMembership.bank_account', {
    get() {
      let bankAccount
      if (this.isAgency) {
        bankAccount = this.get('currentOrganization.agency.bank_account') || {}
      } else {
        bankAccount = this.get('gramoMembership.bank_account')
      }
      set(bankAccount, 'errors', this.get('model.errors') || [])
      return bankAccount
    },
    set(key, val) {
      const bankAccount = val
      set(val, 'errors', [])
      if (this.isAgency) {
        this.set('currentOrganization.agency.bank_account', bankAccount)
      } else {
        this.set('gramoMembership.bank_account', bankAccount)
      }
      return bankAccount
    }
  }),
  accountType: computed('bankAccount', {
    get() {
      if (this.__accountType) {
        return this.__accountType
      }
      if (this.get('model.errors').length) {
        return this.get('model.user.person.inputAccountType')
      }
      if (!this.gramoMembership && !this.isAgency) {
        return null
      }
      const bankAccount = this.bankAccount
      const type = bankAccount.__typename || 'blank'
      this.set('model.user.person.inputAccountType', type)
      delete bankAccount.__typename
      return type
    },
    set(key, value) {
      this.__accountType = value
      return value
    }
  }),

  editableBankAccountFields: computed('accountType', function () {
    const fields = Object.keys(this.accountTypeFields[this.accountType]).filter(
      key => key !== 'currency' && key !== 'type' && key !== 'errors'
    )
    return fields
  }),

  currentOrganization: computed('profile.activeProfile', 'bankAccount', function () {
    const org = getCurrentOrganization(
      this.get('profile.activeProfile'),
      this.get('model.user.person')
    )
    const hasForeignAddress = isForeignAddress(org.contact_information)
    set(org, 'hasForeignAddress', hasForeignAddress)
    return org
  }),

  isAgency: computed('profile.activeProfile', function () {
    if (this.get('profile.isContact')) {
      return !!this.currentOrganization.agency
    }
    return false
  }),

  shouldShowSecondPage: computed('gramoMembership', 'profile.activeProfile', function () {
    if (this.get('profile.isContact')) {
      return !!this.isAgency || !!this.gramoMembership
    }
    return !!this.gramoMembership
  }),

  accountTypeFields: {
    blank: {},
    NorwegianBankAccount: {
      account_number: '',
      currency: 'NOK'
    },
    IBANBankAccount: {
      account_number: '',
      currency: 'NOK'
    },
    BICBankAccount: {
      account_number: '',
      bic: '',
      currency: 'NOK'
    },
    AmericanBankAccount: {
      account_number: '',
      bank_code: '',
      bank_name: '',
      currency: 'NOK'
    }
  },

  actions: {
    updateAccountType(value) {
      this.setBankAccount(value)
    },
    nextPage() {
      this.removeOtherAddressType()
      return this.verify()
        .then(() => this.toggleProperty('firstPage'))
        .catch(errors => this.handleSaveWithFlash(RSVP.reject(errors)))
    },
    onDismiss() {
      this.onDismiss()
    },
    saveOnboardingInfo() {
      this.removeOtherAddressType()
      const promise = this.verify()
        .then(_ => {
          if (this.get('profile.isContact')) {
            return this.saveOrgOnboardingInfo()
          }
          return this.saveOnboardingInfo()
        })
        .catch(errors => {
          if (this.firstPage) {
            this.set('contactInformation.errors', errors.validationErrors)
          } else {
            this.set('bankAccount.errors', errors.validationErrors)
          }
          return RSVP.reject(errors)
        })

      this.handleSaveWithFlash(promise)

      return promise
    }
  }
})
