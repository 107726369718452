import Service, { inject } from '@ember/service'

const POSTFIX = ' - Gramo'
const DEFAULT = 'page-title.default'
/*
 * This function basically traverses up the tree until it finds a translation,
 * eventually giving up and just returning `page-title.default`.
 *
 * The idea is that we do not want to define a title for every since page, some
 * pages can share the same title, for example when editing a recording rather
 * than define `user.edit-recording.info` and `user.edit-recording.releases` in
 * the translation files with the same string, we can use `user.edit-recording`.
 */
function tokenLookup(intl, token) {
  if (!token) {
    return DEFAULT
  }
  if (intl.exists(`page-title.${token}`)) {
    return `page-title.${token}`
  }
  const paths = token.split('.')
  paths.pop()
  return tokenLookup(intl, paths.join('.'))
}

export default Service.extend({
  intl: inject(),
  headData: inject(),

  update(token) {
    const variables = this.get('tokenVariables') || {}
    const actualToken = tokenLookup(this.intl, token)
    let title = ''
    try {
      title = this.intl.t(actualToken, variables)
    } catch (e) {
      title = this.intl.t(DEFAULT)
    }

    this.headData.set('title', `${title}${POSTFIX}`)
    // When testing locally in chrome I noticed the history was using the default
    // title on some pages, this seems to fix that!
    document.title = `${title}${POSTFIX}`
  },

  setVariables(tokenVariables) {
    this.set('tokenVariables', tokenVariables)
  }
})
