import Component from '@ember/component'
import { inject } from '@ember/service'

export default Component.extend({
  router: inject(),
  tagName: '',
  actions: {
    redirectToRelease(releaseID) {
      this.router.transitionTo('user.edit-release', releaseID)
    }
  }
})
