import OrganizationContactInfoValidationRules, {
  validatePhoneNumber
} from './organization-contact-info'

export const defaultContactInfoObject = {
  address: {
    care_of: '',
    postal_area: '',
    postal_code: '',
    street: '',
    content: ''
  },
  email: '',
  mobile: '',
  telephone: '',
  webpage: '',
  alternative_mobile: ''
}

export default {
  ...OrganizationContactInfoValidationRules,
  alternative_mobile: validatePhoneNumber({
    message: 'validation.invalid-mobile'
  })
}
