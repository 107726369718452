import Controller, { inject as injectController } from '@ember/controller'
import { inject } from '@ember/service'
import { readOnly } from '@ember/object/computed'

export default Controller.extend({
  profile: inject(),
  childController: injectController('user.ledger.remuneration.recording-diff.index'),
  recording: readOnly('childController.recording'),
  subSubHeader: readOnly('childController.subSubHeader'),
  metaFields: readOnly('childController.metaFields'),
  renumerationId: readOnly('childController.renumerationId')
})
