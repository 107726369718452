import Controller from '@ember/controller'
import { reads } from '@ember/object/computed'
import { inject } from '@ember/service'
import { params } from './route'

const nonNullDefaults = { period: 'last_twelve_months' }
const queryParamsObjectWithDefaults = params.reduce((acc, param) => {
  acc[param] = nonNullDefaults[param] || null
  return {
    ...acc
  }
}, {})

export default Controller.extend({
  queryParams: params,
  profile: inject(),
  ...queryParamsObjectWithDefaults,
  ledger: reads('model'),

  setQueryParamsToDefaultValues() {
    this.setProperties({ ...queryParamsObjectWithDefaults })
  },
  actions: {
    setParam(name, value) {
      this.set(name, value)
    },

    clearRangeParams() {
      this.setProperties({
        date_from: null,
        date_until: null
      })
    }
  }
})
