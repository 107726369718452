import Controller from '@ember/controller'
import { computed } from '@ember/object'
import { reads } from '@ember/object/computed'
import { tracked } from '@glimmer/tracking'
import { dropTask } from 'ember-concurrency-decorators'
import { inject as service } from '@ember/service'
import { formatErrors } from 'min-side/utils/changeset-helpers'
import updateBankAccountMutation from 'min-side/graphql/mutations/profile/bank-account-info'
import { queryManager } from 'ember-apollo-client'
import { PROFILE_TYPES_MAP } from 'min-side/constants/profile'

import { mapChangesetErrors } from 'min-side/utils/changeset-helpers'
import updateOrgMembershipMutation from 'min-side/graphql/mutations/profile/organization-membership'
import BankAccountValidations, {
  defaultBankAccountObject
} from 'min-side/validations/profile/bank-account'
import Changeset from 'ember-changeset'
import lookupValidator from 'ember-changeset-validations'
import { getBankInfo } from 'min-side/graphql/helpers/bank-info'

export default class ProfileInfoBankController extends Controller {
  @tracked changeset = null

  @queryManager apollo
  @service intl
  @service profileInfo

  @reads('profileInfo.currentProfileName')
  currentProfile

  @reads('profileInfo.currentProfileType')
  currentProfileType
  @reads('profileInfo.profile.currentActiveProfileBankAccount')
  currentActiveProfileBankAccount

  @computed('model', 'model.bank_account')
  get hasActiveMembership() {
    return !!this.model
  }

  get description() {
    let desc = this.intl.t('profile-info.headers.bank-info-description')
    if (this.get('profileInfo').profile.hasPaymentsThroughAgency) {
      desc += ` ${this.intl.t('profile-info.headers.bank-info-description-agency')}`
    }
    return desc
  }

  setupChangeset(model) {
    this.changeset = this.constructor.setupChangesetAndReturnIt(model)
  }

  @dropTask
  *onBankInfoValidate(changeset) {
    try {
      yield changeset.validate()

      const isValid = changeset.get('isValid')

      if (!isValid) {
        const validationErrors = this.constructor.collectChangesetErrors(changeset)

        return validationErrors.map(({ attribute, message }) => ({
          attribute,
          message: this.intl.t(message)
        }))
      }

      return []
    } catch (error) {
      throw error
    }
  }

  @dropTask
  *onBankInfoSubmit(changeset) {
    try {
      const variables = this.getBankInfoMutationVariables(changeset)

      const res = yield this.sendMutationRequest(variables)

      if (res && res.errors) {
        return formatErrors(res.errors)
      }

      changeset.save()
      return []
    } catch (error) {
      throw error
    }
  }

  getBankInfoMutationVariables(changeset) {
    if (this.currentProfileType === 'agency') {
      return {
        input: {
          ...this.constructor.extractChangesetData(changeset),
          organization_id: this.profileInfo.activeProfile.id,
          organization_type: 'AGENCY'
        }
      }
    }

    if (this.currentProfile === 'contact') {
      return {
        input: {
          ...this.constructor.extractChangesetData(changeset),
          organization_id: this.profileInfo.activeProfile.id,
          organization_type: 'PRODUCER'
        }
      }
    }

    return {
      input: {
        ...this.constructor.extractChangesetData(changeset),
        id: this.profileInfo.activeProfile.id,
        profile: this._getMutationProfileByProfileType(this.currentProfile)
      }
    }
  }

  sendMutationRequest(variables) {
    if (this.currentProfile === 'contact' || this.currentProfileType === 'agency') {
      return this.apollo.mutate({ mutation: updateOrgMembershipMutation, variables }, 'return_data')
    }

    return this.apollo.mutate(
      { mutation: updateBankAccountMutation, variables },
      'update_bank_account_info'
    )
  }

  _getMutationProfileByProfileType(currentProfileType) {
    return PROFILE_TYPES_MAP[currentProfileType].toUpperCase()
  }

  /**
   * Sets up the changeset and returns it
   * @param {object} bankAccount
   * @returns {Changeset}
   */
  static setupChangesetAndReturnIt(bankAccount) {
    const bankAccountObject = {
      ...defaultBankAccountObject,
      ...(bankAccount !== null ? bankAccount : {})
    }

    return new Changeset(
      bankAccountObject,
      lookupValidator(BankAccountValidations),
      BankAccountValidations
    )
  }

  static collectChangesetErrors(bankAccount) {
    const withCorrectAttribute = bankAccount.get('errors').map(err => {
      const replaceWithAccountNumber = ['norwegian_account_number', 'international_account_number']
      const key = replaceWithAccountNumber.includes(err.key) ? 'account_number' : err.key
      return {
        ...err,
        key
      }
    })

    return mapChangesetErrors('bank_account/', withCorrectAttribute)
  }

  static extractChangesetData(bankAccountChangeset) {
    const bankAccount = {
      ...bankAccountChangeset.get('data'),
      ...bankAccountChangeset.get('change')
    }

    return getBankInfo(bankAccount)
  }
}
