import Component from '@glimmer/component'
import Query from 'min-side/graphql/queries/suggestion-search'
import { queryManager } from 'ember-apollo-client'
import { inject as service } from '@ember/service'
import { action } from '@ember/object'
import { reads } from '@ember/object/computed'
import { tracked } from '@glimmer/tracking'
import { isEmpty } from '@ember/utils'
import { restartableTask } from 'ember-concurrency-decorators'
import { timeout } from 'ember-concurrency'

const TIME_TO_WAIT_BEFORE_ACTUALLY_TRIGGERING_THE_TASK = 300

export default class GlobalSearch extends Component {
  @queryManager apollo
  @service profile
  @service router

  @tracked searchTerm = ''
  @tracked allowedToShowSuggestions = false

  constructor(...rest) {
    super(...rest)

    if (this.args.searchTerm) {
      this.searchTerm = this.args.searchTerm
      this.performSearch.perform()
    }
  }

  get isSearchTermEmpty() {
    return isEmpty(this.searchTerm)
  }

  get shouldShowSuggestionContainer() {
    return !this.isSearchTermEmpty && this.allowedToShowSuggestions
  }

  get searchResults() {
    if (this.performSearch.last) {
      return this.performSearch.last.value
    }

    return []
  }

  get emptyResult() {
    if (this.performSearch.last) {
      const resp = this.performSearch.last.value
      let myTotal = 0
      if (this.hasDiscography) {
        myTotal = resp.myReleases.total + resp.myRecordings.total
      }
      const result = myTotal + resp.gramoReleases.total + resp.gramoRecordings.total
      return result === 0
    }
    return true
  }

  get hasDiscography() {
    return this.profile.current.meta.hasDiscography
  }

  get overlayClass() {
    return this.allowedToShowSuggestions ? 'overlay' : 'overlay overlay-hidden'
  }

  get borderRadius() {
    return this.shouldShowSuggestionContainer() ? '0' : '24px'
  }

  @reads('performSearch.isRunning') isLoading;

  @restartableTask
  *performSearch() {
    yield timeout(TIME_TO_WAIT_BEFORE_ACTUALLY_TRIGGERING_THE_TASK)
    const variables = {
      query: this.searchTerm,
      rightsholder_id: this.profile.current.meta.id,
      include_my_discography: this.profile.current.meta.hasDiscography,
      sector: this.profile.current.meta.sector
    }

    return yield this.apollo.query({ query: Query, variables })
  }

  // Actions
  @action
  makeEmpty() {
    this.searchTerm = ''
  }

  @action
  goToDiscography() {
    this.disableShowingSuggestionsContainer()
    if (this.args.closeEvent) {
      this.args.closeEvent()
    }
    this.router.transitionTo('user.discography.albums')
  }

  @action
  onKeyUp(value, event) {
    switch (event.key) {
      case 'Enter': {
        break
      }
      case 'ArrowUp': {
        if (this.args.onArrowUp) {
          this.args.onArrowUp(event)
        }
        break
      }
      case 'ArrowDown': {
        if (this.args.onArrowDown) {
          this.args.onArrowDown(event)
        }
        break
      }
      case 'Escape': {
        this.__handleEscapeKey()
        break
      }
      default: {
        if (value !== this.searchTerm) {
          this.enableShowingSuggestionsContainer()
          this.performSearch.perform()

          if (this.args.onSearchTermUpdated) {
            this.args.onSearchTermUpdated(event, value)
          }
        }
        break
      }
    }
    this.searchTerm = value
  }

  @action
  onSubmit(event) {
    event.preventDefault()
    if (this.args.onSubmit) {
      this.args.onSubmit(event, this.searchTerm)
    }
  }

  @action
  enableShowingSuggestionsContainer() {
    this.allowedToShowSuggestions = true
  }

  @action
  disableShowingSuggestionsContainer() {
    this.allowedToShowSuggestions = false
  }

  __handleEscapeKey() {
    this.disableShowingSuggestionsContainer()
  }

  @action
  goToMyReleasesResults() {
    this.__goToReleasesResults(true, false)
  }

  @action
  goToMyRecordingsResults() {
    this.__goToRecordingsResults(true, false)
  }

  @action
  goToGramoReleasesResults() {
    this.__goToReleasesResults(false, true)
  }

  @action
  goToGramoRecordingsResults() {
    this.__goToRecordingsResults(false, true)
  }

  __goToReleasesResults(...args) {
    this.__goToResults('user.global-search-results.releases', ...args)
  }

  __goToRecordingsResults(...args) {
    this.__goToResults('user.global-search-results.recordings', ...args)
  }

  __goToResults(route, include_person_discography, include_gramo_registry) {
    this.disableShowingSuggestionsContainer()
    if (this.args.closeEvent) {
      this.args.closeEvent()
    }
    const params = {
      query: this.searchTerm,
      include_person_discography,
      include_gramo_registry
    }
    this.router.transitionTo(route, { queryParams: params })
  }
}
