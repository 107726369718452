import Component from '@ember/component'
import { computed } from '@ember/object'
import { inject as service } from '@ember/service'

export default Component.extend({
  'data-test-phone-number-field': true,
  intl: service(),
  classNames: ['phone-number-field'],
  isValidNumber: true,
  block: true,
  defaultCountry: 'no',
  required: false,

  init(...rest) {
    this._super(...rest)

    /**
     * Setting this to true will disabled the input and the country dropdown.
     * Defaults to `false`
     * @argument disabled
     * @type {boolean}
     */
    this.disabled = this.disabled || false

    /**
     Add or remove input placeholder with an example number for the selected
     country. Possible values are 'polite', 'aggressive' and 'off'. Defaults to
     'polite'.

     @argument autoPlaceholder
     @type {string}
     */
    this.autoPlaceholder = this.autoPlaceholder || 'off'

    /**
     It will just be the first country in the list. Set the initial country by
     it's country code. Defaults to ''.

     @argument initialCountry
     @type {string}
     */
    this.initialCountry = this.initialCountry || 'no'

    /**
     It will force the selected country. Set the country by it's country code.
     Usefull if you want to provide the component with a country, instead of
     using the built-in country dropdown.
     Defaults to ''.

     @argument country
     @type {string}
     */
    this.country = this.country || ''

    /**
     Display only the countries you specify -
     [see example](http://jackocnr.com/lib/intl-tel-input/examples/gen/only-countries-europe.html).

     @argument onlyCountries
     @type {Array}
     */
    this.onlyCountries = this.onlyCountries || []

    /**
     Specify the countries to appear at the top of the list.

     @argument preferredCountries
     @type {Array}
     */
    this.preferredCountries = this.preferredCountries || ['us', 'gb']

    /**
     Display the country dial code next to the selected flag so it's not part of the typed number

     @argument separateDialCode
     @type {boolean}
     */
    this.separateDialCode = this.separateDialCode || true
  },
  /**
   * The whole point of this computed prop is to handle the errors for this particular field type
   * in a different way:
   *   - accepting any external errors passed in the `errors` prop
   */
  computedErrors: computed('errors', 'shouldShowInvalidNumberError', function () {
    return {
      errors: [...(this.errors ? this.errors : [])]
    }
  }),
  onUpdate: () => null,
  actions: {
    updatePhoneNumber(value, extra) {
      this.setProperties({
        ...extra
      })
      this.get('onUpdate')(value, extra)
    }
  }
})
