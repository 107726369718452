import Component from '@glimmer/component'
import { tracked } from '@glimmer/tracking'
import { reads } from '@ember/object/computed'
import { queryManager } from 'ember-apollo-client'
import { restartableTask } from 'ember-concurrency-decorators'
import { inject as service } from '@ember/service'
import { sectorForUser } from 'min-side/helpers/user-types'
import query from 'min-side/graphql/queries/ledger/distribution-area-channels'

export default class LedgerDistributionAreaChannelTable extends Component {
  @service router
  @service profile
  @queryManager apollo

  get channels() {
    return []
  }

  @tracked summary = {}

  @reads('summary.distribution_area_summaries.0.channels')
  channels

  get hasStatements() {
    return this.data.annual_income_statements.length > 0
  }

  get noStatements() {
    return !this.hasStatements
  }

  get loadChannelsTask() {
    this.loadChannels.perform()
    return this.loadChannels
  }

  @restartableTask *loadChannels() {
    const variables = {
      owner_id: this.profile.activeProfile.id,
      sector: sectorForUser(this.profile.activeProfile),
      years: [parseInt(this.args.year, 10)],
      distribution_area_ids: [this.args.distributionAreaId]
    }
    const result = yield this.apollo.watchQuery({
      query,
      variables
    })
    this.summary = result.ledger.remuneration_years[0]
  }
}
