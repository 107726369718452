import Component from '@glimmer/component'
import { inject as service } from '@ember/service'
import { action } from '@ember/object'

export default class MostPlayedDashlet extends Component {
  @service profile
  @service router
  @service features

  get showShareSize() {
    return this.profile.current.meta.profileName !== 'performer'
  }

  @action
  viewPlayedRecordings() {
    if (this.features.get('discography_rework')) {
      if (this.profile.activeProfile.type === 'producer_company') {
        this.router.transitionTo('user.recordings')
      } else {
        this.router.transitionTo('user.played-recordings')
      }
    } else {
      this.router.transitionTo('user.discography.recordings')
    }
  }
}
