import { copy } from 'ember-copy'
import { getGramoMembership } from 'min-side/helpers/memberships'
import { profileNameInModel, toProfileEnum } from 'min-side/helpers/profiles'
import { isEmpty } from '@ember/utils'

const accountTypes = {
  NorwegianBankAccount: 'norwegian_bank_account',
  IBANBankAccount: 'iban_bank_account',
  BICBankAccount: 'bic_bank_account',
  AmericanBankAccount: 'american_bank_account'
}

function membershipBankAccount(membership) {
  if (isEmpty(membership)) return null

  const account = membership.bank_account
  if (account && account.account_number) {
    delete account.__typename
    return account
  }
  return null
}

function setBankAccount(person, currentProfile, variables) {
  let bankAccount
  const profileName = profileNameInModel(currentProfile.profile)
  const hasGramoMembership = !!getGramoMembership(person[profileName].society_memberships)
  if (profileName === 'personal_inheritance_party' && hasGramoMembership) {
    const memberships = person.personal_inheritance_party.society_memberships
    bankAccount = copy(membershipBankAccount(getGramoMembership(memberships)))
  } else if (hasGramoMembership) {
    const memberships = person[profileName].society_memberships
    bankAccount = copy(membershipBankAccount(getGramoMembership(memberships)))
  }

  if (bankAccount && accountTypes[person.inputAccountType]) {
    delete bankAccount.errors
    variables.input[accountTypes[person.inputAccountType]] = bankAccount
  }
  return variables
}

export const inputVariables = (person, currentProfile) => {
  delete person.contact_information.__typename
  delete person.contact_information.errors

  if (person.contact_information.address) {
    delete person.contact_information.address.__typename
  }
  const variables = {
    input: {
      id: person.id,
      profile: toProfileEnum(currentProfile.profile),
      contact_information: person.contact_information
    }
  }
  return setBankAccount(person, currentProfile, variables)
}

export const contactMembershipInputVariables = (organization, accountType) => {
  const input = {
    input: {
      organization_id: organization.id
    }
  }
  let bankAccount
  if (organization.producer_company) {
    const gramoMembership = getGramoMembership(organization.producer_company.society_memberships)
    input.input.organization_type = 'PRODUCER'
    bankAccount = copy(membershipBankAccount(gramoMembership))
  } else if (organization.agency) {
    input.input.organization_type = 'AGENCY'
    bankAccount = copy(organization.agency.bank_account)
  }
  if (bankAccount && accountTypes[accountType]) {
    delete bankAccount.errors
    input.input[accountTypes[accountType]] = bankAccount
  } else {
    return
  }
  return input
}

export const contactContactInputVariables = organization => {
  let organization_type
  if (organization.producer_company) {
    organization_type = 'PRODUCER'
  } else if (organization.agency) {
    organization_type = 'AGENCY'
  }
  const contact_information = copy(organization.contact_information)
  delete contact_information.__typename
  delete contact_information.errors
  if (contact_information.address) {
    delete contact_information.address.__typename
  }

  return {
    input: {
      organization_type,
      organization_id: organization.id,
      contact_information
    }
  }
}
