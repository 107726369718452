import { validatePresence } from 'ember-changeset-validations/validators'

export const defaultPersonalInfoObject = {
  previous_names: [],
  first_name: '',
  last_name: '',
  gender: '',
  born_on: '',
  social_security_number: '',
  citizenships: [],
  residencies: []
}

export default {
  first_name: validatePresence({
    presence: true,
    message: 'validation.missing-first-name'
  }),
  last_name: validatePresence({
    presence: true,
    message: 'validation.missing-last-name'
  }),
  born_on: validatePresence({
    presence: true,
    message: 'validation.missing-born-on'
  })
}
