import Controller from '@ember/controller'
import PlaybackControllerMixin from 'min-side/mixins/playbacks/controller'
import { computed } from '@ember/object'
import { inject } from '@ember/service'
import { queryManager } from 'ember-apollo-client'
import RecordingPlaybackAggregates from 'min-side/graphql/queries/recording-playback-aggregates'

export default Controller.extend(PlaybackControllerMixin, {
  profile: inject(),
  apollo: queryManager(),

  isPerformer: computed('profile.activeProfile.profile', function () {
    return this.profile.activeProfile.profile === 'performer'
  }),

  actions: {
    async createClaim(selectedRecording) {
      // open create quick claim dialog
      const activeRecording = selectedRecording.playbacks.find(playback => playback.recording)

      this.set('loading', true)
      // load extra playback info here to get the list of related playbacks
      if (activeRecording) {
        const recording = await this.get('apollo').query({
          query: RecordingPlaybackAggregates,
          fetchPolicy: 'network-only',
          variables: { isrc_or_id: activeRecording.recording.id }
        })
        this.set(
          'relatedPlaybackGroups',
          recording.recording_by_isrc_or_id.public_playbacks_aggregates.edges
            .map(edge => edge.node.playbacks)
            .flat()
        )
      }
      this.set('selectedRecording', selectedRecording)
      this.set('selectedRecording.performances', [{}])
      this.set('loading', false)
    },
    closeRecording() {
      this.set('selectedRecording', null)
      this.send('reload')
    }
  }
})
