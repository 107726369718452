import { inject as service } from '@ember/service'
import Route from '@ember/routing/route'
import { queryManager } from 'ember-apollo-client'
import { copy } from 'ember-copy'
import query from 'min-side/graphql/queries/claim'
import RemovePlaybackFromClaim from 'min-side/graphql/mutations/claims/remove_playback_from_claim'
import DeleteClaimFile from 'min-side/graphql/mutations/claims/delete_claim_file'
import SubmitClaim from 'min-side/graphql/mutations/claims/submit_claim'
import { PROFILE_TYPES, PROFILE_TYPES_MAP } from 'min-side/constants/profile'
import { set } from '@ember/object'
import { getActiveOrganization } from 'min-side/helpers/organization'

export default Route.extend({
  apollo: queryManager(),
  profile: service(),
  features: service(),
  router: service(),
  intl: service(),

  queryParams: {
    sector: { refreshModel: true },
    openRejectedClaimRecording: { refreshModel: false },
    first: { refreshModel: true },
    after: { refreshModel: true }
  },

  beforeModel() {
    if (this.profile.activeProfile.impersonating) {
      this.router.transitionTo('')
    }
  },

  setupController(controller, model) {
    this._super(controller, model)

    const { openRejectedClaimRecording } = this.paramsFor('user.claims.item')

    // when this route loaded with openRejectedClaimRecording param set we want to open first
    // editable recording. It is made like this so we can open first rejected claim recording for
    // user to start updating it right away
    if (openRejectedClaimRecording) {
      controller.editRecording(controller.editableRecordings[0])
      this.router.transitionTo('user.claims.item', model.claim.id, {
        queryParams: { openRejectedClaimRecording: null }
      })
    }
  },

  async model({ id, sector, first, after }) {
    const availableProfiles = this.get('profile.selectableProfileObjects')
    const availableProfileTypes = availableProfiles.map(({ profile }) => profile)
    const currentProfile = this.get('profile.activeProfile')
    const variables = {
      claim_id: id,
      include_producer: false,
      include_performer: false,
      include_contact: false,
      sector: null,
      first,
      after
    }

    if (availableProfileTypes.includes(PROFILE_TYPES.PERSONAL_PRODUCER)) {
      variables.include_producer = true
    }
    if (availableProfileTypes.includes(PROFILE_TYPES.PERFORMER)) {
      variables.include_performer = true
    }
    if (availableProfileTypes.includes(PROFILE_TYPES.CONTACT)) {
      if (sector) {
        variables.sector = sector
      }
      variables.include_contact = true
    }
    const results = await this.apollo.query({ query, variables, fetchPolicy: 'network-only' })

    let claim = null
    let newProfile = null

    availableProfiles.find(profile => {
      if (profile.profile === PROFILE_TYPES.CONTACT) {
        const ownerId = profile.id
        const activeOrg = getActiveOrganization(results.user.person.contact.positions, ownerId)
        if (activeOrg) {
          let entity
          if (activeOrg.agency) {
            entity = activeOrg.agency
          } else {
            entity = activeOrg.producer_company
          }
          if (entity.claims.edges.length) {
            claim = copy(entity.claims.edges[0].node, true)
            if (profile.id !== currentProfile.id) {
              newProfile = profile
            }
            return true
          }
        }
      } else {
        const profileNode = PROFILE_TYPES_MAP[profile.profile]
        if (results.user.person[profileNode]) {
          const entity = results.user.person[profileNode]
          if (entity.claims.edges.length) {
            claim = copy(entity.claims.edges[0].node, true)
            if (profile.profile !== currentProfile.profile) {
              newProfile = profile
            }
            return true
          }
        }
      }
      return false
    })

    if (!claim) {
      this.replaceWith('/not-found')
    }

    if (newProfile) {
      this.get('profile').switchTo(newProfile)
    }

    // Emails sent to performers include a link to the claim. Since claims
    // created by performers only have one claim recording, and they do not use
    // the claims UI, we can just redirect them to the recording page.
    if (this.get('profile.activeProfile.profile') === 'performer') {
      const recordingId = claim.claim_recordings.edges[0].node.id
      this.router.transitionTo('user.recording', recordingId)
    }

    set(
      claim,
      'claim_recordings.edges',
      claim.claim_recordings.edges.map(claim_recording => {
        set(claim_recording.node, 'ownership', claim_recording.node.ownerships?.[0] || {})
        return claim_recording.node
      })
    )

    set(
      claim,
      'claim_recordings.edges',
      claim.claim_recordings.edges.map(claim_recording => {
        if (claim_recording.typename === 'PerformerClaimRecording') {
          if (!claim_recording.performances?.length) {
            set(claim_recording, 'performances', [{}])
          }
          /* eslint-disable no-unused-expressions */
          claim_recording.performances?.forEach(performance => {
            if (!performance.instrument?.id) {
              const name = results.instruments.find(
                instrument => instrument.id === performance.instrument
              )?.name
              if (name) {
                set(performance, 'instrument', {
                  id: performance.instrument,
                  name
                })
              }
            }
          })
        }
        return claim_recording
      })
    )

    return {
      claim,
      active: claim.state === 'CREATED',
      sector,
      instruments: results.instruments
    }
  },
  async removePlaybackFromClaim(claim_recording_id) {
    const variables = {
      input: {
        id: claim_recording_id
      }
    }
    await this.apollo.mutate({
      variables,
      mutation: RemovePlaybackFromClaim
    })
  },
  actions: {
    reload() {
      this.refresh()
    },
    async removePlaybackFromClaim(claim_recording_id) {
      await this.removePlaybackFromClaim(claim_recording_id)
      this.refresh()
    },
    async removeFileFromClaim(claim_file_id) {
      const variables = {
        input: {
          id: claim_file_id
        }
      }
      await this.apollo.mutate({
        variables,
        mutation: DeleteClaimFile
      })
      this.refresh()
    },
    async submitClaim(id) {
      const variables = {
        input: {
          id
        }
      }

      try {
        const result = await this.apollo.mutate({
          variables,
          mutation: SubmitClaim
        })
        if (result.submit_claim.errors) {
          this.get('flashMessages').error(this.get('intl').t('flash.save.invalid'))
          return
        }
        this.flashMessages.success(this.intl.t('claims.submit-success'))
        this.refresh()
      } catch (e) {
        this.get('flashMessages').error(this.get('intl').t('flash.save.invalid'))
      }
    },
    async removeRecordings(recordings) {
      for (let i = 0; i < recordings.length; i++) {
        await this.removePlaybackFromClaim(recordings[i])
      }
      this.refresh()
    }
  }
})
