import EmberObject from '@ember/object'
import { isPresent, isEmpty } from '@ember/utils'
import { getWithDefault } from '@ember/object'
import isObject from 'min-side/utils/isObject'

export const isForeignAddress = contact_information => {
  if (isEmpty(contact_information) || isEmpty(contact_information.address)) {
    return false
  }
  return isPresent(contact_information.address.content)
}

export const getContactInformation = (contact_information, hasForeignAddress) => {
  if (contact_information === null) {
    return null
  }
  const { email, mobile, telephone, webpage } = contact_information
  const contactInformation = {
    email,
    mobile,
    telephone,
    webpage
  }
  if (hasForeignAddress) {
    contactInformation.international_address = contact_information.address.content
  } else if (contact_information.address) {
    const { care_of, postal_area, postal_code, street } = contact_information.address
    contactInformation.address = { care_of, postal_area, postal_code, street }
  }
  return contactInformation
}

export const emptyContactInformation = _ => {
  return EmberObject.extend({
    address: EmberObject.extend({}).create()
  }).create()
}

export const hasAlternativeMobile = (model = {}, path = 'user.person.user') => {
  let object = {}

  if (path === '') {
    object = model
  } else {
    object = getWithDefault(model, path, {})
  }
  // if we get at this point, and we don't have an actual object in our variable then we just return false
  if (!isObject(object)) return false

  return 'alternative_mobile' in object
}
