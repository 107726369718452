import Component from '@glimmer/component'
import { computed } from '@ember/object'

export default class LedgerAmount extends Component {
  @computed('bankTransactionState')
  get isRejectedPaymentState() {
    switch (this.args.bankTransactionState) {
      case ('REJECTED', 'RETURNED', 'REFUNDED_TO_LEDGER'):
        return true
    }
  }

  get numberClass() {
    if (this.args.amount) {
      const amount = parseInt(this.args.amount, 10)
      if (amount > 0) {
        return 'positive'
      } else if (amount < 0) {
        return 'negative'
      }
    }
    return ''
  }
}
