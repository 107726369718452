const ACCOUNT_TYPES = {
  blank: 'blank',
  NorwegianBankAccount: 'norwegian',
  BICBankAccount: 'bic-swift',
  IBANBankAccount: 'iban',
  AmericanBankAccount: 'american'
}

const ACCOUNT_TYPENAMES = {
  blank: 'blank',
  norwegian: 'NorwegianBankAccount',
  'bic-swift': 'BICBankAccount',
  iban: 'IBANBankAccount',
  american: 'AmericanBankAccount'
}

export { ACCOUNT_TYPES, ACCOUNT_TYPENAMES }
