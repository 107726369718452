import moment from 'moment'
import { reads } from '@ember/object/computed'
import signedInQuery from 'min-side/graphql/queries/profile/signed-in'

/**
 * Properties on the signed in object belongs to the signed in user.
 * It will never be affected by profile changes nor impersonations.
 */
export default class SignedIn {
  constructor(session, apollo) {
    this.session = session
    this.apollo = apollo
  }

  /**
   * Returns the user properties we get from JWT session or null if user is not signed in
   */
  async user() {
    if (!this._isAuthenticated) return null

    const { id, email, name, locale, mobile, sign_in_count } = this._userFromSession
    const { createdAt, intercomUserHash, features } = await this._data()

    return {
      id,
      email,
      name,
      locale,
      mobile,
      createdAt,
      features,
      intercomUserHash,
      signInCount: sign_in_count
    }
  }

  /**
   * Returns the signed in person's properties.
   *
   * A person in echo is coupled to a user. The user contains sign in credentials, while the person
   * object has properties like addresses, citizenships etc etc.
   *
   * Not all properties found on the Person GraphQL type are exposed here, but we'll expose it as needed.
   */
  async person() {
    const { email, mobile } = await this.user()
    const {
      person: { id, firstName, lastName }
    } = await this._data()

    return {
      id,
      name: [firstName, lastName].join(' '),
      firstName,
      lastName,
      email,
      mobile
    }
  }

  async profiles() {
    const {
      person: { profiles }
    } = await this._data()

    return profiles
  }

  async organizations() {
    const {
      person: {
        contact: { positions }
      }
    } = await this._data()

    return positions.map(({ organization: { id, name, profile } }) => ({ id, name, profile }))
  }

  @reads('session.isAuthenticated') _isAuthenticated
  @reads('session.session.content.authenticated.user') _userFromSession

  async _data() {
    if (this._loadedData) return this._loadedData

    try {
      this._loadedData = (await this.apollo.query({ query: signedInQuery })).user

      if (!this._loadedData.person) this._loadedData.person = { profiles: [] } // Most likely echo-only user without a person object

      this._loadedData.person.contact = this._loadedData.person.contact || { positions: [] } // user may not have contact profile
      this._loadedData.createdAt = moment(this._loadedData.createdAt)

      return this._loadedData
    } catch (error) {
      console.error(error)
    }
  }
}
