import Component from '@glimmer/component'
import { action, computed } from '@ember/object'
import Changeset from 'ember-changeset'
import lookupValidator from 'ember-changeset-validations'
import CitizenshipValidations from 'min-side/validations/profile/citizenship'
import ResidencyValidations from 'min-side/validations/profile/residency'
import PreviousNameValidations from 'min-side/validations/profile/previous-name'

export default class ProfileSectionPersonalInfoComponent extends Component {
  get data() {
    return this.args.changeset
  }

  get previousNames() {
    return this.data.get('previous_names')
  }

  @computed('args.errors.length')
  get nestedErrorsObject() {
    return {
      errors: this.args.errors
    }
  }

  @action
  removePreviousName(_, index) {
    this.data.set(
      'previous_names',
      this.data.get('previous_names').filter((_, idx) => idx !== index)
    )
  }

  @action
  addPreviousName() {
    this.data.set('previous_names', [
      ...this.data.get('previous_names'),
      new Changeset(
        {
          description: '',
          name: ''
        },
        lookupValidator(PreviousNameValidations),
        PreviousNameValidations
      )
    ])
  }

  @action
  addCitizenship() {
    this.data.set('citizenships', [
      ...this.data.get('citizenships'),
      new Changeset(
        {
          country: null,
          from: '',
          to: ''
        },
        lookupValidator(CitizenshipValidations),
        CitizenshipValidations
      )
    ])
  }

  @action
  removeCitizenship(index) {
    this.data.set(
      'citizenships',
      this.data.get('citizenships').filter((_, idx) => idx !== index)
    )
  }

  @action
  addResidency() {
    this.data.set('residencies', [
      ...this.data.get('residencies'),
      new Changeset(
        {
          country: null,
          from: '',
          to: ''
        },
        lookupValidator(ResidencyValidations),
        ResidencyValidations
      )
    ])
  }

  @action
  removeResidency(index) {
    this.data.set(
      'residencies',
      this.data.get('residencies').filter((_, idx) => idx !== index)
    )
  }
}
