import Component from '@ember/component'
import { reads, equal } from '@ember/object/computed'

export default Component.extend({
  classNames: ['row'],
  'data-test-search-recordings-box': true,

  showResults: false,
  foundRecordings: reads('searchRecordingsTask.last.value'),
  noRecordingsFound: equal('foundRecordings.length', 0),

  actions: {
    async search(query) {
      await this.searchRecordingsTask.perform(query)
      this.set('showResults', true)
    }
  }
})
