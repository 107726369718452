import Component from '@ember/component'
import { inject } from '@ember/service'

export default Component.extend({
  'data-test-release-form-review': true,
  eventBus: inject(),

  actions: {
    save() {
      this.saveReleaseTask.perform()
    },

    cancel() {
      this.eventBus.publish('stepBackToPerformers')
      this.stepBackAction()
    }
  }
})
