import Component from '@ember/component'
import { get } from '@ember/object'
import { mapChanges } from 'min-side/extractors/edit-recording/performance-changes'

export default Component.extend({
  tagName: '',
  supportsDataTestProperties: true,

  init(...args) {
    this._super(...args)

    const nonModifiedPerformers = this._getNonModifiedPerformers()
    const modifiedPerformers = mapChanges(this.changeRequest)

    this.setProperties({
      nonModifiedPerformers,
      modifiedPerformers,
      modifiedPerformersInstruments: this._initInstruments(modifiedPerformers),
      nonModifiedPerformersInstruments: this._initInstruments(nonModifiedPerformers)
    })
  },

  _getNonModifiedPerformers() {
    const modifiedPerformerIds = this.changeRequest.map(change => {
      return (
        get(change, 'old_value.performer.person.id') || get(change, 'new_value.performer.person.id')
      )
    })

    return this.performers.reject(p => modifiedPerformerIds.includes(get(p, 'id')))
  },

  _initInstruments(performers) {
    const selectedInstruments = []

    performers.forEach(performer => {
      const code = get(performer, 'instrument_code')
      const instrument = this.instruments.findBy('id', code)
      selectedInstruments.push(instrument)
    })
    return selectedInstruments
  }
})
