export default {
  "new-global-search": "_new-global-search_x4xzkj",
  "inner-container": "_inner-container_x4xzkj",
  "dashboard": "_dashboard_x4xzkj",
  "dashboard-body": "_dashboard-body_x4xzkj",
  "placeholder": "_placeholder_x4xzkj",
  "message": "_message_x4xzkj",
  "discography-dashlets": "_discography-dashlets_x4xzkj",
  "money-dashlets": "_money-dashlets_x4xzkj",
  "payment-dashlets": "_payment-dashlets_x4xzkj",
  "graph-dashlet": "_graph-dashlet_x4xzkj",
  "played-dashlet": "_played-dashlet_x4xzkj"
};
