import Route from '@ember/routing/route'
import { queryManager } from 'ember-apollo-client'

export default class BaseGlobalSearchResultsRoute extends Route {
  @queryManager apollo

  queryParams = {
    first: { refreshModel: true },
    after: { refreshModel: true },
    property: { refreshModel: true },
    direction: { refreshModel: true }
  }

  defaultOrderBy = [
    { property: 'USER_RELEVANCE', direction: 'DESC' },
    { property: 'RELEASE_YEAR', direction: 'DESC' },
    { property: 'TITLE', direction: 'ASC' },
    { property: 'MAIN_ARTIST', direction: 'ASC' }
  ]

  _orderByFromUserOrDefault(parameters) {
    const { property, direction } = parameters
    return property ? { direction, property } : this.defaultOrderBy
  }
}
