import Component from '@ember/component'
import { getWithDefault } from '@ember/object'
import { filter } from '@ember/object/computed'
import { GRAPHQL_PROFILE_TYPES } from 'min-side/constants/profile'

export default Component.extend({
  'data-test-testator-list-view': true,

  filteredTestators: filter('testators', testator => {
    const profiles = getWithDefault(testator, 'inherited.profiles', [])
    const firstProfileInheritanceParty = profiles[0] === GRAPHQL_PROFILE_TYPES.INHERITANCE_PARTY
    const isOnlyInheritanceParty = profiles.length === 1 && firstProfileInheritanceParty
    return !isOnlyInheritanceParty
  })
})
