import { task, timeout } from 'ember-concurrency'
import mainArtistSuggestionsQuery from 'min-side/graphql/queries/main-artist-suggestions'
import { DEBOUNCE_MS } from 'min-side/constants/time'

export default task(function* (query) {
  yield timeout(DEBOUNCE_MS)
  const { main_artist_name_suggestions } = yield this.apollo.watchQuery({
    query: mainArtistSuggestionsQuery,
    variables: { query }
  })

  return main_artist_name_suggestions.map(suggestion => ({
    name: suggestion
  }))
}).restartable()
