import Component from '@ember/component'
import { reads, equal, gt } from '@ember/object/computed'

export default Component.extend({
  classNames: ['row'],
  'data-test-search-producers-box': true,

  foundProducers: reads('searchProducersTask.last.value'),
  foundProducersExist: gt('foundPerformers', 0),
  noProducersFound: equal('foundProducers.length', 0),

  actions: {
    search(query) {
      return this.searchProducersTask.perform(query)
    }
  }
})
