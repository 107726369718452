/**
 * Moves an item in array to a new position
 * @param array
 * @param oldItemIndex
 * @param newItemIndex
 * @returns {*}
 */
function arrayMoveItem([...array], oldItemIndex, newItemIndex) {
  let newIndex = newItemIndex
  const oldIndex = oldItemIndex
  if (newIndex >= array.length) newIndex = array.length - 1
  array.splice(newIndex, 0, array.splice(oldIndex, 1)[0])
  return array
}

export { arrayMoveItem }
