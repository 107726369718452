import ProfileInfoRouteBankAndMembership from 'min-side/abstracts/routes/profile-info-route-bank-and-membership'
import { getQueryByProfileType } from 'min-side/graphql/helpers/profile-info/queries'
import producerCompanyQuery from 'min-side/graphql/queries/profile/producer-company'

export default class ProfileInfoMembershipRoute extends ProfileInfoRouteBankAndMembership {
  async model() {
    if (this.currentProfile === 'contact') {
      return await this.modelForContact()
    }
    const impersonatedUserId = this.profileInfo.profile.impersonatedUserId
    const variables = impersonatedUserId ? { person_id: impersonatedUserId } : null
    const profileType = this.currentProfile
    const query = getQueryByProfileType(profileType)
    const response = await this.apollo.watchQuery({ query, variables, fetchPolicy: 'network-only' })
    return {
      profile: this.getCurrentActiveProfileObject(response),
      membership: this.getCurrentActiveProfileMembershipObject(response),
      federationMemberships: this.getCurrentActiveProfileFederationMembership(response),
      societies: response.societies
    }
  }

  async modelForContact() {
    const id = this.profileInfo.profile.activeProfile.id
    const variables = { id }

    const response = await this.apollo.watchQuery({
      query: producerCompanyQuery,
      variables,
      fetchPolicy: 'network-only'
    })

    return {
      profile: this.getCurrentActiveProfileObject(response),
      membership: this.getCurrentActiveProfileMembershipObject(response),
      federationMemberships: this.getCurrentActiveProfileFederationMembership(response),
      societies: response.societies
    }
  }
}
