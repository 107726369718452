import Component from '@glimmer/component'
import { inject as service } from '@ember/service'

export default class PaymentsOwedDashlet extends Component {
  @service router

  get amount() {
    return this.args.model || 0
  }
}
