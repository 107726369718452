import Component from '@ember/component'
import { inject } from '@ember/service'
import { computed, get, getWithDefault, set } from '@ember/object'
import { task } from 'ember-concurrency'
import Changeset from 'ember-changeset'
import groupByProperty from 'min-side/helpers/group-by-property'
import cloneDeep from 'lodash/cloneDeep'
import flatten from 'lodash/flatten'
import { prepareTrackForInput } from 'min-side/extractors/edit-release/tracks'
import UpdateReleaseTracks from 'min-side/graphql/mutations/edit-release/update-release-tracks'
import { queryManager } from 'ember-apollo-client'

const MAX_CONCURRENCY = 1
export default Component.extend({
  'data-test-edit-release-tracks': true,
  tagName: 'section',
  elementId: 'track-info',
  disabled: true,
  intl: inject(),
  apollo: queryManager(),

  tracks: computed('release.tracks.@each.node', function () {
    return getWithDefault(this, 'release.tracks', [])
  }),
  sidesInChangeSet: computed('changeset.sides.[]', function () {
    return get(this, 'changeset').get('sides')
  }),
  tracksInSideChangesets: computed('sidesInChangeSet.@each.tracks', function () {
    return flatten(this.sidesInChangeSet.map(side => side.get('tracks').map(track => track)))
  }),

  tracksItemInSideChangeset: computed('tracksInSideChangesets.@each.node', function () {
    return this.tracksInSideChangesets.map(track => get(track, 'node'))
  }),

  disableControls: computed('tracksItemInSideChangeset.@each.addingRecording', function () {
    return this.tracksItemInSideChangeset.any(track => get(track, 'addingRecording'))
  }),

  sides: computed('tracks', function () {
    if (!get(this, 'tracks.length')) return []

    return groupByProperty(get(this, 'tracks'), 'node.side').map(side => {
      return [...side].sort((a, b) => {
        return get(a, 'node.number') - get(b, 'node.number')
      })
    })
  }),

  sidesCount: computed('changeset.sides.@each', function () {
    return get(this, 'changeset.sides.length')
  }),

  sidesChangesets: computed('sides', function () {
    return get(this, 'sides').map(side => {
      return new Changeset({
        tracks: side,
        addingNewTrack: false
      })
    })
  }),

  tracksCount: computed('changeset.sides.@each.tracks', function () {
    const tracksCount = []
    const changeset = get(this, 'changeset')
    const sides = changeset.get('sides')
    sides.map(side =>
      tracksCount.push(...side.get('tracks').filter(track => track.hasOwnProperty('node')))
    )
    return tracksCount.length
  }),

  init(...args) {
    this._super(...args)
    this.changeset = new Changeset({ sides: get(this, 'sidesChangesets') })
  },

  submitTask: task(function* (changeset) {
    this._exitAddNewTrackMode()
    this.sidesChangesets.forEach(changeset => changeset.save())
    changeset.save()

    let rawData = changeset.get('sides').map(side => {
      return side.get('tracks')
    })

    rawData = flatten(rawData)
    const preparedData = rawData.map(track => prepareTrackForInput(track))

    const variables = {
      input: {
        release_id: get(this, 'releaseId'),
        release_tracks: preparedData
      }
    }

    try {
      const {
        update_release_tracks: { errors }
      } = yield this.apollo.mutate({
        variables,
        mutation: UpdateReleaseTracks
      })

      if (errors) {
        this.get('flashMessages').error(this.get('intl').t('flash.save.invalid'))
        this.set('errors.errors', errors)
      } else {
        set(this, 'disabled', true)
        this.flashMessages.success(this.intl.t('flash.save.success'))
        set(this, 'release.tracks', rawData)
        this.set('changeset', new Changeset({ sides: get(this, 'sidesChangesets') }))
      }
    } catch (e) {
      this.flashMessages.error(this.intl.t('flash.save.failed'))
    }
  })
    .enqueue()
    .maxConcurrency(MAX_CONCURRENCY),

  actions: {
    edit() {
      set(this, 'disabled', false)
    },
    rollback() {
      this.sidesChangesets.forEach(changeset => {
        changeset.get('tracks').forEach(track => {
          set(track, 'node.addingRecording', false)
        })
        changeset.rollback()
      })
      this.changeset.rollback()
      set(this, 'disabled', true)
    },
    addNewSide() {
      this.changeset.set('sides', [
        ...this.changeset.get('sides'),
        new Changeset({
          tracks: [],
          addingNewTrack: false
        })
      ])
    },
    removeSide(index) {
      this.changeset.set(
        'sides',
        this.changeset.get('sides').filter((item, position) => position !== index)
      )
    },
    moveInNewSideUp(sideIndex, trackToBeAppended) {
      const sides = this.changeset.get('sides')
      const side = sides[sideIndex - 1]
      const resultedTracks = [
        ...side.get('tracks'),
        this._decreaseSideNumberOnTrack(sideIndex, trackToBeAppended)
      ]
      side.set('tracks', this._updateTracksPositionInSide(resultedTracks))
    },
    moveInNewSideDown(sideIndex, trackToBePrepended) {
      const sides = this.changeset.get('sides')
      const side = sides[sideIndex + 1]
      const resultedTracks = [
        this._increaseSideNumberOnTrack(sideIndex, trackToBePrepended),
        ...side.get('tracks')
      ]
      side.set('tracks', this._updateTracksPositionInSide(resultedTracks))
    }
  },
  _updateTracksPositionInSide(tracks) {
    return tracks.map((track, index) => {
      set(track, 'node.number', index + 1)
      return track
    })
  },

  _decreaseSideNumberOnTrack(currentSideIndex, track) {
    const clonedTrack = cloneDeep(track)
    set(clonedTrack, 'node.side', currentSideIndex)
    return clonedTrack
  },

  _increaseSideNumberOnTrack(currentSideIndex, track) {
    const clonedTrack = cloneDeep(track)
    set(clonedTrack, 'node.side', currentSideIndex + 2) // eslint-disable-line no-magic-numbers
    return clonedTrack
  },

  _exitAddNewTrackMode() {
    const sides = this.changeset.get('sides')
    sides.forEach(side => side.set('addingNewTrack', false))
  }
})
