import Route from '@ember/routing/route'
import query from 'min-side/graphql/queries/discography/recordings'
import { queryManager } from 'ember-apollo-client'
import { inject } from '@ember/service'
import { isPresent } from '@ember/utils'

export default Route.extend({
  apollo: queryManager(),
  profile: inject(),
  features: inject(),

  renderTemplate(controller, model) {
    this._super(controller, model)

    this.render('user.discography.recordings.pagination', {
      outlet: 'pagination',
      controller: this.controllerFor('user.discography.recordings.index')
    })
  },

  queryParams: {
    after: { refreshModel: true }
  },

  async model({ after }) {
    const { q } = this.paramsFor('user.discography')
    const { property, direction } = this.paramsFor('user.discography.recordings')
    const first = this.controllerFor('user.discography.recordings.index').get('first')

    const order = []
    if (property && direction) {
      order.push({ property, direction })
    }

    const maskId = this.get('profile.impersonatedUserId')
    const profileName = this.get('profile.activeProfile.profile')
    let includePerformer = true
    let includeProducer = true

    if (maskId !== null) {
      includePerformer = profileName === 'performer'
      includeProducer = profileName === 'personal-producer'
    }

    const variables = {
      person_id: this.get('profile.impersonatedUserId'),
      query: isPresent(q) ? q : null,
      first,
      after: isPresent(after) ? after : null,
      include_performer: includePerformer,
      include_producer: includeProducer,
      order
    }
    return this.apollo.query({ query, variables, fetchPolicy: 'network-only' })
  },
  setupController(controller, model, transition) {
    this._super(controller, model)
    controller.set('query', transition.to.queryParams.q)
  },
  actions: {
    redirectToRecording(isrc, id) {
      if (this.features.get('discography_rework')) {
        this.transitionTo('user.recording', isrc || id)
      } else {
        this.transitionTo('user.edit-recording', isrc || id)
      }
    }
  }
})
