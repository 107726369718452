import Route from '@ember/routing/route'
import { inject } from '@ember/service'
import EmberObject from '@ember/object'
import { queryManager } from 'ember-apollo-client'
import query from 'min-side/graphql/queries/members-recording'
import { sectorForUser } from 'min-side/helpers/user-types'

export default Route.extend({
  features: inject(),
  router: inject(),
  apollo: queryManager(),
  profile: inject(),
  queryParams: {
    single: { refreshModel: true }
  },

  beforeModel() {
    if (!this.features.get('discography_rework')) {
      this.router.transitionTo('')
    }
  },

  async model({ existing }) {
    let claim_recording = null
    if (existing) {
      // load the existing registration data instead of an empty one
      const variables = {
        id: existing,
        owner_id: this.get('profile.activeProfile.id'),
        sector: sectorForUser(this.get('profile.activeProfile'))
      }
      const response = await this.apollo.query({ query, variables, fetchPolicy: 'network-only' })
      if (response && response.members_recording && response.members_recording.claim_recordings) {
        claim_recording = response.members_recording.claim_recordings.find(cr => !cr.claim)
        return {
          release: {
            title: claim_recording.album,
            catalog_number: claim_recording.catalog_number,
            label_name: claim_recording.label_name,
            release_year: claim_recording.release_year.toString()
          },
          recordings: [
            EmberObject.create({
              recording_date: null,
              title: claim_recording.title,
              isrc: claim_recording.isrc,
              main_artist: claim_recording.main_artist,
              errors: []
            })
          ],
          performances: claim_recording.performances?.map(p => {
            return EmberObject.create(p)
          }),
          ownership: claim_recording.ownerships?.map(o => {
            return EmberObject.create(o)
          })[0]
        }
      }
    }
    return {
      release: {
        title: ''
      },
      recordings: [
        EmberObject.create({
          release_year: null,
          title: '',
          isrc: '',
          errors: []
        })
      ],
      performances: [
        EmberObject.create({
          performer: null,
          role_code: null,
          errors: []
        })
      ],
      ownership: EmberObject.create({
        start_date: null,
        producer_name: null,
        producer_id: null,
        share: null,
        errors: []
      })
    }
  }
})
