import Component from '@ember/component'
import { computed } from '@ember/object'
import { gt } from '@ember/object/computed'
import { observer } from '@ember/object'
/**
 * Returns errors for an attribute
 *
 * @param  {Array} errors           The errors array from the server on a model.
 * @param  {String} attributeName   The mame of the attirbute we are findign errors
 *                                  for. Can be ex. 'firstName', or
 *                                  'contactInformation.email'. In other words
 *                                  it can be an Ember property key which is nested.
 * @return {Array}                  An array with error messages related to the attribute.
 */
function errorsForAttribute(errors, attributeName) {
  // The server uses '/' to indicate errors on nested objects.
  // Here in front end land, we use Ember's '.'.
  const attrNameFromServer = attributeName.replace(/\./g, '/')

  if (!errors) return []

  let filteredErrors = errors.filterBy('attribute', attrNameFromServer)

  if (attrNameFromServer !== attributeName) {
    filteredErrors = filteredErrors.concat(errors.filterBy('attribute', attributeName))
  }

  return filteredErrors
}

/**
 * model-error component for making an area connected to errors
 * for a given attribute of a model.
 */
export default Component.extend({
  classNameBindings: ['isInputInline:input--inline'],

  isInputInline: false,

  /**
   * The model we'll read our attribute's errors from
   *
   * @type {EmberData Model}
   */
  model: null,

  /**
   * The attribute name
   *
   * @type {String}
   */
  attribute: null,

  errorLabelClass: '',
  hasErrorLabelClass: 'has-error',

  /**
   * Should we render errors for attribute?
   *
   * @type {Boolean}
   */
  showErrors: true,

  errors: computed(
    'model.errors',
    'model.errors.[]',
    'model.errors.@each.{attribute,message}',
    'attribute',
    function () {
      return errorsForAttribute(this.get('model.errors'), this.get('attribute'))
    }
  ),
  hasErrors: gt('errors.length', 0),

  setHasErrorWhenErrorsExist: observer(
    'errors.[]',
    'model.errors.@each.{attribute,message}',
    'showErrors',
    function () {
      // Sometimes this.element returns null, could this be a possible fix?
      if (!this.element) return
      const labels = this.element.querySelectorAll('label')
      const hasErrorLabelClass = this.errorLabelClass || this.hasErrorLabelClass

      if (this.hasErrors && this.showErrors) {
        labels.forEach(label => label.classList.add(hasErrorLabelClass))
      } else {
        labels.forEach(label => label.classList.remove(hasErrorLabelClass))
      }
    }
  ),

  /**
   * Removes errors on focus in. This works for elements like input
   */
  focusIn() {
    return this._clearErrors()
  },

  /**
   * Removes errors on change. This works for elements like radio buttons
   */
  change() {
    return this._clearErrors()
  },

  _clearErrors() {
    const errors = this.get('model.errors')
    if (errors) errors.removeObjects(errorsForAttribute(errors, this.get('attribute')))
    return true
  }
})
