const LEDGER_ENTRY_TYPES = {
  ADMINISTRATION_FEE_ENTRY: 'LedgerAdministrationFeeEntry',
  FOREIGN_REMUNERATION_ENTRY: 'LedgerForeignRemunerationEntry',
  INHERITANCE_ENTRY: 'LedgerInheritanceEntry',
  LEDGER_ENTRY: 'LedgerEntry',
  PAYMENT_ENTRY: 'LedgerPaymentEntry',
  PAYMENT_DEBT_TRANSFER_ENTRY: 'LedgerPaymentDebtTransferEntry',
  REMUNERATION_ENTRY: 'LedgerRemunerationEntry'
}

export { LEDGER_ENTRY_TYPES }
