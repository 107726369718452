import BaseGlobalSearchControllerController from 'min-side/abstracts/controllers/global-search-results-controller'
import { computed } from '@ember/object'
import { inject as service } from '@ember/service'

export default class GlobalSearchResultsIndexController extends BaseGlobalSearchControllerController {
  queryParams = []
  @service profile

  @computed('profile.current.meta.id')
  get profileId() {
    return this.profile.current.meta.id
  }
  @computed('parentController.include_gramo_registry')
  get doNotRenderRoleCode() {
    return this.parentController.include_gramo_registry
  }
}
