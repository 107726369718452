import { validatePresence } from 'ember-changeset-validations/validators'
import buildMessage from 'ember-changeset-validations/utils/validation-errors'
import { validate } from 'ember-validators'

function presenceIfKeyInArray(value, key, keysArray = []) {
  if (!keysArray.includes(key)) return true

  const result = validate('presence', value, { presence: true }, null, key)

  if (typeof result === 'boolean' || typeof result === 'string') {
    return result
  }

  if (result.type === 'present') {
    result.type = 'blank'
  } else if (result.type === 'blank') {
    result.type = 'present'
  }

  result.message = 'validation.empty'

  return buildMessage(key, result)
}

function validateBankAccount() {
  return (key, newValue, oldValue, changes, content) => {
    const initType = content.accountType || ''
    const currentType = changes.accountType ? changes.accountType : initType

    switch (currentType) {
      case 'norwegian':
        return presenceIfKeyInArray(newValue, key, ['norwegian_account_number', 'currency'])
      case 'iban':
        return presenceIfKeyInArray(newValue, key, ['international_account_number', 'currency'])
      case 'bic-swift':
        return presenceIfKeyInArray(newValue, key, ['account_number', 'currency', 'bic'])
      case 'american':
        return presenceIfKeyInArray(newValue, key, [
          'account_number',
          'bank_name',
          'bank_code',
          'currency'
        ])
      default:
        return true
    }
  }
}

export const defaultBankAccountObject = {
  accountType: 'blank',
  norwegian_account_number: '',
  currency: 'NOK',
  international_account_number: '',
  account_number: '',
  bank_name: '',
  bank_code: '',
  bic: ''
}

export default {
  accountType: validatePresence(true),
  norwegian_account_number: validateBankAccount(),
  currency: validateBankAccount(),
  international_account_number: validateBankAccount(),
  account_number: validateBankAccount(),
  bank_name: validateBankAccount(),
  bank_code: validateBankAccount(),
  bic: validateBankAccount()
}
