import Controller from '@ember/controller'
import { inject } from '@ember/service'
import { computed } from '@ember/object'
import { profileNameInModel } from 'min-side/helpers/profiles'

export default Controller.extend({
  profile: inject(),

  description: computed('ledger', function () {
    return this.ledger.entries[0].description
  }),

  entry: computed('ledger', function () {
    return this.ledger.entries[0].offset_entries.edges[0].node
  }),

  ledger: computed('model', function () {
    const activeProfile = this.get('profile.activeProfile.profile')
    if (!activeProfile) {
      return {}
    }
    return this.get(`model.user.person.${profileNameInModel(activeProfile)}.ledger`)
  })
})
