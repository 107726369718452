import Route from '@ember/routing/route'
import { inject } from '@ember/service'
import { queryManager } from 'ember-apollo-client'
import query from 'min-side/graphql/queries/members-recording'
import { sectorForUser } from 'min-side/helpers/user-types'

export default Route.extend({
  apollo: queryManager(),
  queryParams: inject(),
  pageTitle: inject(),
  profile: inject(),

  beforeModel(transition) {
    this._super()
    if (transition.to.params.id !== undefined) {
      this.queryParams.add(transition.to.params.id)
    }
  },

  model({ id }) {
    const variables = {
      id,
      owner_id: this.get('profile.activeProfile.id'),
      sector: sectorForUser(this.get('profile.activeProfile'))
    }

    return this.apollo.query({ query, variables, fetchPolicy: 'network-only' }).then(model => {
      this.pageTitle.setVariables({ title: model.members_recording.title })
      return model
    })
  }
})
