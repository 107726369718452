import Route from '@ember/routing/route'
import { inject } from '@ember/service'
import { w } from '@ember/string'

import ledgerEntriesQuery from 'min-side/graphql/queries/ledger/ledger-entries'
import { sectorForUser } from 'min-side/helpers/user-types'
import { dateRangeInputFromPeriod } from 'min-side/utils/date-ranges'

import { queryManager } from 'ember-apollo-client'

export const params = w('year sector period date_from date_until')
const queryParams = params.reduce(
  (acc, param) => ({
    ...acc,
    [param]: { refreshModel: true }
  }),
  {}
)

export default Route.extend({
  profile: inject(),
  apollo: queryManager(),
  queryParams,

  beforeModel(transition) {
    const { queryParams } = transition.to
    if (!queryParams.hasOwnProperty('sector') || !queryParams.sector) {
      transition.abort()
      /*
       *  In this case we only care about having the correct sector set in queryParams
       */
      return this.replaceWith(this.routeName, {
        queryParams: {
          ...queryParams,
          sector: sectorForUser(this.get('profile.activeProfile')).toLowerCase()
        }
      })
    }
  },
  async model(params) {
    const { sector, period, date_from, date_until } = params
    const dateRange = dateRangeInputFromPeriod(period, date_from, date_until)

    const variables = {
      owner_id: this.get('profile.activeProfile.id'),
      date_range: dateRange,
      sector: sector.toUpperCase(),
      include_offset_entries: false
    }
    const { ledger } = await this.apollo.query({ query: ledgerEntriesQuery, variables })
    return ledger
  },

  resetController(controller, isExiting) {
    if (isExiting) {
      // isExiting would be false if only the route's model was changing
      // if we leave this route we want to reset the query params
      controller.setQueryParamsToDefaultValues()
    }
  }
})
