import CookiesService from 'ember-cookies/services/cookies'

const { localStorage } = window

export default CookiesService.extend({
  isEnabled() {
    const setting = localStorage.getItem('cookieWarning')
    if (setting) {
      if (setting === 'refuse') {
        return false
      }
      return true
    }
    return false
  },
  isDismissed() {
    return !!localStorage.getItem('cookieWarning')
  },
  accept() {
    localStorage.setItem('cookieWarning', 'accept')
  },
  refuse() {
    localStorage.setItem('cookieWarning', 'refuse')
  }
})
