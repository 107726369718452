import Component from '@ember/component'
import { inject } from '@ember/service'
import { task, timeout } from 'ember-concurrency'
import { DEBOUNCE_MS } from 'min-side/constants/time'
import { queryManager } from 'ember-apollo-client'
import searchProducersQuery from 'min-side/graphql/queries/edit-recording/producers-suggestions'

const MAX_CONCURRENCY = 1

export default Component.extend({
  intl: inject(),
  profile: inject(),
  tagName: '',
  apollo: queryManager(),

  tFetchProducerTask: task(function* (query) {
    yield timeout(DEBOUNCE_MS)
    const { search_producers } = yield this.apollo.watchQuery({
      query: searchProducersQuery,
      variables: { query }
    })

    return search_producers.edges.map(suggestion => {
      if (suggestion.node.as_public_person_or_public_company.name) {
        return {
          name: `${suggestion.node.as_public_person_or_public_company.name}`,
          id: suggestion.node.id
        }
      }
      return {
        name: `${suggestion.node.as_public_person_or_public_company.first_name} ${suggestion.node.as_public_person_or_public_company.last_name}`,
        id: suggestion.node.id
      }
    })
  })
    .maxConcurrency(MAX_CONCURRENCY)
    .restartable(),

  actions: {
    updateSearchableField(fieldName, { name, id }) {
      this.set(`ownership.${fieldName}`, name)
      if (id) {
        this.set(`ownership.producer_id`, id)
      } else {
        this.set(`ownership.producer_id`, null)
      }
    },
    cancelSearchResult() {
      this.tFetchProducerTask.cancelAll()
    }
  }
})
