/* eslint-disable no-magic-numbers */
import moment from 'moment'

export const dateRangeInputFromDates = (dateFrom, dateUntil) => {
  const currentYear = moment().year()
  const from = dateFrom || `${currentYear - 3}-01-01`
  const to = dateUntil || `${currentYear}-12-31`
  return { from, to }
}

export const dateRangeInputFromPeriod = (period, from, to) => {
  const currentYear = moment().year()
  switch (period) {
    case 'last_twelve_months': {
      const pastTwelveMonths = moment().subtract(12, 'months')
      return { from: pastTwelveMonths.format('YYYY-MM-DD'), to: moment().format('YYYY-MM-DD') }
    }
    case 'last_four_years':
      return { from: `${currentYear - 3}-01-01`, to: `${currentYear}-12-31` }
    case 'custom':
      return dateRangeInputFromDates(from, to)
    default:
      return null
  }
}

export const dateRangeInputFromYear = year => {
  if (year && year !== 'null') {
    return { from: `${year}-01-01`, to: `${year}-12-31` }
  }
  return null
}

export const createYearsList = (year_count, offset = 0) => {
  const date = new Date()
  const startYear = date.getFullYear() - offset - year_count + 1
  return Array.from({ length: year_count }, (v, k) => k + startYear)
}
