import Component from '@ember/component'
import { computed } from '@ember/object'
import { ROLE_CODES } from 'min-side/constants/role-codes'
import { equal } from '@ember/object/computed'

export default Component.extend({
  tagName: 'li',
  classNameBindings: ['isDeleted:pending'],
  'data-test-change-request-box': true,

  selectedInstrument: computed('selectedInstruments', 'index', function () {
    return this.selectedInstruments[this.index]
  }),

  radioButtons: computed('performer.role_code', 'performer.old_role_code', function () {
    return ROLE_CODES.map(code => {
      return {
        code,
        isPending: this._isEqualOldRoleCode(code),
        disabled: code !== this.get('performer.role_code')
      }
    })
  }),

  isDeleted: equal('performer.operation', 'deletion'),
  isModified: equal('performer.operation', 'modification'),

  init(...args) {
    this._super(...args)

    this.set('roleCodes', ROLE_CODES)
  },

  _isEqualOldRoleCode(code) {
    return this.isModified && code === this.get('performer.old_role_code')
  }
})
