import Component from '@glimmer/component'
import { action } from '@ember/object'

export default class PageHeader extends Component {
  @action
  reload(event) {
    event.preventDefault()
    window.location.reload()
  }
}
