import { getProperties, getWithDefault } from '@ember/object'
import { durationAsHms } from 'echo-ember-common/helpers/duration-as-hms'
import omit from 'lodash/omit'

const RECORDING_ATTRIBUTES = [
  'id',
  'title',
  'main_artist',
  'alternative_titles',
  'duration_in_seconds',
  'isrc',
  'alternative_isrcs',
  'version',
  'record_label',
  'recorded_in',
  'recording_date',
  'produced_in',
  'release_date',
  'composers'
]

const OMITTED_ATTRIBUTES = ['id', 'duration_hms', 'composers']

export const cleanRecordingResponse = recording => {
  const recordingProperties = getProperties(recording, RECORDING_ATTRIBUTES)
  recordingProperties.alternative_isrcs = getWithDefault(
    recordingProperties,
    'alternative_isrcs',
    []
  ).map(obj => getProperties(obj || {}, ['isrc', 'description']))

  recordingProperties.alternative_titles = getWithDefault(
    recordingProperties,
    'alternative_titles',
    []
  ).map(obj => getProperties(obj || {}, ['title', 'description']))

  return {
    ...recordingProperties,
    duration_hms: durationAsHms([recordingProperties.duration_in_seconds], { showHours: false })
  }
}

export const prepareRecordingInputType = recording => {
  const clearedRecording = omit(recording, OMITTED_ATTRIBUTES)

  const recordLabel = recording.record_label && recording.record_label.name
  const recordedIn = recording.recorded_in && recording.recorded_in.id
  const producedIn = recording.produced_in && recording.produced_in.id

  if (recordLabel || recordLabel === '') clearedRecording.record_label = recordLabel
  clearedRecording.recorded_in = recordedIn
  clearedRecording.produced_in = producedIn
  return clearedRecording
}
