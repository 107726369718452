// DUPLICATION echo-common-front-end-candidate

/**
 * Extract the "problems" from a graphQL response error
 *
 * @param  {error} msg      The graphQL error
 * @return {Array}          The extracted problems
 */
export function extractProblems(error) {
  if (error.hasOwnProperty('extensions')) return error.extensions.problems
  return error.problems
}

/**
 * Temporary way of constructing an error message
 *
 * @param  {String} msg     The message will start with this string
 * @param  {Array} errors   An array of errors, on JSON API format.
 * @return {String}         The constructed error string
 */
export function tempErrorMsg(msg, errors, translatorForAttributes) {
  const messages = [msg]
  const mappedErrors = {}

  errors.forEach(error => {
    const errorPath = error.source.pointer.split('/')
    const attr = errorPath[errorPath.length - 1]

    mappedErrors[attr] = mappedErrors[attr] || []
    mappedErrors[attr].push(error.detail)
  })

  for (const attr in mappedErrors) {
    if ({}.hasOwnProperty.call(mappedErrors, attr)) {
      const errs = mappedErrors[attr]
      messages.push(`${translatorForAttributes(attr)} ${errs.join(', ')}`)
    }
  }

  return `${messages.join('. ')}.`
}

/**
 * Returns a function which translates, where it's context is bound to given context.
 *
 * Ex.
 * 	let t = i18nBoundTo(i18nService, 'foo.bar')
 * 	t('baz') // will call translate with 'foo.bar.baz'
 *
 * @param  {[I18n]}   i18n
 * @param  {[String]} context   Default context we'll give to i18n service.
 * @return {[String]}           Translated string
 */
export function i18nBoundTo(i18n, context = '') {
  return function (lookupKey) {
    return i18n.t([context, lookupKey].join('.'))
  }
}
