import Component from '@ember/component'
import { computed } from '@ember/object'
import { inject as service } from '@ember/service'
import { isEmpty } from '@ember/utils'
import { GRAPHQL_PROFILE_TYPES } from 'min-side/constants/profile'

export default Component.extend({
  'data-test-testator-row': true,
  tagName: 'tr',

  router: service(),
  profile: service(),

  shareSize: computed('testator.share_size', function () {
    const { share_size } = this.testator
    const numberTokens = share_size.split('/')
    const [numerator, denominator] = numberTokens.map(num => parseInt(num, 10))
    const percentage = numerator / denominator
    // eslint-disable-next-line no-magic-numbers
    return `${Math.round(percentage * 100)}%`
  }),

  fullName: computed('testator.inherited.{first_name,last_name}', function () {
    return `${this.get(
      'testator.inherited.first_name'
    )} ${this.get('testator.inherited.last_name')}`
  }),

  actions: {
    async impersonate() {
      const { id, first_name, last_name, profiles } = this.get('testator.inherited')
      const [profileType] = (profiles || [])
        .filter(profile => profile !== GRAPHQL_PROFILE_TYPES.INHERITANCE_PARTY)
        .map(profile => profile.toLowerCase().replace('_', '-'))
      if (isEmpty(profileType)) return
      await this.profile.impersonate(id, first_name, last_name, profileType)
      this.router.transitionTo('user.index')
    }
  }
})
