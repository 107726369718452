import Component from '@ember/component'
import { computed } from '@ember/object'
import { inject } from '@ember/service'
import ENV from 'min-side/config/environment'
import { urlFor, toQueryString } from 'min-side/utils/url-helpers'
import { SESSION_DATA_KEY } from 'min-side/mixins/update-session'

export default Component.extend({
  session: inject(),
  'data-test-annual-reports': true,

  reports: computed('model.annual_income_statements.[]', function () {
    return this.get('model.annual_income_statements').map(report => {
      const year = report.year
      const query = { year }

      // DUPLICATION url-with-auth-token
      const tokenPath = [
        `${SESSION_DATA_KEY}.authenticated`,
        ENV['ember-simple-auth-token'].tokenPropertyName
      ].join('.')

      const token = this.get(tokenPath)
      if (token !== undefined) {
        query.token = token
      }

      return {
        year,
        createdAt: report.created_at,
        downloadUrl: urlFor(`/files/annual-income-statement?${toQueryString(query)}`)
      }
    })
  })
})
