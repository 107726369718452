import Component from '@ember/component'
import { reads, equal } from '@ember/object/computed'

export default Component.extend({
  foundPerformers: reads('searchPerformersTask.last.value'),
  noPerformersFound: equal('foundPerformers.length', 0),

  searchQuery: null,

  actions: {
    search(query) {
      return this.searchPerformersTask.perform(query)
    },
    toggleSearch() {
      this.toggleSearch()
    }
  }
})
