import Controller from '@ember/controller'
import { computed } from '@ember/object'
import { reads } from '@ember/object/computed'
import {
  getGramoMembershipByProfileType,
  getFederationMembershipsByProfileType
} from 'min-side/helpers/memberships'
import { inject as service } from '@ember/service'

export default class ProfileInfoMembershipController extends Controller {
  @service profileInfo

  @reads('profileInfo.currentProfileName')
  profileType

  @reads('profileInfo.currentProfileType')
  currentProfileType

  @computed('model.user.person')
  get federationMemberships() {
    return getFederationMembershipsByProfileType(
      this.model,
      this.currentProfileType || this.profileType
    )
  }

  @computed('model.user.person')
  get membership() {
    return getGramoMembershipByProfileType(this.model, this.currentProfileType || this.profileType)
  }
}
