import Service from '@ember/service'
import { tracked } from '@glimmer/tracking'
import { computed } from '@ember/object'

export default class SpinnerScreen extends Service {
  // Do not set visible directly.
  // Use functions to ask service to change it's state.
  @tracked _visible = false

  @computed('_visible')
  get isVisible() {
    return this._visible
  }

  /**
   * Shows the spinner while given function is called.
   *
   * The spinner is not hidden if the spinner is already shown when called. In
   * other words, if you for some reason nest calls to this showWhile spinner should
   * stay until first call completes.
   *
   * @param {function} fn     The function to call while spinner is shown
   */
  async showWhile(fn) {
    const notVisibleToBeginWith = !this.isVisible

    if (notVisibleToBeginWith) this.show()

    try {
      await fn()
    } finally {
      if (notVisibleToBeginWith) this.hide()
    }
  }

  show() {
    this._visible = true
  }

  hide() {
    this._visible = false
  }
}
