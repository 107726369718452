import Route from '@ember/routing/route'
import query from 'min-side/graphql/queries/testators-for-inheritor'
import { queryManager } from 'ember-apollo-client'

export default Route.extend({
  apollo: queryManager(),
  queryParams: {
    q: { refreshModel: true },
    after: { refreshModel: true }
  },

  model({ q, after }) {
    const variables = {
      after,
      query: q,
      first: 10,
      only_active: true
    }
    return this.apollo.watchQuery({ query, variables })
  }
})
