import { computed } from '@ember/object'
import Component from '@ember/component'
import { gramoId, isGramo } from 'echo-ember-common/utils/societies'

const currentYear = () => new Date().getFullYear()

/**
 * A membership component, responsible for holding information about a single membership
 * The component will not update the model passed directly,
 * but will trigger the onChange#1 function when the content updates
 *
 * @param membership: The object containing the existing membership information,
 *        will not be modified directly, but should be updated when `onChange#` is called
 *
 *
 * @param societies: A list of societies that can be selected.
 *  Each society in the list should be on the form `{id, name, region, section}`
 *
 * DUPLICATION echo-common-front-end-candidate
 * Might be harder because variable names are different (snake_case/camelCase)
 */
export default Component.extend({
  tagName: 'form',
  classNames: 'form',
  /**
   * A list of the fields this component exposes thought `onChange#`
   */
  fields: [
    'id',
    'membership_number',
    'society',
    'starts_on',
    'ends_on',
    'territory_mandate',
    'isNew'
  ],
  hasTerritoryMandate: true,
  gramoId,

  /**
   * Set this to `true` to disable editing in the society field, wanted for performers
   */
  disableEditingSociety: false,
  showTerritoryMandate: computed(function () {
    return this.hasTerritoryMandate && isGramo(this.get('membership.society_id'))
  }),
  isSocietyEditingDisabled: computed(
    'disabled',
    'disableEditingSociety',
    'membership.isNew',
    function () {
      return this.disabled || this.disableEditingSociety || !this.get('membership.isNew')
    }
  ),

  availableTerritoryMandates: computed('societies', 'society', function () {
    const currentSociety = this.societies.findBy('id', this.get('membership.society_id'))
    return this.societies.without(currentSociety)
  }),

  societyInfoFieldsDisabled: computed(
    'disabled',
    'disableEditingSociety',
    'membership.society_id',
    function () {
      return (
        this.disabled || (this.disableEditingSociety && !isGramo(this.get('membership.society_id')))
      )
    }
  ),

  resetFields() {
    const fieldsToKeep = ['starts_on', 'ends_on', 'id']
    const fieldsToReset = fieldsToKeep.reduce((toRemove, keep) => {
      return toRemove.without(keep)
    }, this.fields)
    fieldsToReset.forEach(field => {
      this.set(field, null)
    })
  },
  actions: {
    societyUpdated(society) {
      this.set('membership.society_id', society)
    },

    addEndsOnDate() {
      const endson = `${currentYear()}-12-31`
      this.set('membership.ends_on', endson)
    }
  }
})
