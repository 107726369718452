import Mixin from '@ember/object/mixin'

/**
 * This mixin was created to solve the problem with the combination of ember's loading substate and
 * ember-apollo-client's .watchQuery method which causes the page to remain blocked in the loading template.
 * More details about this issue can be found at this link https://github.com/ember-graphql/ember-apollo-client/issues/67,
 * also an brief explanation of the solution can be found here
 * https://github.com/ember-graphql/ember-apollo-client/issues/67#issuecomment-375442359
 */
export default Mixin.create({
  resetController(_controller, isExiting, transition) {
    if (isExiting && transition && transition.targetName !== 'error') {
      this.apollo.unsubscribeAll()
    }
  }
})
