import Mixin from '@ember/object/mixin'
import { equal } from '@ember/object/computed'
import Changeset from 'ember-changeset'
import { computed } from '@ember/object'
import { inject } from '@ember/service'
import EmberObject from '@ember/object'
import ReleaseInformationValidations from 'min-side/validations/new-release/release-information'
import lookupValidator from 'ember-changeset-validations'
import { isEmpty } from '@ember/utils'
import { NEW_RELEASE_FORM_STEPS } from 'min-side/constants/new-release-steps'
import {
  EMPTY_ALTERNATIVE_CATALOGUE_NUMBER_OBJECT,
  EMPTY_ALTERNATIVE_CATALOGUE_NUMBER_ERROR_OBJECT
} from 'min-side/constants/empty-alternative-catalogue-defaults'

const { RELEASE_INFO_STEP } = NEW_RELEASE_FORM_STEPS

export default Mixin.create({
  tagName: 'section',
  intl: inject(),
  router: inject(),
  eventBus: inject(),

  disabled: false,
  showChildrenErrors: false,

  init(...args) {
    this._super(...args)
    this.changeset = new Changeset(
      this.releaseInfoData,
      lookupValidator(ReleaseInformationValidations),
      ReleaseInformationValidations
    )

    this.setProperties({
      alternativeCatalogueNumberErrors: [],
      alternativeCatalogNumbersChangesets: [],
      errors: { errors: [] },
      suggestedRecordLabel: this.get('releaseInfoData.recording_label')
    })
  },

  isCurrentStep: equal('currentStep', RELEASE_INFO_STEP),

  formats: computed(() => [
    { value: '', translationKey: 'molecules.format-relations.select-format' },
    { value: 'Album', translationKey: 'album.form.release_info.formats.album' },
    { value: 'EP', translationKey: 'album.form.release_info.formats.ep' },
    { value: 'Compilation', translationKey: 'album.form.release_info.formats.compilation' },
    { value: 'Single', translationKey: 'album.form.release_info.formats.single' }
  ]),

  genresCollection: computed('genres', function () {
    return [{ id: 0, english_name: 'Select genre', norwegian_name: 'Velg sjanger' }, ...this.genres]
  }),

  genreLabelPath: computed('intl.locale', function () {
    return this.get('intl.locale') === 'en' ? 'english_name' : 'norwegian_name'
  }),

  countriesCollection: computed('countries', 'intl.locale', function () {
    return [
      {
        id: null,
        name: this.intl.t('molecules.country-relations.select-country').toString()
      },
      ...this.countries
    ]
  }),

  hasAlternativeCatalogueNumberError: computed(
    'alternativeCatalogueNumberErrors.@each.{isError}',
    function () {
      return this.alternativeCatalogueNumberErrors.some(error => error.isError === true)
    }
  ),

  disableSaveOnInit: computed(
    'changeset.title',
    'changeset.main_artist',
    'changeset.release_date',
    'changeset.recorded_in_country.id',
    'hasAlternativeCatalogueNumberError',
    {
      get() {
        if (this.__disableOnSaveInit !== undefined) {
          return this.__disableOnSaveInit
        }
        return (
          isEmpty(this.get('changeset.title')) ||
          isEmpty(this.get('changeset.main_artist')) ||
          isEmpty(this.get('changeset.release_date')) ||
          isEmpty(this.get('changeset.recorded_in_country.id')) ||
          this.hasAlternativeCatalogueNumberError
        )
      },
      set(key, value) {
        this.__disableOnSaveInit = value
        return value
      }
    }
  ),

  selectedLabel: computed(
    'changeset.recording_label',
    'changeset._changes.recording_label',
    function () {
      if (typeof this.changeset.get('recording_label') === 'object') {
        return this.changeset.get('recording_label')
      }
      if (typeof this.changeset.get('recording_label') === 'string') {
        return {
          name: this.changeset.get('recording_label')
        }
      }
      return {}
    }
  ),

  actions: {
    async submit(changeset) {
      await this.submitAction(changeset)
      if (this.disabled) this.stepForwardAction()
    },

    async save(changeset) {
      return await this.submitAction(changeset)
    },

    rollback(changeset) {
      this.rollbackAction(changeset)
    },

    edit() {
      this.editAction()
    },

    updateRecordedInCountry(country) {
      this.changeset.set('recorded_in_country', country)
      this.set('selectedCountry', country)
    },

    updateGenre(genre) {
      this.changeset.set('genre', genre)
      this.set('selectedGenre', genre)
    },

    updateLabel(recordingLabel) {
      this.set('changeset.recording_label', {
        ...recordingLabel,
        id: recordingLabel.id,
        name: recordingLabel.name
      })
    },

    addAlternativeCatalogueNumber() {
      this.changeset
        .get('alternative_catalog_numbers')
        .pushObject(EmberObject.create(EMPTY_ALTERNATIVE_CATALOGUE_NUMBER_OBJECT))
      this.alternativeCatalogueNumberErrors.pushObject(
        EmberObject.create(EMPTY_ALTERNATIVE_CATALOGUE_NUMBER_ERROR_OBJECT)
      )
    },

    removeAlternativeCatalogueNumber(object, index) {
      this.changeset.get('alternative_catalog_numbers').removeObject(object)
      this.alternativeCatalogueNumberErrors.removeAt(index)
      this.alternativeCatalogNumbersChangesets.removeAt(index)
    },

    addAlternativeCatalogueNumberError(index) {
      const alternativeCatalogueNumberError = this.alternativeCatalogueNumberErrors.objectAt(index)
      alternativeCatalogueNumberError.set('isError', true)
    },

    addAlternativeCatalogueNumberChangeset(changeset) {
      this.alternativeCatalogNumbersChangesets.pushObject(changeset)
    },

    clearAlternativeCatalogueNumberError(index) {
      const alternativeCatalogueNumberError = this.alternativeCatalogueNumberErrors.objectAt(index)
      alternativeCatalogueNumberError.set('isError', false)
    },

    updateSearchableField(fieldName, { name }) {
      this.changeset.set(fieldName, name)
    },

    cancelSearchResult() {
      this.fetchArtistsCollectionTask.cancelAll()
    }
  },

  async submitAction(changeset) {
    await this._validate(changeset)
    if (changeset.get('isValid') && !this.hasAlternativeCatalogueNumberError) {
      await changeset.save()
      this.alternativeCatalogNumbersChangesets.forEach(changeset => changeset.save())

      const saveReleaseInfoAction = this.saveReleaseInfoAction

      if (saveReleaseInfoAction) {
        try {
          await saveReleaseInfoAction(changeset._content)

          this.set('disabled', true)
          this.updateFormEditStatusAction(RELEASE_INFO_STEP, false)
        } catch (error) {
          this.set('error', error)
        }
      }
    } else {
      const errors = this._mapValidationErrors(changeset.get('errors'))
      this.setProperties({
        errors,
        showChildrenErrors: true
      })
    }
  },

  async rollbackAction(changeset) {
    changeset.rollback()
    this._restorePreviousCatalogNumbersState()
    this.alternativeCatalogNumbersChangesets.forEach(changeset => changeset.rollback())
    this.setProperties({
      showChildrenErrors: false,
      selectedCountry: changeset.get('recorded_in_country'),
      selectedGenre: changeset.get('genre')
    })
    this.updateFormEditStatusAction(RELEASE_INFO_STEP, false)
    if (this.isCurrentStep) {
      this.router.transitionTo('user.global-search-results.releases')
    } else {
      this.set('disabled', true)
    }
  },

  async editAction() {
    this._enableInputs()
    this._resetAlternativeCatalogueNumberErrors()
    this._savePreviousCatalogNumbersState()
    this.updateFormEditStatusAction(RELEASE_INFO_STEP, true)
  },

  _validate(changeset) {
    changeset.validate()
  },

  _enableInputs() {
    this.setProperties({
      disabled: false,
      showChildrenErrors: false
    })
  },

  _mapValidationErrors(changesetErrors) {
    const errors = []
    changesetErrors.forEach(error => {
      errors.push(
        EmberObject.create({
          attribute: error.key || error.attribute,
          message: this.intl.t('validation.empty')
        })
      )
    })
    return { errors }
  },

  _savePreviousCatalogNumbersState() {
    this.setProperties({
      previousAlternativeCatalogNumbers: [...this.changeset.get('alternative_catalog_numbers')],
      previousAlternativeCatalogNumbersChangesets: [...this.alternativeCatalogNumbersChangesets],
      previousAlternativeCatalogueNumberErrors: [...this.alternativeCatalogueNumberErrors]
    })
  },

  _restorePreviousCatalogNumbersState() {
    if (!this.previousAlternativeCatalogNumbersChangesets) return

    this.changeset.set('alternative_catalog_numbers', this.previousAlternativeCatalogNumbers)
    this.setProperties({
      alternativeCatalogNumbersChangesets: [...this.previousAlternativeCatalogNumbersChangesets],
      alternativeCatalogueNumberErrors: [...this.previousAlternativeCatalogueNumberErrors]
    })
  },

  _resetAlternativeCatalogueNumberErrors() {
    this.alternativeCatalogueNumberErrors.forEach(error => error.set('isError', false))
  }
})
