import Component from '@ember/component'
import { computed } from '@ember/object'

export default Component.extend({
  'data-test-welcome-message': true,
  personName: null,
  welcomeName: computed('personName', function () {
    if (this.personName === this.personName.toUpperCase()) {
      // upper case the first chracter in the name or any character after a space
      return this.personName.toLowerCase().replace(/(^.|([\s-])[^\s-])/g, function (l) {
        return l.toUpperCase()
      })
    }
    return this.personName
  })
})
