import Component from '@ember/component'
import { computed } from '@ember/object'

export default Component.extend({
  tagName: 'table',
  classNames: ['table table--fluid table--list table--five text--normal'],
  'data-test-search-recordings-list': true,

  translationKeys: computed(() => [
    'search_recordings_box.title',
    'search_recordings_box.main_artist_name',
    'search_recordings_box.duration',
    'search_recordings_box.release_date',
    'search_recordings_box.isrc'
  ])
})
