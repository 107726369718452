define("min-side/services/service-worker-update-notify", ["exports", "ember-service-worker-update-notify/services/service-worker-update-notify"], function (_exports, _serviceWorkerUpdateNotify) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _serviceWorkerUpdateNotify.default;
    }
  });
});
