import Route from '@ember/routing/route'
import { inject as service } from '@ember/service'
import { queryManager } from 'ember-apollo-client'
import { getQueryByProfileType } from 'min-side/graphql/helpers/profile-info/queries'

import producerCompanyQuery from 'min-side/graphql/queries/profile/producer-company'

export default class ProfilelInfoProfileRoute extends Route {
  @queryManager apollo
  @service profileInfo

  model() {
    if (this.profileInfo.currentProfileName === 'contact') {
      return this.modelForContact()
    }

    const impersonatedUserId = this.profileInfo.maskId
    const variables = impersonatedUserId ? { person_id: impersonatedUserId } : null

    const query = getQueryByProfileType(this.profileInfo.currentProfileName)

    return this.apollo
      .watchQuery({ query, variables, fetchPolicy: 'network-only' })
      .then(responseModel => {
        return {
          ...responseModel,
          errors: []
        }
      })
  }

  modelForContact() {
    const id = this.profileInfo.activeProfile.id
    const variables = { id }

    return this.apollo
      .watchQuery({ query: producerCompanyQuery, variables, fetchPolicy: 'network-only' })
      .then(pjoModel => {
        return {
          ...pjoModel,
          errors: []
        }
      })
  }

  setupController(controller, model) {
    super.setupController(controller, model)

    controller.setupDefaultProfile(model)

    if (this.profileInfo.currentProfileName === 'performer') {
      controller.setupPerformerInfoChangeset(model)
    }
  }
}
