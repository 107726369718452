import Component from '@ember/component'
import { computed } from '@ember/object'
import { readOnly } from '@ember/object/computed'
import { LEDGER_ENTRY_TYPES } from 'min-side/constants/ledger-entries'

const {
  PAYMENT_ENTRY,
  PAYMENT_DEBT_TRANSFER_ENTRY,
  INHERITANCE_ENTRY,
  LEDGER_ENTRY,
  REMUNERATION_ENTRY,
  FOREIGN_REMUNERATION_ENTRY
} = LEDGER_ENTRY_TYPES

export default Component.extend({
  tagName: '',
  isDiffEntry: computed('entry.snapshot.type', function () {
    const entryType = this.get('entry.snapshot.type')
    return entryType === 'CORRECTION' || entryType === 'ADDITIONAL'
  }),
  isInflictedByLegacySystem: readOnly('entry.snapshot.inflicted_by_legacy_system'),
  link: computed('entry.typename', 'isDiffEntry', function () {
    switch (this.get('entry.typename')) {
      case LEDGER_ENTRY:
        return 'user.ledger.entry'
      case PAYMENT_ENTRY:
      case PAYMENT_DEBT_TRANSFER_ENTRY:
        return 'user.ledger.payment'
      case REMUNERATION_ENTRY:
        if (this.isInflictedByLegacySystem) {
          return null
        }

        return this.isDiffEntry
          ? 'user.ledger.remuneration.by-recording-diff'
          : 'user.ledger.remuneration.by-recording'
      case INHERITANCE_ENTRY:
        return 'user.ledger.inheritance'
      case FOREIGN_REMUNERATION_ENTRY:
        return this.get('entry.snapshot_id') ? 'user.ledger.foreign-remuneration' : null
      default:
        return null
    }
  })
})
