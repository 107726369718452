import Component from '@ember/component'
import ButtonAtomStyling from './button-atom-styling'

export default Component.extend(ButtonAtomStyling, {
  tagName: 'button',
  attributeBindings: ['disabled', 'type'],
  classNameBindings: ['solo:btn--solo'],
  action: () => false,
  /**
   * Disabled - darkened with an opaque overlay of black
   */
  disabled: false,

  click(e) {
    e.preventDefault()
    this.action(e)
  }
})
