import Service, { inject as service } from '@ember/service'
import ENV from 'min-side/config/environment'
import fetch, { Headers } from 'fetch'
import { SESSION_DATA_KEY } from 'min-side/mixins/update-session'

/**
 * Service to make authenticated/unauthenticated ajax requests using fetch
 *
 * This service can be used when you want to make requests otuside of
 * Ember Data, as all Ember Data requests are authenticated
 */
export default class AjaxRequests extends Service {
  @service session

  /**
   * Makes an authenticated/unauthenticated GET request
   *
   * @param  {String}   url     The URL
   * @param  {Object}   params  GET params, if any
   * @param  {Headers}   headers Any additional headers
   * @param auth {Boolean} Decides if it should include the Bearer header or not
   * @return {Promise}          A promise
   */
  getRequest(url, params = {}, headers = new Headers(), auth = false) {
    return this._makeRequest({
      method: 'GET',
      url: this._addSearchParamsToURLAndReturnURLInstance(url, params),
      headers,
      auth
    })
  }

  /**
   * Makes an authenticated/unauthenticated POST request
   *
   * @param  {String}   url         The URL
   * @param  {Object}   data        The data
   * @param  {Headers}   headers Any additional headers
   * @param auth {Boolean} Decides if it should include the Bearer header or not
   * @return {Promise}              A promise
   */
  postRequest(url, data, headers = new Headers(), auth = false) {
    return this._makeRequest({
      method: 'POST',
      url,
      data,
      headers: this._mergePassedHeadersWithDefaultAndReturnThem(headers, data),
      auth
    })
  }

  /**
   * Makes an authenticated/unauthenticated PUT request
   *
   * @param  {String}   url         The URL
   * @param  {Object}   data        The data
   * @param  {Headers}   headers Any additional headers
   * @param auth {Boolean} Decides if it should include the Bearer header or not
   * @return {Promise}              A promise
   */
  putRequest(url, data, headers = new Headers(), auth = false) {
    return this._makeRequest({
      method: 'PUT',
      url,
      data,
      headers: this._mergePassedHeadersWithDefaultAndReturnThem(headers, data),
      auth
    })
  }

  /**
   * Makes an authenticated/unauthenticated DELETE request
   *
   * @param  {String}   url         The URL
   * @param  {Object}   data        The data
   * @param  {Headers}   headers Any additional headers
   * @param auth {Boolean} Decides if it should include the Bearer header or not
   * @return {Promise}              A promise
   */
  deleteRequest(url, data, headers = new Headers(), auth = false) {
    return this._makeRequest({
      method: 'DELETE',
      url,
      data,
      headers,
      auth
    })
  }

  /**
   * Makes an authenticated/unauthenticated PATCH request
   *
   * @param  {String}   url         The URL
   * @param  {Object}   data        The data
   * @param  {Headers}   headers Any additional headers
   * @param auth {Boolean} Decides if it should include the Bearer header or not
   * @return {Promise}              A promise
   */
  patchRequest(url, data, headers = new Headers(), auth = false) {
    return this._makeRequest({
      method: 'PATCH',
      url,
      data,
      headers,
      auth
    })
  }

  /**
   * Makes a http request based on the provided params.
   * @param method
   * @param url
   * @param data
   * @param headers {Headers}
   * @param auth
   * @private
   */
  _makeRequest({ method, url, data = null, headers, auth }) {
    if (!headers instanceof Headers) {
      throw new Error(
        'The header parameter is not an instance of Headers, please see https://developer.mozilla.org/en-US/docs/Web/API/Headers for mode details'
      )
    }
    const fetchOptions = {
      method,
      headers: this._mergePassedHeadersWithAuthAndReturnThem(auth, headers)
    }
    if (data) {
      fetchOptions.body = data instanceof FormData ? data : JSON.stringify(data)
    }
    return fetch(url, fetchOptions)
  }

  /**
   * Merges the given headers with default content type header.
   *
   * In case of passed data being FormData we'll not add a custom
   * header, and let the browser determine a proper content type, as
   * if may be application/x-www-form-urlencoded or
   * multipart/form-data with a boundary
   *
   * @param headers
   * @returns {*}
   * @private
   */
  _mergePassedHeadersWithDefaultAndReturnThem(headers, data) {
    const defaultHeadersList = []

    if (!(headers.has('Content-Type') || data instanceof FormData)) {
      defaultHeadersList.push(['Content-Type', 'application/json'])
    }

    return new Headers([...defaultHeadersList, ...headers.entries()])
  }

  _mergePassedHeadersWithAuthAndReturnThem(auth, headers) {
    const authHeader = auth ? [['Authorization', `Bearer ${this._token()}`]] : []
    return new Headers([...authHeader, ...headers.entries()])
  }

  _token() {
    const tokenPath = [
      `${SESSION_DATA_KEY}.authenticated`,
      ENV['ember-simple-auth-token'].tokenPropertyName
    ].join('.')

    return this.get(tokenPath)
  }

  /**
   * Instantiate a URL object and use searchParams to append params and return it
   * @param url
   * @param params
   * @returns {URL}
   * @private
   */
  _addSearchParamsToURLAndReturnURLInstance(url, params) {
    const finalUrl = new URL(url)
    Object.keys(params).forEach(key => finalUrl.searchParams.append(key, params[key]))
    return finalUrl
  }
}
