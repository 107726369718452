import Route from '@ember/routing/route'
import { inject } from '@ember/service'
import HandlesHttpStatusErrorMixin from 'echo-ember-common/mixins/handles-http-status-error'
import AuthenticatedRouteMixin from 'ember-simple-auth/mixins/authenticated-route-mixin'
import LocaleMixin from 'min-side/mixins/locale'
import UpdateSessionMixin from 'min-side/mixins/update-session'

export default Route.extend(
  LocaleMixin,
  UpdateSessionMixin,
  HandlesHttpStatusErrorMixin,
  AuthenticatedRouteMixin,
  {
    intl: inject(),
    profile: inject(),
    logout: inject(),
    session: inject(),

    async model() {
      await this.profile.loadActiveProfile()
      if (!this.profile.current) {
        const defaultProfile = await this.profile.getDefaultProfile()
        if (defaultProfile) {
          await this.profile.switchTo(defaultProfile)
        } else {
          return null
        }
      }

      return { person: this.profile.current.person }
    },

    redirect(model) {
      if (!model || !model.person) {
        this.transitionTo('profile')
      }
    },

    async profileChanged() {
      this.transitionTo('/')
    },

    actions: {
      async logout(event) {
        event.preventDefault()
        await this.logout.logout()
      },

      didTransition() {
        this.controller.set('navExpanded', false)
      },

      async profileChanged() {
        this.profileChanged()
      },

      async switchProfile(profile) {
        await this.profile.switchTo(profile)
        return this.profileChanged()
      },

      switchLanguage() {
        this.toggleLanguage(true)
      },

      endImpersonation() {
        this.profile.endImpersonation().then(() => {
          this.profileChanged()
        })
      }
    }
  }
)
