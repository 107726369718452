import Component from '@ember/component'
import { computed, set, get, defineProperty } from '@ember/object'
import { assert } from '@ember/debug'

export default Component.extend({
  tagName: 'div',
  classNames: ['citizenships'],
  supportsDataTestProperties: true,

  isEdit: null,
  disabled: null,
  model: null,
  errors: null,
  propertyName: null,
  label: null,
  addLabel: null,
  countries: [],
  errorKey: '',

  onAdd: () => null,
  onRemove: () => null,

  init(...args) {
    this._super(...args)

    assert('You must provide "propertyName" to crud component', this.propertyName)
    assert('You must provide "model" to crud component', this.model)

    defineProperty(
      this,
      'collection',
      computed(`model.${this.propertyName}`, 'model.${_content,_changes}', {
        get() {
          return get(this, `model.${this.propertyName}`)
        }
      })
    )
  },

  actions: {
    add() {
      return this.onAdd()
    },

    remove(object, index) {
      return this.onRemove(index)
    },

    select(object, country) {
      set(object, 'country', country)
    },
    onChangeDates(object, property, value) {
      set(object, property, value)
    }
  },

  lastIndex: computed('collection.length', function () {
    return this.get('collection.length') - 1
  })
})
