import Component from '@glimmer/component'
import { tracked } from '@glimmer/tracking'
import { action } from '@ember/object'
import { reads } from '@ember/object/computed'

const LIST_OF_ROUTES_THAT_SHOULD_MARK_DISCOGRAPHY_ACTIVE = [
  'user.edit-recording',
  'user.edit-release',
  'user.discography.albums',
  'user.discography.recordings',
  'user.discography.groups',
  'user.new-release',
  'user.global-search.recordings',
  'user.global-search.releases',
  'user.recording'
]

class PageHeaderMenu extends Component {
  @reads('args.visible') visible

  @tracked isProfileMenuOpen = true
  @tracked isProfileSwitcherOpen = false

  get discographyCurrentWhen() {
    return LIST_OF_ROUTES_THAT_SHOULD_MARK_DISCOGRAPHY_ACTIVE.join(' ')
  }

  get showProfileMenu() {
    if (
      this.args.profileObjectsFromLegacyService &&
      this.args.profileObjectsFromLegacyService.length > 1
    ) {
      return true
    }
    return false
  }

  @action
  toggleProfileSwitcher(event) {
    if (event.target.classList.contains('disabled')) return

    event.stopPropagation()
    event.preventDefault()

    this.isProfileMenuOpen = !this.isProfileMenuOpen
    this.isProfileSwitcherOpen = !this.isProfileSwitcherOpen
  }

  @action
  closeMenu(event) {
    if (event && event.target.classList.contains('disabled')) return
    if (!this.visible) return

    // I considered capture all clicks on component and check if
    // it's target was an a-tag, but not all clicks on a should
    // close the menu. So I thought it was better to be explicit
    // and call closeMenu from template.
    this.isProfileMenuOpen = true
    this.isProfileSwitcherOpen = false
    this.args.toggleNav()
  }

  @action
  setup() {
    document.addEventListener('keydown', this._closeOnEsc)
  }

  @action
  teardown() {
    document.removeEventListener('keydown', this._closeOnEsc)
  }

  _closeOnEsc = event => {
    if (this.visible && event.key === 'Escape') {
      this.closeMenu()
    }
  }
}

export default PageHeaderMenu
