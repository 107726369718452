import Component from '@ember/component'
import { queryManager } from 'ember-apollo-client'
import { inject } from '@ember/service'
import { equal } from '@ember/object/computed'
import { isEmpty, isPresent } from '@ember/utils'
import { task, timeout } from 'ember-concurrency'
import { DEBOUNCE_MS } from 'min-side/constants/time'
import FindOrganizationQuery from 'min-side/graphql/queries/find-brreg-organization'
import INCOME_TYPE from 'min-side/constants/income-type'

export default Component.extend({
  'data-test-income-type': true,
  apollo: queryManager(),
  intl: inject(),

  commercialTypeSelected: equal('taxInfo.income_type', INCOME_TYPE.COMMERCIAL),
  classNames: ['form__fieldset', 'form__fieldset--group'],
  incomeTypes: [
    { name: 'capital', value: INCOME_TYPE.CAPITAL },
    { name: 'commercial', value: INCOME_TYPE.COMMERCIAL }
  ],
  tagName: 'fieldset',

  fetchOrganizationFromBrregTask: task(function* () {
    yield timeout(DEBOUNCE_MS)
    const number = this.get('taxInfo.income_organization_number')
    const errors = []
    if (this._invalidOrgnr(number)) {
      this.set('taxInfo.income_organization_name', null)
      errors.push({
        attribute: 'income_organization_number',
        message: this.intl.t('personal-information.errors.invalid-number')
      })
    } else {
      const { organization } = yield this.apollo.watchQuery({
        query: FindOrganizationQuery,
        variables: { number: parseInt(number, 10), form: 'ENK' }
      })

      if (isPresent(organization)) {
        this.set('taxInfo.income_organization_name', organization.name)
      } else {
        this.set('taxInfo.income_organization_name', null)
        errors.push({
          attribute: 'income_organization_number',
          message: this.intl.t('personal-information.errors.not-found')
        })
      }
    }
    if (errors.length) {
      this.set('model.errors', errors)
      return { validationErrors: errors }
    }
    return this.get('taxInfo.income_organization_name')
  })
    .maxConcurrency(1)
    .restartable(),

  _invalidOrgnr: number => {
    return isEmpty(number) || isNaN(parseInt(number, 10))
  }
})
