define("min-side/utils/societies", ["exports", "echo-ember-common/graphql/societies"], function (_exports, _societies) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _societies.default;
    }
  });
  Object.defineProperty(_exports, "gramoId", {
    enumerable: true,
    get: function get() {
      return _societies.gramoId;
    }
  });
  Object.defineProperty(_exports, "isGramo", {
    enumerable: true,
    get: function get() {
      return _societies.isGramo;
    }
  });
});
