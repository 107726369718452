import Component from '@ember/component'
import ReleaseFormLogic from 'min-side/mixins/release-form/logic'
import ReleaseFormStepper from 'min-side/mixins/release-form/stepper'
import ReleaseFormHeader from 'min-side/mixins/release-form/header'
import { inject } from '@ember/service'

export default Component.extend(ReleaseFormLogic, ReleaseFormStepper, ReleaseFormHeader, {
  'data-test-new-release': true,
  intl: inject()
})
