import Component from '@ember/component'
import { inject } from '@ember/service'
import { computed } from '@ember/object'

export default Component.extend({
  tagName: '',
  profile: inject(),

  playbacksAvailable: computed(function () {
    return !this.profile.activeProfile.impersonating
  })
})
