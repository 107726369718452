import Component from '@ember/component'
import { map } from '@ember/object/computed'
import { mapProducer } from 'min-side/extractors/producer'

export default Component.extend({
  'data-test-edit-recording-ownership-change-request': true,

  shares: map('changeRequest.shares', share => ({
    ...mapProducer(share.producer),
    value: share.size
  }))
})
