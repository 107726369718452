import Component from '@glimmer/component'
import { computed } from '@ember/object'

const profileNameToColorClass = {
  performer: 'blue',
  personalProducer: 'purple',
  contactProducerCompany: 'green',
  contactAgency: 'red',
  inheritanceParty: 'gray'
}

/**
 * Example of usage:
 *
 * <ColoredUl @profileName={{@profileName}} local-class="profile-menu {{if this.isProfileMenuOpen 'open'}}">
 *   <li>
 *     <LinkTo {{on "click" this.closeMenu}} @route="user.index" @disabled={{@userMenuDisabled}}>
 *       <Components::Icon @type="dashboard" />
 *       {{t 'main-menu.dashboard'}}
 *     </LinkTo>
 *   </li>
 * </ColoredUl>
 */
export default class ColoredUl extends Component {
  /**
   * colorClass is calculated from either a profile name you give it,
   * or a color class directly if you know which color you want.
   *
   * If you provide the profile name it should be the *full* name, like contactAgency.
   *
   */
  @computed('args.{profileName,color}')
  get colorClass() {
    const { color, profileName } = this.args
    const c = color || profileNameToColorClass[profileName]

    if (!c) console.warn('No color set for ColoredUl component', { color, profileName })

    return c
  }
}
