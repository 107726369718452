import Service from '@ember/service'
import { inject } from '@ember/service'
import { task } from 'ember-concurrency'

export default Service.extend({
  invitationRequests: inject(),

  verifyInvitationTokenTask: task(function* (invitationToken) {
    this.invitationRequests.verify(invitationToken)
  }).drop(),

  submitTask: task(function* (token, email, password) {
    const data = { token, email, password }
    this.invitationRequests.submit(data)
  }).drop()
})
