import Component from '@ember/component'
import { computed, getWithDefault, get } from '@ember/object'
import { scheduleOnce, next } from '@ember/runloop'

// FIX ME - THIS COMPONENT NEEDS TOTAL REFACTOR
// APPARENTLY IT DEPENDS ON LEAKING STATE (LEVEL PROPERTY)
// PROCEED WITH EXTREME CAUTION!!!

export default Component.extend({
  tagName: 'tr',
  classNameBindings: ['isOpen:dd-open', 'isClosed:dd-close'],
  attributeBindings: ['level:data-level', 'hidden'],
  isOpen: null,
  isClosed: null,
  level: 1,

  hidden: computed('level', 'isOrphan', function () {
    if (this.level === 1) {
      return null
    }
    return this.isOrphan ? null : true
  }),

  didRender(...args) {
    this._super(...args)
    scheduleOnce('afterRender', this, 'setHasChild')
    scheduleOnce('afterRender', this, 'setIsOrphan')
  },

  setHasChild() {
    const hasChild =
      parseInt(getWithDefault(this, 'element.nextSibling.dataset.level', 0), 10) > this.level

    this.set('hasChild', hasChild)
    if (hasChild || this.loadData) {
      this.set('isClosed', true)
    }
  },

  setIsOrphan() {
    const parentLevel = this.level - 1
    const hasParent =
      parseInt(get(this, 'element.previousSibling.dataset.level'), 10) === parentLevel

    this.set('isOrphan', !hasParent)
  },

  click(event) {
    if (!this.isOpen && this.loadData) {
      if (this.isLoadingData) {
        return
      }

      this.set('isLoadingData', true)

      return this.loadData()
        .then(result => {
          if (result !== false) {
            this.set('hasChild', true)
            return next(this, this._click, event)
          }
        })
        .finally(() => this.set('isLoadingData', false))
    }

    return this._click(event)
  },

  _click(event) {
    const tagName = get(event, 'target.tagName')
    const targetIsInput = ['INPUT', 'SELECT', 'BUTTON', 'A'].includes(tagName)

    const isChildless = !this.hasChild
    if (isChildless || targetIsInput) {
      // do not toggle if user focused on an input element and skip elements without children
      return
    }

    this.toggleProperty('isOpen')

    const nextSiblings = this.getNextAllSiblings(this.element, 'tr')

    let i = 0
    while (i < nextSiblings.length) {
      const $el = nextSiblings[i]
      const elLevel = parseInt(get($el, 'dataset.level'), 10)
      const componentLevel = this.level

      if (elLevel <= componentLevel) {
        // break the loop if level is same or higher
        break
      }

      if (elLevel > componentLevel) {
        const isElVisible = $el.offsetWidth > 0 && $el.offsetHeight > 0
        const isClosing = isElVisible

        if (isClosing || elLevel === componentLevel + 1) {
          // close all higher level siblings and only show first level higher siblings
          $el.toggleAttribute('hidden')
        }
      }

      i++
    }

    return true
  },

  getNextAllSiblings(element, tag) {
    let el = element
    const siblings = []
    while ((el = el.nextSibling)) {
      if (el.tagName === tag.toUpperCase()) {
        siblings.push(el)
      }
    }
    return siblings
  }
})
