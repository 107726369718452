import { inject } from '@ember/service'
import { computed } from '@ember/object'

import { durationAsHms } from 'echo-ember-common/helpers/duration-as-hms'
import { toLocaleString } from 'echo-ember-common/helpers/to-locale-string'

import RemunerationController from '../../controller'

export default RemunerationController.extend({
  profile: inject(),
  intl: inject(),

  recordingEdge: computed('remunerationEntry', function () {
    return this.get('remunerationEntry.snapshot.remuneration_details.edges.firstObject')
  }),

  channelDetailsEdges: computed('profile.activeProfile', 'model.recordingId', function () {
    return this.get('remunerationEntry.snapshot.remuneration_details_by_channel.edges') || []
  }),

  renumerationId: computed('ledgerId', function () {
    return this.ledgerId
  }),

  subSubHeader: computed('recordingEdge', 'intl.locale', function () {
    const recordingEdge = this.recordingEdge
    if (!recordingEdge) return false
    switch (this.get('profile.activeProfile.profile')) {
      case 'performer':
        return this.intl.t('ledger.remuneration.recording.points', {
          roleCodes: recordingEdge.role_codes.join('/'),
          points: recordingEdge.base_points
        })
      case 'personal-producer':
        return `${this.intl.t(
          'edit_recording.ownership.header'
        )} ${recordingEdge.ownership_share_size}%`
      default:
        return ''
    }
  }),

  metaFields: computed('recordingEdge', 'intl.locale', function () {
    const totalRemuneration = toLocaleString([this.get('recordingEdge.total_remuneration')])
    const totalPlayCount = this.get('recordingEdge.total_playtime_play_count')
    const totalPlaytime = durationAsHms([
      this.get('recordingEdge.total_playtime_duration_in_seconds')
    ])
    return [
      {
        title: this.intl.t('ledger.remuneration.recording.headers.total-remuneration'),
        value: totalRemuneration
      },
      {
        title: this.intl.t('ledger.remuneration.recording.headers.total-play-count'),
        value: totalPlayCount
      },
      {
        title: this.intl.t('ledger.remuneration.recording.headers.total-playtime'),
        value: totalPlaytime
      }
    ]
  })
})
