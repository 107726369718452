import Service from '@ember/service'
import { inject as service } from '@ember/service'
import { computed } from '@ember/object'
import { reads } from '@ember/object/computed'

export default class ProfileInfo extends Service {
  @service profile
  @reads('profile.activeProfile') activeProfile

  @reads('profile.impersonatedUserId') maskId
  @reads('activeProfile.profile') currentProfileName
  @reads('activeProfile.type') currentProfileType

  @computed('maskId')
  get includePerformer() {
    return this.maskId !== null ? this.currentProfileName === 'performer' : true
  }

  @computed('maskId')
  get includeProducer() {
    return this.maskId !== null ? this.currentProfileName === 'personal-producer' : true
  }
}
