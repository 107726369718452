import Mixin from '@ember/object/mixin'
import { inject as service } from '@ember/service'
import { computed } from '@ember/object'

const DEFAULT_RESULTS = 100

export default Mixin.create({
  intl: service(),
  intercom: service(),

  first: DEFAULT_RESULTS,
  after: null,
  sort: null,
  direction: null,
  filters: [],
  year: null,
  term: '',
  parsedFilters: computed('filters', function () {
    return this._parseFilters(this.get('filters'))
  }),

  actions: {
    openIntercom() {
      this.intercom.show()
    },

    onPageChange(_, after) {
      this.set('after', after)
    },

    onKeyUp(value, event) {
      switch (event.key) {
        case 'Enter': {
          this.set('after', null)
          this.set('term', value)
          break
        }
      }
    },

    onYearChange(value) {
      this.set('year', value)
      this.set('after', null)
    },

    onAddFilter(field, value) {
      const filters = this.get('filters')
      const oldFilters = filters.filter(filter => {
        return filter !== `${field}:${value}`
      })

      this.set('after', null)
      this.set('filters', [...oldFilters, `${field}:${value}`])
    },

    onClearFilter({ field, value }) {
      const filters = this.get('filters')
      const newFilters = filters.filter(filter => {
        return filter !== `${field}:${value}` && filter !== `${field}:!${value}`
      })
      this.set('after', null)
      this.set('filters', newFilters)
    }
  },

  _parseFilters(filters) {
    if (!filters) {
      return []
    }

    if (filters && filters.length > 0) {
      return filters.map(filter => {
        const matches = filter.match(/^([^: ]*):(!*)(.*)$/)
        if (matches && matches[1] && matches[3]) {
          return { field: matches[1], value: matches[3], isNegative: matches[2] }
        }
        return { field: '', value: '' }
      })
    }
    return []
  }
})
