import { modifier } from 'ember-modifier'

/**
 * Modifier for callback whenever user clicks outside the element it is added to.
 * It calls the callback with the click event.
 *
 * Usage:
 *
 *    <div {{on-click-outside this.someAction}}>
 *      ...
 *    </div>
 *
 *    this.someAction will be called whenever user clicks outside of div.
 */
export default modifier((element, [callback]) => {
  const handleClick = event => {
    const clickedOutsideOfElement = !element.contains(event.target)
    if (clickedOutsideOfElement) return callback(event)
  }

  document.addEventListener('click', handleClick)

  return () => {
    document.removeEventListener('click', handleClick)
  }
})
