import Component from '@glimmer/component'
import { computed, getWithDefault } from '@ember/object'
import { action } from '@ember/object'
import { inject } from '@ember/service'

export default class GlobalSearchResultsRecordingsTableRow extends Component {
  @inject router
  @inject features

  @computed('recording', 'profileId')
  get roleCode() {
    const matchingPerformance = (this.args.recording.performances || []).findBy(
      'public_performer.id',
      this.args.profileId
    )
    if (!matchingPerformance) return ''

    return getWithDefault(matchingPerformance, 'role_codes.firstObject', '')
  }
  @action
  redirectToRecording() {
    if (this.features.get('discography_rework')) {
      this.router.transitionTo('user.recording', this.args.recording.id)
    } else {
      this.router.transitionTo('user.edit-recording', this.args.recording.id)
    }
  }
}
