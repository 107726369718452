import Component from '@glimmer/component'
import { action, computed } from '@ember/object'
import { rollbackPropsExcept } from 'min-side/utils/changeset-helpers'

export default class ProfileSectionTaxlInfoComponent extends Component {
  @computed('args.errors.length')
  get nestedErrorsObject() {
    return {
      errors: this.args.errors
    }
  }

  get data() {
    return this.args.changeset
  }

  @action
  onTaxInfoChange(incomeType) {
    this.data.set('income_type', incomeType)
    rollbackPropsExcept(this.data, ['income_type'])
  }
}
