import Component from '@ember/component'
import { set } from '@ember/object'

export default Component.extend({
  tagName: '',
  supportsDataTestProperties: true,
  actions: {
    select(country) {
      set(this, 'changeset.country', country)
    }
  }
})
