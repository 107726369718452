import Component from '@glimmer/component'
import { computed, getWithDefault } from '@ember/object'
import { inject as service } from '@ember/service'

export default class ChangeRequestHandler extends Component {
  @service session

  @computed('args.taskResult')
  get isFinished() {
    return !!this.args.taskResult
  }

  @computed('args.taskResult')
  get taskErrors() {
    return getWithDefault(this.args.taskResult, 'errors', null)
  }

  @computed('taskErrors')
  get isAlreadyUsed() {
    if (!this.taskErrors) return false
    return this.taskErrors.any(e => e.detail === 'already_used')
  }

  @computed('taskErrors', 'isAlreadyUsed')
  get isSuccessful() {
    return !this.taskErrors || this.isAlreadyUsed
  }

  @computed('isSuccessful')
  get headerTranslationKey() {
    if (this.isSuccessful) {
      return 'change_request_handler.thanks'
    }

    return 'flash.save.failed'
  }

  @computed('isAlreadyUsed')
  get responseTranslationKey() {
    if (this.isAlreadyUsed) return 'change_request_handler.rejected_message'
    return 'change_request_handler.completed_message'
  }

  @computed('session.isAuthenticated')
  get button() {
    if (this.session.isAuthenticated) {
      return {
        translationKey: 'change_request_handler.dashboard_btn',
        target: 'user.index'
      }
    }

    return {
      translationKey: 'change_request_handler.login_btn',
      target: 'login'
    }
  }
}
