import Route from '@ember/routing/route'
import { get, action } from '@ember/object'
import { inject as service } from '@ember/service'
import { urlFor } from 'min-side/utils/url-helpers'
import { tempErrorMsg, i18nBoundTo } from 'min-side/utils/error-helpers'
import UnauthenticatedRouteMixin from 'ember-simple-auth/mixins/unauthenticated-route-mixin'
import { isEmpty, isPresent } from '@ember/utils'
import { reject } from 'rsvp'
import { HTTP_UNASSIGNED, HTTP_UNAUTHORIZED } from 'min-side/constants/http-status-codes'

export default class LoginRoute extends Route.extend(UnauthenticatedRouteMixin) {
  @service intl
  @service apollo
  @service ajaxRequests

  @action
  async loginFirstFactor() {
    if (!this._validate()) {
      const errors = this.errors
      this._displayErrorMessages(errors)
      return reject(false)
    }

    const dataToBeSent = {
      data: {
        attributes: this.controllerFor('login').getProperties('email', 'password')
      },
      locale: get(this, 'intl.primaryLocale')
    }
    dataToBeSent.data.attributes.site = 'min-side'

    try {
      const response = await this.ajaxRequests.postRequest(urlFor('auth/identify'), dataToBeSent)
      // if http request was not ok thrown an error
      if (!response.ok) throw response

      const { data } = await response.json()

      await this.apollo.client.resetStore()

      const xNextStep = response.headers.get('x-next-step')
      const preconfiguredSMS = response.status === HTTP_UNASSIGNED ? 1 : 0

      this.controllerFor('login').setProperties({ email: null, password: null })

      if (xNextStep === 'choose mobile') {
        this.transitionTo('login-choose-mobile', {
          queryParams: {
            token: data.attributes.token
          }
        })
      } else {
        this.transitionTo('verify', {
          queryParams: {
            token: data.attributes.token,
            preconfiguredSMS
          }
        })
      }
    } catch (err) {
      if (typeof err.json === 'function') {
        const { errors } = await err.json()
        if (errors && errors && errors.length) {
          this._displayErrorMessages(errors)
          return
        }
        if (err.status === HTTP_UNAUTHORIZED) {
          return this.transitionTo('inactive')
        }
      }
      const msg = [this.intl.t('login.failed'), this.intl.t('login.backend-is-unavailable')].join(
        '. '
      )
      // this.flashMessages.error(msg)
      this.controller.errors = msg
    }
  }

  _validate() {
    let valid = true
    const email = this.controllerFor('login').get('email')
    const password = this.controllerFor('login').get('password')
    const errors = []

    if (isEmpty(email)) {
      errors.push({
        source: { pointer: 'data/attributes/email' },
        detail: this.intl.t('login.validation.filled')
      })
    }
    if (isEmpty(password)) {
      errors.push({
        source: { pointer: 'data/attributes/password' },
        detail: this.intl.t('login.validation.filled')
      })
    }
    if (isPresent(email) && !email.includes('@')) {
      errors.push({
        source: { pointer: 'data/attributes/email' },
        detail: this.intl.t('login.validation.format')
      })
    }

    if (isPresent(errors)) {
      valid = false
      this.errors = errors
    }
    return valid
  }

  _displayErrorMessages(errors) {
    let msg = tempErrorMsg(
      this.intl.t('login.failed'),
      errors,
      i18nBoundTo(this.intl, 'model.user')
    )
    msg = msg.concat(` ${this.intl.t('login.try-again')}`)
    // this.flashMessages.error(msg)
    this.controller.errors = msg
  }
}
