import { task, timeout } from 'ember-concurrency'
import labelsQuery from 'min-side/graphql/queries/new-release/labels'
import { DEBOUNCE_MS } from 'min-side/constants/time'

export default task(function* (query) {
  yield timeout(DEBOUNCE_MS)
  const variables = { query }
  const labels = yield this.apollo.watchQuery({ query: labelsQuery, variables })
  return labels.search_record_labels.edges.map(edge => edge.node)
}).restartable()
