import Controller from '@ember/controller'
import { inject } from '@ember/service'
import { computed, get } from '@ember/object'
import { reads } from '@ember/object/computed'

const wrapperClassForRoute = { login: 'login-page__wrapper' }

export default Controller.extend({
  classNameBindings: ['media.classNames'],
  router: inject(),
  profile: inject(),
  cookies: inject(),
  currentRouteNameComputed: reads('router.currentRouteName'),
  impersonatingUser: reads('profile.impersonatedUserId'),
  isUserRoute: computed('router.currentRouteName', {
    get() {
      const routeName = get(this, 'router.currentRouteName')

      return routeName.split('.')[0] === 'user'
    }
  }),
  pageWrapperClass: computed('router.currentRouteName', {
    get() {
      const routeName = get(this, 'router.currentRouteName')

      return wrapperClassForRoute[routeName] || ''
    }
  }),
  cookiesEnabled: computed('cookies.isEnabled', function () {
    return this.get('cookies').isEnabled()
  })
})
