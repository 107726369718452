import Component from '@ember/component'
import { computed } from '@ember/object'
import { equal } from '@ember/object/computed'

export default Component.extend({
  tagName: 'div',
  'data-test-release-form-recordings-form-search-box-candidate': true,
  supportsDataTestProperties: true,
  detailsVisible: computed('isRegularRecording', 'isNewRecording', {
    get() {
      return this.isRegularRecording || this.isNewRecording || this.__detailsVisible
    },
    set(key, value) {
      this.__detailsVisible = value
      return value
    }
  }),
  isRegularRecording: equal('candidate.typename', 'Recording'),
  isNewRecording: equal('candidate.typename', 'NewRecording'),

  actions: {
    accept() {
      this.acceptCandidateAction(this.candidate)
    },

    reset() {
      this.resetSearchAction()
    },

    toggleVisibility() {
      this.toggleProperty('detailsVisible')
    }
  }
})
