const EMPTY_ALTERNATIVE_CATALOGUE_NUMBER_OBJECT = {
  catalog_number: '',
  description: ''
}

const EMPTY_ALTERNATIVE_CATALOGUE_NUMBER_ERROR_OBJECT = {
  isError: false
}

export {
  EMPTY_ALTERNATIVE_CATALOGUE_NUMBER_OBJECT,
  EMPTY_ALTERNATIVE_CATALOGUE_NUMBER_ERROR_OBJECT
}
