import Component from '@glimmer/component'
import { inject as service } from '@ember/service'
import { computed } from '@ember/object'

export default class UserMenuItems extends Component {
  @service features
  @service profile

  @computed('profile.activeProfile.type')
  get isAgency() {
    return this.profile.activeProfile.type === 'agency'
  }

  @computed('profile.activeProfile.profile')
  get isPerformer() {
    return this.profile.activeProfile.profile === 'performer'
  }

  @computed('features.discography_rework')
  get discographyRework() {
    if (this.features.get('discography_rework')) {
      return true
    }
    return false
  }

  @computed('features.claims', 'isAgency')
  get performerClaimsEnabled() {
    if (this.isAgency) {
      return true
    }
    if (this.features.get('claims-performer')) {
      return true
    }
    return false
  }

  // should a claim menu item be show at all
  @computed('showPerformerClaims', 'isPerformer')
  get showClaims() {
    if (this.isPerformer) {
      if (this.performerClaimsEnabled && !this.discographyRework) {
        return true
      }
      return false
    }
    return true
  }
}
