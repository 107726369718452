import Component from '@glimmer/component'
import { action, computed } from '@ember/object'
import Changeset from 'ember-changeset'
import lookupValidator from 'ember-changeset-validations'
import InstrumentValidations from 'min-side/validations/profile/instrument'
import AlternativeArtistNameValidations from 'min-side/validations/profile/alternative-artist-name'

export default class ProfileSectionPerformerProfileInfoComponent extends Component {
  @computed('args.errors.length')
  get nestedErrorsObject() {
    return {
      errors: this.args.errors
    }
  }

  get data() {
    return this.args.changeset
  }

  @computed('data.alternative_artist_names.[]')
  get altArtistNames() {
    return this.data.get('alternative_artist_names')
  }

  @computed('data.instruments.[]')
  get instruments() {
    return this.data.get('instruments')
  }

  @action
  addAlternativeArtistName() {
    const altArtistNames = this.altArtistNames

    const blankAltArtistName = {
      artist_name: '',
      description: ''
    }

    const blankChangeset = new Changeset(
      blankAltArtistName,
      lookupValidator(AlternativeArtistNameValidations),
      AlternativeArtistNameValidations
    )

    this.data.set('alternative_artist_names', [...altArtistNames, blankChangeset])
  }

  @action
  removeAlternativeArtistName(_, index) {
    const altArtistNames = this.altArtistNames

    this.data.set(
      'alternative_artist_names',
      altArtistNames.filter((_, altIdx) => altIdx !== index)
    )
  }

  @action
  selectMainInstrument(instrument) {
    this.data.set('main_instrument', instrument)
  }

  @action
  selectInstrument(index, { id, name }) {
    const instruments = this.instruments

    instruments[index].set('id', id)
    instruments[index].set('name', name)
  }

  @action
  unselectInstrument(index) {
    const instruments = this.instruments

    instruments[index].set('id', null)
    instruments[index].set('name', null)
  }

  @action
  removeInstrument(index) {
    const instruments = this.instruments
    this.data.set(
      'instruments',
      instruments.filter((_, instrIdx) => instrIdx !== index)
    )
  }

  @action
  addInstrument() {
    const instruments = this.instruments

    const blankInstrument = {
      id: null,
      name: null
    }

    const blankChangeset = new Changeset(
      blankInstrument,
      lookupValidator(InstrumentValidations),
      InstrumentValidations
    )

    this.data.set('instruments', [...instruments, blankChangeset])
  }
}
