import { computed } from '@ember/object'
import Mixin from '@ember/object/mixin'

const availableButtonStyles = ['primary', 'secondary', 'danger', 'secondary-inversed', null]

/**
 * Contains common functionality we share between our two
 * button atoms: a-button and async-button
 *
 * It let's both share style-to-class settings, and have configuration
 * like block, borderless and inline.
 */
export default Mixin.create({
  classNames: 'btn',
  classNameBindings: [
    'buttonStyle',
    'block:btn--block',
    'borderless:btn--borderless',
    'inline:btn--inline',
    'flex:btn--flex'
  ],
  /**
   * Block - Uppercase text. Stretches to full width of parent container
   */
  block: false,

  /**
   * Borderless - no bottom borderless
   */
  borderless: false,

  /**
   * Inline - used in flash message (vertical-align:baseline)
   */
  inline: false,

  buttonStyle: computed('style', function () {
    const style = this.style
    if (this.style) {
      return `btn--${style}`
    }
  }),

  /**
   Checks whether 'buttonStyle' corresponds to one of the above availableButtonStyles
   */
  style: null,

  didReceiveAttrs() {
    const style = this.style

    if (!availableButtonStyles.includes(style)) {
      throw new Error(`Selected Style ${style} is not an available button`)
    }
  }
})
