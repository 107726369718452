export default {
  "distribution-areas": "_distribution-areas_7cqd1j",
  "area-navigation": "_area-navigation_7cqd1j",
  "card": "_card_7cqd1j",
  "card-title": "_card-title_7cqd1j",
  "area-list": "_area-list_7cqd1j",
  "area": "_area_7cqd1j",
  "area-selected": "_area-selected_7cqd1j",
  "breakdown": "_breakdown_7cqd1j"
};
