import { computed } from '@ember/object'
import Component from '@ember/component'

/**
 * Component for representing a collection of alternative attributes in the UI
 *
 * Examples:
 *    - alternativeTitles
 *    - alternativeIsrcs
 *    - alternativeCatalogNumbers
 */
export default Component.extend({
  tagName: '',
  supportsDataTestProperties: true,

  isEdit: false,
  hideDescription: false,
  disabled: true,

  /**
   * The model the alternatives belongs to
   */
  model: null,

  /**
   * What key are you expecting erros to be found?
   *
   * If your array with alternatives are named for example `alternative-titles` you
   * most likely have this as your error key too.
   */
  errorKey: null,

  /**
   * Within each alternative, what is the attribute name?
   *
   * Ex: For alternative isrc, your alternative object may look like
   *  [
   *    {isrc: 'isrc', description: 'some description'}
   *  ]
   *
   * In this case the alternativeValuePropertyName is `isrc` and alternativeDescriptionPropertyName is `description`
   */
  alternativeValuePropertyName: null,
  alternativeDescriptionPropertyName: 'description',

  alternativeValuePropertyNameErrorKey: computed('alternativeValuePropertyName', function () {
    return this.alternativeValuePropertyName
  }),

  classNamesForValue: 'form__input form__input--block',
  classNamesForDescription: 'form__input form__input--block',

  /**
   * Human translations used for labels for your alternative value and description
   */
  humanName: null,
  humanDescription: null,

  actions: {
    addAlternative() {
      const alternative = {}

      alternative[this.alternativeValuePropertyName] = ''
      alternative[this.alternativeDescriptionPropertyName] = ''

      this.alternatives.pushObject(alternative)
    },

    removeAlternative(alternative, index) {
      const onRemove = this.onRemove
      if (onRemove && typeof onRemove === 'function') return onRemove(alternative, index)
      this.alternatives.removeObject(alternative)
    }
  }
})
