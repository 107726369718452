import { computed } from '@ember/object'
import Component from '@ember/component'
import { isEqual } from '@ember/utils'

export default Component.extend({
  possibleDirections: ['ASC', 'DESC'], // eslint-disable-line
  tagName: 'span',

  orderByApplies: computed('orderByProperty', function () {
    return isEqual(this.orderByProperty, this.property)
  }),
  direction: computed('orderByApplies', 'orderByDirection', function () {
    return this.orderByApplies ? this.orderByDirection : null
  }),
  sortingClass: computed('direction', function () {
    return `table-sort--${this.direction}`.toLowerCase()
  }),

  actions: {
    toggleSort() {
      const dirs = this.possibleDirections
      const dir = dirs[dirs.indexOf(this.direction) + 1]
      const property = this.property

      if (dir) {
        this.onOrderByChange(property, dir)
      } else {
        this.onOrderByChange(null, null)
      }
    }
  }
})
