import Route from '@ember/routing/route'
import { on } from '@ember/object/evented'
import UnauthenticatedRouteMixin from 'ember-simple-auth/mixins/unauthenticated-route-mixin'

export default Route.extend(UnauthenticatedRouteMixin, {
  loadUntilTransition: on('deactivate', function () {
    this.controller.loading = false
    this.controller.disabled = false
  })
})
