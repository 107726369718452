import { computed } from '@ember/object'
import Component from '@ember/component'

export default Component.extend({
  roles: computed(function () {
    return [
      {
        id: 'roleA',
        name: 'A',
        isChecked: this.role === 'a'
      },
      {
        id: 'roleB',
        name: 'B',
        isChecked: this.role === 'b'
      },
      {
        id: 'roleC',
        name: 'C',
        isChecked: this.role === 'c'
      }
    ]
  })
})
