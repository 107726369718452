import Component from '@ember/component'
import { computed, get, set } from '@ember/object'
import { equal, reads } from '@ember/object/computed'
import { cleanUpRecording } from 'min-side/extractors/edit-release/tracks'
import { queryManager } from 'ember-apollo-client'
import { arrayMoveItem } from 'min-side/helpers/array-move-item'
import filter from 'lodash/filter'

const emptyTrackObject = (sideNumber, numberOnSide) => {
  return { node: { side: sideNumber, number: numberOnSide } }
}

export default Component.extend({
  'data-test-edit-release-tracks-side': true,
  tagName: 'tbody',
  apollo: queryManager(),

  isFirstSide: equal('sideIndex', 0),

  tracksCount: reads('side.tracks.length'),

  number: computed('sideIndex', function () {
    return get(this, 'sideIndex') + 1
  }),

  isLastSide: computed('sideIndex', 'sidesCount', function () {
    return get(this, 'sideIndex') === get(this, 'sidesCount') - 1
  }),

  actions: {
    submitRemoveSide() {
      get(this, 'onRemoveSide')(get(this, 'sideIndex'))
    },
    addNewTrack() {
      const side = get(this, 'side')
      const sideNumber = get(this, 'number')
      if (side.get('tracks')) {
        const tracks = [
          ...side.get('tracks'),
          emptyTrackObject(sideNumber, get(this, 'side.tracks.length') + 1)
        ]
        side.set('tracks', this.updateTracksPositionInSide(tracks))
      } else {
        side.set('tracks', [emptyTrackObject(sideNumber, get(this, 'side.tracks.length') + 1)])
      }
    },
    removeTrack(index) {
      const side = get(this, 'side')
      const resultedTracksArray = side.get('tracks').filter((item, position) => position !== index)
      side.set('tracks', this.updateTracksPositionInSide(resultedTracksArray))
    },
    moveTrackUp(currentIndex) {
      if (get(this, 'isFirstSide') && currentIndex === 0) return false

      if (currentIndex === 0) {
        const removedTrack = this._takeOutTrackFromSide(currentIndex)
        get(this, 'moveInNewSideUp')(get(this, 'sideIndex'), removedTrack)
        return false
      }
      const newIndex = currentIndex - 1
      const side = get(this, 'side')
      const newResultedTracksArray = this.updateTracksPositionInSide(
        arrayMoveItem(side.get('tracks'), currentIndex, newIndex)
      )

      side.set('tracks', newResultedTracksArray)
    },
    moveTrackDown(currentIndex) {
      const side = get(this, 'side')
      if (get(this, 'isLastSide') && currentIndex === side.get('tracks.length') - 1) return false

      if (currentIndex === side.get('tracks.length') - 1) {
        const removedTrack = this._takeOutTrackFromSide(currentIndex)
        get(this, 'moveInNewSideDown')(get(this, 'sideIndex'), removedTrack)
        return false
      }
      const newIndex = currentIndex + 1
      const newResultedTracksArray = this.updateTracksPositionInSide(
        arrayMoveItem(side.get('tracks'), currentIndex, newIndex)
      )

      side.set('tracks', newResultedTracksArray)
    },
    addRecording(index, recording) {
      const tracks = [...this.side.get('tracks')]
      set(tracks, `${index}.node`, {
        ...get(tracks, `${index}.node`),
        ...cleanUpRecording(recording),
        recording_id: get(recording, 'id'),
        addingRecording: false
      })
      this.side.set('tracks', tracks)
    }
  },
  _takeOutTrackFromSide(trackIndex) {
    const side = get(this, 'side')
    const tracks = [...side.get('tracks')]
    const track = tracks[trackIndex]
    side.set(
      'tracks',
      filter(tracks, (track, index) => index !== trackIndex)
    )
    return track
  }
})
