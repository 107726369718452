import EmberRouter from '@ember/routing/router'
import config from './config/environment'

/**
 * The whole purpose of this function is to allow for index route on leaf routes.
 *
 * We needed this for adding the following structure to a leaf route:
 * route-name
 *   template (main template here)
 *     index
 *       template (usually dynamic content here)
 *     index-loading
 *        template (loading indicator here)
 */
// eslint-disable-next-line no-empty-function
const WITH_INDEX_ROUTE = function () {}

const Router = EmberRouter.extend({
  location: config.locationType
})

Router.map(function () {
  this.route('login')
  this.route('register')
  this.route('no-support')
  this.route('login-choose-mobile')
  this.route('verify')
  this.route('profile')
  this.route('inactive')
  this.route('forgot-password')
  this.route('check-email')
  this.route('set-password')
  this.route('playbacks')

  this.route('cookies-info')

  this.route('user', function () {
    this.route('clients', function () {
      this.route('producers')
      this.route('performers')
    })

    this.route('testators', { path: 'my-rights' })

    this.route('discography', function () {
      this.route('albums', WITH_INDEX_ROUTE)
      this.route('groups', WITH_INDEX_ROUTE)
      this.route('recordings', WITH_INDEX_ROUTE)
    })

    this.route('played-recordings')
    this.route('recordings')

    this.route('global-search-results', function () {
      this.route('recordings', WITH_INDEX_ROUTE)
      this.route('releases', WITH_INDEX_ROUTE)
    })

    this.route('recording', { path: 'recording/:id' })
    this.route('register-recording', { path: 'register' })

    this.route('edit-recording', { path: 'discography/recordings/:isrc_or_id' }, function () {
      this.route('info', { path: '/' })
      this.route('ownership', { path: '/ownership' })
      this.route('performers', { path: '/performers' })
      this.route('releases', { path: '/releases' })
    })
    this.route('edit-release', { path: 'discography/releases/:id' }, function () {
      this.route('info', { path: '/' })
      this.route('tracks', { path: '/tracks' })
    })
    this.route('new-release', { path: 'discography/release/new' })

    this.route('ledger', function () {
      this.route('distribution-areas', { path: '/distribution-areas' })
      this.route('entry', { path: 'entry/:id' })
      this.route('inheritance', { path: 'inheritance/:id' })
      this.route('payment', { path: 'payment/:id' })
      this.route('foreign-remuneration', { path: 'foreign-remuneration/:entry_id' })
      this.route('remuneration', { path: 'remuneration/:id' }, function () {
        this.route('by-recording', WITH_INDEX_ROUTE)
        this.route('by-recording-diff', WITH_INDEX_ROUTE)
        this.route('by-broadcaster', WITH_INDEX_ROUTE)
        this.route('by-broadcaster-diff', WITH_INDEX_ROUTE)
        this.route('recording', { path: 'recording/:recording_id' }, WITH_INDEX_ROUTE)
        this.route('recording-diff', { path: 'recording-diff/:recording_id' }, WITH_INDEX_ROUTE)
      })
    })

    this.route('profile-info', function () {
      this.route('personal')
      this.route('contact')
      this.route('tax-reports')
      this.route('profile')
      this.route('bank')
      this.route('membership')
      this.route('org-info')
      this.route('registrant-codes')
      this.route('documents')
    })

    this.route('claims', { path: 'claims' }, function () {
      this.route('list')
      this.route('playbacks')
      this.route('item', { path: 'item/:id' })
    })
  })

  this.route('change-request', { path: 'change_request' }, function () {
    this.route('approve')
    this.route('reject')
  })

  this.route('invitation', { path: '/:invitation_token/view' })
  this.route('invalid-invitation-token')
  this.route('set-locale', { path: '/:locale' })
  this.route('not-found', { path: '*path' })

  return null
})

export default Router
