import Component from '@ember/component'

export default Component.extend({
  actions: {
    territoryUpdated(territory) {
      this.set('society.society_id', territory)
    },
    removeSociety() {
      this.onRemove()
    }
  }
})
