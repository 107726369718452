define("min-side/components/molecules/time-input", ["exports", "echo-ember-common/components/molecules/time-input"], function (_exports, _timeInput) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _timeInput.default;
    }
  });
});
