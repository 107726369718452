import { validatePresence } from 'ember-changeset-validations/validators'

export const defaultResidencyObject = {
  country: null,
  from: '',
  to: ''
}

export default {
  country: validatePresence({
    presence: true,
    message: 'validation.missing-country'
  })
}
