import Component from '@glimmer/component'
import { inject as service } from '@ember/service'

import 'chartjs-plugin-datalabels'
import { toLocaleString } from 'echo-ember-common/helpers/to-locale-string'

import { createYearsList } from 'min-side/utils/date-ranges'

const YEARS_TO_SHOW = 4
const DEFAULT_YAXES_MAX = 700
const SHOW_LABEL_THRESHOLD = 250

function hasPayments(remunerationYears) {
  return remunerationYears && remunerationYears.length > 0
}

function createEmptyYears() {
  const years = createYearsList(YEARS_TO_SHOW, 1)

  return years.reduce((acc, year) => {
    acc[year] = 0
    return acc
  }, {})
}

export default class PaymentHistoryDashlet extends Component {
  @service router

  get remunerationYears() {
    const remunerationYears = this.args.model || []
    const years = createEmptyYears()

    if (hasPayments(remunerationYears)) {
      for (const remunerationYear of remunerationYears) {
        const { year, total_paid_amount: amount } = remunerationYear
        if (amount) {
          years[year] = parseFloat(amount)
        }
      }
    }

    return years
  }

  get graphOptions() {
    const router = this.router
    return {
      maintainAspectRatio: false,
      scales: {
        yAxes: [
          {
            ticks: {
              suggestedMax: DEFAULT_YAXES_MAX
            }
          }
        ]
      },
      tooltips: {
        enabled: false
      },
      // prettier-ignore
      onHover: function (e) { // eslint-disable-line object-shorthand
        if (this.getElementsAtEvent(e).length) {
          document.body.style.cursor = 'pointer'
          return
        }
        document.body.style.cursor = null
      },
      // prettier-ignore
      onClick: function (e) { // eslint-disable-line object-shorthand
        if (this.getElementsAtEvent(e).length) {
          const year = this.getElementsAtEvent(e)[0]._model.label
          router.transitionTo('user.ledger.distribution-areas', { queryParams: { year } })
        }
      },
      plugins: {
        datalabels: {
          display: true,
          formatter: value => {
            if (value < SHOW_LABEL_THRESHOLD) {
              return null
            }
            return `${toLocaleString([value])}\nNOK`
          }
        }
      }
    }
  }

  get graphData() {
    const data = this.remunerationYears
    return {
      labels: Object.keys(data),
      datasets: [
        {
          data: Object.values(data)
        }
      ]
    }
  }
}
