import isObject from 'min-side/utils/isObject'
/*
 * So far this method handles two levels deep nested changeset rollback for sure
 * Extend and test if needed
 */
const recursivelyRollback = (changeset, exceptions = [], path = 'data') => {
  const obj = changeset.get(path)

  Object.entries(obj).forEach(([key, value]) => {
    if (isObject(value)) {
      recursivelyRollback(changeset, exceptions, key)
      return
    }

    const keyToRollback = path !== 'data' ? `${path}.${key}` : key

    if (!exceptions.includes(keyToRollback)) {
      changeset.rollbackProperty(keyToRollback)
      // we need to tell the changeset that we've just updated the value
      // ¯\_(ツ)_/¯
      changeset.set(keyToRollback, changeset.get(keyToRollback))
    }
  })
}

export const rollbackPropsExcept = (changeset, exceptions = []) => {
  recursivelyRollback(changeset, exceptions)
}

export const formatErrors = errors => {
  return errors.map(({ key, message }) => ({ attribute: key, message }))
}

export const mapChangesetErrors = (root = '', arrayOfErrors) => {
  return arrayOfErrors.map(err => {
    const { key = '', validation = [] } = err

    const attributeKey = `${root}${key.split('.').join('/')}`
    return {
      attribute: attributeKey,
      message: validation
    }
  })
}

export const getErrorsFromArrayOfChangesets = (root = '', changesetArr) => {
  return changesetArr.reduce((acc, changeset, idx) => {
    const errors = changeset.get('errors')

    if (errors.length) {
      const { key, validation } = errors[0]
      acc.push({
        key: `${root}${idx}.${key}`,
        validation
      })
    }

    return acc
  }, [])
}
