export default {
  iPad: [
    { name: 'Safari', version: '9.3.5' },
    { name: 'Mobile Safari', version: '9.3.5' }
  ],
  iPad2: [
    { name: 'Safari', version: '9.3.5' },
    { name: 'Mobile Safari', version: '9.3.5' }
  ]
}
