import { get, getWithDefault } from '@ember/object'
import { PROFILE_TYPES_MAP, PROFILE_TYPES } from 'min-side/constants/profile'

const extractRequesterPersonId = currentProfile => {
  if (get(currentProfile, 'activeProfile.profile') === PROFILE_TYPES.CONTACT) {
    return get(currentProfile, '_person.contact.id')
  }
  return currentProfile.activeProfile.id
}

export const prepareOwnershipInput = (ownership, recordingId, currentProfile) => {
  const formData = new FormData()
  formData.append(
    'query',
    'mutation CreateOwnershipsChangeRequest($input: CreateOwnershipsChangeRequestInput!) {\n  create_ownerships_change_request(input: $input) {\n    errors {\n      key\n      message\n      __typename\n    }\n    __typename\n  }\n}\n'
  )
  formData.append('variables[input][recording_id]', recordingId)

  formData.append('variables[input][requester_person_id]', extractRequesterPersonId(currentProfile))

  formData.append(
    'variables[input][requester_person_profile]',
    PROFILE_TYPES_MAP[currentProfile.activeProfile.profile]
  )
  formData.append('variables[input][changes][][starts_at]', ownership.get('starts_at'))
  formData.append('variables[input][changes][][ends_at]', ownership.get('ends_at'))
  formData.append('variables[input][changes][][files][][file]', ownership.get('attachment.file'))
  formData.append(
    'variables[input][changes][][files][][description]',
    ownership.get('attachment.description')
  )

  ownership.get('shares').forEach(share => {
    formData.append('variables[input][changes][][shares][][producer_id]', share.get('producer_id'))
    formData.append('variables[input][changes][][shares][][size]', parseInt(share.get('value'), 10))
  })

  return formData
}

export const getOwnershipChangeRequest = ownershipsChangeRequests => {
  if (!ownershipsChangeRequests) return []

  return getWithDefault(ownershipsChangeRequests, 'edges.0.node.changes.0', [])
}
