import groupBy from 'lodash/groupBy'

/**
 * Given an array of objects it groups by a given property path
 * @param array
 * @param propertyPath
 * @returns Array[Array[]]
 */
export default function groupByProperty(array, propertyPath) {
  if (!(array instanceof Array)) throw new Error('First parameter should be an array of objects')
  if (!array.length) throw new Error('First parameter should contain at least one object')
  if (typeof propertyPath !== 'string' && !(propertyPath instanceof String)) {
    throw new Error('Second parameter should be a string')
  }

  const objectGroups = groupBy(array, propertyPath)
  return Object.keys(objectGroups).map(key => (key === 'undefined' ? [] : objectGroups[key]))
}
