import { set } from '@ember/object'
import { inject as service } from '@ember/service'
import Mixin from '@ember/object/mixin'

/**
 * Contains a helper used to save session data
 *
 * The reason that we need this is that calling .set() on the session does not persist data
 * (see https://github.com/simplabs/ember-simple-auth/issues/926)
 */

export const SESSION_DATA_KEY = 'session.data'

export default Mixin.create({
  session: service(),

  updateSessionData(key, value) {
    // .set() on session does not persist data (see https://github.com/simplabs/ember-simple-auth/issues/926)
    const session = this.get(SESSION_DATA_KEY)
    set(session, key, value)
    this.get('session.store').persist(session)
  },

  readSessionData(key) {
    return this.get([SESSION_DATA_KEY, key].join('.'))
  },

  removeKeyFromSessionData(key) {
    this.updateSessionData(key, undefined)
  }
})
