import Route from '@ember/routing/route'
export default Route.extend({
  queryParams: {
    property: { refreshModel: true },
    direction: { refreshModel: true }
  },
  actions: {
    onOrderByChange(property, direction) {
      this.transitionTo({ queryParams: { property, direction, after: null } })
    }
  }
})
