import Component from '@ember/component'
import { computed, observer, set, get } from '@ember/object'
import { next, debounce, schedule } from '@ember/runloop'
import { isPresent, isBlank, isEmpty } from '@ember/utils'
import { or } from '@ember/object/computed'
import { addObserver, removeObserver } from '@ember/object/observers'
import { mapToKey } from 'min-side/utils/keyboard-helpers'
import { inject } from '@ember/service'
import { on } from '@ember/object/evented'
import { DEBOUNCE_MS } from 'min-side/constants/time'

export default Component.extend({
  'data-test-searchable-list': true,
  classNames: 'searchable-list',
  classNameBindings: ['isOpen:searchable-list--open', 'disabled:searchable-list--disabled'],
  activeClass: 'searchable-list__option--active',
  intl: inject(),
  eventBus: inject(),
  selected: null,
  loading: false,

  init(...args) {
    this._super(...args)
    this.setProperties({
      currentLabel: '',
      isOpen: false,
      searchTerm: '',
      tabIndex: 0,
      unselectLabel: null
    })
    if (this.eventBusEventName) {
      this.eventBus.subscribe(this.eventBusEventName, this, 'externalTrigger')
    }
  },
  externalTrigger(searchTerm) {
    this.toggleOpen()
    if (searchTerm) {
      this.set('searchTerm', searchTerm)
      this._fetchFilteredCollection(searchTerm)
    }
  },
  filteredCollection: computed('searchTerm', 'collection.[]', function () {
    const term = this.searchTerm.toLowerCase()
    const collection = this.collection || []

    if (this.withAPI) {
      this._fetchFilteredCollection(term)
    } else {
      return this._filterExistingCollection(collection, term)
    }
  }),

  searchResults: or('filteredCollection', 'fetchedCollection'),

  async _fetchFilteredCollection(term) {
    if (isBlank(term)) return []
    schedule('afterRender', () => {
      this._setLoadingToTrue()
    })
    const resetElement = {
      label: this.intl.t('reset'),
      object: null
    }

    try {
      let fetchedCollection = await this.fetchCollectionTask.perform(term)
      fetchedCollection = this._mapCollectionToViewItems(this.labelPath, fetchedCollection)
      this.set('fetchedCollection', [resetElement, ...fetchedCollection])
    } catch (error) {
      this.set('error', error)
    }
    debounce(this, '_setLoadingToFalse', DEBOUNCE_MS)
  },

  _filterExistingCollection(collection, term) {
    if (isBlank(term)) {
      return this._mapCollectionToViewItems(this.labelPath, collection)
    }
    const labelPath = this.labelPath
    const filteredCollection = collection.filter(object => {
      return get(object, labelPath).toLowerCase().indexOf(term) !== -1
    })

    const sortedCollection = filteredCollection.sort((a, b) => {
      const aText = get(a, labelPath)
      const bText = get(b, labelPath)

      return aText.length - bText.length || aText.localeCompare(bText)
    })

    return this._mapCollectionToViewItems(this.labelPath, sortedCollection)
  },

  /**
   * We show the unselect option when it's label is present, search term is blank and you
   * have a selected item.
   */
  showUnselect: computed('unselect', 'searchTerm', 'selected', function () {
    return isPresent(this.unselectLabel) && isBlank(this.searchTerm) && this.selected
  }),

  updateCurrentLabel() {
    if (this.isDestroyed) {
      return
    }

    this.set('currentLabel', this.get(`selected.${this.labelPath}`))
  },

  /**
   * Observes the selected object's label path and whenever it changes we have to
   * update our currentLabel as well.
   *
   * We have to do this, as the dependency key isn't fixed; it is calculated based
   * on the labelPath.
   */
  valueMaintainer: on(
    'init',
    'didDestroy',
    observer('selected', 'labelPath', function () {
      const selected = this.selected
      const lastSelected = this._lastSelected

      const labelPath = this.labelPath
      const lastLabelPath = this._lastLabelPath

      // Remove observer of last selected
      if (lastSelected) {
        removeObserver(lastSelected, lastLabelPath, this, 'updateCurrentLabel')
      }

      // Update current label, as we do observe 'selected' and 'labelPath'
      // and we can end up here when one of them changes
      this.updateCurrentLabel()

      if (selected && !this.isDestroyed) {
        // Add observer to new selected object
        addObserver(selected, labelPath, this, 'updateCurrentLabel')
        // selected.addObserver(labelPath, this, 'updateCurrentLabel')

        // Keep this to the next round
        this.set('_lastLabelPath', labelPath)
        this.set('_lastSelected', selected)
      }
    })
  ),

  currentLabelOrPlaceholder: computed('currentLabel', 'placeholder', function () {
    const currentLabel = this.currentLabel

    if (isEmpty(currentLabel)) {
      return this.placeholder
    }

    return currentLabel
  }),

  didReceiveAttrs() {
    if (isEmpty(this.labelPath)) {
      throw new Error('"labelPath" attribute is required for {{components/searchable-list}}')
    }
    if (this.withAPI && isEmpty(this.fetchCollectionTask)) {
      throw new Error(
        '"fetchCollectionTask" needs to be passed if working with API on {{components/searchable-list}}'
      )
    }
  },

  didRender() {
    window.addEventListener('click', this.closeIfOutsideHandlerBound)
  },
  willDestroyElement() {
    window.removeEventListener('click', this.closeIfOutsideHandlerBound)

    if (this.eventBusEventName) {
      this.eventBus.unsubscribe(this.eventBusEventName, this, 'externalTrigger')
    }
  },

  closeIfOutsideHandler(event) {
    if (this.isOpen === false) {
      return
    }

    const componentElement = this.element
    const path = event.path || (event.composedPath && event.composedPath())

    if (path && !path.includes(componentElement)) {
      this.toggleOpen()
    }
  },

  closeIfOutsideHandlerBound: computed(function () {
    return this.closeIfOutsideHandler.bind(this)
  }),

  toggleOpen() {
    const willOpen = this.isOpen === false
    const willClose = !willOpen

    if (willClose) {
      this.clearActiveSuggestion()
      this.set('searchTerm', '')
      this.element.querySelector('.searchable-list__heading').blur()
    }

    this.toggleProperty('isOpen')

    if (willOpen) {
      // eslint-disable-next-line callback-return
      next(this, this._focusInputElement)
    }
  },

  select(item) {
    this.toggleOpen()
    this.onSelect(item.object)
  },

  actions: {
    selectFirst() {
      const first = this.get('searchResults.firstObject')

      if (first) {
        this.select(first)
      }
    },

    toggleOpen() {
      this.toggleOpen()
    },

    select(item) {
      this.select(item)
    },

    unselect() {
      const onUnselect = this.onUnselect

      if (onUnselect && typeof onUnselect === 'function') {
        onUnselect()
      } else {
        this.select({ object: null })
      }
    },

    onKeyDown(val, event) {
      this.preventCursorMove(event)

      const key = mapToKey(event)
      if (!key) {
        return
      }

      const offset = key === 'arrowUp' ? -1 : 1
      const items = this.searchResults || []
      const activeItem = this.activeItem
      const activeItemIndex = this.activeItemIndex

      if (key === 'arrowUp' || key === 'arrowDown') {
        if (items.length > 0) {
          let index = activeItemIndex >= 0 ? activeItemIndex : -1
          index = this._wrapWithinBounds(index + offset, items.length)
          this.clearActiveSuggestion()
          set(items.objectAt(index), 'active', true)
          const rate = 50
          debounce(this, this._scrollToActiveElement, rate)
        }
      }
      if (key === 'enter') {
        if (activeItem) {
          this.send('select', activeItem)
        } else {
          this.send('selectFirst')
        }
      }
      if (key === 'esc') {
        this.send('toggleOpen')
      }
      if (key === 'backspace' && isEmpty(this.searchTerm)) {
        this.send('unselect')
      }
      if (key === 'shift-tab') {
        /**
         * If we are tabbing backwards, the focus-out will be cancelled by the focus-in
         * on the parent element. To get around this we temporarily disable tabIndex by setting
         * it to -1 thereby skipping the element which has the focus-in listener.
         */
        this.send('toggleOpen')
        this.set('tabIndex', -1)
        // eslint-disable-next-line callback-return
        next(this, () => this.set('tabIndex', 0))
      }
      if (key === 'tab') {
        this.send('toggleOpen')
      }
    }
  },

  keyUp(e) {
    this.preventCursorMove(e)
  },
  keyPress(e) {
    this.preventCursorMove(e)
  },

  preventCursorMove(event) {
    const key = mapToKey(event)
    if (key === 'arrowUp' || key === 'arrowDown') {
      event.preventDefault()
    }
  },

  activeItem: computed('searchResults.@each.active', function () {
    const emptyItem = { label: '', object: null }
    return isEmpty(this.searchResults) ? emptyItem : this.searchResults.findBy('active', true)
  }),

  activeItemIndex: computed('searchResults.@each.active', function () {
    if (isEmpty(this.searchResults)) return -1
    return this.searchResults.indexOf(this.activeItem)
  }),

  clearActiveSuggestion() {
    const activeSuggestion = this.activeItem
    if (activeSuggestion) {
      set(activeSuggestion, 'active', false)
    }
  },

  _wrapWithinBounds(x, bounds) {
    return ((x % bounds) + bounds) % bounds
  },

  _focusInputElement() {
    const list = this.element.querySelector('.searchable-list__search')
    if (list) {
      list.focus()
    }
  },

  _mapCollectionToViewItems(keyPath, collection) {
    if (isEmpty(keyPath)) {
      throw new Error(
        'No key path given to extract label from objects! Add component attribute labelPath.'
      )
    }

    return collection.map(object => {
      return {
        label: get(object, keyPath),
        object
      }
    })
  },

  _scrollToActiveElement() {
    const activeElement = this.element.querySelector(`li.${this.activeClass}`)

    if (activeElement && activeElement.scrollIntoView) {
      activeElement.scrollIntoView({
        behavior: 'smooth',
        block: 'center'
      })
    }
  },

  _setLoadingToFalse() {
    this.set('loading', false)
  },

  _setLoadingToTrue() {
    this.set('loading', true)
  }
})
