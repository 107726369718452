import Component from '@ember/component'
import Changeset from 'ember-changeset'
import { inject } from '@ember/service'
import LocaleMixin from 'min-side/mixins/locale'

export default Component.extend(LocaleMixin, {
  intercom: inject(),
  tagName: 'form',
  classNames: ['form'],
  intl: inject(),

  init(...args) {
    this._super(...args)
    const model = this.model
    this.changeset = new Changeset(model)
  },

  actions: {
    submit(changeset) {
      changeset.save()
      this.submitAction()
    },

    rollback(changeset) {
      changeset.rollback()
      this.cancelAction()
    },

    switchLanguage() {
      this.toggleLanguage(false)
    }
  }
})
