import { inject as service } from '@ember/service'
import Mixin from '@ember/object/mixin'

export default Mixin.create({
  intl: service(),
  formatErrors(errors) {
    return errors.map(error => {
      return {
        attribute: error.key,
        message: error.message
      }
    })
  }
})
