import Component from '@ember/component'
import { bool } from '@ember/object/computed'

export default Component.extend({
  'data-test-file-list-row': true,

  didReceiveAttrs(...args) {
    this._super(...args)
    this.fetchFileUrl()
  },

  fetchFileUrl() {
    const id = this.file.id
    const filePromise = this.fetchFileUrlAction(id)
    this.set('filePromise', filePromise)
  },

  canDeleteFile: bool('deleteFileAction'),

  actions: {
    deleteAction(id) {
      this.deleteFileAction(id)
    }
  }
})
