import Controller from '@ember/controller'
import { computed, get } from '@ember/object'
import { tracked } from '@glimmer/tracking'
import { dropTask } from 'ember-concurrency-decorators'
import { isPresent } from '@ember/utils'
import { inject as service } from '@ember/service'
import { queryManager } from 'ember-apollo-client'
import mutation from 'min-side/graphql/mutations/personal-information'
import { formatErrors, mapChangesetErrors } from 'min-side/utils/changeset-helpers'
import Changeset from 'ember-changeset'

export default class ProfileInfoTaxReportsController extends Controller {
  @tracked taxInfoChangeset = null

  @queryManager apollo
  @service intl
  @service profileInfo

  @computed('model.annual_income_statements')
  get displayReportsSection() {
    return isPresent(get(this.model, 'annual_income_statements'))
  }

  setupChangeset(model) {
    const personData = get(model, 'user.person')
    this.taxInfoChangeset = this.constructor.setupChangesetAndReturnIt(personData)
  }

  @dropTask
  *onContactlInfoValidate(changeset) {
    try {
      yield changeset.validate()

      const isValid = changeset.get('isValid')

      if (!isValid) {
        const validationErrors = this.constructor.collectChangesetErrors(changeset)

        return validationErrors.map(({ attribute, message }) => ({
          attribute,
          message: this.intl.t(message)
        }))
      }

      return []
    } catch (error) {
      throw error
    }
  }

  @dropTask
  *onContactlInfoSubmit(changeset) {
    try {
      const variables = {
        input: {
          ...this.constructor.extractChangesetData(changeset),
          id: this.profileInfo.maskId
        },
        include_performer: this.profileInfo.includePerformer,
        include_producer: this.profileInfo.includeProducer
      }

      const res = yield this.apollo.mutate({ mutation, variables }, 'update_personal_information')

      if (res && res.errors) {
        const formattedErrors = formatErrors(res.errors)

        return formattedErrors
      }

      changeset.save()
      return []
    } catch (error) {
      throw error
    }
  }

  static setupChangesetAndReturnIt(data) {
    const { income_type: incomeType, income_organization: incomeOrganization } = data

    const {
      name: incomeName = null,
      orgnr: incomeNumber = null,
      error = null
    } = incomeOrganization || {
      name: null,
      orgnr: null
    }
    const taxInfoData = {
      income_type: incomeType,
      income_organization_name: incomeName,
      income_organization_number: incomeNumber,
      fetch_error: error
    }

    return new Changeset(taxInfoData)
  }

  static collectChangesetErrors(changeset) {
    return mapChangesetErrors('tax_information/', changeset)
  }

  static extractChangesetData(changeset) {
    const incomeType = changeset.get('income_type')
    const incomeOrgNumber = parseInt(changeset.get('income_organization_number'), 10)

    return {
      income_type: incomeType,
      income_organization_number: incomeType === 'COMMERCIAL' ? incomeOrgNumber : null
    }
  }
}
