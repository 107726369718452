import Controller from '@ember/controller'
import { computed, getWithDefault } from '@ember/object'

export default Controller.extend({
  statements: computed('model', function () {
    const finalStatements = getWithDefault(this, 'model.snapshot_group.final_statements', [])

    return finalStatements.map(statement => {
      const recordingInfo = getWithDefault(statement, 'remuneration_entry.recording', null)

      return {
        title: statement.description,
        amount: statement.amount,
        previousAmount: statement.previous_amount,
        recordingInfo
      }
    })
  }),

  hasRecordingStatments: computed('statements', function () {
    return this.get('statements').some(statement => {
      return !!statement.recordingInfo
    })
  })
})
