import { getGramoMembership } from 'min-side/helpers/memberships'

export const getCurrentOrganization = (profile, person) => {
  if (profile.profile !== 'contact') {
    return null
  }
  return person.contact.positions.find(position => position.organization.id === profile.id)
    .organization
}

export const getOrganizationGramoMembership = (profile, person) => {
  const organization = getCurrentOrganization(profile, person)
  if (organization.producer_company) {
    return getGramoMembership(organization.producer_company.society_memberships)
  }
}
