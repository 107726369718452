import Component from '@glimmer/component'
import { tracked } from '@glimmer/tracking'
import Bowser from 'bowser'
import { action, computed } from '@ember/object'
import { inject as service } from '@ember/service'

const { localStorage } = window

const BROWSER_REQUIREMENTS = {
  chrome: '>=80',
  edge: '>= 80',
  firefox: '>=72',
  safari: '>=13',
  samsung_internet: '>=10.2'
}

const GOOGLE_CHROME_DESKTOP_DOWNLOAD_LINK = {
  name: 'Google Chrome',
  link: 'https://www.google.com/chrome/',
  requiredVersion: BROWSER_REQUIREMENTS.chrome
}

const FIREFOX_DESKTOP_DOWNLOAD_LINK = {
  name: 'Firefox',
  link: 'https://www.mozilla.org/firefox/new/',
  requiredVersion: BROWSER_REQUIREMENTS.chrome
}

/* BrowserCheck
 *
 * A component that gives the user a warning when she is running an
 * unsupported or old browser.
 *
 * The component stores the following in local starage:
 *
 *  - browserCheckSnoozeUntil if the warning is dismissed, it will not
 *                            show againg until this timestamp
 *
 * To test this component, you can set some values in local storage.
 *
 *  - _testBrowserName e.g "Firefox" to see the message for supported,
 *                     but too old. "Opera" to see the message for unsupported.
 *  - _testVersion e.g 70.1
 *
 * (_testVersion is for the time being only being used for display purposes.)
 */
export default class BrowserCheck extends Component {
  @tracked showMoreInfo = false
  @tracked riskTaker = false

  @service intl

  /* eslint-disable-next-line no-magic-numbers */
  snoozeInterval = 7 * 60 * 60 * 24 // 7 days

  browserDownloadLinks = {
    mobile: {
      android: [
        {
          name: 'Chrome',
          link: 'https://play.google.com/store/apps/details?id=com.android.chrome',
          requiredVersion: BROWSER_REQUIREMENTS.chrome
        },
        {
          name: 'Microsoft Edge',
          link: 'https://play.google.com/store/apps/details?id=com.microsoft.emmx',
          requiredVersion: BROWSER_REQUIREMENTS.edge
        },
        {
          name: 'Firefox',
          link: 'https://play.google.com/store/apps/details?id=org.mozilla.firefox',
          requiredVersion: BROWSER_REQUIREMENTS.firefox
        },
        {
          name: 'Samsung Internet Browser',
          link: 'https://play.google.com/store/apps/details?id=com.sec.android.app.sbrowser',
          requiredVersion: BROWSER_REQUIREMENTS.samsung_internet
        }
      ],
      ios: [
        {
          name: 'Google Chrome',
          link: 'https://apps.apple.com/us/app/google-chrome/id535886823'
        },
        {
          name: 'Safari',
          link: 'https://www.apple.com/safari/'
        },
        {
          name: 'Firefox',
          link: 'https://apps.apple.com/app/firefox-private-safe-browser/id989804926'
        },
        {
          name: 'Microsoft Edge',
          links: 'https://apps.apple.com/us/app/microsoft-edge/id1288723196'
        }
      ]
    },
    desktop: {
      linux: [
        {
          name: 'Microsoft Edge',
          link:
            'https://support.microsoft.com/help/4501095/download-the-new-microsoft-edge-based-on-chromium',
          requiredVersion: BROWSER_REQUIREMENTS.edge
        },
        GOOGLE_CHROME_DESKTOP_DOWNLOAD_LINK,
        FIREFOX_DESKTOP_DOWNLOAD_LINK
      ],
      macos: [
        {
          name: 'Safari',
          link: 'https://www.apple.com/safari/',
          requiredVersion: BROWSER_REQUIREMENTS.edge
        },
        GOOGLE_CHROME_DESKTOP_DOWNLOAD_LINK,
        FIREFOX_DESKTOP_DOWNLOAD_LINK
      ],
      windows: [
        {
          name: 'Microsoft Edge',
          link:
            'https://support.microsoft.com/help/4501095/download-the-new-microsoft-edge-based-on-chromium',
          requiredVersion: BROWSER_REQUIREMENTS.edge
        },
        GOOGLE_CHROME_DESKTOP_DOWNLOAD_LINK,
        FIREFOX_DESKTOP_DOWNLOAD_LINK
      ]
    }
  }

  constructor(...args) {
    super(...args)
    this.browser = Bowser.getParser(window.navigator.userAgent)
    const data = this.browser.getBrowser()
    this.browserName = data.name
    this.browserVersion = data.version
    this.isValidBrowser = this.browser.satisfies(BROWSER_REQUIREMENTS)

    /* test overrides */
    const testName = localStorage.getItem('_testBrowserName')
    if (testName) this.browserName = testName
    const testVersion = localStorage.getItem('_testBrowserVersion')
    if (testName) this.browserVersion = testVersion
    if (testName || testVersion) this.isValidBrowser = false
  }

  get unsupportedBrowser() {
    const names = Object.getOwnPropertyNames(BROWSER_REQUIREMENTS)
    return names.indexOf(this.browserName.toLowerCase()) === -1
  }

  @computed('riskTaker')
  get showBrowserWarning() {
    if (this.riskTaker) return false
    if (this.isSnoozing()) return false
    return !this.isValidBrowser
  }

  @action
  ok() {
    this.riskTaker = true
    this.snooze()
  }

  @action
  more() {
    this.showMoreInfo = true
  }

  @action
  closeMore() {
    this.showMoreInfo = false
  }

  @computed('intl.locale')
  get browserRequirementsForDisplay() {
    const os = this.browser.getOSName(true)
    const platform = this.browser.getPlatformType(true)
    return this.browserDownloadLinks[platform][os].map(link => {
      return {
        ...link,
        linkLabel: this.intl.t('browser-check.download')
      }
    })
  }

  isSnoozing() {
    const until = localStorage.getItem('browserCheckSnoozeUntil')
    if (until === null) return false
    return Date.now() <= until
  }

  snooze() {
    const until = Date.now() + this.snoozeInterval
    localStorage.setItem('browserCheckSnoozeUntil', until)
  }
}
