import Component from '@glimmer/component'

export default class ProfileIcon extends Component {
  get iconType() {
    if (!this.args.profile) {
      return 'icon-performer'
    }
    if (this.args.profile.type) {
      if (this.args.profile.type === 'agency') {
        return 'icon-agent'
      }
      if (this.args.profile.type === 'producer_company') {
        return 'icon-producer'
      }
    } else if (this.args.profile.profile) {
      if (this.args.profile.profile === 'performer') {
        return 'icon-performer'
      }
      if (this.args.profile.profile === 'producer') {
        return 'icon-producer'
      }
    }
    return 'icon-agent'
  }
}
