import Component from '@ember/component'
import { computed } from '@ember/object'
import { gt, lt } from '@ember/object/computed'
/**
 * Molecule for representing a numeric value as either
 * red (negative) or green (positive).
 *
 * Zero is not rendered by default, but it can with renderZero=true.
 * Zero is rendered in default text color, which is currently black.
 */
export default Component.extend({
  tagName: 'p',
  classNameBindings: ['isPositive:text--plus', 'isNegative:text--minus'],

  renderNumber: computed('change', 'renderZero', function () {
    return this.renderZero || this.change !== 0
  }),

  change: computed('new', 'old', function () {
    return this.new - this.old
  }),

  isPositive: gt('change', 0),

  isNegative: lt('change', 0),

  didInsertElement(...args) {
    this._super(...args)
    this.setProperties({
      renderZero: false
    })
  }
})
