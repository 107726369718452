export const phone_number_regex = /^[\d\s()\-+]+$/
export const postal_code_regex = /^\d{4}$/
export const organization_number_regex = /^\d{9}$/

export const validPhoneNumber = arg => {
  return arg.match(phone_number_regex)
}

export const validPostalCode = arg => {
  return arg.match(postal_code_regex)
}

export const validOrganizationNumber = arg => {
  const onlyDigits = arg.replace(' ', '')
  return organization_number_regex.test(onlyDigits)
}
