import Component from '@glimmer/component'
import { inject as service } from '@ember/service'
import { action } from '@ember/object'

const LIMIT = 3

export default class RegistrantCodeDashlet extends Component {
  @service router

  get registrantCodes() {
    return this.args.codes
      .slice(0, LIMIT)
      .map(code => code.code)
      .join(', ')
  }

  @action
  goToRegistrantCodes() {
    this.router.transitionTo('user.profile-info.registrant-codes')
  }
}
