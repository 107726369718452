import CurrentProfileMixin from 'min-side/mixins/current-profile'
import Controller from '@ember/controller'
import { inject as service } from '@ember/service'
import { computed } from '@ember/object'
import { alias } from '@ember/object/computed'
import { isPresent } from '@ember/utils'

export default Controller.extend(CurrentProfileMixin, {
  after: null,
  first: 10,
  profile: service(),
  recordings: alias('currentProfile.recordings'),

  showRecordings: computed('recordings.edges', function () {
    const recordings = this.get('recordings.edges')
    return isPresent(recordings) && recordings.length > 0
  }),

  afterSearch: computed('query', function () {
    return ![undefined, ''].includes(this.query)
  }),

  actions: {
    onPageChange(_, after) {
      this.set('after', after)
    }
  }
})
