import Route from '@ember/routing/route'
import { inject } from '@ember/service'
import { isPresent } from '@ember/utils'
import query from 'min-side/graphql/queries/discography/overview'
import { queryManager } from 'ember-apollo-client'

export default Route.extend({
  apollo: queryManager(),
  profile: inject(),
  queryParams: {
    q: { refreshModel: true }
  },
  model({ q }) {
    const maskId = this.get('profile.impersonatedUserId')
    const profileName = this.get('profile.activeProfile.profile')
    let includePerformer = true
    let includeProducer = true

    if (maskId !== null) {
      includePerformer = profileName === 'performer'
      includeProducer = profileName === 'personal-producer'
    }

    const variables = {
      query: isPresent(q) ? q : null,
      rightsholder_id: this.get('profile.impersonatedUserId'),
      include_performer: includePerformer,
      include_producer: includeProducer
    }

    return this.apollo.query({ query, variables, fetchPolicy: 'network-only' })
  }
})
