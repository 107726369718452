import Component from '@ember/component'

export default Component.extend({
  'data-test-loading-spinner': true,
  classNames: 'spinner',
  /**
   Adds the --minimal modifier class which removes margins and set height to 25px
   */
  classNameBindings: 'isMinimal:spinner--minimal',
  isMinimal: false
})
