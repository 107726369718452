import { validateFormat, validatePresence } from 'ember-changeset-validations/validators'
import { postal_code_regex } from 'min-side/utils/validators'
import { get, getWithDefault } from '@ember/object'
import { isEmpty } from '@ember/utils'

export function validateFormatIf({ regex, message, ifFalse = '' }) {
  /* const [key, newValue, oldValue, changes, content] = args */
  return (...args) => {
    const content = args[4]
    const changes = args[3]

    const isFalse = getWithDefault(changes, ifFalse, get(content, ifFalse)) === false

    if (isFalse) {
      return validateFormat({ regex, message })(...args)
    }

    return true
  }
}

export function validatePresenceOMobileNumbers({ presence, message }) {
  return (...args) => {
    args[1] = args[1].value
    return validatePresence({
      message,
      presence
    })(...args)
  }
}

export function validatePhoneNumber({ message, allowBlank = true }) {
  return (...args) => {
    const newValue = args[1]
    if (allowBlank === true && isEmpty(newValue.value)) {
      return true
    }
    if (!window.intlTelInputUtils) {
      throw new Error(
        'It seems you have loaded the ember phone input util scripts, please check the documentation'
      )
    }
    return window.intlTelInputUtils.isValidNumber(newValue.value, newValue.country) ? true : message
  }
}

export default {
  email: [
    validatePresence({
      presence: true,
      message: 'validation.missing-email'
    }),
    validateFormat({
      type: 'email',
      allowBlank: true,
      message: 'validation.invalid-email'
    })
  ],
  mobile: [
    validatePresenceOMobileNumbers({
      presence: true,
      message: 'validation.missing-mobile'
    }),
    validatePhoneNumber({
      message: 'validation.invalid-mobile'
    })
  ],
  telephone: validatePhoneNumber({
    message: 'validation.invalid-telephone'
  }),
  webpage: validateFormat({ type: 'url', allowBlank: true, message: 'validation.invalid-url' }),

  'address.postal_code': validateFormatIf({
    ifFalse: 'hasForeignAddress',
    message: 'validation.invalid-postal-code',
    regex: postal_code_regex
  })
}
