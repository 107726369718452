import CurrentProfileMixin from 'min-side/mixins/current-profile'
import { computed } from '@ember/object'
import { alias } from '@ember/object/computed'
import Controller from '@ember/controller'

export default Controller.extend(CurrentProfileMixin, {
  after: null,
  first: 10,
  releases: alias('currentProfile.releases'),

  afterSearch: computed('query', function () {
    return ![undefined, ''].includes(this.query)
  }),

  actions: {
    onPageChange(_, after) {
      this.set('after', after)
    }
  }
})
