import { getProperties, get, set } from '@ember/object'
import { find, cloneDeep } from 'lodash'

const RELEASE_ATTRIBUTES = [
  'title',
  'main_artist',
  'catalog_number',
  'alternative_catalog_numbers',
  'release_date',
  'genre',
  'format',
  'record_label'
]

const ALTERNATIVE_CATALOGUE_NUMBERS_ATTRIBUTES = ['catalog_number', 'description']

export const cleanReleaseProperties = release => getProperties(release, RELEASE_ATTRIBUTES)

export const prepareReleaseObject = response => {
  const { release, genres } = response
  release.genre = find(genres, genre => genre.id === release.genre)
  return { ...response, release }
}

export const prepareReleaseInput = release => {
  const { record_label, genre, release_date, format } = cloneDeep(release)

  set(release, 'record_label', null)

  if (genre && genre.hasOwnProperty('id')) {
    release.genre = get(genre, 'id')
  }

  if (record_label && typeof record_label === 'object') {
    set(release, 'record_label', get(record_label, 'name'))
  }

  if (release_date === '') set(release, 'release_date', null)
  if (format === '') set(release, 'format', null)

  release.alternative_catalog_numbers = release.alternative_catalog_numbers.map(item =>
    getProperties(item, ALTERNATIVE_CATALOGUE_NUMBERS_ATTRIBUTES)
  )
  return release
}
