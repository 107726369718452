import Route from '@ember/routing/route'
import EmberObject from '@ember/object'
import { inject } from '@ember/service'
import isEqual from 'lodash/isEqual'
import { task } from 'ember-concurrency'
import ConfirmationMixin from 'ember-onbeforeunload/mixins/confirmation'
import { queryManager } from 'ember-apollo-client'
import query from 'min-side/graphql/queries/new-release/new-release'
import { A } from '@ember/array'
import tSearchPerformers from 'min-side/tasks/search-performers'
import tFetchArtistsCollection from 'min-side/tasks/fetch-artists-collection'
import tFetchLabelsCollection from 'min-side/tasks/fetch-labels-collection'
import tSearchRecordings from 'min-side/tasks/search-recordings-by-isrc'
import mutation from 'min-side/graphql/mutations/create-release-with-recordings'
import HandlesSaveWithFlashMixin from 'echo-ember-common/mixins/handles-save-with-flash'
import ErrorFormater from 'min-side/mixins/error-formater'
import flashGeneralErrorMessage from 'min-side/mixins/flash-general-error-message'
import {
  getPerformancesForMutation,
  getRecordingsForMutation,
  getOwnershipsForMutation,
  getReleaseDataForMutation
} from 'min-side/extractors/new-release'
import { get } from '@ember/object'

const MAX_CONCURRENCY = 1

const modelKeys = [
  'releaseInfo',
  'recordings',
  'ownership',
  'performers',
  'performerCount',
  'tracksCount',
  'releaseYear'
]

export default Route.extend(
  ConfirmationMixin,
  HandlesSaveWithFlashMixin,
  ErrorFormater,
  flashGeneralErrorMessage,
  {
    apollo: queryManager(),
    intl: inject(),
    defaultModelData: null,
    modelKeys,
    formSuccessfullySubmitted: false,
    formSubmitted: false,
    model() {
      const formData = EmberObject.extend({
        releaseInfo: {
          title: undefined,
          main_artist: undefined,
          recording_label: undefined,
          catalog_number: undefined,
          alternative_catalog_numbers: [],
          release_date: null,
          recorded_in_country: undefined,
          genre: {
            id: undefined
          },
          format: undefined
        },
        recordings: A(),
        ownership: undefined,
        performers: A(),
        performerCount: 3,
        tracksCount: 2,
        releaseYear: undefined
      }).create()

      return this.apollo.watchQuery({ query }).then(model => {
        return EmberObject.extend({
          ...model,
          formData,
          errors: EmberObject.extend({
            recordings: A()
          }).create()
        }).create()
      })
    },
    activate() {
      this._super()
      this.resetView()
    },

    resetView() {
      if (document.getElementsByTagName('main')[0]) {
        document.getElementsByTagName('main')[0].scrollTo(0, 0)
      }
    },

    setupController(controller, model) {
      this.set('defaultModelData', model.getProperties(modelKeys))
      controller.setProperties({
        formData: model.formData,
        countries: model.countries,
        genres: model.genres,
        instruments: model.instruments,
        errors: model.errors,
        formSuccessfullySubmitted: false
      })
    },

    isPageDirty() {
      const changesExist = !isEqual(
        this.modelFor('user.new-release').getProperties(modelKeys),
        this.defaultModelData
      )

      return !this.formSuccessfullySubmitted && changesExist
    },

    confirmationMessage() {
      return this.intl.t('unsaved-changes-navigate-away')
    },

    actions: {
      saveReleaseInfo(releaseInfoData) {
        this.modelFor('user.new-release').set('releaseInfo', releaseInfoData)
        this.resetView()
      },

      saveRecordings(recordingsData) {
        this.modelFor('user.new-release').set('recordings', recordingsData)
        this.resetView()
      },

      saveOwnerships(ownershipsData) {
        this.modelFor('user.new-release').set('ownerships', ownershipsData)
      },

      savePerformers(performersData) {
        this.modelFor('user.new-release').set('performers', performersData)
        this.resetView()
      }
    },

    tFetchLabelsCollection,
    tSearchPerformers,
    tFetchArtistsCollection,
    tSearchRecordings,

    tSaveRelease: task(function* () {
      try {
        const returnValue = yield this.apollo.mutate({
          mutation,
          variables: { input: this.getMutationProperties() }
        })
        const errors = this.getErrorsFromReturnValue(returnValue)
        if (errors && errors.length) {
          const formattedErrors = this.formatErrors(errors)
          this.currentModel.set('errors', formattedErrors)
          this.flashGeneralErrorMessage(formattedErrors)
        } else {
          this.set('formSuccessfullySubmitted', true)
          this.transitionTo('user.edit-release', this.getIdFromReturnValue(returnValue))
          this.controller.set('formSuccessfullySubmitted', this.formSuccessfullySubmitted)
        }
      } catch (response) {
        this.set('formSubmitted', false)
        this.flashMessages.error(this.intl.t('flash.save.failed'))
      }
    })
      .enqueue()
      .maxConcurrency(MAX_CONCURRENCY),

    getIdFromReturnValue(returnValue) {
      return get(returnValue, 'create_release_with_recordings.release.id')
    },
    getErrorsFromReturnValue(returnValue) {
      return get(returnValue, 'create_release_with_recordings.errors')
    },

    getMutationProperties() {
      return {
        release: getReleaseDataForMutation(this.currentModel.releaseInfo),
        recordings: getRecordingsForMutation(this.currentModel.recordings),
        performances: getPerformancesForMutation(this.currentModel.performers),
        ownerships: getOwnershipsForMutation(this.currentModel.ownerships)
      }
    }
  }
)
