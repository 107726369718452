import { computed } from '@ember/object'
import Component from '@ember/component'
import { inject as service } from '@ember/service'
import Ember from 'ember'

export default Component.extend({
  cookies: service(),
  intercom: service(),
  tagName: 'footer',
  classNames: ['site-footer site-footer--for-cookies'],
  isVisible: computed({
    get() {
      if (this.__isVisible) {
        return this.__isVisible
      }
      return !this.get('cookies').isDismissed()
    },
    set(key, value) {
      this.__isVisible = value
      return value
    }
  }),

  actions: {
    dismiss() {
      this.set('isVisible', false)
      this.get('cookies').accept()
      if (!Ember.testing) {
        window.location.reload()
      }
    },
    refuse() {
      this.set('isVisible', false)
      this.get('cookies').refuse()
      if (!Ember.testing) {
        window.location.reload()
      }
    }
  }
})
