import Controller from '@ember/controller'
import { computed } from '@ember/object'
import { inject } from '@ember/service'
import { formatAlbum } from 'min-side/extractors/recording'
import { alias } from '@ember/object/computed'
import { isEmpty } from '@ember/utils'

export default Controller.extend({
  'data-test-recording-view': true,
  intl: inject(),
  profile: inject(),

  members_recording: alias('model.members_recording'),
  recording: alias('model.members_recording.recording'),
  claim_recording: alias('model.members_recording.claim_recordings.firstObject'),
  release: alias('recording.tracks.firstObject'),

  album: computed('claim_recording.album', 'release.release_title', function () {
    return this.get('release.release_title') || this.get('claim_recording.album')
  }),

  catalog_number: computed('claim_recording.catalog_number', 'release.catalog_number', function () {
    return this.get('release.catalog_number') || this.get('claim_recording.catalog_number')
  }),

  label_name: computed('claim_recording.label_name', 'recording.record_label', function () {
    return this.get('recording.record_label') || this.get('claim_recording.label_name')
  }),

  albumText: computed('recording.tracks.[]', 'intl.locale', function () {
    const { title, more } = formatAlbum(this.get('recording.tracks'))
    const translationKey = this._getAlbumTranslationKey(more)

    return this.intl.t(`recording.header.${translationKey}`, {
      firstTitle: title,
      numMore: more
    })
  }),

  hasPlaytime: computed('members_recording.total_playtime', function () {
    return !isEmpty(this.members_recording.total_playtime)
  }),

  hasOnlyRegistrations: computed('members_recording.claim_recordings.[]', function () {
    // check if all claim recordings are registrations (not connected to a claim)
    return this.members_recording.claim_recordings.every(claim_recording => !claim_recording.claim)
  }),

  registration: computed('members_recording.claim_recordings.[]', function () {
    // just find the first claim recording that is a "registration" (not connected to a claim)
    return this.members_recording.claim_recordings.find(claim_recording => !claim_recording.claim)
  }),

  myPerformance: computed(
    'recording.performances.[]',
    'claim_recording.performances.[]',
    function () {
      if (this.get('recording.performances')) {
        return this.get('recording.performances').find(
          p => p.public_performer.id === this.get('profile.activeProfile.id')
        )
      } else if (this.get('claim_recording.performances')) {
        return this.get('claim_recording.performances').find(
          p => p.public_performer.id === this.get('profile.activeProfile.id')
        )
      }
    }
  ),

  myOwnership: computed('recording.ownerships.[]', function () {
    if (this.get('recording.ownerships')) {
      return this.get('recording.ownerships').find(
        o =>
          o.shares.find(s => s.producer.id === this.get('profile.activeProfile.id')) &&
          o.active === true
      )
    }
  }),

  _getAlbumTranslationKey(amtItems) {
    if (amtItems === 1) return 'album_text_one_more'
    return amtItems >= 1 ? 'album_text_multiple' : 'album_text'
  }
})
