import Controller from '@ember/controller'
import { inject } from '@ember/service'
import { inject as injectController } from '@ember/controller'
import { computed } from '@ember/object'
import { isPresent } from '@ember/utils'

export default Controller.extend({
  profile: inject(),
  discographyController: injectController('user.discography'),
  matchesQuery(groupMembership) {
    const controllerQ = this.get('discographyController.q')
    const q = controllerQ ? controllerQ.toLocaleLowerCase() : ''
    return (
      groupMembership.group.name.toLocaleLowerCase().includes(q) ||
      groupMembership.group.nationality.name.toLocaleLowerCase().includes(q)
    )
  },
  showGroups: computed('model.user.person.performer.group_memberships', function () {
    const groups = this.get('model.user.person.performer.group_memberships')
    return isPresent(groups) && groups.length > 0
  }),
  groups: computed(
    'model.user.person.performer.group_memberships',
    'discographyController.q',
    function () {
      return this.get('model.user.person.performer.group_memberships').filter(groupMembership =>
        this.matchesQuery(groupMembership)
      )
    }
  )
})
