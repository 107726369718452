import Component from '@ember/component'
import { computed, get, set } from '@ember/object'
import { alias } from '@ember/object/computed'

/**
 * A bank information component responsible for the information about a single bank account
 * The component does not update the model directly, but will trigger the `onChange#1`
 * function when the data is changed
 *
 * @param account: The object containing the existing bank-info,
 *        will not be modified directly, but should be updated in `onChange#`
 * @param onChange#: This function will be called each time the value of the component changes,
 * for texts fields this is when a field loses focus
 */

export default Component.extend({
  'data-test-bank-info': true,
  tagName: '',
  supportsDataTestProperties: true,
  errorPath: '',
  disabled: false,

  accountType: alias('account.accountType'),
  bic: alias('account.bic'),
  bankName: alias('account.bank_name'),
  bankCode: alias('account.bank_code'),
  accountTypes: computed(() => ['blank', 'norwegian', 'iban', 'bic-swift', 'american']),

  actions: {
    onAccountTypeChange(accountType) {
      const onChange = this.onChange

      if (!get(this, 'account')) {
        return
      }
      if (onChange && typeof onChange === 'function') return onChange(accountType)

      set(this, 'account.accountType', accountType)
    }
  }
})
