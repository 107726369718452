const doc = {
  "kind": "Document",
  "definitions": [
    {
      "kind": "OperationDefinition",
      "operation": "query",
      "name": {
        "kind": "Name",
        "value": "Releases"
      },
      "variableDefinitions": [
        {
          "kind": "VariableDefinition",
          "variable": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "query"
            }
          },
          "type": {
            "kind": "NamedType",
            "name": {
              "kind": "Name",
              "value": "String"
            }
          },
          "directives": []
        },
        {
          "kind": "VariableDefinition",
          "variable": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "after"
            }
          },
          "type": {
            "kind": "NamedType",
            "name": {
              "kind": "Name",
              "value": "String"
            }
          },
          "directives": []
        },
        {
          "kind": "VariableDefinition",
          "variable": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "first"
            }
          },
          "type": {
            "kind": "NamedType",
            "name": {
              "kind": "Name",
              "value": "Int"
            }
          },
          "directives": []
        },
        {
          "kind": "VariableDefinition",
          "variable": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "person_id"
            }
          },
          "type": {
            "kind": "NamedType",
            "name": {
              "kind": "Name",
              "value": "ID"
            }
          },
          "directives": []
        },
        {
          "kind": "VariableDefinition",
          "variable": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "include_performer"
            }
          },
          "type": {
            "kind": "NonNullType",
            "type": {
              "kind": "NamedType",
              "name": {
                "kind": "Name",
                "value": "Boolean"
              }
            }
          },
          "directives": []
        },
        {
          "kind": "VariableDefinition",
          "variable": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "include_producer"
            }
          },
          "type": {
            "kind": "NonNullType",
            "type": {
              "kind": "NamedType",
              "name": {
                "kind": "Name",
                "value": "Boolean"
              }
            }
          },
          "directives": []
        },
        {
          "kind": "VariableDefinition",
          "variable": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "order"
            }
          },
          "type": {
            "kind": "ListType",
            "type": {
              "kind": "NonNullType",
              "type": {
                "kind": "NamedType",
                "name": {
                  "kind": "Name",
                  "value": "ReleaseSearchResultOrderInput"
                }
              }
            }
          },
          "directives": []
        }
      ],
      "directives": [],
      "selectionSet": {
        "kind": "SelectionSet",
        "selections": [
          {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "user"
            },
            "arguments": [],
            "directives": [],
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [
                {
                  "kind": "Field",
                  "name": {
                    "kind": "Name",
                    "value": "id"
                  },
                  "arguments": [],
                  "directives": []
                },
                {
                  "kind": "Field",
                  "name": {
                    "kind": "Name",
                    "value": "person"
                  },
                  "arguments": [
                    {
                      "kind": "Argument",
                      "name": {
                        "kind": "Name",
                        "value": "id"
                      },
                      "value": {
                        "kind": "Variable",
                        "name": {
                          "kind": "Name",
                          "value": "person_id"
                        }
                      }
                    }
                  ],
                  "directives": [],
                  "selectionSet": {
                    "kind": "SelectionSet",
                    "selections": [
                      {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "id"
                        },
                        "arguments": [],
                        "directives": []
                      },
                      {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "performer"
                        },
                        "arguments": [],
                        "directives": [
                          {
                            "kind": "Directive",
                            "name": {
                              "kind": "Name",
                              "value": "include"
                            },
                            "arguments": [
                              {
                                "kind": "Argument",
                                "name": {
                                  "kind": "Name",
                                  "value": "if"
                                },
                                "value": {
                                  "kind": "Variable",
                                  "name": {
                                    "kind": "Name",
                                    "value": "include_performer"
                                  }
                                }
                              }
                            ]
                          }
                        ],
                        "selectionSet": {
                          "kind": "SelectionSet",
                          "selections": [
                            {
                              "kind": "Field",
                              "name": {
                                "kind": "Name",
                                "value": "id"
                              },
                              "arguments": [],
                              "directives": []
                            },
                            {
                              "kind": "Field",
                              "name": {
                                "kind": "Name",
                                "value": "releases"
                              },
                              "arguments": [
                                {
                                  "kind": "Argument",
                                  "name": {
                                    "kind": "Name",
                                    "value": "query"
                                  },
                                  "value": {
                                    "kind": "Variable",
                                    "name": {
                                      "kind": "Name",
                                      "value": "query"
                                    }
                                  }
                                },
                                {
                                  "kind": "Argument",
                                  "name": {
                                    "kind": "Name",
                                    "value": "after"
                                  },
                                  "value": {
                                    "kind": "Variable",
                                    "name": {
                                      "kind": "Name",
                                      "value": "after"
                                    }
                                  }
                                },
                                {
                                  "kind": "Argument",
                                  "name": {
                                    "kind": "Name",
                                    "value": "first"
                                  },
                                  "value": {
                                    "kind": "Variable",
                                    "name": {
                                      "kind": "Name",
                                      "value": "first"
                                    }
                                  }
                                },
                                {
                                  "kind": "Argument",
                                  "name": {
                                    "kind": "Name",
                                    "value": "order_by"
                                  },
                                  "value": {
                                    "kind": "Variable",
                                    "name": {
                                      "kind": "Name",
                                      "value": "order"
                                    }
                                  }
                                }
                              ],
                              "directives": [],
                              "selectionSet": {
                                "kind": "SelectionSet",
                                "selections": [
                                  {
                                    "kind": "Field",
                                    "name": {
                                      "kind": "Name",
                                      "value": "total"
                                    },
                                    "arguments": [],
                                    "directives": []
                                  },
                                  {
                                    "kind": "Field",
                                    "name": {
                                      "kind": "Name",
                                      "value": "pageInfo"
                                    },
                                    "arguments": [],
                                    "directives": [],
                                    "selectionSet": {
                                      "kind": "SelectionSet",
                                      "selections": [
                                        {
                                          "kind": "FragmentSpread",
                                          "name": {
                                            "kind": "Name",
                                            "value": "PageInfoFragment"
                                          },
                                          "directives": []
                                        }
                                      ]
                                    }
                                  },
                                  {
                                    "kind": "Field",
                                    "name": {
                                      "kind": "Name",
                                      "value": "edges"
                                    },
                                    "arguments": [],
                                    "directives": [],
                                    "selectionSet": {
                                      "kind": "SelectionSet",
                                      "selections": [
                                        {
                                          "kind": "Field",
                                          "name": {
                                            "kind": "Name",
                                            "value": "node"
                                          },
                                          "arguments": [],
                                          "directives": [],
                                          "selectionSet": {
                                            "kind": "SelectionSet",
                                            "selections": [
                                              {
                                                "kind": "Field",
                                                "name": {
                                                  "kind": "Name",
                                                  "value": "id"
                                                },
                                                "arguments": [],
                                                "directives": []
                                              },
                                              {
                                                "kind": "Field",
                                                "name": {
                                                  "kind": "Name",
                                                  "value": "title"
                                                },
                                                "arguments": [],
                                                "directives": []
                                              },
                                              {
                                                "kind": "Field",
                                                "name": {
                                                  "kind": "Name",
                                                  "value": "main_artist"
                                                },
                                                "arguments": [],
                                                "directives": []
                                              },
                                              {
                                                "kind": "Field",
                                                "name": {
                                                  "kind": "Name",
                                                  "value": "record_label"
                                                },
                                                "arguments": [],
                                                "directives": []
                                              },
                                              {
                                                "kind": "Field",
                                                "name": {
                                                  "kind": "Name",
                                                  "value": "release_date"
                                                },
                                                "arguments": [],
                                                "directives": []
                                              },
                                              {
                                                "kind": "Field",
                                                "name": {
                                                  "kind": "Name",
                                                  "value": "catalog_number"
                                                },
                                                "arguments": [],
                                                "directives": []
                                              },
                                              {
                                                "kind": "Field",
                                                "name": {
                                                  "kind": "Name",
                                                  "value": "format"
                                                },
                                                "arguments": [],
                                                "directives": []
                                              }
                                            ]
                                          }
                                        }
                                      ]
                                    }
                                  }
                                ]
                              }
                            }
                          ]
                        }
                      },
                      {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "personal_producer"
                        },
                        "arguments": [],
                        "directives": [
                          {
                            "kind": "Directive",
                            "name": {
                              "kind": "Name",
                              "value": "include"
                            },
                            "arguments": [
                              {
                                "kind": "Argument",
                                "name": {
                                  "kind": "Name",
                                  "value": "if"
                                },
                                "value": {
                                  "kind": "Variable",
                                  "name": {
                                    "kind": "Name",
                                    "value": "include_producer"
                                  }
                                }
                              }
                            ]
                          }
                        ],
                        "selectionSet": {
                          "kind": "SelectionSet",
                          "selections": [
                            {
                              "kind": "Field",
                              "name": {
                                "kind": "Name",
                                "value": "id"
                              },
                              "arguments": [],
                              "directives": []
                            },
                            {
                              "kind": "Field",
                              "name": {
                                "kind": "Name",
                                "value": "releases"
                              },
                              "arguments": [
                                {
                                  "kind": "Argument",
                                  "name": {
                                    "kind": "Name",
                                    "value": "query"
                                  },
                                  "value": {
                                    "kind": "Variable",
                                    "name": {
                                      "kind": "Name",
                                      "value": "query"
                                    }
                                  }
                                },
                                {
                                  "kind": "Argument",
                                  "name": {
                                    "kind": "Name",
                                    "value": "after"
                                  },
                                  "value": {
                                    "kind": "Variable",
                                    "name": {
                                      "kind": "Name",
                                      "value": "after"
                                    }
                                  }
                                },
                                {
                                  "kind": "Argument",
                                  "name": {
                                    "kind": "Name",
                                    "value": "first"
                                  },
                                  "value": {
                                    "kind": "Variable",
                                    "name": {
                                      "kind": "Name",
                                      "value": "first"
                                    }
                                  }
                                },
                                {
                                  "kind": "Argument",
                                  "name": {
                                    "kind": "Name",
                                    "value": "order_by"
                                  },
                                  "value": {
                                    "kind": "Variable",
                                    "name": {
                                      "kind": "Name",
                                      "value": "order"
                                    }
                                  }
                                }
                              ],
                              "directives": [],
                              "selectionSet": {
                                "kind": "SelectionSet",
                                "selections": [
                                  {
                                    "kind": "Field",
                                    "name": {
                                      "kind": "Name",
                                      "value": "total"
                                    },
                                    "arguments": [],
                                    "directives": []
                                  },
                                  {
                                    "kind": "Field",
                                    "name": {
                                      "kind": "Name",
                                      "value": "pageInfo"
                                    },
                                    "arguments": [],
                                    "directives": [],
                                    "selectionSet": {
                                      "kind": "SelectionSet",
                                      "selections": [
                                        {
                                          "kind": "FragmentSpread",
                                          "name": {
                                            "kind": "Name",
                                            "value": "PageInfoFragment"
                                          },
                                          "directives": []
                                        }
                                      ]
                                    }
                                  },
                                  {
                                    "kind": "Field",
                                    "name": {
                                      "kind": "Name",
                                      "value": "edges"
                                    },
                                    "arguments": [],
                                    "directives": [],
                                    "selectionSet": {
                                      "kind": "SelectionSet",
                                      "selections": [
                                        {
                                          "kind": "Field",
                                          "name": {
                                            "kind": "Name",
                                            "value": "node"
                                          },
                                          "arguments": [],
                                          "directives": [],
                                          "selectionSet": {
                                            "kind": "SelectionSet",
                                            "selections": [
                                              {
                                                "kind": "Field",
                                                "name": {
                                                  "kind": "Name",
                                                  "value": "id"
                                                },
                                                "arguments": [],
                                                "directives": []
                                              },
                                              {
                                                "kind": "Field",
                                                "name": {
                                                  "kind": "Name",
                                                  "value": "title"
                                                },
                                                "arguments": [],
                                                "directives": []
                                              },
                                              {
                                                "kind": "Field",
                                                "name": {
                                                  "kind": "Name",
                                                  "value": "main_artist"
                                                },
                                                "arguments": [],
                                                "directives": []
                                              },
                                              {
                                                "kind": "Field",
                                                "name": {
                                                  "kind": "Name",
                                                  "value": "record_label"
                                                },
                                                "arguments": [],
                                                "directives": []
                                              },
                                              {
                                                "kind": "Field",
                                                "name": {
                                                  "kind": "Name",
                                                  "value": "release_date"
                                                },
                                                "arguments": [],
                                                "directives": []
                                              },
                                              {
                                                "kind": "Field",
                                                "name": {
                                                  "kind": "Name",
                                                  "value": "catalog_number"
                                                },
                                                "arguments": [],
                                                "directives": []
                                              },
                                              {
                                                "kind": "Field",
                                                "name": {
                                                  "kind": "Name",
                                                  "value": "format"
                                                },
                                                "arguments": [],
                                                "directives": []
                                              }
                                            ]
                                          }
                                        }
                                      ]
                                    }
                                  }
                                ]
                              }
                            }
                          ]
                        }
                      },
                      {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "contact"
                        },
                        "arguments": [],
                        "directives": [],
                        "selectionSet": {
                          "kind": "SelectionSet",
                          "selections": [
                            {
                              "kind": "Field",
                              "name": {
                                "kind": "Name",
                                "value": "id"
                              },
                              "arguments": [],
                              "directives": []
                            },
                            {
                              "kind": "Field",
                              "name": {
                                "kind": "Name",
                                "value": "positions"
                              },
                              "arguments": [],
                              "directives": [],
                              "selectionSet": {
                                "kind": "SelectionSet",
                                "selections": [
                                  {
                                    "kind": "Field",
                                    "name": {
                                      "kind": "Name",
                                      "value": "description"
                                    },
                                    "arguments": [],
                                    "directives": []
                                  },
                                  {
                                    "kind": "Field",
                                    "name": {
                                      "kind": "Name",
                                      "value": "organization"
                                    },
                                    "arguments": [],
                                    "directives": [],
                                    "selectionSet": {
                                      "kind": "SelectionSet",
                                      "selections": [
                                        {
                                          "kind": "Field",
                                          "name": {
                                            "kind": "Name",
                                            "value": "id"
                                          },
                                          "arguments": [],
                                          "directives": []
                                        },
                                        {
                                          "kind": "Field",
                                          "name": {
                                            "kind": "Name",
                                            "value": "producer_company"
                                          },
                                          "arguments": [],
                                          "directives": [],
                                          "selectionSet": {
                                            "kind": "SelectionSet",
                                            "selections": [
                                              {
                                                "kind": "Field",
                                                "name": {
                                                  "kind": "Name",
                                                  "value": "id"
                                                },
                                                "arguments": [],
                                                "directives": []
                                              },
                                              {
                                                "kind": "Field",
                                                "name": {
                                                  "kind": "Name",
                                                  "value": "releases"
                                                },
                                                "arguments": [
                                                  {
                                                    "kind": "Argument",
                                                    "name": {
                                                      "kind": "Name",
                                                      "value": "query"
                                                    },
                                                    "value": {
                                                      "kind": "Variable",
                                                      "name": {
                                                        "kind": "Name",
                                                        "value": "query"
                                                      }
                                                    }
                                                  },
                                                  {
                                                    "kind": "Argument",
                                                    "name": {
                                                      "kind": "Name",
                                                      "value": "after"
                                                    },
                                                    "value": {
                                                      "kind": "Variable",
                                                      "name": {
                                                        "kind": "Name",
                                                        "value": "after"
                                                      }
                                                    }
                                                  },
                                                  {
                                                    "kind": "Argument",
                                                    "name": {
                                                      "kind": "Name",
                                                      "value": "first"
                                                    },
                                                    "value": {
                                                      "kind": "Variable",
                                                      "name": {
                                                        "kind": "Name",
                                                        "value": "first"
                                                      }
                                                    }
                                                  },
                                                  {
                                                    "kind": "Argument",
                                                    "name": {
                                                      "kind": "Name",
                                                      "value": "order_by"
                                                    },
                                                    "value": {
                                                      "kind": "Variable",
                                                      "name": {
                                                        "kind": "Name",
                                                        "value": "order"
                                                      }
                                                    }
                                                  }
                                                ],
                                                "directives": [],
                                                "selectionSet": {
                                                  "kind": "SelectionSet",
                                                  "selections": [
                                                    {
                                                      "kind": "Field",
                                                      "name": {
                                                        "kind": "Name",
                                                        "value": "total"
                                                      },
                                                      "arguments": [],
                                                      "directives": []
                                                    },
                                                    {
                                                      "kind": "Field",
                                                      "name": {
                                                        "kind": "Name",
                                                        "value": "pageInfo"
                                                      },
                                                      "arguments": [],
                                                      "directives": [],
                                                      "selectionSet": {
                                                        "kind": "SelectionSet",
                                                        "selections": [
                                                          {
                                                            "kind": "FragmentSpread",
                                                            "name": {
                                                              "kind": "Name",
                                                              "value": "PageInfoFragment"
                                                            },
                                                            "directives": []
                                                          }
                                                        ]
                                                      }
                                                    },
                                                    {
                                                      "kind": "Field",
                                                      "name": {
                                                        "kind": "Name",
                                                        "value": "edges"
                                                      },
                                                      "arguments": [],
                                                      "directives": [],
                                                      "selectionSet": {
                                                        "kind": "SelectionSet",
                                                        "selections": [
                                                          {
                                                            "kind": "Field",
                                                            "name": {
                                                              "kind": "Name",
                                                              "value": "node"
                                                            },
                                                            "arguments": [],
                                                            "directives": [],
                                                            "selectionSet": {
                                                              "kind": "SelectionSet",
                                                              "selections": [
                                                                {
                                                                  "kind": "Field",
                                                                  "name": {
                                                                    "kind": "Name",
                                                                    "value": "id"
                                                                  },
                                                                  "arguments": [],
                                                                  "directives": []
                                                                },
                                                                {
                                                                  "kind": "Field",
                                                                  "name": {
                                                                    "kind": "Name",
                                                                    "value": "title"
                                                                  },
                                                                  "arguments": [],
                                                                  "directives": []
                                                                },
                                                                {
                                                                  "kind": "Field",
                                                                  "name": {
                                                                    "kind": "Name",
                                                                    "value": "main_artist"
                                                                  },
                                                                  "arguments": [],
                                                                  "directives": []
                                                                },
                                                                {
                                                                  "kind": "Field",
                                                                  "name": {
                                                                    "kind": "Name",
                                                                    "value": "record_label"
                                                                  },
                                                                  "arguments": [],
                                                                  "directives": []
                                                                },
                                                                {
                                                                  "kind": "Field",
                                                                  "name": {
                                                                    "kind": "Name",
                                                                    "value": "release_date"
                                                                  },
                                                                  "arguments": [],
                                                                  "directives": []
                                                                },
                                                                {
                                                                  "kind": "Field",
                                                                  "name": {
                                                                    "kind": "Name",
                                                                    "value": "catalog_number"
                                                                  },
                                                                  "arguments": [],
                                                                  "directives": []
                                                                },
                                                                {
                                                                  "kind": "Field",
                                                                  "name": {
                                                                    "kind": "Name",
                                                                    "value": "format"
                                                                  },
                                                                  "arguments": [],
                                                                  "directives": []
                                                                }
                                                              ]
                                                            }
                                                          }
                                                        ]
                                                      }
                                                    }
                                                  ]
                                                }
                                              }
                                            ]
                                          }
                                        }
                                      ]
                                    }
                                  }
                                ]
                              }
                            }
                          ]
                        }
                      }
                    ]
                  }
                }
              ]
            }
          }
        ]
      }
    }
  ],
  "loc": {
    "start": 0,
    "end": 1970
  }
};
export default doc;
import dep0 from 'min-side/graphql/fragments/page-info';
doc.definitions = doc.definitions.concat(dep0.definitions);
