import Route from '@ember/routing/route'

export default class ChangeRequestIndexRoute extends Route {
  /**
   * The sole purpose of this is to not allow anyone to go to `/change_request` since this does nothing
   * @param transition
   * @returns {Transition}
   */
  beforeModel(transition) {
    if (this.routeName === transition.to.name) {
      transition.abort()
      return this.replaceWith('/not-found')
    }
  }
}
