import Route from '@ember/routing/route'
import { inject as service } from '@ember/service'
import { queryManager } from 'ember-apollo-client'
import { get, getWithDefault } from '@ember/object'
import { reads } from '@ember/object/computed'
import { ACCOUNT_TYPES } from 'min-side/constants/account-types'
import { getGramoMembership } from 'min-side/helpers/memberships'
import { getActiveOrganization } from 'min-side/helpers/organization'
import { PROFILE_TYPES } from 'min-side/constants/profile'

const { PERFORMER, PERSONAL_PRODUCER, INHERITANCE_PARTY, AGENCY, PRODUCER_COMPANY } = PROFILE_TYPES

// todo[sergiu]: all the code related to retrieving the bank account, membership and federation
//  membership found in this class should be moved to profile service, at the time of writing this code
//  a PR(https://github.com/gramo-org/min-side/pull/1374) is being worked on, once finished it should simplify the way
//  the service exposes certain profile related properties

export default class ProfileInfoRouteBankAndMembership extends Route {
  @queryManager apollo
  @service profileInfo

  @reads('profileInfo.currentProfileName')
  currentProfile
  @reads('profileInfo.currentProfileType')
  currentProfileType

  /**
   * Given a model this function will attempt to extract and return the bank account object for current active profile
   * @param model
   * @returns {{accountType: *}|{accountType: string, currency: string}|null}
   */
  getCurrentActiveProfileBankAccount(model) {
    return this._getBankAccountObjectWithType(model)
  }

  // get the object representing the current active profile
  getCurrentActiveProfileObject(model) {
    const profileType = this.currentProfileType || this.currentProfile
    switch (profileType) {
      case PERSONAL_PRODUCER:
        return get(model, 'user.person.personal_producer')
      case PERFORMER:
        return get(model, 'user.person.performer')
      case INHERITANCE_PARTY:
        return get(model, 'user.person.personal_inheritance_party')
      case PRODUCER_COMPANY:
        return this._getProducerCompanyObject(model)
      case AGENCY:
        return this._getAgencyObject(model)
      default:
        throw new Error(`Failed to get object for profile type "${profileType}".`)
    }
  }

  getCurrentActiveProfileMembershipObject(model) {
    const profileObject = this.getCurrentActiveProfileObject(model)
    return getGramoMembership(profileObject.society_memberships)
  }

  getCurrentActiveProfileFederationMembership(model) {
    const profileObject = this.getCurrentActiveProfileObject(model)
    return get(profileObject, 'federation_memberships')
  }

  _getProducerCompanyObject(model) {
    const id = this.profileInfo.activeProfile.id
    const organization = getActiveOrganization(get(model, 'user.person.contact.positions'), id)
    return organization.producer_company
  }

  _getAgencyObject(model) {
    const id = this.profileInfo.activeProfile.id
    const organization = getActiveOrganization(get(model, 'user.person.contact.positions'), id)
    return organization.agency
  }

  _getBankAccountObjectWithType(model) {
    const activeProfileObject = this.getCurrentActiveProfileObject(model)

    let bankAccount = null
    // An agency has the bank account in the agency object
    if (activeProfileObject && activeProfileObject.__typename === 'Agency') {
      bankAccount = activeProfileObject.bank_account
    } else {
      // everything else has bank account in gramo society membership
      const gramoMembership = this.getCurrentActiveProfileMembershipObject(model)
      if (!gramoMembership) {
        // if there is no membership object don't return a bank account
        return null
      }
      bankAccount = get(gramoMembership, 'bank_account')
    }

    if (bankAccount) {
      return {
        ...bankAccount,
        accountType: ACCOUNT_TYPES[getWithDefault(bankAccount, '__typename', 'blank')]
      }
    }
    // if they have gramo membership with no bank info, or are an agency with no bank info then return a blank account
    return { accountType: 'blank', currency: 'NOK' }
  }
}
