import Component from '@ember/component'
import { get, computed } from '@ember/object'
import { not } from '@ember/object/computed'
import { getCitizenshipCountryNames, getBirthYear } from 'min-side/extractors/performer'

export default Component.extend({
  tagName: 'tr',
  classNameBindings: ['clickable:row--clickable', 'disabled:table__row--disabled'],

  'data-test-search-performers-entry': true,

  fullName: computed('performer', function () {
    return `${get(this, 'performer.first_name')} ${get(this, 'performer.last_name')}`
  }),

  citizenships: computed('performer.current_citizenships', function () {
    return getCitizenshipCountryNames(this.performer)
  }),

  birthYear: computed('performer.born_on', function () {
    return getBirthYear(this.performer)
  }),

  disabled: computed('performer.id', 'excludedIds', function () {
    if (!this.excludedIds) return false
    return this.excludedIds.includes(this.get('performer.id'))
  }),

  clickable: not('disabled'),

  click() {
    if (this.disabled) return
    this.addPerformerAction(this.performer)
  }
})
