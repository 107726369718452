import Controller from '@ember/controller'
import { getJWTPayload } from 'echo-ember-common/utils/jtw-helpers'
import { computed } from '@ember/object'

export default class LoginChooseMobileController extends Controller {
  queryParams = ['token']

  @computed('token')
  get mobileNumbers() {
    const payload = getJWTPayload(this.token)
    return [
      { type: 'main', number: payload.data.user.mobile },
      { type: 'alternative', number: payload.data.user.alternative_mobile }
    ]
  }
}
