import Component from '@ember/component'
import { assert } from '@ember/debug'
import { isPresent } from '@ember/utils'

export default Component.extend({
  tagName: 'main',

  init(...args) {
    this._super(...args)

    assert(
      'Component `invalid-invitation-token` must have `cancelAction` closure action passed',
      isPresent(this.cancelAction)
    )
  },

  actions: {
    cancel() {
      this.cancelAction()
    }
  }
})
