import Component from '@ember/component'
import Changeset from 'ember-changeset'
import lookupValidator from 'ember-changeset-validations'
import RecordingValidations from 'min-side/validations/new-release/recording'
import EmberObject from '@ember/object'
import { NEW_RELEASE_FORM_STEPS } from 'min-side/constants/new-release-steps'
import { inject } from '@ember/service'
import { equal, not } from '@ember/object/computed'
import { resolve } from 'rsvp'

const { RECORDINGS_STEP } = NEW_RELEASE_FORM_STEPS
const convertedValidator = lookupValidator(RecordingValidations)

export default Component.extend({
  tagName: 'section',
  'data-test-create-release-recordings': true,

  eventBus: inject(),
  intl: inject(),

  disabled: false,

  isCurrentStep: equal('currentStep', RECORDINGS_STEP),
  notCurrentStep: not('isCurrentStep'),

  disableSave: equal('recordings.length', 0),

  init(...args) {
    this._super(...args)

    this._initSearches()
    this._addSearchToCollection()
  },

  actions: {
    addSearch() {
      this._addSearchToCollection()
    },

    addRecording(recording) {
      this.recordings.pushObject(this._prepareRecordingChangeset(recording))
    },

    removeRecording(recording) {
      this.recordings.removeObject(recording)
    },

    removeSearch(search) {
      this.searches.removeObject(search)
    },

    edit() {
      this._enableInputs()
    },

    async save() {
      this._save()
      return resolve(true)
    },

    rollback() {
      this._rollback()
    },

    submit() {
      if (this._save()) this.stepForwardAction()
    },

    stepBack() {
      this._rollback()
      this.eventBus.publish('stepBackToReleaseInformation')
      this.stepBackAction()
    }
  },

  _save() {
    this._validate()

    if (this._isInvalid()) {
      this._mapValidationErrors()
      return false
    }

    this.recordings.forEach(r => {
      r.set('saved', true)
      r.save()
    })
    this.saveRecordingsAction(this.recordings)
    this._disableInputs()
    return true
  },

  _rollback() {
    this.recordings.forEach(r => r.rollback())
    this._disableInputs()
  },

  _validate() {
    const recordings = this.recordings
    recordings.forEach(r => {
      r.set('mappedErrors', { errors: [] }) // clean mapped errors
      r.validate()
    })
  },

  _isInvalid() {
    return this.recordings.any(r => r.get('isInvalid'))
  },

  _mapValidationErrors() {
    this.recordings.forEach(changeset => {
      const errors = []
      changeset.get('errors').forEach(error => {
        if (error.validation && error.validation.length) {
          error.validation.forEach(v => {
            errors.push(
              EmberObject.create({
                attribute: error.key,
                message: this.intl.t(v)
              })
            )
          })
        }
      })
      changeset.set('mappedErrors', { errors })
    })
  },

  _enableInputs() {
    this.set('disabled', false)
    this.updateFormEditStatusAction(RECORDINGS_STEP, true)
  },

  _disableInputs() {
    this._clearSearches()
    this._clearUnsavedRecordings()
    this.set('disabled', true)
    this.updateFormEditStatusAction(RECORDINGS_STEP, false)
  },

  _clearSearches() {
    this.set('searches', [])
  },

  _clearUnsavedRecordings() {
    const savedRecordings = this.recordings.reject(recording => !recording.get('saved'))
    this.set('recordings', savedRecordings)
  },

  _prepareRecordingChangeset(recording) {
    return new Changeset(
      {
        number_on_side: '',
        side: '',
        ...recording,
        mappedErrors: [],
        saved: false
      },
      options => {
        const duplicationCheckResults = this._checkForDuplicateNumberOnSide(options)
        if (typeof duplicationCheckResults === 'object') {
          return duplicationCheckResults
        }
        return convertedValidator(options)
      },
      RecordingValidations
    )
  },
  /**
   * This method checks if there are any recordings on the same side with same number on side values
   * @param key
   * @param newValue
   * @param changes
   * @param content
   * @returns {Array|Boolean}
   * @private
   */
  _checkForDuplicateNumberOnSide({ key, newValue, changes, content }) {
    const recordings = this.recordings
    if (key !== 'number_on_side') return true
    let returnValue
    recordings.forEach(recording => {
      if (content.isrc !== recording.get('isrc')) {
        const side = changes.side || content.side
        if (side === recording.get('side') && newValue === recording.get('number_on_side')) {
          returnValue = ['validation.number-on-side-has-to-be-unique']
        }
      }
    })
    return returnValue || true
  },
  _initSearches() {
    this.set('searches', [])
  },

  _addSearchToCollection() {
    this.searches.pushObject({
      isrc: undefined,
      candidates: []
    })
  }
})
