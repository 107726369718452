import { inject as service } from '@ember/service'
import Route from '@ember/routing/route'
import { urlFor } from 'min-side/utils/url-helpers'
import UnauthenticatedRouteMixin from 'ember-simple-auth/mixins/unauthenticated-route-mixin'

const HTTP_NOT_FOUND = 404

export default Route.extend(UnauthenticatedRouteMixin, {
  intl: service(),
  ajaxRequests: service(),
  actions: {
    submit() {
      this.controller.invalidEmail = false
      this.controller.unknownError = false
      const ajaxRequests = this.get('ajaxRequests')
      return ajaxRequests
        .postRequest(urlFor('admin/users/request-reset'), { email: this.controller.get('email') })
        .then(res => {
          if (!res.ok) {
            throw res
          }
          this.transitionTo('check-email', { queryParams: { email: this.controller.get('email') } })
        })
        .catch(err => {
          if (err.status === HTTP_NOT_FOUND) {
            this.controller.invalidEmail = true
          } else {
            this.controller.unknownError = true
          }
        })
    }
  }
})
