import Component from '@ember/component'
import { once } from '@ember/runloop'

export default Component.extend({
  'data-test-edit-recording-multiple-input': true,

  actions: {
    deleteItem(obj) {
      this.set(
        'objects',
        this.objects.filter(o => o !== obj)
      )
      this.onChange(this.objects)
    },

    createItem() {
      this.set('objects', [...this.objects, this._createObject()])
      this.onChange(this.objects)
    },

    inputValueChanged(obj, keyName, newValue) {
      once(() => {
        const newObj = { ...obj, [keyName]: newValue }
        const newObjects = this.objects.map(item => {
          let newItem = item
          if (item === obj) newItem = newObj
          return newItem
        })
        this.set('objects', newObjects)
        this.onChange(this.objects)
      })
    }
  },

  _createObject() {
    const key = this.keyName
    return { [key]: '', description: '' }
  }
})
