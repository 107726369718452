import Component from '@glimmer/component'
import { action } from '@ember/object'
import { inject as service } from '@ember/service'

export default class PaymentsDashlet extends Component {
  @service router
  @service intl
  @service profile

  get title() {
    if (this.args.profile === 'producer') {
      return this.intl.t('dashlet.missing-bank.producer-title')
    }
    return this.intl.t('dashlet.missing-bank.performer-title')
  }

  get description() {
    if (this.args.profile === 'producer') {
      return this.intl.t('dashlet.missing-bank.producer-description')
    }
    return this.intl.t('dashlet.missing-bank.performer-description')
  }

  @action
  async goToBank() {
    let profileName = 'performer'
    if (this.args.profile === 'producer') {
      profileName = 'personal-producer'
    }
    const profile = await this.profile.selectableProfileObjects.find(
      profile => profile.profile === profileName
    )
    if (profile) {
      await this.profile.switchTo(profile)
    }
    this.router.transitionTo('user.profile-info.bank')
  }
}
