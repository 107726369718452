import Service from '@ember/service'
import userQuery from 'min-side/graphql/queries/user'
import { queryManager } from 'ember-apollo-client'
import { task } from 'ember-concurrency'
import { inject } from '@ember/service'
import { profileNameInModel } from 'min-side/helpers/profiles'
import { serializeBankAccount } from 'min-side/graphql/helpers/bank-info'
import EmberObject, { computed, get, set } from '@ember/object'
import { reads } from '@ember/object/computed'

const serializeMembership = membership => {
  if (membership && membership.bank_account) {
    serializeBankAccount(membership.bank_account)
  }
}

export default Service.extend({
  apollo: queryManager(),
  profile: inject(),
  maskId: reads('profile.impersonatedUserId'),
  profileName: reads('profile.activeProfile.profile'),

  includePerformer: computed('profileName', 'maskId', function () {
    if (!this.maskId) return true
    return this.profileName === 'performer'
  }),

  includeProducer: computed('profileName', 'maskId', function () {
    if (!this.maskId) return true
    return this.profileName === 'personal-producer'
  }),

  userTask: task(function* (includePerformer = false, includeProducer = false) {
    const variables = {
      person_id: this.maskId,
      include_performer: includePerformer || this.includePerformer,
      include_producer: includeProducer || this.includeProducer,
      states: ['INITIAL', 'READY_FOR_SIGNING']
    }
    const model = EmberObject.extend({
      ...(yield this.apollo.watchQuery({ query: userQuery, variables }))
    }).create()

    this._serializeUserModel(model)
    set(model, 'errors', [])
    return model
  }),

  _serializeUserModel(model) {
    const user = get(model, 'user')

    if (user && user.person) {
      const profiles = get(model, 'user.person.profiles').map(p => p.toLowerCase())
      profiles.forEach(profile => {
        if (!get(model, `user.person.${profileNameInModel(profile)}`)) {
          return
        }
        if (profile === 'contact') {
          get(model, 'user.person.contact.positions').forEach(position => {
            if (position.organization.producer_company) {
              position.organization.producer_company.society_memberships.map(serializeMembership)
            } else if (position.organization.agency) {
              serializeBankAccount(position.organization.agency.bank_account)
            }
          })
        } else if (profile === 'inheritance_party') {
          get(model, `user.person.personal_inheritance_party.society_memberships`).map(
            serializeMembership
          )
        } else {
          get(model, `user.person.${profile}.society_memberships`).map(serializeMembership)
        }
      })
    }
  }
})
