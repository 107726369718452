import Route from '@ember/routing/route'
import { inject as service } from '@ember/service'
import { queryManager } from 'ember-apollo-client'
import { get } from '@ember/object'
import { reads } from '@ember/object/computed'
import { getQueryByProfileType } from 'min-side/graphql/helpers/profile-info/queries'

import producerCompanyQuery from 'min-side/graphql/queries/profile/producer-company'
import { getActiveOrganization } from 'min-side/helpers/organization'

export default class ProfileInfoRegistrantCodesRoute extends Route {
  @queryManager apollo
  @service profile

  @reads('profile.activeProfile.profile')
  profileType

  async model() {
    if (this.profileType === 'contact') {
      return await this.modelForContact()
    }
    const impersonatedUserId = get(this, 'profile.impersonatedUserId')
    const variables = impersonatedUserId ? { person_id: impersonatedUserId } : null

    const query = getQueryByProfileType(this.profileType)

    const response = await this.apollo.watchQuery({ query, variables, fetchPolicy: 'network-only' })

    return get(response, 'user.person.personal_producer.registrant_codes')
  }

  async modelForContact() {
    const id = this.get('profile.activeProfile.id')
    const variables = { id }

    const response = await this.apollo.watchQuery({
      query: producerCompanyQuery,
      variables,
      fetchPolicy: 'network-only'
    })

    return get(
      getActiveOrganization(response.user.person.contact.positions, id),
      'producer_company.registrant_codes'
    )
  }
}
