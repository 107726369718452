import RavenLogger from 'ember-cli-sentry/services/raven'

export default RavenLogger.extend({
  unhandledPromiseErrorMessage: 'unhandledPromiseErrorMessage',

  captureException(...args) {
    this._super(...args)
  },

  captureMessage(...args) {
    return this._super(...args)
  },

  enableGlobalErrorCatching(...args) {
    return this._super(...args)
  },

  ignoreError(error) {
    return (
      // Ignore transition aborted errors. We get them in router, when we abort transitions.
      // global error catching catches these.
      error.ignoreByRaven || (error.message && error.message === 'TransitionAborted')
    )
  },

  callRaven(...args) {
    return this._super(...args)
  }
})
