const SEPARATOR_CONTENT = '...'
const rangeWithDots = params => {
  // eslint-disable-next-line no-magic-numbers
  const [currentPage, pageCount, delta = 2] = params
  const separate = (a, b) => [
    a,
    ...({
      0: [],
      1: [b],
      2: [a + 1, b]
    }[b - a] || [SEPARATOR_CONTENT, b])
  ]

  // eslint-disable-next-line no-magic-numbers
  return Array(delta * 2 + 1)
    .fill()
    .map((_, index) => currentPage - delta + index)
    .filter(page => page > 0 && page <= pageCount)
    .flatMap((page, index, { length }) => {
      if (!index) return separate(1, page)
      if (index === length - 1) return separate(page, pageCount)

      return [page]
    })
}
export { rangeWithDots, SEPARATOR_CONTENT }
