import Component from '@glimmer/component'
import { tracked } from '@glimmer/tracking'
import { action } from '@ember/object'
import { queryManager } from 'ember-apollo-client'
import { restartableTask } from 'ember-concurrency-decorators'
import { inject as service } from '@ember/service'
import AnnualIncomeStatementsQuery from 'min-side/graphql/queries/annual-income-statements'

export default class AnnualStatementsDashlet extends Component {
  @service router
  @queryManager apollo

  @tracked data = { annual_income_statements: [] }

  get hasStatements() {
    return this.data.annual_income_statements.length > 0
  }

  get noStatements() {
    return !this.hasStatements
  }

  get loadStatementsTask() {
    this.loadStatements.perform()
    return this.loadStatements
  }

  @restartableTask *loadStatements() {
    const result = yield this.apollo.watchQuery({
      query: AnnualIncomeStatementsQuery
    })
    this.data = result
  }

  @action
  goToTaxReports() {
    this.router.transitionTo('user.profile-info.tax-reports')
  }
}
