import Component from '@glimmer/component'
import { action } from '@ember/object'
import { tracked } from '@glimmer/tracking'

export default class LeftSideNavigation extends Component {
  @tracked
  showDropDown = false

  @action
  toggleDropdown() {
    this.showDropDown = !this.showDropDown
  }
  @action
  onClickInsideDropdown(event) {
    event.preventDefault()
    this.showDropDown = false
  }
}
