export default {
  "new-global-search": "_new-global-search_12qjxn",
  "inner-container": "_inner-container_12qjxn",
  "dashboard": "_dashboard_12qjxn",
  "dashboard-body": "_dashboard-body_12qjxn",
  "placeholder": "_placeholder_12qjxn",
  "message": "_message_12qjxn",
  "discography-dashlets": "_discography-dashlets_12qjxn",
  "money-dashlets": "_money-dashlets_12qjxn",
  "payment-dashlets": "_payment-dashlets_12qjxn",
  "graph-dashlet": "_graph-dashlet_12qjxn",
  "played-dashlet": "_played-dashlet_12qjxn"
};
