import Component from '@ember/component'
import { computed } from '@ember/object'
import { isPresent } from '@ember/utils'

export default Component.extend({
  'data-test-releases-listing': true,
  tagName: 'tbody',

  showReleases: computed('releases.edges', function () {
    const releases = this.get('releases.edges')
    return isPresent(releases) && releases.length > 0
  })
})
