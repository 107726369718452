import Component from '@ember/component'
import { computed } from '@ember/object'

export default Component.extend({
  tagName: '',
  supportsDataTestProperties: true,
  recordingLink: computed('diff', function () {
    if (this.diff) {
      return 'user.ledger.remuneration.by-recording-diff'
    }
    return 'user.ledger.remuneration.by-recording'
  }),
  broadcastLink: computed('diff', function () {
    if (this.diff) {
      return 'user.ledger.remuneration.by-broadcaster-diff'
    }
    return 'user.ledger.remuneration.by-broadcaster'
  })
})
