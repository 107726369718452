import { inject as service } from '@ember/service'
import BaseGlobalSearchResultsRoute from 'min-side/abstracts/routes/global-search-results-route'
import { getReleases } from 'min-side/extractors/global-search'
import query from 'min-side/graphql/queries/global-search/releases'

export default class GlobalSearchResultsReleasesIndexRoute extends BaseGlobalSearchResultsRoute {
  queryParams = []
  @service profile
  renderTemplate(controller, model) {
    super.renderTemplate(controller, model)

    this.render('user.global-search-results.pagination', {
      outlet: 'pagination',
      into: 'user.global-search-results.releases',
      controller
    })
  }
  async model() {
    const baseQueryParams = this.paramsFor('user.global-search-results')
    const parrentQueryParams = this.paramsFor('user.global-search-results.releases')
    const variables = {
      ...baseQueryParams,
      ...parrentQueryParams,
      order_by: this._orderByFromUserOrDefault(parrentQueryParams),
      rightsholder_id: this.profile.current.meta.id,
      sector: this.profile.current.meta.sector
    }

    return getReleases(await this.apollo.query({ query, variables, fetchPolicy: 'network-only' }))
  }
}
