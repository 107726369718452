import Component from '@ember/component'
import Changeset from 'ember-changeset'
import ProducerValidations from 'min-side/validations/edit-recording/producer'
import lookupValidator from 'ember-changeset-validations'
import { empty } from '@ember/object/computed'
import EmberObject, { computed } from '@ember/object'
import { inject } from '@ember/service'
import Ember from 'ember'
import tSearchProducers from 'min-side/tasks/search-producers'
import { queryManager } from 'ember-apollo-client'
import RemapValidationErrors from 'min-side/mixins/remap-validation-errors'

const CORRECT_SHARES_SUM = 100

export default Component.extend(RemapValidationErrors, {
  'data-test-edit-recording-new-ownership': true,
  apollo: queryManager(),
  intl: inject(),

  sharesEmpty: empty('ownership.shares'),

  files: computed('ownership.attachment', function () {
    return [this.get('ownership.attachment')]
  }),

  init(...args) {
    this._super(...args)

    this.setProperties({
      searchQuery: null,
      showIncorrectSharesError: false
    })
  },

  actions: {
    save() {
      if (!Ember.testing && !confirm(this.intl.t('edit_recording.ownership.confirmation'))) {
        return
      }

      this._validate()

      if (this._isInvalid()) {
        this.flashMessages.error(this.intl.t('flash.save.invalid'))
        this._mapValidationErrors()
      } else {
        this.saveAction()
      }
    },

    addProducer(producer) {
      this.get('ownership.shares').addObject(
        new Changeset(
          this._addPropertiesToProducer(producer),
          lookupValidator(ProducerValidations),
          ProducerValidations
        )
      )

      this.set('tSearchProducers.last.value', null)
      this.set('searchQuery', null)
    },

    removeShare(index) {
      this.get('ownership.shares').removeAt(index)
    }
  },

  _addPropertiesToProducer(producer) {
    producer.value = undefined
    producer.mapped_errors = EmberObject.create({ errors: [] })

    return producer
  },

  _validate() {
    this.ownership.validate()

    const shares = this.get('ownership.shares').map(share => {
      share.validate()
      return parseInt(share.get('value'), 10)
    })
    const sum = shares.reduce((a, b) => a + b, 0)

    this.set('showIncorrectSharesError', sum !== CORRECT_SHARES_SUM)
  },

  _isInvalid() {
    return (
      this.showIncorrectSharesError ||
      this.get('ownership.isInvalid') ||
      this.get('ownership.shares').any(s => s.get('isInvalid'))
    )
  },

  _mapValidationErrors() {
    this._mapChangesetErrors(this.ownership)
    this.get('ownership.shares').forEach(changeset => {
      this._mapChangesetErrors(changeset)
    })
  },

  _mapChangesetErrors(changeset) {
    const errors = []
    changeset.get('errors').forEach(error => {
      errors.push(
        EmberObject.create({
          attribute: error.key,
          message: this._errorMessage(error.key)
        })
      )
    })
    changeset.get('mapped_errors').set('errors', errors)
  },

  _errorMessage(key) {
    const intl = this.intl

    switch (key) {
      case 'ends_at':
        return intl.t('edit_recording.ownership.new.date_error')
      case 'value':
        return ''
      default:
        return intl.t('validation.empty')
    }
  },

  tSearchProducers
})
