import Component from '@glimmer/component'
import { computed, action } from '@ember/object'
import { toLocaleString } from 'echo-ember-common/helpers/to-locale-string'
import { inject as service } from '@ember/service'

export default class LedgerEntryOverviewRowComponent extends Component {
  @service profile
  @service router

  @computed('amount')
  get formattedAmount() {
    return toLocaleString([this.args.amount])
  }

  @action
  async impersonate(personId, firstName, lastName, sector) {
    await this.profile.impersonate(personId, firstName, lastName, sector, true)
    this.router.replaceWith('user.ledger', { queryParams: { sector } })
  }
}
