import Service from '@ember/service'
import { inject } from '@ember/service'
import mutation from 'min-side/graphql/mutations/documents'

export default Service.extend({
  router: inject(),
  intl: inject(),
  apollo: inject(),
  flashMessages: inject(),

  async sign(party, person_id) {
    let url = party.sign_url || null
    // if the document already has a url then just redirect them there
    if (!url) {
      if (window.confirm(this.intl.t('documents.confirmation', { name: party.document.name }))) {
        // call the mutation to create the scrive document and redirect them there
        const href = location.href.replace(location.search, '')
        const variables = {
          input: {
            document_id: party.document.id,
            success_redirect_url: `${href}?documentId=${party.document.id}`
          },
          person_id
        }
        try {
          const res = await this.apollo.mutate(
            {
              mutation,
              variables
            },
            'assign_electronic_signature_document'
          )
          if (res && res.document && res.document.parties && res.document.parties[0]) {
            url = res.document.parties[0].sign_url
          }
        } catch (error) {
          this.flashMessages.error(this.intl.t('documents.unknown-error'))
          return
        }
      } else {
        return
      }
    }
    if (url) {
      // redirect to this url
      // this.flashMessages.success('redirected!')
      window.location.replace(url)
    } else {
      // no url now means it did not work
      this.flashMessages.error(this.intl.t('documents.unknown-error'))
    }
  }
})
