export default {
  "unified-search": "_unified-search_1b304v",
  "search-form": "_search-form_1b304v",
  "icon": "_icon_1b304v",
  "icon-x": "_icon-x_1b304v",
  "search-form-input": "_search-form-input_1b304v",
  "search-form-submit-button": "_search-form-submit-button_1b304v",
  "suggestions-container": "_suggestions-container_1b304v",
  "showing-suggestions": "_showing-suggestions_1b304v",
  "loading-container": "_loading-container_1b304v",
  "empty-result-container": "_empty-result-container_1b304v",
  "description": "_description_1b304v",
  "overlay": "_overlay_1b304v",
  "overlay-hidden": "_overlay-hidden_1b304v"
};
