import Component from '@ember/component'
import { computed } from '@ember/object'
import { inject } from '@ember/service'
import { equal } from '@ember/object/computed'
import { PROFILE_TYPES } from 'min-side/constants/profile'

export default Component.extend({
  profile: inject(),

  isAgency: equal('profile.activeProfile.type', PROFILE_TYPES.AGENCY),

  isInheritanceParty: equal('profile.activeProfile.profile', PROFILE_TYPES.INHERITANCE_PARTY),

  ledgerEntries: computed('ledger.entries.[]', function () {
    return this.getWithDefault('ledger.entries', [])
  }),
  currentBalance: computed('ledger', function () {
    return [
      {
        balance: this.ledger.currentBalance
      }
    ]
  })
})
