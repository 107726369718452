import { sort, reads } from '@ember/object/computed'

import RemunerationController from '../../controller'

export default RemunerationController.extend({
  renumerationEdgesSorting: ['node.title'],

  sortedRemunerationEdges: sort(
    'remunerationEntry.snapshot.remuneration_details.edges',
    'renumerationEdgesSorting'
  ),

  snapshotId: reads('remunerationEntry.snapshot.id'),
  groupBy: 'recording'
})
