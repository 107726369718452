import Component from '@ember/component'
import { inject } from '@ember/service'
import { task, timeout } from 'ember-concurrency'
import { DEBOUNCE_MS } from 'min-side/constants/time'
import { queryManager } from 'ember-apollo-client'
import searchPerformerQuery from 'min-side/graphql/queries/new-release/search-people'
import { computed } from '@ember/object'
import { ROLE_CODES } from 'min-side/constants/role-codes'

const MAX_CONCURRENCY = 1

export default Component.extend({
  intl: inject(),
  profile: inject(),
  tagName: '',
  apollo: queryManager(),

  roleCodes: computed(function () {
    return ROLE_CODES
  }),
  errorRoleCodeAttribute: computed('index', function () {
    return `performances/${this.index}/role_code`
  }),
  errorInstrumentAttribute: computed('index', function () {
    return `performances/${this.index}/instrument`
  }),
  tFetchPerformerTask: task(function* (query) {
    yield timeout(DEBOUNCE_MS)
    const { public_search_people } = yield this.apollo.watchQuery({
      query: searchPerformerQuery,
      variables: { query }
    })

    return public_search_people.edges.map(suggestion => {
      if (suggestion.node.performer.artist_name) {
        return {
          name: `${suggestion.node.first_name} ${suggestion.node.last_name} (${suggestion.node.performer.artist_name})`,
          born_on: suggestion.node.born_on,
          id: suggestion.node.id
        }
      }
      return {
        name: `${suggestion.node.first_name} ${suggestion.node.last_name}`,
        born_on: suggestion.node.born_on,
        id: suggestion.node.id
      }
    })
  })
    .maxConcurrency(MAX_CONCURRENCY)
    .restartable(),

  actions: {
    updatePerformerSearchableField(fieldName, { name, id }) {
      this.set(`performance.${fieldName}`, name)
      if (id) {
        this.set(`performance.performer_id`, id)
      } else {
        this.set(`performance.performer_id`, null)
      }
    },
    cancelPerformerSearchResult() {
      this.tFetchPerformerTask.cancelAll()
    },
    instrumentChanged(instrument) {
      if (!instrument) {
        return
      }
      this.set('performance.instrument', instrument)
    }
  }
})
