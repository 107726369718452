import Component from '@glimmer/component'
import { computed } from '@ember/object'
import { toLocaleString } from 'echo-ember-common/helpers/to-locale-string'
import { inject as service } from '@ember/service'

export default class CurrentBalanceComponent extends Component {
  @service intl

  @computed('currentBalance', 'intl.primaryLocale')
  get currentFormattedBalances() {
    if (!Array.isArray(this.args.currentBalance)) {
      throw new Error(
        `Invalid argument "currentBalance", array expected, ${typeof this.currentBalance} give`
      )
    }

    return this.args.currentBalance.map(currentBalance => {
      const object = {
        balance: toLocaleString([currentBalance.balance]),
        label: this.intl.t('molecules.current-balance.balance')
      }

      if ('sector' in currentBalance) {
        const sector = currentBalance.sector.toLowerCase()
        object.queryParams = {
          sector
        }
        object.label = this.intl.t('molecules.current-balance.balance-with-sector', {
          sector: this.intl.t(`sectors.${sector}`).toLowerCase()
        })
      }

      return object
    })
  }
}
