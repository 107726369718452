import { tryInvoke } from '@ember/utils'
import { later } from '@ember/runloop'
import { equal } from '@ember/object/computed'
import { inject } from '@ember/service'
import AsyncButton from 'echo-ember-common/components/atoms/async-button'
import ButtonAtomStyling from '../a-button/button-atom-styling'
import { computed, observer, set, getWithDefault } from '@ember/object'

export const WAIT_TIME_MILLIS = 1000

export default AsyncButton.extend(ButtonAtomStyling, {
  'data-test-async-button': true,
  intl: inject(),

  // Translations for button text for given states
  pendingText: null,
  defaultText: null,
  pending: computed('pendingText', function () {
    return this.pendingText || this.intl.t('saving')
  }),
  default: computed('defaultText', function () {
    return this.defaultText || this.intl.t('submit')
  }),

  isPending: equal('textState', 'pending'),
  scheduleIsLongRunningSetter: observer('textState', function () {
    this._clearAndCancelIsLongRunning()

    if (this.isPending) {
      this.attrs.isLongRunningSetter = later(() => {
        this._setIsLongRunningIfStillPending()
      }, WAIT_TIME_MILLIS)
    }
  }),
  click() {
    const params = getWithDefault(this, 'params', [])
    const actionArguments = [...params]

    this.set('promise', this.action(...actionArguments))

    set(this, 'textState', 'pending')

    // If this is part of a form, it will perform an HTML form
    // submission without returning false to prevent action bubbling
    return false
  },

  _clearAndCancelIsLongRunning() {
    this._setIsLongRunning(false)
    tryInvoke(this.attrs.isLongRunningSetter, 'cancel')
  },

  _setIsLongRunningIfStillPending() {
    if (!this.isDestroyed && this.isPending) {
      this._setIsLongRunning(true)
    }
  },

  _setIsLongRunning(value) {
    if (!this.isDestroyed) {
      this.set('isLongRunning', value)
    }
  }
})
