import Component from '@ember/component'

export default Component.extend({
  tagName: '',
  supportsDataTestProperties: true,

  actions: {
    addProducer() {
      this.addProducerAction(this.producer)
    }
  }
})
