import { camelize } from '@ember/string'

class CurrentMeta {
  constructor(current) {
    this.legacyProfile = current.legacyProfile
    this.activeProfile = current.legacyProfile.activeProfile
  }

  /**
   * Returns a string describing selected profile
   *
   * Examples of value:
   *
   * performer
   * personalProducer
   * contact
   *
   * @return  String
   */
  get profileName() {
    return camelize(this.activeProfile.profile)
  }

  /**
   * Returns a string describing selected profile
   *
   * Examples of value:
   *
   * performer
   * personalProducer
   * contactProducerCompany
   * contactAgent
   *
   * @return  String
   */
  get profileNameFull() {
    // FIXME: The " || this.activeProfile.profile"-part is here to
    // make this work for already logged in users at the time of the deploy,
    // as they will not have this property persisted on the activeProfile in
    // their local storage. Whenever users logs in, or switches profile, this
    // will be set and colors be correct. I say this is an OK situation for now.
    //
    // Anyways, if you see this now and the date is after 2020.04.01 you still
    // cannot remove the or-part because impersonating profiles still miss the
    // `profileNameFull` property.
    return camelize(this.activeProfile.profileNameFull || this.activeProfile.profile)
  }

  /**
   * Returns the contact company type when current profile is a contact
   *
   * Examples of values:
   *
   * producerCompany
   * agency
   *
   * @return  String | null
   */
  get companyType() {
    if (!this.isContact) return null

    return camelize(this.activeProfile.type)
  }

  get isOrganization() {
    return this.companyType !== null
  }

  get sector() {
    return this.legacyProfile.activeSector.toUpperCase()
  }

  /**
   * Only performers or producers do have discography
   *
   * @return boolean
   */
  get hasDiscography() {
    return (
      (this.isContact && this.companyType === 'producerCompany') ||
      ['performer', 'personalProducer'].includes(this.profileName)
    )
  }

  /**
   * If you are a contact person of an agency you'll have client list.
   *
   * @return boolean
   */
  get hasClientsList() {
    return this.isContact && this.companyType === 'agency'
  }

  /**
   * If you currently view min side as an inheritanceParty you'll have testators.
   *
   * (This is somehow not 100% true, as the dead person is also an inheritanceParty, but
   * as the dead person isn't allowed to enter min side this detail isn't that important)
   *
   * @return boolean
   */
  get hasTestators() {
    return this.profileName === 'inheritanceParty'
  }

  /**
   * We only support claims from personal producers so far
   *
   * @return boolean
   */
  get hasClaims() {
    // disable claims for agencies

    return (
      this.profileName === 'personalProducer' ||
      this.profileName === 'performer' ||
      this.profileName === 'contact'
    )
  }

  get isContact() {
    return this.legacyProfile.isContact
  }

  get isImpersonating() {
    return !!this.activeProfile.impersonating
  }

  /**
   * Returns the id of this profile
   *
   * It will be the currently selected profile or impersonated person
   */
  get id() {
    return this.activeProfile.id
  }
}

/**
 * Properties on the current object belongs to the "current"
 * person / profile selected. It also takes in to account if a person
 * is impersonating another person. In which case "current" is the person
 * being impersonated.
 *
 * If you are looking for the "impersonator" try signedIn.
 *
 * TODO:  All properties of the current is read from the legacy profile service.
 *        It is like this to give the app a more sane interface to read data from.
 *        Until we have migrated completely, the changing of profile and impersonation
 *        must be initiated from the legacy profile service.
 */
export default class Current {
  constructor(legacyProfile, legacyPerson, apollo) {
    this.legacyProfile = legacyProfile
    this.legacyPerson = legacyPerson
    this.apollo = apollo
  }

  /**
   * Returns the current person's properties.
   */
  get person() {
    const { id, firstName, lastName, yearOfDeath } = this.legacyPerson
    const { email, mobile } = this.legacyPerson.contactInformation || {}

    return {
      id,
      name: [firstName, lastName].join(' '),
      firstName,
      lastName,
      email,
      mobile,
      yearOfDeath,
      isDead: !!yearOfDeath
    }
  }

  /**
   * An object which answers the small questions like
   *
   * - What company type is this?
   * - Does the current profile have any discography?
   * - What is the id of this profile?
   */
  get meta() {
    return new CurrentMeta(this)
  }

  /**
   * Returns the "display name".
   *
   * The display name is performer / personal producer, or name of company
   * if a user is a contact person.
   */
  get displayName() {
    return this.legacyProfile.displayName
  }

  /**
   * Returns all profiles for this person.
   *
   * NOTE:  This selectableProfileObjects() normally returns all
   *        selectable profiles, but when impersonating it removes the
   *        inheritance profile for some reason. If I where to guess
   *        this would lead to a stack of impersonations,
   *        which, and again I'm guessing, we don't support.
   *
   * Note 2:  I'm naming this function something easily to search for,
   *          as this function may be refactored to something simpler
   *          when time comes for the legacy service to fall.
   *
   * @return  Array of objects, created by legacyProfileService._createProfileObject()
   */
  get profileObjectsFromLegacyService() {
    return this.legacyProfile.selectableProfileObjects
  }

  /**
   * Returns the current profile object, from legacy service.
   *
   * @see profileObjectsFromLegacyService
   */
  get profileObjectFromLegacyService() {
    return this.legacyProfile.activeProfile
  }
}
