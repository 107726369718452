import Component from '@ember/component'
import { inject as service } from '@ember/service'
import { reads } from '@ember/object/computed'

export default Component.extend({
  localClassNames: ['impersonate-banner'],
  profile: service(),

  displayName: reads('profile.current.person.name')
})
