import Controller from '@ember/controller'
import { computed, get } from '@ember/object'
import { reads } from '@ember/object/computed'
import { tracked } from '@glimmer/tracking'
import { dropTask } from 'ember-concurrency-decorators'
import { formatErrors } from 'min-side/utils/changeset-helpers'
import orgContactInfoMutation from 'min-side/graphql/mutations/profile/organization-contact-info'
import { inject as service } from '@ember/service'
import { queryManager } from 'ember-apollo-client'
import Changeset from 'ember-changeset'
import organizationInfoMutation from 'min-side/graphql/mutations/profile/organization-info'
import ProfileInfoContactController from 'min-side/pods/user/profile-info/contact/controller'
import lookupValidator from 'ember-changeset-validations'
import OrganizationContactInfoValidations from 'min-side/validations/profile/organization-contact-info'
import { omit } from 'lodash'

const setupOrgInfoChangeset = data => {
  const { country = '', organization_number = '', name = '' } = data

  const orgInfoData = {
    country,
    organization_number,
    name
  }

  return new Changeset(orgInfoData)
}

const extractOrgInfoChangesetData = orgInfoChangeset => {
  return {
    ...orgInfoChangeset.get('data'),
    ...orgInfoChangeset.get('change'),
    country: orgInfoChangeset.get('country.id')
  }
}

export default class ProfileInfoOrgInfoController extends Controller {
  @tracked contactInfoChangeset = null
  @tracked orgInfoChangeset = null

  @queryManager apollo
  @service intl
  @service profileInfo

  @reads('profileInfo.currentProfileType')
  currentProfileType

  @computed('model.{producer_company,agency}')
  get company() {
    return this.currentProfileType === 'agency' ? this.model.agency : this.model.producer_company
  }

  setupChangeset(company) {
    this.contactInfoChangeset = this.constructor.setUpChangesetAndReturnIt(company)
    this.orgInfoChangeset = setupOrgInfoChangeset(company)
  }

  @dropTask
  *onContactlInfoValidate(changeset) {
    try {
      const contactInfo = changeset
      yield contactInfo.validate()

      const isValid = contactInfo.get('isValid')

      if (!isValid) {
        const validationErrors = ProfileInfoContactController.collectChangesetError(contactInfo)
        const intlErrors = validationErrors.map(({ attribute, message }) => ({
          attribute,
          message: this.intl.t(message)
        }))

        return intlErrors
      }

      return []
    } catch (error) {
      throw error
    }
  }

  @dropTask
  *onContactlInfoSubmit(changeset) {
    try {
      const { contact_information } = ProfileInfoContactController.extractChangesetData(changeset)
      const isAgency = this.currentProfileType === 'agency'

      const variables = {
        input: {
          contact_information,
          organization_id: get(this, 'company.id'),
          organization_type: isAgency ? 'AGENCY' : 'PRODUCER'
        }
      }

      const res = yield this.apollo.mutate(
        { mutation: orgContactInfoMutation, variables },
        'return_data'
      )

      if (res && res.errors) {
        const formattedErrors = formatErrors(res.errors)

        return formattedErrors
      }

      changeset.save()
      return []
    } catch (error) {
      throw error
    }
  }

  @dropTask
  *onOrgInfoValidate() {
    return []
  }

  @dropTask
  *onOrglInfoSubmit(changeset) {
    try {
      const variables = {
        input: {
          ...extractOrgInfoChangesetData(changeset),
          id: get(this, 'company.id')
        }
      }

      const res = yield this.apollo.mutate(
        { mutation: organizationInfoMutation, variables },
        'upsert_international_agency'
      )

      if (res && res.errors) {
        return formatErrors(res.errors)
      }

      return []
    } catch (error) {
      throw error
    }
  }

  /**
   * Sets up the changeset, for organization info we want to omit the alternative_mobile property,
   * since an organization does not have an alternative mobile.
   * @param data
   * @returns {Changeset}
   */
  static setUpChangesetAndReturnIt(data) {
    const newData = omit(ProfileInfoContactController.prepareContactInfoDataObject(data), [
      'alternative_mobile'
    ])

    return new Changeset(
      newData,
      lookupValidator(OrganizationContactInfoValidations),
      OrganizationContactInfoValidations
    )
  }
}
