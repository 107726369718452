import Component from '@ember/component'
import { computed, get } from '@ember/object'

export default Component.extend({
  tagName: 'section',
  classNames: ['container container--padded container--col-1'],

  overviewCards: computed(
    'agency.clients_performers.total',
    'agency.clients_producers.total',
    function () {
      return [
        {
          count: get(this, 'agency.clients_performers.total'),
          headerTranslationKey: 'molecules.clients-overview.performers',
          icon: 'performer',
          link: 'user.clients.performers'
        },
        {
          count: get(this, 'agency.clients_producers.total'),
          headerTranslationKey: 'molecules.clients-overview.producers',
          icon: 'producer',
          link: 'user.clients.producers'
        }
      ]
    }
  )
})
