import Controller from '@ember/controller'
import { action, computed } from '@ember/object'
import { inject as service } from '@ember/service'
import { reads } from '@ember/object/computed'
import { createYearsList } from 'min-side/utils/date-ranges'
import { sectorForUser } from 'min-side/helpers/user-types'

const YEARS_TO_SHOW = 4

export default class DistributionAreaController extends Controller {
  @service profile
  queryParams = ['year', 'distributionAreaId']

  @computed('offset')
  get possibleYears() {
    return createYearsList(YEARS_TO_SHOW, this.get('offset'))
      .reverse()
      .map(y => {
        return String(y)
      })
  }

  @computed('model.ledger.remuneration_years.0.year')
  get selectedYear() {
    return String(this.get('model.ledger.remuneration_years.0.year'))
  }

  @computed('model.ledger.remuneration_years.0.distribution_area_summaries')
  get distributionAreaSummaries() {
    const summaries = this.get('model.ledger.remuneration_years.0.distribution_area_summaries')
    if (summaries) {
      return summaries
        .sort((a, b) => {
          return a.gross_amount - b.gross_amount
        })
        .reverse()
    }
  }

  @computed('distributionAreaId', 'distributionAreaSummaries')
  get selectedSummary() {
    if (!this.get('distributionAreaSummaries')) {
      return null
    }
    const selected = this.get('distributionAreaSummaries').find(dist => {
      return dist.distribution_area.id === this.get('distributionAreaId')
    })
    return selected || this.get('distributionAreaSummaries.0')
  }

  @computed('profile.activeProfile')
  get sector() {
    return sectorForUser(this.get('profile.activeProfile')).toLowerCase()
  }

  @reads('selectedSummary.distribution_area')
  selectedSummaryDistributionArea

  @action
  onYearChange(year) {
    this.set('year', year)
  }

  @action
  selectArea(distributionAreaId) {
    this.set('distributionAreaId', distributionAreaId)
  }
}
