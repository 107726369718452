import Component from '@ember/component'
import { computed, get } from '@ember/object'
import { reads } from '@ember/object/computed'

export default Component.extend({
  'data-test-edit-release-tracks-side-track': true,
  tagName: 'tr',
  classNameBindings: ['isEditing:table__row--is-editing'],
  isEditing: reads('item.addingRecording'),

  empty: computed('item', function () {
    return Object.keys(get(this, 'item')).length <= 3 // eslint-disable-line no-magic-numbers
  }),
  item: reads('track.node'),
  canMoveUp: computed('trackIndex', function () {
    return !(get(this, 'isFirstSide') && get(this, 'trackIndex') === 0)
  }),
  canMoveDown: computed('isLastSide', 'tracksCount', 'trackIndex', function () {
    if (get(this, 'isLastSide')) {
      if (get(this, 'tracksCount') - 1 === get(this, 'trackIndex')) return false
    }
    return true
  }),
  actions: {
    remove() {
      get(this, 'onRemoveTrack')(get(this, 'trackIndex'))
    },
    moveUp() {
      if (!get(this, 'canMoveUp')) return false
      get(this, 'onMoveTrackUp')(get(this, 'trackIndex'))
    },
    moveDown() {
      if (!get(this, 'canMoveDown')) return false
      get(this, 'onMoveTrackDown')(get(this, 'trackIndex'))
    }
  }
})
