import Component from '@ember/component'

export default Component.extend({
  'data-test-release-form-recordings-recording': true,
  tagName: 'li',
  actions: {
    remove() {
      this.removeRecordingAction(this.recording)
    }
  }
})
