import Route from '@ember/routing/route'
import { queryManager } from 'ember-apollo-client'
import { inject as service } from '@ember/service'
import SearchResultCounts from 'min-side/graphql/queries/suggestion-search'

export default class GlobalSearchResultsRoute extends Route {
  @queryManager apollo
  @service pageTitle
  @service router
  @service profile

  queryParams = {
    query: {
      refreshModel: true
    },
    include_person_discography: {
      refreshModel: true
    },
    include_gramo_registry: {
      refreshModel: true
    }
  }

  model(params) {
    // if we end up here with no query, the search has nothing to load so redirect the user to the dashboard
    if (!params.query) {
      this.router.transitionTo('user.index')
      return
    }
    this.pageTitle.setVariables({ query: params.query })
    const variables = {
      query: params.query,
      include_person_discography: params.include_person_discography,
      sector: this.profile.current.meta.sector,
      include_gramo_registry: params.include_gramo_registry,
      include_my_discography: this.profile.current.meta.hasDiscography,
      rightsholder_id: this.profile.current.meta.id
    }

    return this.apollo.query({ query: SearchResultCounts, variables, fetchPolicy: 'network-only' })
  }
}
