export default {
  "nav": "_nav_ts8bge",
  "visible": "_visible_ts8bge",
  "site-menu": "_site-menu_ts8bge",
  "list-header": "_list-header_ts8bge",
  "other-menu": "_other-menu_ts8bge",
  "logout-menu": "_logout-menu_ts8bge",
  "menu-profile-header": "_menu-profile-header_ts8bge",
  "profile-avatar": "_profile-avatar_ts8bge",
  "profile-name": "_profile-name_ts8bge",
  "profile-role-name": "_profile-role-name_ts8bge"
};
