define("min-side/helpers/duration-as-hms", ["exports", "echo-ember-common/helpers/duration-as-hms"], function (_exports, _durationAsHms) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _durationAsHms.default;
    }
  });
  Object.defineProperty(_exports, "durationAsHms", {
    enumerable: true,
    get: function get() {
      return _durationAsHms.durationAsHms;
    }
  });
});
