import Route from '@ember/routing/route'
import { inject } from '@ember/service'
import HandlesHttpStatusErrorMixin from 'echo-ember-common/mixins/handles-http-status-error'
import { queryManager } from 'ember-apollo-client'
import LocaleMixin from 'min-side/mixins/locale'
import ApplicationRouteMixin from 'ember-simple-auth/mixins/application-route-mixin'
import { observer } from '@ember/object'
import { on } from '@ember/object/evented'
import unsupportedDevices from 'min-side/utils/unsupported-ua'
import UAParser from 'ua-parser-js'
import { debounce } from '@ember/runloop'
import signedInToIntercomData from 'min-side/utils/intercom'
import { camelize } from '@ember/string'
import Ember from 'ember'

// DUPLICATION echo-common-front-end-candidate

export default Route.extend(ApplicationRouteMixin, LocaleMixin, HandlesHttpStatusErrorMixin, {
  apollo: queryManager(),
  session: inject(),
  intercom: inject(),
  cookies: inject(),
  raven: inject(),
  intl: inject(),
  router: inject(),
  profile: inject(),
  features: inject(),
  pageTitle: inject(),

  beforeModel() {
    this._super()

    // Locale needs to be set after eg. reload
    this._setLocale()

    if (!this.isUserAgentSupported()) {
      this.transitionTo('no-support')
      return
    }
  },
  actions: {
    error(error) {
      return this.handleHttpStatusError(error)
    },
    back() {
      history.back()
    }
  },

  maintainPageTitle: on(
    'activate',
    observer('router.currentRouteName', async function () {
      this.get('pageTitle').update(this.router.currentRouteName)
    })
  ),

  maintainUserContext: on(
    'activate',
    observer('session.isAuthenticated', async function () {
      if (this.get('session.isAuthenticated')) {
        await this.setRavenUserContext()
        await this.setIntercomUserContextAndBeginUpdateOnRouteChange()
        await this.setFeatures()
      } else {
        this.unsetRavenUserContext()
        this.unsetIntercomUserContextAndStopUpdateOnRouteChange()
        this.unsetFeatures()
      }
    })
  ),

  async setRavenUserContext() {
    const { id, email, name, locale } = await this.profile.signedIn.user()
    this.raven.callRaven('setUserContext', { id, email, name, locale })
  },

  unsetRavenUserContext() {
    this.raven.callRaven('setUserContext')
  },

  async setFeatures() {
    if (Ember.testing) return false
    const user = await this.profile.signedIn.user()
    if (user) {
      const feats = {}
      for (const feature of user.features) {
        feats[camelize(feature.name)] = feature.enabled
      }
      this.features.setup(feats)
    } else {
      this.unsetFeatures()
    }
  },

  unsetFeatures() {
    if (Ember.testing) return false
    this.features.setup({})
  },

  async setIntercomUserContextAndBeginUpdateOnRouteChange() {
    const { intercom, router } = this.getProperties('intercom', 'router')

    if (!this.get('updateIntercomOnPageChangeIsSetup')) {
      // Ember called us twice; that is why this flag is here so we don't set up multiple listeners.
      intercom.setProperties({ user: await signedInToIntercomData(this.profile.signedIn) })

      router.on('routeDidChange', this.updateIntercomOnPageChangeFn())
      this.set('updateIntercomOnPageChangeIsSetup', true)
    }
  },

  unsetIntercomUserContextAndStopUpdateOnRouteChange() {
    const { intercom, router } = this.getProperties('intercom', 'router')

    // Clear state and start with a clean intercom session
    if (this.get('cookies').isEnabled()) {
      intercom.shutdown()
      intercom.start({ language_override: this.get('intl.primaryLocale') })
    }

    if (this.get('updateIntercomOnPageChangeIsSetup')) {
      router.off('routeDidChange', this.updateIntercomOnPageChangeFn())
      this.set('updateIntercomOnPageChangeIsSetup', false)
    }
  },

  /**
   * Ask intercom to update, to update intercom with the latest
   * page the user is on.
   */
  updateIntercom() {
    this.get('intercom').update({ language_override: this.get('intl.primaryLocale') })
  },

  updateIntercomOnPageChangeFn() {
    if (!this.boundAndDebouncedIntercomUpdateFn) {
      this.boundAndDebouncedIntercomUpdateFn = () => {
        // eslint-disable-next-line no-magic-numbers
        debounce(this, this.updateIntercom, 1000)
      }
    }

    return this.boundAndDebouncedIntercomUpdateFn
  },

  isUserAgentSupported() {
    const parser = new UAParser(window.navigator.userAgent)
    const { name: uaName = '', version: uaVersion = '' } = parser.getBrowser()
    const { model: uaModel = '' } = parser.getDevice()
    const unsupportedBrowsers = unsupportedDevices[uaModel] || []

    return !unsupportedBrowsers.filter(
      ({ name, version }) => uaName === name && uaVersion === version
    ).length
  }
})
