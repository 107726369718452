export const serializeRestErrors = function (errors) {
  return errors.reduce((acc, error) => {
    if (error.source) {
      acc.push({
        attribute: error.source.pointer,
        message: error.detail
      })
    }
    return acc
  }, [])
}
