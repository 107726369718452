import Controller from '@ember/controller'
import CurrentProfileMixin from 'min-side/mixins/current-profile'

export default Controller.extend(CurrentProfileMixin, {
  queryParams: ['q', 'property', 'direction', 'after'],
  q: null,
  property: null,
  direction: null,
  after: null,
  first: 25,
  loading: false,

  actions: {
    onPageChange(_first, after) {
      this.set('after', after)
    },
    async search(query) {
      this.set('after', null)
      this.set('q', query)
    }
  }
})
