import query from 'min-side/graphql/queries/profiles'
import { dasherize } from '@ember/string'
import Service from '@ember/service'
import { inject as service } from '@ember/service'
import { get, getWithDefault, computed, setProperties } from '@ember/object'
import { equal, filter, notEmpty, or, reads } from '@ember/object/computed'
import { queryManager } from 'ember-apollo-client'
import UpdateSessionData, { SESSION_DATA_KEY } from 'min-side/mixins/update-session'
import { isPresent } from '@ember/utils'
import { PROFILE_TYPES } from 'min-side/constants/profile'
import { task } from 'ember-concurrency'
import { sectorForUser } from 'min-side/helpers/user-types'
import { camelize } from '@ember/string'

import { checkIfAgencyAgreementActive } from 'min-side/utils/profile-helpers'

import SignedIn from 'min-side/pods/profile/signed-in'
import Current from 'min-side/pods/profile/current'

const { localStorage } = window
const {
  PERFORMER,
  PERSONAL_PRODUCER,
  CONTACT,
  INHERITANCE_PARTY,
  AGENCY,
  PRODUCER_COMPANY
} = PROFILE_TYPES
const SELECTABLE_PROFILES_LIST = [PERFORMER, PERSONAL_PRODUCER, CONTACT, INHERITANCE_PARTY]

const PROFILE_SESSION_KEY = 'profile-session-key'

const iconForProfile = profile => {
  switch (profile) {
    case PERFORMER:
      return 'artist'
    case PERSONAL_PRODUCER:
      return 'producer'
    case CONTACT:
      return 'quill'
    case INHERITANCE_PARTY:
      return 'quill'
    default:
      throw new Error(`No icon defined for ${profile}`)
  }
}
// TODO[sergiu] Find some ways of improving this service or redo it from scratch
export default Service.extend(UpdateSessionData, {
  localStorage,
  intl: service(),
  apollo: queryManager(),
  session: service(),
  spinnerScreen: service(),

  isContact: equal('activeProfile.profile', CONTACT),
  isPersonalProducer: equal('activeProfile.profile', PERSONAL_PRODUCER),
  isProducerCompany: equal('activeProfile.type', PRODUCER_COMPANY),
  isProducer: or('isPersonalProducer', 'isProducerCompany'),
  userId: reads(`${SESSION_DATA_KEY}.authenticated.user.id`),

  impersonatedUserId: computed('activeProfile.profile.impersonating', function () {
    if (!this.get('activeProfile.impersonating')) return null
    return this.get('activeProfile.id')
  }),

  activeSector: computed('activeProfile', function () {
    return sectorForUser(this.activeProfile).toLowerCase()
  }),

  /**
   * Provides an object to access the signed in user and person
   * Intent: Make Ember cache SignedIn object for us
   */
  signedIn: computed('session.isAuthenticated', function () {
    return new SignedIn(this.session, this.apollo)
  }),

  /**
   * Provides an object to access the currently active user, person and profile
   *
   * "Currently active" will means whatever profile the user has selected, and
   * we are also taking in to account if the user has "impersonated" another person.
   *
   * Example of impersonations are:
   *  - An agent      acting on behalf of a client (performer/producer)
   *  - An inheritor  acting on behalf of a inherited
   */
  current: computed('activeProfile', '_person', function () {
    if (!this.activeProfile.userId) {
      return null
    }

    return new Current(this, this._person, this.apollo)
  }),

  /**
   * Returns an active profile object
   *
   * @returns {*|{isAgencyAgreementActive: *, displayName: *, profile: *, icon: *, id: *, type: *}|{}}
   */
  activeProfile: computed(
    `${SESSION_DATA_KEY}.${PROFILE_SESSION_KEY}`,
    'selectableProfiles.[]',
    '_positions.[]',
    function () {
      return JSON.parse(this.readSessionData(PROFILE_SESSION_KEY) || '{}')
    }
  ),

  hasActiveProfile: computed('activeProfile', function () {
    return Object.keys(this.activeProfile).length !== 0
  }),

  displayName: computed('activeProfile', 'intl.locale', function () {
    return this.isContact
      ? this.activeProfile.displayName
      : this.intl.t(`profiles.${this.activeProfile.profile}`)
  }),

  inactiveProfiles: computed('_simpleProfiles', '_contactProfiles', 'activeProfile', function () {
    const profiles = this._simpleProfiles.concat(this._contactProfiles)
    return profiles.filter(profileObject => this._isInactive(profileObject))
  }),

  isAgencyAgreementActive: computed('_lastAgencyAgreement', function () {
    if (this._lastAgencyAgreement) {
      return checkIfAgencyAgreementActive(this._lastAgencyAgreement)
    }

    return false
  }),

  isManagedByAgency: computed('isAgencyAgreementActive', '_lastAgencyAgreement', function () {
    return (
      this.get('isAgencyAgreementActive') &&
      this.get('_lastAgencyAgreement.agency_manages_discography')
    )
  }),

  hasPaymentsThroughAgency: computed(
    'isAgencyAgreementActive',
    '_lastAgencyAgreement',
    function () {
      return (
        this.get('isAgencyAgreementActive') &&
        this.get('_lastAgencyAgreement.payments_through_agency')
      )
    }
  ),

  isDead: notEmpty('_person.year_of_death'),
  inactiveUser: or('isManagedByAgency', 'isDead'),

  selectableProfileObjects: computed(
    '_simpleProfiles',
    '_contactProfiles',
    'activeProfile.impersonating',
    function () {
      const {
        _simpleProfiles,
        _contactProfiles,
        activeProfile: { impersonating }
      } = this
      const profiles = [..._simpleProfiles, ..._contactProfiles]
      if (!impersonating) return profiles

      return profiles.filter(profile => profile.profile !== PROFILE_TYPES.INHERITANCE_PARTY)
    }
  ),

  selectableProfiles: filter('_profiles', function (profile) {
    return SELECTABLE_PROFILES_LIST.includes(profile.profileType)
  }),

  showProfileSelect: computed('selectableProfiles.[]', 'inactiveUser', function () {
    const hasMoreThanOneProfile = this.selectableProfileObjects.length > 1

    if (this.inactiveUser && !this.activeProfile.impersonating) {
      return false
    }

    return hasMoreThanOneProfile
  }),

  async loadActiveProfile() {
    await this._fetchPersonTask.perform()
    if (this.activeProfile.userId && this.activeProfile.userId !== this.userId) {
      this._persistActiveProfile(null)
    }
  },

  async getDefaultProfile() {
    // Use the profile the user has set as the default profile settings.
    // This could be profile with an agency agreement, set before the agreement
    // was put in place. At this point we assume that it being the default is
    // null and void, and should be ignored.
    const defaultProfile = this._defaultProfile
    if (defaultProfile && !defaultProfile.isAgencyAgreementActive) {
      return defaultProfile
    }

    // If there is only one sensible profile use that as the default
    const profilesWithoutAgency = this.selectableProfileObjects.filter(
      ({ isAgencyAgreementActive }) => !isAgencyAgreementActive
    )
    if (profilesWithoutAgency.length === 1) {
      return profilesWithoutAgency[0]
    }

    // If there is only a single profile to choose from, use that. This is not
    // a "valid" profile but a "helpful" message will be shown to the user.
    if (profilesWithoutAgency.length < 1) {
      const allProfiles = this.selectableProfileObjects
      if (allProfiles.length === 1) {
        return allProfiles[0]
      }
    }

    // We assume at this point there are several profiles that the user will
    // need to choose from manually
    return null
  },

  get _defaultProfile() {
    const defaultProfileName = (this._person.defaultMinSideProfile || '')
      .toLowerCase()
      .replace('_', '-')
    const defaultOrganizationIdForPerson = this._person.defaultMinSideOrganizationId

    return this.selectableProfileObjects.find(selectableProfile => {
      const personalProfileIsDefault = selectableProfile.profile === defaultProfileName
      const organizationIsDefault = selectableProfile.id === defaultOrganizationIdForPerson

      return organizationIsDefault || personalProfileIsDefault
    })
  },

  async impersonate(personId, firstName, lastName, type, accessToOneProfileOnly = false) {
    const profile = {
      userId: this.userId,
      displayName: `${firstName} ${lastName}`,
      id: personId,
      isAgencyAgreementActive: accessToOneProfileOnly,
      icon: 'artist',
      profile: type,
      impersonating: true
    }
    const currentProfile = this.activeProfile
    this.updateSessionData('profileBeforeImpersonating', JSON.stringify(currentProfile || {}))
    if (accessToOneProfileOnly) {
      this.updateSessionData('accessOnlyTo', type)
    }
    await this.get('spinnerScreen').showWhile(async () => {
      await this._persistActiveProfile(profile, false)
      await this._fetchPersonTask.perform()
    })
  },

  async endImpersonation() {
    await this.get('spinnerScreen').showWhile(async () => {
      const previousProfile = JSON.parse(this.readSessionData('profileBeforeImpersonating'))
      await this._persistActiveProfile(previousProfile || {}, true)
      this.removeKeyFromSessionData('profileBeforeImpersonating')
      this.removeKeyFromSessionData('accessOnlyTo')
      await this._fetchPersonTask.perform()
    })
  },

  /**
   * Switch profile to given one
   *
   * @param {object}  activeProfile     The profile to set active
   *                                    If you pass in null we'll "unset" the profile chosen
   *                                    state and resolve the persisted profile after login.
   *                                    We'll not leverage default profile in this case, which
   *                                    will present the user with the profile select component.
   * @param {boolean} endImpersonation  Are we ending impersonation?
   */
  async switchTo(activeProfile) {
    this._persistActiveProfile(activeProfile, false)
  },

  _lastAgencyAgreement: computed('_person.performer', 'activeProfile.profile', function () {
    const profileInModel = getWithDefault(this, 'activeProfile.profile', '').replace('-', '_')
    if (!profileInModel) return null
    const agencyAgreements = get(this, `_person.${profileInModel}.agency_agreements`)
    if (agencyAgreements) return agencyAgreements.slice(-1)[0]
  }),

  _positions: computed('_person.contact.positions.[]', function () {
    return get(this, '_person.contact.positions') || []
  }),

  _profiles: computed('_person.profiles.[]', function () {
    const profiles = (this._person && this._person.profiles) || []
    return profiles.map(profile => ({
      profileType: dasherize(profile),
      agencyAgreements: get(this, `_person.${profile.toLowerCase()}.agency_agreements`)
    }))
  }),

  _person: reads('_fetchPersonTask.lastSuccessful.value'),

  _fetchPersonTask: task(function* () {
    const person_id = this.impersonatedUserId

    let variables = {
      person_id,
      include_performer: true,
      include_producer: true
    }
    const accessRestrictedTo = this.readSessionData('accessOnlyTo')
    if (accessRestrictedTo && accessRestrictedTo === PROFILE_TYPES.PERSONAL_PRODUCER) {
      variables = {
        ...variables,
        include_performer: false
      }
    }
    if (accessRestrictedTo && accessRestrictedTo === PROFILE_TYPES.PERFORMER) {
      variables = {
        ...variables,
        include_producer: false
      }
    }
    const { user, annual_income_statements } = yield this.apollo.query({ query, variables })

    if (user.person) {
      return { ...user.person, annual_income_statements }
    }

    return null
  }),

  _persistActiveProfile(profileToBePersisted, endImpersonation = false) {
    if (this.activeProfile.impersonating && !endImpersonation) {
      setProperties(profileToBePersisted, {
        id: this.activeProfile.id,
        impersonating: true
      })
    }
    const activeProfileString = JSON.stringify(profileToBePersisted || {})

    this.updateSessionData(PROFILE_SESSION_KEY, activeProfileString)
  },

  _createProfileObject(profile, organization) {
    const profileType = profile.profileType
    const icon = iconForProfile(profileType)
    const profileIsContact = this._isContact(profileType)
    let isAgencyAgreementActive = false

    // This looks really similar to the computed property on this service with the same name.
    // I believe the computed property takes in to account the current locale which can be changed,
    // so that computed property will be more correct. The names calculated here is also used in
    // the menu, as _createProfileObject() is used when creating profile objects.
    const displayName =
      profileIsContact && organization ? organization.name : this.intl.t(`profiles.${profileType}`)
    let type
    let id = this.get('_person.id')

    if (profileIsContact) {
      id = organization.id
      if (organization.producer_company) {
        type = PRODUCER_COMPANY
      }
      if (organization.agency) {
        type = AGENCY
      }
    } else {
      const lastAgencyAgreement = profile.agencyAgreements
        ? profile.agencyAgreements.slice(-1)[0]
        : null

      if (lastAgencyAgreement) {
        isAgencyAgreementActive = checkIfAgencyAgreementActive(lastAgencyAgreement)
      }
    }

    const profileNameFull = camelize([profileType, type].compact().join('-'))

    return {
      userId: this.userId,
      profile: profileType,
      profileNameFull,
      displayName,
      icon,
      id,
      type,
      isAgencyAgreementActive
    }
  },

  _isContact(profileType) {
    return profileType === CONTACT
  },

  _isOrganizationPresent(id) {
    return isPresent(
      this.selectableProfileObjects.filter(selectableProfile => {
        return selectableProfile.id === id
      })
    )
  },

  _isInactive(profileObject) {
    const { profile, id } = this.activeProfile
    if (this._isContact(profile)) {
      return profileObject.id !== id
    }
    return profileObject.profile !== profile
  },

  _contactProfiles: computed('_positions', 'intl.locale', function () {
    return this._positions.map(({ organization }) =>
      this._createProfileObject({ profileType: CONTACT }, organization)
    )
  }),

  _simpleProfiles: computed('selectableProfiles', 'intl.locale', function () {
    return this.selectableProfiles
      .filter(profile => !this._isContact(profile.profileType))
      .map(profile => this._createProfileObject(profile))
  })
})
