import { get } from '@ember/object'

export const getLedgerEntriesForContactPerson = contactPerson => {
  const entries = []
  if (contactPerson) {
    get(contactPerson, 'positions').forEach(item => {
      item.organization.ledgers.forEach(ledger => {
        ledger.entries.forEach(entry => entries.push(entry))
      })
    })
  }
  return entries
}
