import { alias } from '@ember/object/computed'
import { computed } from '@ember/object'
import Controller from '@ember/controller'

export default Controller.extend({
  numberOfTracks: computed(function () {
    return this.get('model.sides').reduce((acc, arr) => acc + arr.length, 0)
  }),

  numberOfPerformers: alias('model.performers.length'),

  releaseYear: computed(function () {
    return new Date(this.get('model.release_date')).getFullYear()
  }),

  sidebarLinks: [
    {
      id: '#album-info',
      name: 'album.sidebar.album-info',
      active: true
    },
    {
      id: '#track-info',
      name: 'album.sidebar.tracks'
    },
    {
      id: '#', // TODO: add id here after performers section is added
      name: 'album.sidebar.performers'
    }
  ],

  actions: {
    updateRecordingCountry(value) {
      this.set('model.recording_country', value)
    },
    updateProducingCountry(value) {
      this.set('model.producing_country', value)
    }
  }
})
