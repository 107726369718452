export default {
  "css-modules-active-route-app": "_css-modules-active-route-app_z1xmmd",
  "controls": "_controls_z1xmmd",
  "claims-list-link": "_claims-list-link_z1xmmd",
  "recordings-list": "_recordings-list_z1xmmd",
  "ignored": "_ignored_z1xmmd",
  "file-list": "_file-list_z1xmmd",
  "file-list--row": "_file-list--row_z1xmmd",
  "remove-file": "_remove-file_z1xmmd",
  "delete-file": "_delete-file_z1xmmd",
  "file-description": "_file-description_z1xmmd"
};
