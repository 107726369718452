import Component from '@glimmer/component'
import { inject as service } from '@ember/service'
import { action } from '@ember/object'
import { durationAsHms } from 'echo-ember-common/helpers/duration-as-hms'

export default class TotalPlaytimeDashlet extends Component {
  @service router

  get totalPlaytimeInHours() {
    return durationAsHms([this.args.totalPlaytime])
  }

  @action
  goToPlayedRecordings() {
    this.router.transitionTo('user.played-recordings')
  }
}
