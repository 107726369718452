import Mixin from '@ember/object/mixin'
import { inject } from '@ember/service'

export default Mixin.create({
  intl: inject(),

  generalErrorKey: 'base',

  /**
   * The server returns errors on form
   *
   *   { key: 'name', message: 'must be filled'},
   *   { key: 'name', message: 'some other message'},
   *   { key: 'base', message: 'some general error message'},
   *   { key: 'base', message: 'some other general error message'}
   * ]
   *
   * This function will extract the messages for
   * key 'base' (as a default) and flash them.
   *
   * Note: The errors are to be passed through min-side/mixins/error-formater
   * and it renames key to attribute.
   *
   * @see generalErrorKey
   */
  flashGeneralErrorMessage(errors) {
    const key = this.generalErrorKey
    const generalErrors = errors.filterBy('attribute', key)

    if (generalErrors.length > 0) {
      this.flashMessages.error(generalErrors.mapBy('message').join(', '))
    } else {
      this.flashMessages.error(this.intl.t('flash.save.invalid'))
    }
  }
})
