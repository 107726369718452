import Component from '@ember/component'
import { computed } from '@ember/object'
import { or } from '@ember/object/computed'
import { ROLE_CODES } from 'min-side/constants/role-codes'

export default Component.extend({
  tagName: 'li',
  'data-test-performer-box': true,

  selectedInstrument: computed('selectedInstruments', 'index', function () {
    return this.selectedInstruments[this.index]
  }),

  fullName: computed('performer.first_name', 'performer.last_name', function () {
    return `${this.get('performer.first_name')} ${this.get('performer.last_name')}`
  }),

  countries: computed('performer.current_citizenships', function () {
    return (
      this.get('performer.current_citizenships')
        .map(c => c.country.name)
        .join(', ') || []
    )
  }),

  hideRemoveBtn: or('disallowRemoving', 'disabled'),

  init(...args) {
    this._super(...args)

    this.set('roleCodes', ROLE_CODES)
  },

  actions: {
    removePerformer() {
      this.removePerformerAction(this.index)
    },

    instrumentChanged(instrument) {
      if (!instrument) {
        return
      }
      this.selectedInstruments.splice(this.index, 1, instrument)
      this.set('performer.instrument_code', instrument.id)
      this.notifyPropertyChange('selectedInstruments')
    }
  }
})
