import Route from '@ember/routing/route'
import { inject } from '@ember/service'
import { isPresent } from '@ember/utils'
import performerQuery from 'min-side/graphql/queries/members-recordings-performer'
import producerQuery from 'min-side/graphql/queries/members-recordings-personal-producer'
import producerCompanyQuery from 'min-side/graphql/queries/members-recordings-producer-company'
import { queryManager } from 'ember-apollo-client'

export default Route.extend({
  apollo: queryManager(),
  profile: inject(),
  router: inject(),
  features: inject(),

  queryParams: {
    q: { refreshModel: true },
    property: { refreshModel: true },
    direction: { refreshModel: true },
    after: { refreshModel: true },
    first: { refreshModel: true }
  },

  beforeModel() {
    if (!this.features.get('discography_rework')) {
      this.router.transitionTo('')
    }
  },

  model({ q, property, direction, after, first }) {
    const currentProfile = this.profile.current
    const order = []
    if (property && direction) {
      order.push({ property, direction })
    }

    const variables = {
      query: isPresent(q) ? q : null,
      order,
      after,
      first,
      played: null
    }

    let query

    if (currentProfile.meta.companyType === 'producerCompany') {
      query = producerCompanyQuery
      variables.organization_id = currentProfile.meta.id
    } else if (currentProfile.meta.profileName === 'performer') {
      query = performerQuery
    } else if (currentProfile.meta.profileName === 'personalProducer') {
      query = producerQuery
    }

    this.controllerFor('user.recordings').set('loading', true)
    return this.apollo.query({ query, variables, fetchPolicy: 'network-only' }).then(response => {
      this.controllerFor('user.recordings').set('loading', false)
      return response
    })
  },

  actions: {
    onOrderByChange(property, direction) {
      this.transitionTo({ queryParams: { property, direction, after: null } })
    }
  }
})
