import Component from '@ember/component'
import { inject } from '@ember/service'

export default Component.extend({
  tagName: '',
  expanded: false,
  profile: inject(),
  actions: {
    expand() {
      this.toggleProperty('expanded')
    }
  }
})
