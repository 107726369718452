import Mixin from '@ember/object/mixin'
import { inject } from '@ember/service'
import config from '../config/environment'
import Ember from 'ember'

/**
 * Handles the the user's current locale
 *
 * Sets and maintains the current locale selected by a logged in user
 */
const STORAGE_KEY = 'locale'

export default Mixin.create({
  moment: inject(),
  intl: inject(),
  intercom: inject(),
  cookies: inject(),
  currentLocale: localStorage.getItem(STORAGE_KEY) || config.intl.defaultLocale,

  setLocale(newLocale) {
    if (newLocale === this.currentLocale) {
      return
    }

    localStorage.setItem(STORAGE_KEY, newLocale)
    this.set('currentLocale', newLocale)
    this._setLocale()
    if (this.get('cookies').isEnabled()) {
      this.intercom.shutdown()
      this.intercom.start({ language_override: newLocale })
    }
  },

  async toggleLanguage(requireReload = false) {
    const newLocale = this.currentLocale === 'nb' ? 'en' : 'nb'
    localStorage.setItem(STORAGE_KEY, newLocale)
    this.set('currentLocale', newLocale)

    if (requireReload && window.confirm(this.intl.t('main-menu.confirm-switch-language'))) {
      this._setLocale()
      this._locationReload()
    } else {
      this._setLocale()
      if (this.get('cookies').isEnabled()) {
        this.intercom.shutdown()
        this.intercom.start({ language_override: newLocale })
      }
    }
  },

  _setLocale() {
    const moment = this.moment
    const locale = this.currentLocale
    const timeZone = 'Europe/Oslo'

    this.intl.setLocale(locale)
    moment.changeLocale(locale)
    moment.changeTimeZone(timeZone)
  },

  _locationReload() {
    if (!Ember.testing) {
      window.location.reload()
    }
  }
})
