import { mapBy, map, sum } from '@ember/object/computed'
import { inject } from '@ember/service'
import { computed } from '@ember/object'

import { durationAsHms } from 'echo-ember-common/helpers/duration-as-hms'
import { toLocaleString } from 'echo-ember-common/helpers/to-locale-string'

import RemunerationController from '../../controller'

export default RemunerationController.extend({
  profile: inject(),
  intl: inject(),

  diffEdges: computed(
    'profile.activeProfile',
    'remunerationEntry.snapshot.diff.edges',
    function () {
      return this.get('remunerationEntry.snapshot.diff.edges') || []
    }
  ),

  nodes: mapBy('diffEdges', 'node'),

  firstNode: computed('nodes', function () {
    return this.get('nodes.firstObject')
  }),

  recording: computed('firstNode', function () {
    return this.get('firstNode.recording')
  }),

  renumerationId: computed('ledgerId', function () {
    return this.ledgerId
  }),

  subSubHeader: computed('firstNode', 'intl.locale', function () {
    const firstNode = this.firstNode
    if (!firstNode) return null
    switch (this.get('profile.activeProfile.profile')) {
      case 'performer':
        return this.intl.t('ledger.remuneration.recording.points', {
          roleCodes: firstNode.new_role_codes.join('/'),
          points: firstNode.new_base_points
        })
      case 'personal-producer':
        return `${this.intl.t(
          'edit_recording.ownership.header'
        )} ${firstNode.new_ownership_share_size}%`
      default:
        return ''
    }
  }),

  newTotalMoneyAmounts: mapBy('nodes', 'new_total_amount'),
  newTotalAmounts: map('newTotalMoneyAmounts', function (amount) {
    // because MoneyAmount is serialized to string in graphql..
    const radix = 10
    return parseFloat(amount, radix)
  }),
  newPlayCount: mapBy('nodes', 'new_play_count'),
  newDurations: mapBy('nodes', 'new_duration_in_seconds'),

  totalRemuneration: sum('newTotalAmounts'),
  totalPlayCount: sum('newPlayCount'),
  totalPlaytime: sum('newDurations'),

  metaFields: computed(
    '{totalRemuneration,totalPlaybacks,totalPlaytime}',
    'intl.locale',
    function () {
      const totalRemuneration = this.totalRemuneration
      const totalPlayCount = this.totalPlayCount
      const totalPlaytime = durationAsHms([this.totalPlaytime])
      return [
        {
          title: this.intl.t('ledger.remuneration.recording.headers.total-remuneration'),
          value: toLocaleString([totalRemuneration])
        },
        {
          title: this.intl.t('ledger.remuneration.recording.headers.total-play-count'),
          value: totalPlayCount
        },
        {
          title: this.intl.t('ledger.remuneration.recording.headers.total-playtime'),
          value: totalPlaytime
        }
      ]
    }
  )
})
