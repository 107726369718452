import { getCitizenshipCountryNames } from 'min-side/extractors/performer'

export const mapChanges = changes => {
  return changes.map(change => {
    const value = (() => {
      switch (change.operation) {
        case 'modification':
        case 'addition':
          return change.new_value
        case 'deletion':
          return change.old_value
      }
    })()

    const performer = value.performer.person

    return {
      operation: change.operation,
      name: `${performer.first_name} ${performer.last_name}`,
      countries: getCitizenshipCountryNames(performer),
      instrument_code: value.instrument ? value.instrument.code : undefined,
      role_code: value.role_codes[0],
      old_role_code: change.operation === 'modification' ? change.old_value.role_codes[0] : null
    }
  })
}
