import Controller from '@ember/controller'
import { computed } from '@ember/object'
import { reads } from '@ember/object/computed'
import { inject as service } from '@ember/service'
import { action } from '@ember/object'

export default class GlobalSearchResultsController extends Controller {
  queryParams = [
    {
      query: {
        scope: 'controller'
      }
    },
    'include_person_discography',
    'include_gramo_registry'
  ]
  query = null
  include_person_discography = false
  include_gramo_registry = false
  @service intl
  @service router
  @service features
  @service profile

  @reads('router.currentRouteName') currentRouteName

  get hasDiscography() {
    return this.profile.current.meta.hasDiscography
  }

  @computed(
    'model.{myRecordings,myReleases}.total',
    'include_person_discography',
    'include_gramo_registry'
  )
  get myDiscographyTotal() {
    if (this.hasDiscography) {
      return this.model.myRecordings.total + this.model.myReleases.total
    }
  }

  @computed(
    'model.{gramoRecordings,gramoReleases}.total',
    'include_person_discography',
    'include_gramo_registry'
  )
  get gramosDiscographyTotal() {
    return this.model.gramoRecordings.total + this.model.gramoReleases.total
  }

  @computed(
    'model.{myRecordings,gramoRecordings}.total',
    'include_person_discography',
    'include_gramo_registry'
  )
  get recordings() {
    if (this.include_gramo_registry || !this.hasDiscography) {
      return this.model.gramoRecordings
    }

    return this.model.myRecordings
  }

  @computed(
    'model.{myReleases,gramoReleases}.total',
    'include_person_discography',
    'include_gramo_registry'
  )
  get releases() {
    if (this.include_gramo_registry || !this.hasDiscography) {
      return this.model.gramoReleases
    }

    return this.model.myReleases
  }

  @computed('{releases,recordings}.total', 'intl.locale')
  get tabs() {
    return [
      {
        name: this.intl.t('global-search-results.tabs.releases'),
        route: 'user.global-search-results.releases',
        total: this.releases.total
      },
      {
        name: this.intl.t('global-search-results.tabs.recordings'),
        route: 'user.global-search-results.recordings.index',
        total: this.recordings.total
      }
    ]
  }

  @computed('include_person_discography', 'include_gramo_registry')
  get isGramoDiscography() {
    return this.include_gramo_registry
  }

  @action
  changeFilter(type) {
    this.router.transitionTo(this.currentRouteName, {
      queryParams: {
        query: this.query,
        include_person_discography: type === 'include_person_discography',
        include_gramo_registry: type === 'include_gramo_registry',
        after: null,
        property: undefined,
        direction: undefined
      }
    })
  }
}
