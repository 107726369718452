import Route from '@ember/routing/route'
import query from 'min-side/graphql/queries/ledger/remuneration-by-recording'
import { queryManager } from 'ember-apollo-client'
import { inject } from '@ember/service'
import ResetControllerHook from 'min-side/mixins/overwrite-apollo-client-reset-controller-hook'
import EmberObject from '@ember/object'

export default Route.extend(ResetControllerHook, {
  profile: inject(),
  apollo: queryManager(),
  renderTemplate(controller, model) {
    this._super(controller, model)

    this.render('user.ledger.remuneration.header-remuneration-entry-description', {
      outlet: 'remuneration-description',
      controller: this.controllerFor('user.ledger.remuneration.by-recording.index')
    })
    this.render('user.ledger.remuneration.download', {
      outlet: 'ledger-tabs-controls',
      into: 'user.ledger.remuneration.by-recording',
      controller: this.controllerFor('user.ledger.remuneration.by-recording.index')
    })
  },
  async model() {
    const { id } = this.paramsFor('user.ledger.remuneration')
    const maskId = this.get('profile.impersonatedUserId')
    const profileName = this.get('profile.activeProfile.profile')
    let includePerformer = true
    let includeProducer = true

    if (maskId !== null) {
      includePerformer = profileName === 'performer'
      includeProducer = profileName === 'personal-producer'
    }

    const variables = {
      id,
      rightsholder_id: this.get('profile.activeProfile.id'),
      person_id: maskId,
      include_performer: includePerformer,
      include_producer: includeProducer
    }
    const rawModel = await this.apollo.query({ query, variables })
    return EmberObject.extend({ ...rawModel }).create()
  }
})
