import Controller from '@ember/controller'
import { reads } from '@ember/object/computed'

export default Controller.extend({
  queryParams: ['q', 'after'],
  q: null,
  after: null,
  searchQuery: reads('q'),
  actions: {
    onSearch(query) {
      if (!query || query === '') return this.set('q', null)
      this.setProperties({
        q: query,
        after: null
      })
    },

    onPageChange(_, after) {
      this.set('after', after)
    }
  }
})
