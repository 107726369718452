import Mixin from '@ember/object/mixin'
import { computed, getWithDefault, get } from '@ember/object'
import { equal, or } from '@ember/object/computed'
import { inject } from '@ember/service'
import moment from 'moment'

const DEFAULT_VALUE = '-'

export default Mixin.create({
  intl: inject(),

  noRecordings: equal('formData.recordings.[].length', 0),

  disableSave: or(
    'isEditing',
    'isComplete',
    'noRecordings',
    'saveReleaseTask.isRunning',
    'formSuccessfullySubmitted'
  ),

  activeOwnerships: computed('formData.releaseInfo.recording_label.ownerships', function () {
    const ownerships = this.get('formData.releaseInfo.recording_label.ownerships')
    if (!ownerships) return []
    const now = moment()
    now.set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
    return ownerships.filter(function (item) {
      let endsAt = get(item, 'ends_at')
      if (endsAt === null) {
        return true
      }
      endsAt = moment(endsAt)
      endsAt.set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
      return now.diff(endsAt) <= 0
    })
  }),

  labelDetails: computed('formData.releaseInfo.recording_label', function () {
    const activeOwnership = this.getWithDefault('activeOwnerships.firstObject', {})

    return {
      name: getWithDefault(this, 'formData.releaseInfo.recording_label.name', DEFAULT_VALUE),
      shares: this._getShareDetails(activeOwnership)
    }
  }),

  _getShareDetails(ownership) {
    const shares = getWithDefault(ownership, 'shares', [])

    return shares.map(share => {
      return {
        starts_at: getWithDefault(ownership, 'starts_at', null),
        ends_at: getWithDefault(ownership, 'ends_at', null),
        value: share.size,
        producer_id: getWithDefault(share, 'producer.id', null),
        producer_name: getWithDefault(share, 'producer.name', this.intl.t('unknown-s')),
        producer_country: getWithDefault(share, 'producer.country.name', DEFAULT_VALUE)
      }
    })
  }
})
