import Route from '@ember/routing/route'
import query from 'min-side/graphql/queries/ledger/remuneration-recording'
import { queryManager } from 'ember-apollo-client'
import { inject } from '@ember/service'
import EmberObject from '@ember/object'

export default Route.extend({
  profile: inject(),
  apollo: queryManager(),
  async model() {
    const { id } = this.paramsFor('user.ledger.remuneration')
    const { recording_id } = this.paramsFor('user.ledger.remuneration.recording')
    this.set('recordingId', recording_id)
    const maskId = this.get('profile.impersonatedUserId')
    const profileName = this.get('profile.activeProfile.profile')
    let includePerformer = true
    let includeProducer = true

    if (maskId !== null) {
      includePerformer = profileName === 'performer'
      includeProducer = profileName === 'personal-producer'
    }

    const variables = {
      id,
      recording_id,
      rightsholder_id: this.get('profile.activeProfile.id'),
      person_id: maskId,
      include_performer: includePerformer,
      include_producer: includeProducer
    }

    return EmberObject.extend({ ...(await this.apollo.watchQuery({ query, variables })) }).create()
  },

  setupController(controller, model) {
    this._super(controller, model)

    const id = this.paramsFor('user.ledger.remuneration').id
    controller.set('ledgerId', id)
  }
})
