import Controller from '@ember/controller'
import { action } from '@ember/object'
import { reads } from '@ember/object/computed'
import { inject as service } from '@ember/service'
import { inject as injectController } from '@ember/controller'

const FIRST = 10
export default class BaseGlobalSearchResultsController extends Controller {
  queryParams = ['property', 'direction', 'after', 'first']
  first = FIRST
  after = null
  @service router
  @service intl
  @reads('router.currentRouteName') currentRouteName

  @injectController('user.global-search-results') parentController

  @action
  onPageChange(_first, after) {
    this.router.transitionTo(this.router.currentRouteName, {
      queryParams: { after }
    })
  }

  @action
  onOrderByChange(property, direction) {
    this.router.transitionTo(this.router.currentRouteName, {
      queryParams: {
        property,
        direction,
        after: null
      }
    })
  }
}
