import { getCitizenshipCountryNames } from 'min-side/extractors/performer'

const mapPersonalProducer = producer => {
  return {
    producer_id: producer.id,
    producer_name: `${producer.as_public_person_or_public_company.first_name} ${producer.as_public_person_or_public_company.last_name}`,
    producer_country: getCitizenshipCountryNames(producer.as_public_person_or_public_company)
  }
}

const mapProducerCompany = producer => {
  return {
    producer_id: producer.id,
    producer_name: producer.as_public_person_or_public_company.name,
    producer_country: producer.as_public_person_or_public_company.country.name
  }
}

export const mapProducer = producer => {
  const mapper = producer.type === 'PERSONAL_PRODUCER' ? mapPersonalProducer : mapProducerCompany
  return mapper(producer)
}

export const getProducers = producer_nodes => {
  const producers = []
  const mixedProducers = producer_nodes.search_producers.edges.map(e => e.node)

  mixedProducers.forEach(producer => {
    producers.push(mapProducer(producer))
  })

  return producers
}
