import { computed } from '@ember/object'
import Component from '@ember/component'

export default Component.extend({
  tagName: 'tr',
  inherited: computed('inheritance.inherited.{first_name,last_name}', function () {
    const person = this.get('inheritance.inherited')
    const { first_name, last_name } = person
    return [first_name, last_name].join(' ')
  })
})
