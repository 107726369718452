import Route from '@ember/routing/route'
import { isEmpty } from '@ember/utils'

export default class ChangeRequestIndexRoute extends Route {
  beforeModel(transition) {
    const id = transition.to.queryParams.id
    const token = transition.to.queryParams.token
    if (isEmpty(id) || isEmpty(token)) {
      transition.abort()
      this.replaceWith('/not-found')
    }
  }
}
