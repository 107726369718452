import { inject } from '@ember/service'
import { getWithDefault } from '@ember/object'
import Route from '@ember/routing/route'
import { run } from '@ember/runloop'
import RSVP, { hash } from 'rsvp'
import mutation from 'min-side/graphql/mutations/onboarding/update-onboarding-info'
import organizationMembershipMutation from 'min-side/graphql/mutations/onboarding/update-organization-membership-info'
import organizationContactMutation from 'min-side/graphql/mutations/onboarding/update-organization-contact-info'
import { queryManager } from 'ember-apollo-client'
import ErrorFormater from 'min-side/mixins/error-formater'
import { isPresent } from '@ember/utils'
import { getCurrentOrganization } from 'min-side/helpers/contact'
import {
  inputVariables,
  contactMembershipInputVariables,
  contactContactInputVariables
} from 'min-side/graphql/helpers/onboarding-input'
import mostPlayedRecordingsPerformerQuery from 'min-side/graphql/queries/most-played-recordings-performer'
import mostPlayedRecordingsProducerQuery from 'min-side/graphql/queries/most-played-recordings-producer'
import mostPlayedRecordingsProducerCompanyQuery from 'min-side/graphql/queries/most-played-recordings-producer-company'
import ledgerSummaryQuery from 'min-side/graphql/queries/ledger/ledger-summary'
import { sectorForUser } from 'min-side/helpers/user-types'

export default Route.extend(ErrorFormater, {
  intl: inject(),
  profile: inject(),
  onboarding: inject(),
  fetchUser: inject(),
  apollo: queryManager(),

  resetController(controller) {
    controller.set('documentId', null)
  },

  async model() {
    // Didn't put this inside hash() as we have to be sure that
    // "fetchUser.userTask" is loaded, before we can access profile here :-(
    const userTask = await this.get('fetchUser.userTask').perform()

    const toLoad = {
      userTask
    }

    const currentProfile = this.profile.current

    if (currentProfile.meta.companyType !== 'agency') {
      const sector = sectorForUser(this.get('profile.activeProfile'))

      // Load data used for ledger based dashlets
      const YEARS_TO_SHOW = 4
      const date = new Date()
      const startYear = date.getFullYear() - YEARS_TO_SHOW
      const variables = {
        owner_id: currentProfile.meta.id,
        sector,
        remuneration_years: Array.from({ length: YEARS_TO_SHOW }, (v, k) => k + startYear) // array of the last 4 years
      }
      const ledgerSummaryPromise = this.apollo.query({ query: ledgerSummaryQuery, variables })

      toLoad.lastPayment = ledgerSummaryPromise.then(result => {
        const edges = getWithDefault(result, 'ledger.payments.edges', [])
        return edges[0] ? edges[0].node : { totalAmount: 0 }
      })
      toLoad.currentBalance = ledgerSummaryPromise.then(result => {
        return getWithDefault(result, 'ledger.currentBalance', 0)
      })
      toLoad.remunerationYears = ledgerSummaryPromise.then(result => {
        return getWithDefault(result, 'ledger.remunerationYears', [])
      })

      // Load data used for playtime dashlets
      let query
      let personId = currentProfile.meta.id
      if (currentProfile.meta.companyType === 'producerCompany') {
        query = mostPlayedRecordingsProducerCompanyQuery
        personId = null
      } else if (currentProfile.meta.profileName === 'performer') {
        query = mostPlayedRecordingsPerformerQuery
      } else if (currentProfile.meta.profileName === 'personalProducer') {
        query = mostPlayedRecordingsProducerQuery
      }
      if (query) {
        const mostPlayed = await this.apollo.query({
          query,
          variables: { person_id: personId }
        })
        if (currentProfile.meta.companyType === 'producerCompany') {
          const organization = getCurrentOrganization(
            this.get('profile.activeProfile'),
            mostPlayed.user.person
          )
          toLoad.mostPlayedRecordings = organization.producer_company.recordings.edges.map(
            ({ node }) => node
          )
          toLoad.producerId = organization.producer_company.id
        } else {
          toLoad.mostPlayedRecordings = mostPlayed.user.person.sector.recordings.edges.map(
            ({ node }) => node
          )

          if (currentProfile.meta.profileName === 'personalProducer') {
            toLoad.producerId = mostPlayed.user.person.sector.id
            toLoad.totalPlaytime = userTask.user.person.personal_producer.recordings.total_playtime
          } else if (currentProfile.meta.profileName === 'performer') {
            toLoad.totalPlaytime = userTask.user.person.performer.recordings.total_playtime
          }
        }
      }
    }

    return hash(toLoad)
  },

  savePersonInfo() {
    const person = this.currentModel.userTask.user.person
    const activeProfile = this.get('profile.activeProfile')
    const variables = inputVariables(person, activeProfile)
    return this.apollo.mutate({ mutation, variables }).then(data => {
      return this.handleResponse(data.update_onboarding_info.errors)
    })
  },

  saveOrgInfo() {
    const organization = getCurrentOrganization(
      this.get('profile.activeProfile'),
      this.currentModel.userTask.user.person
    )
    const promises = []

    const membershipVariables = contactMembershipInputVariables(
      organization,
      this.currentModel.userTask.user.person.inputAccountType
    )
    if (isPresent(membershipVariables)) {
      promises.push(
        this.apollo.mutate({
          mutation: organizationMembershipMutation,
          variables: membershipVariables
        })
      )
    }

    const contactVariables = contactContactInputVariables(organization)
    this.apollo.mutate({
      mutation: organizationContactMutation,
      variables: contactVariables
    })

    return Promise.all(promises).then(data => {
      return run(_ => {
        const errors = data.reduce((acc, response) => {
          const errors = response.return_data.errors
          if (errors) {
            errors.forEach(err => acc.push(err))
          }
          return acc
        }, [])
        return this.handleResponse(errors)
      })
    })
  },

  handleResponse(errors) {
    if (!errors || !errors.length) {
      this.onboarding.dismissOnboarding()
      return RSVP.resolve({ success: true })
    }
    this.currentModel.set('errors', this.formatErrors(errors))
    return RSVP.reject({ validationErrors: this.formatErrors(errors) })
  },

  actions: {
    saveOnboardingInfo() {
      return this.savePersonInfo()
    },

    saveOrgOnboardingInfo() {
      return this.saveOrgInfo()
    },

    globalSearch(target, query, filters) {
      this.transitionTo(`user.global-search.${target}`, { queryParams: { query, ...filters } })
    }
  }
})
