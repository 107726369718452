import Controller from '@ember/controller'
import { inject } from '@ember/service'
import { computed, get } from '@ember/object'

import { profileNameInModel } from 'min-side/helpers/profiles'
import { getLedgerEntriesForContactPerson } from 'min-side/extractors/person'

export default Controller.extend({
  profile: inject(),

  profileName: computed('profile.activeProfile', {
    get() {
      if (this.__profileName) {
        return this.__profileName
      }
      return profileNameInModel(get(this, 'profile.activeProfile.profile'))
    },
    set(key, value) {
      this.__profileName = value
      return value
    }
  }),

  ledgerEntries: computed('profileName', 'model.recordingId', function () {
    const profileName = get(this, 'profileName')
    return profileName === 'contact'
      ? getLedgerEntriesForContactPerson(get(this, 'model.user.person.contact'))
      : this.getWithDefault(`model.user.person.${profileName}.ledger.entries`, [])
  }),

  remunerationEntry: computed('profileName', 'ledgerEntries', function () {
    return get(this, 'ledgerEntries').find(({ typename }) => typename === 'LedgerRemunerationEntry')
  })
})
