import { inject as service } from '@ember/service'
import Controller from '@ember/controller'

export default Controller.extend({
  router: service(),
  queryParams: ['state', 'term'],

  states: ['SUBMITTED', 'COMPLETED', 'REQUESTED_CHANGES'],

  actions: {
    setState(value) {
      this.set('state', value)
    },

    openRejectedClaimRecording(claim) {
      this.router.transitionTo('user.claims.item', claim.node.id, {
        queryParams: { openRejectedClaimRecording: true, sector: this.model.sector }
      })
    },

    async onSearch(query) {
      this.set('term', query || null)
    }
  }
})
