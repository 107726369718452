import Component from '@glimmer/component'
import { durationAsHms } from 'echo-ember-common/helpers/duration-as-hms'
import { computed } from '@ember/object'
import { action } from '@ember/object'
import { inject } from '@ember/service'

export default class Row extends Component {
  @inject router
  @inject features

  @computed('@playback.duration_in_seconds')
  get duration() {
    return durationAsHms([this.args.playback.playtime_in_seconds])
  }

  @computed('@playback.ownerships', '@producerId')
  get shareSize() {
    if (!this.args.producerId) return
    if (!this.args.playback.ownerships) return
    if (!this.args.playback.ownerships.length) return

    const share = this.args.playback.ownerships[0].shares.find(s => {
      return s.producer.id === this.args.producerId
    })
    if (!share) return

    return `${share.size}%`
  }

  @action
  redirectToRecording() {
    if (this.features.get('discography_rework')) {
      this.router.transitionTo('user.recording', this.args.playback.id)
    } else {
      this.router.transitionTo('user.edit-recording', this.args.playback.id)
    }
  }
}
