/**
 * Helper function to get a "type" from a node (recording or release).
 * The Type is here to help the UI out determin if the given node is
 * a user related node, or gramo's global disco.
 *
 * This is kind of here for legacy reasons, as I'm not really sure if I
 * would have put this knowledge in the "extractor" close to the data retrievel.
 * However, it was here to begin with, so I'll let it be here too.
 */
const toLocalOrGlobal = (node, userIds) => {
  if (userIds.includes(node.id)) {
    return 'local'
  }

  return 'global'
}

const getReleases = data => {
  const userReleaseIds = data.public_search_releases.user_discography_release_ids || []

  const releases = data.public_search_releases.edges.map(e => {
    const node = e.node
    const uiSourceType = toLocalOrGlobal(node, userReleaseIds)

    return { ...node, uiSourceType }
  })

  return {
    total: data.public_search_releases.total,
    total_gramo: data.public_search_releases.total_gramo_releases,
    total_user: data.public_search_releases.total_user_releases,
    pageInfo: data.public_search_releases.pageInfo,
    releases
  }
}

const getRecordings = data => {
  const userRecordingIds = data.public_search_recordings.user_discography_recording_ids || []

  const recordings = data.public_search_recordings.edges.map(e => {
    const node = e.node
    const uiSourceType = toLocalOrGlobal(node, userRecordingIds)

    return { ...node, uiSourceType }
  })

  return {
    total: data.public_search_recordings.total,
    total_gramo: data.public_search_recordings.total_gramo_recordings,
    total_user: data.public_search_recordings.total_user_recordings,
    pageInfo: data.public_search_recordings.pageInfo,
    recordings
  }
}

export { getReleases, getRecordings }
