import Component from '@ember/component'
import { computed } from '@ember/object'

export default Component.extend({
  tagName: '',

  _hasType(type) {
    return (
      (this.get('model.new_report_types') || []).includes(type) ||
      (this.get('model.old_report_types') || []).includes(type)
    )
  },

  reportTypePlayCount: computed('model.{new_report_types,old_report_types}', function () {
    return this._hasType('PLAY_COUNT')
  }),

  reportTypeDuration: computed('model.{new_report_types,old_report_types}', function () {
    return this._hasType('DURATION')
  })
})
