import Component from '@ember/component'
import { computed } from '@ember/object'

export default Component.extend({
  tagName: 'table',
  classNames: ['table table--fluid table--list table--five'],
  'data-test-search-producers-list': true,

  translationKeys: computed(() => ['search_producers_box.name', 'search_producers_box.country'])
})
