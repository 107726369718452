import Route from '@ember/routing/route'
import { queryManager } from 'ember-apollo-client'
import query from 'min-side/graphql/queries/clients/agreements-for-agency'
import { inject } from '@ember/service'
import { getAgreementsFragment, getPageInfo, getTotalAmount } from 'min-side/extractors/agency'

export default agreementName =>
  Route.extend({
    apollo: queryManager(),
    profile: inject(),

    queryParams: {
      q: { refreshModel: true },
      after: { refreshModel: true }
    },

    model({ q, after }) {
      const variables = {
        after,
        query: q,
        first: 10,
        only_active: true
      }
      return this.apollo.query({ query, variables })
    },

    setupController(controller, model) {
      this._super(controller, model)
      const actualAgencyId = this.get('profile.activeProfile.id')
      controller.setProperties({
        pageInfo: getPageInfo(model, actualAgencyId, agreementName),
        agreements: getAgreementsFragment(model, actualAgencyId, agreementName),
        performerAgreementsCount: getTotalAmount(model, actualAgencyId, 'performer'),
        producerAgreementsCount: getTotalAmount(model, actualAgencyId, 'producer')
      })
    }
  })
