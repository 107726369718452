import Service from '@ember/service'
import { inject } from '@ember/service'
import { urlFor } from 'min-side/utils/url-helpers'

export default Service.extend({
  router: inject(),
  intl: inject(),
  flashMessages: inject(),
  ajaxRequests: inject(),

  verify(invitationToken) {
    const ajaxRequests = this.get('ajaxRequests')
    return ajaxRequests
      .getRequest(urlFor(`admin/onboarding_invitation/${invitationToken}`))
      .catch(() => this.router.transitionTo('invalid-invitation-token'))
  },

  submit(data) {
    const ajaxRequests = this.get('ajaxRequests')
    return ajaxRequests
      .postRequest(urlFor('admin/onboarding_invitation'), data)
      .then(res => {
        if (!res.ok) throw res
        this.router.transitionTo('login')
      })
      .catch(async err => {
        const error = await err.json()
        const responseError = error.payload.errors[0].detail
        let errorTranslationKey = null

        if (responseError.email) {
          errorTranslationKey =
            responseError.email === 'invalid'
              ? 'flash.save.invitation_invalid_email'
              : 'flash.save.invitation_missing_email'
        } else {
          errorTranslationKey = 'flash.save.invitation_missing_mobile'
        }

        this.flashMessages.error(this.intl.t(errorTranslationKey))
      })
  }
})
