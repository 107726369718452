import { validatePresence } from 'ember-changeset-validations/validators'

export const defaultPreviousNameObject = {
  description: '',
  name: ''
}

export default {
  name: validatePresence({
    presence: true,
    message: 'validation.empty'
  })
}
