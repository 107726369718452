import Route from '@ember/routing/route'
import { inject } from '@ember/service'
import { get } from '@ember/object'
import { isOrganisation, sectorForUser } from 'min-side/helpers/user-types'
import entryQuery from 'min-side/graphql/queries/ledger/foreign-remuneration-entry'
import entryForOrgQuery from 'min-side/graphql/queries/ledger/foreign-remuneration-entry-for-org'

import { queryManager } from 'ember-apollo-client'

export default Route.extend({
  profile: inject(),
  apollo: queryManager(),

  async model({ entry_id, sector }) {
    const ledgerResult = await this.getLedger(entry_id, sector)
    const ledger = this.getLedgerFromResult(ledgerResult)

    return this.getLedgerEntryFromLedger(ledger)
  },

  getLedger(id, sector) {
    const profile = this.get('profile.activeProfile')
    const maskId = this.get('profile.impersonatedUserId')
    const profileName = this.get('profile.activeProfile.profile')
    let includePerformer = true
    let includeProducer = true

    if (maskId !== null) {
      includePerformer = profileName === 'performer'
      includeProducer = profileName === 'personal-producer'
    }

    if (isOrganisation(profile)) {
      const selectedSector = sector === 'performer' ? 'PERFORMER' : 'PRODUCER'

      const variables = {
        id,
        owner_id: this.get('profile.activeProfile.id'),
        sector: selectedSector || sectorForUser(profile)
      }

      return this.apollo.query({ query: entryForOrgQuery, variables })
    }

    const variables = {
      id,
      person_id: maskId,
      include_performer: includePerformer,
      include_producer: includeProducer
    }

    return this.apollo.query({ query: entryQuery, variables })
  },

  getLedgerFromResult(result) {
    const activeProfile = this.get('profile.activeProfile.profile')
    if (!activeProfile) {
      return null
    }
    const profileName = this.get('profile.activeProfile.profile')
    if (activeProfile === 'contact') return get(result, 'ledger')
    return get(result, `user.person.${profileName}.ledger`)
  },

  getLedgerEntryFromLedger(ledger) {
    return ledger.entries[0]
  }
})
