export default {
  "discography-source-container": "_discography-source-container_12ct6t",
  "discography-source-title": "_discography-source-title_12ct6t",
  "my-discography": "_my-discography_12ct6t",
  "gramo-discography": "_gramo-discography_12ct6t",
  "result-category": "_result-category_12ct6t",
  "result-category-title": "_result-category-title_12ct6t",
  "result-category-title-search-term": "_result-category-title-search-term_12ct6t",
  "result-category-result-container": "_result-category-result-container_12ct6t"
};
