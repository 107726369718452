const PROFILE_TYPES = {
  PERFORMER: 'performer',
  PERSONAL_PRODUCER: 'personal-producer',
  INHERITANCE_PARTY: 'inheritance-party',
  CONTACT: 'contact',
  AGENCY: 'agency',
  PRODUCER_COMPANY: 'producer_company'
}

const GRAPHQL_PROFILE_TYPES = {
  INHERITANCE_PARTY: 'INHERITANCE_PARTY'
}

const PROFILE_TYPES_MAP = {
  performer: 'performer',
  'personal-producer': 'personal_producer',
  'inheritance-party': 'inheritance_party',
  contact: 'contact',
  agency: 'agency',
  producer_company: 'producer_company'
}

export { PROFILE_TYPES, PROFILE_TYPES_MAP, GRAPHQL_PROFILE_TYPES }
