import Component from '@ember/component'
import { inject } from '@ember/service'
import { computed } from '@ember/object'
import { alias } from '@ember/object/computed'

export default Component.extend({
  profile: inject(),
  router: inject(),

  tagName: 'tr',
  'data-test-clients-list-row': true,

  principalId: alias('client.agreement.principal_id'),
  startsOn: alias('client.agreement.starts_on'),
  endsOn: alias('client.agreement.ends_on'),
  files: alias('client.agreement.files'),
  firstName: computed(
    'client.first_name',
    'client.as_public_person_or_public_company.first_name',
    function () {
      return (
        this.get('client.first_name') ||
        this.get('client.as_public_person_or_public_company.first_name')
      )
    }
  ),
  lastName: computed(
    'client.last_name',
    'client.as_public_person_or_public_company.last_name',
    function () {
      return (
        this.get('client.last_name') ||
        this.get('client.as_public_person_or_public_company.last_name')
      )
    }
  ),
  artistName: alias('client.performer.artist_name'),

  displayName: computed(
    'firstName',
    'lastName',
    'artistName',
    'client.agreement.name',
    function () {
      if (this.get('firstName')) {
        let name = `${this.get('firstName')} ${this.get('lastName')}`
        if (this.get('artistName')) {
          name = `${name} (${this.get('artistName')})`
        }
        return name
      }
      return this.get('client.name')
    }
  ),

  actions: {
    async impersonate(personId, firstName, lastName) {
      await this.profile.impersonate(personId, firstName, lastName, this.clientType, true)
      this.router.transitionTo('/')
    }
  }
})
