import Controller from '@ember/controller'
import { get, set, getProperties } from '@ember/object'
import { tracked } from '@glimmer/tracking'
import { dropTask } from 'ember-concurrency-decorators'
import { inject as service } from '@ember/service'
import Changeset from 'ember-changeset'
import lookupValidator from 'ember-changeset-validations'
import {
  getContactInformation,
  hasAlternativeMobile,
  isForeignAddress
} from 'min-side/helpers/contact-information'
import ContactInfoValidations, {
  defaultContactInfoObject
} from 'min-side/validations/profile/contact-information'
import { mapChangesetErrors } from 'min-side/utils/changeset-helpers'
import { queryManager } from 'ember-apollo-client'
import mutation from 'min-side/graphql/mutations/personal-information'
import { formatErrors } from 'min-side/utils/changeset-helpers'

export default class ProfileInfoContactController extends Controller {
  @tracked changeset = null

  @queryManager apollo
  @service intl
  @service profileInfo

  setupChangeset(model) {
    const personData = get(model, 'user.person')
    this.changeset = this.constructor.setUpChangesetAndReturnIt(personData)
  }

  @dropTask
  *onContactlInfoValidate(changeset) {
    try {
      const contactInfo = changeset
      yield contactInfo.validate()

      const isValid = contactInfo.get('isValid')

      if (!isValid) {
        const validationErrors = this.constructor.collectChangesetError(contactInfo)
        const intlErrors = validationErrors.map(({ attribute, message }) => ({
          attribute,
          message: this.intl.t(message)
        }))

        return intlErrors
      }

      return []
    } catch (error) {
      throw error
    }
  }

  @dropTask
  *onContactlInfoSubmit(changeset) {
    try {
      const variables = {
        input: {
          ...this.constructor.extractChangesetData(changeset),
          id: this.profileInfo.maskId
        },
        include_performer: this.profileInfo.includePerformer,
        include_producer: this.profileInfo.includeProducer
      }

      const res = yield this.apollo.mutate({ mutation, variables }, 'update_personal_information')

      if (res && res.errors) {
        const formattedErrors = formatErrors(res.errors)

        return formattedErrors
      }

      this.badPracticeManuallyUpdateAlternativeMobile(this.model, variables.input)

      changeset.save()
      return []
    } catch (error) {
      throw error
    }
  }

  /**
   * Sets up the contact info object
   * @param data
   * @returns {{alternative_mobile: string, address: {care_of: string, street: string, postal_code: string, postal_area: string, content: string}, hasForeignAddress: boolean, mobile: {value: *}, telephone: {value: *}, webpage: string, email: string}}
   */
  static prepareContactInfoDataObject(data) {
    const { contact_information: contactInfoData = null } = data
    return {
      ...defaultContactInfoObject,
      ...contactInfoData,
      address: {
        ...defaultContactInfoObject.address,
        ...(contactInfoData && contactInfoData.address)
      },
      mobile: {
        value: contactInfoData.mobile
      },
      telephone: {
        value: contactInfoData.telephone
      },
      hasForeignAddress: isForeignAddress(contactInfoData)
    }
  }
  /**
   * Setup the changeset for contact info section and return it
   * @param data
   * @returns {Changeset}
   */
  static setUpChangesetAndReturnIt(data) {
    const contactInfo = this.prepareContactInfoDataObject(data)

    if (hasAlternativeMobile(data, 'user')) {
      contactInfo.alternative_mobile = {
        value: data.user.alternative_mobile
      }
    }

    return new Changeset(
      contactInfo,
      lookupValidator(ContactInfoValidations),
      ContactInfoValidations
    )
  }

  /**
   * Extract form data from changeset in a format that can be submitted
   * @param changeset
   * @returns {{alternative_mobile: (*|null), contact_information: (null|{mobile: *, telephone: *, webpage: *, email: *})}}
   */
  static extractChangesetData(changeset) {
    const changesetPropertiesToGet = [
      'mobile',
      'alternative_mobile',
      'telephone',
      'webpage',
      'hasForeignAddress',
      'email'
    ]
    const addressProperties = ['care_of', 'postal_area', 'postal_code', 'street', 'content']

    const addressData = addressProperties.reduce(
      (previousValue, currentValueKey) => ({
        ...previousValue,
        [currentValueKey]: changeset.get(`address.${currentValueKey}`)
      }),
      {}
    )
    const { ...contactInfoData } = getProperties(changeset, changesetPropertiesToGet)
    const combinedContactInfo = {
      ...contactInfoData,
      address: {
        ...addressData
      },
      mobile: contactInfoData.mobile.value,
      telephone: contactInfoData.telephone.value
    }

    const { hasForeignAddress, alternative_mobile, ...contactInfo } = combinedContactInfo

    return {
      alternative_mobile: alternative_mobile ? alternative_mobile.value : null,
      contact_information: getContactInformation(contactInfo, hasForeignAddress)
    }
  }

  static collectChangesetError(changeset) {
    return mapChangesetErrors('contact_information/', changeset.get('errors'))
  }

  // TODO[sergiu] maybe this can be avoided? if the model properly refreshes, I will have to check this later
  /*
   * Alternative mobile is not getting automatically updated in model
   * after successful section submit
   *
   * So we need to manually update alt. mob. value on success
   * in order to prevent overriding this value
   * by a following form submit
   */
  badPracticeManuallyUpdateAlternativeMobile(model, input) {
    if (input && 'alternative_mobile' in input) {
      set(model, 'user.person.contact_information.alternative_mobile', input.alternative_mobile)
    }
  }
}
