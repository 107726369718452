import Component from '@ember/component'

export default Component.extend({
  classNames: ['search__form'],
  tagName: 'form',
  'data-test-molecules-search': true,
  searchQuery: null,

  didRender() {
    if (this.autofocus === 'true') {
      this.element.querySelector('input').focus()
    }
  },

  submit(e) {
    e.preventDefault()
  },
  actions: {
    submitAction() {
      return this.onSubmit(this.searchQuery)
    }
  }
})
