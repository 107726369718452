import Component from '@ember/component'
import { computed, observer } from '@ember/object'
import { inject } from '@ember/service'
import { SEARCH_STATES } from 'min-side/constants/recording-search-states'

const { INIT_STATE, NEW_STATE, RECORDING_STATE, ERROR_STATE, ALREADY_IN_USE } = SEARCH_STATES

export default Component.extend({
  tagName: 'li',
  'data-test-new-release-recordings-search': true,

  intl: inject(),

  tryAgainButtonTranslationKey: 'molecules.release_form.recordings.reject_button',
  useThisButtonTranslationKey: 'molecules.release_form.recordings.approve_button',

  hideRemoveLink: false,
  showTrackInputs: true,

  note: computed('state', 'intl.locale', function () {
    switch (this.state) {
      case INIT_STATE:
        return {
          visible: false,
          labelCssClass: ''
        }
      case NEW_STATE:
        return {
          visible: true,
          textCssClass: 'form__input__valid',
          labelCssClass: 'is-valid--simple',
          translatedText: this.intl.t('molecules.release_form.recordings.isrc_available')
        }
      case RECORDING_STATE:
        return {
          visible: true,
          textCssClass: 'form__input__valid',
          labelCssClass: 'is-valid--simple',
          translatedText: this.intl.t('molecules.release_form.recordings.recording_found')
        }
      case ERROR_STATE:
        return {
          visible: true,
          textCssClass: 'form__input__errors',
          labelCssClass: 'has-error--simple',
          translatedText: this.intl.t('molecules.release_form.recordings.search_error')
        }
      case ALREADY_IN_USE:
        return {
          visible: true,
          textCssClass: 'form__input__errors',
          labelCssClass: 'has-error--simple',
          translatedText: this.intl.t(
            'molecules.release_form.recordings.isrc_already_in_use_error'
          ),
          showTryAgainButton: true
        }
    }
  }),
  observeRecordings: observer('recordings.[]', function () {
    const searchedISRC = this.get('search.isrc') ? this.get('search.isrc').trim() : undefined
    if (this._isrcAlreadyInUse(searchedISRC) && this.get('search.candidates.length')) {
      this.set(
        'search.candidates',
        this.get('search.candidates').filter(item => item.isrc !== searchedISRC)
      )
      this.set('state', ALREADY_IN_USE)
    }
  }),

  init(...args) {
    this._super(...args)

    this.set('state', INIT_STATE)

    if (this.get('search.isrc')) this.send('search')
  },

  actions: {
    async search() {
      const isrc = this.get('search.isrc') ? this.get('search.isrc').trim() : null
      // check if the searched isrc is not already present in the recordings array of changesets
      if (this._isrcAlreadyInUse(isrc)) {
        this.set('state', ALREADY_IN_USE)
        return false
      }
      return await this._performSearch(isrc)
    },

    acceptCandidate(candidate) {
      this.addRecordingAction(candidate)
      this._removeCandidate(candidate)
      this._tryResolveSearchBox()
    },

    resetSearch() {
      this.setProperties({
        state: INIT_STATE,
        'search.isrc': null,
        'search.candidates': []
      })
    },

    remove() {
      this.removeSearchAction(this.search)
    }
  },

  async _performSearch(isrc) {
    this.setProperties({
      state: INIT_STATE
    })
    try {
      const result = await this.searchRecordingsTask.perform({ isrc })
      const recordings = result.recordings_by_isrc.edges.map(e => e.node)
      const noRecordings = result.recordings_by_isrc.edges.length === 0
      const regularRecording = recordings.findBy('typename', 'Recording')
      if (noRecordings) {
        //  If no recordings we set the new state and initiate an dummy candidate
        this.setProperties({
          state: NEW_STATE,
          'search.candidates': [this._newCandidate(isrc)]
        })
        return false
      }

      if (regularRecording) {
        /**
         * if we found an recording in GRAMO's registry, we set the correct state and add the recording
         * to the candidates array then stop, isrc are unique.
         */
        this.setProperties({
          state: RECORDING_STATE,
          'search.candidates': [regularRecording]
        })
        return false
      }
    } catch (e) {
      this.setProperties({
        state: ERROR_STATE,
        'search.candidates': []
      })
    }
  },

  _removeCandidate(candidate) {
    this.get('search.candidates').removeObject(candidate)
  },

  _tryResolveSearchBox() {
    if (this.get('search.candidates.length') === 0) {
      this.removeSearchAction(this.search)
    }
  },
  _isrcAlreadyInUse(isrc) {
    return this.recordings.isAny('isrc', isrc)
  },
  _newCandidate(isrc) {
    return {
      typename: 'NewRecording',
      isrc,
      title: undefined,
      main_artist: undefined,
      duration_in_seconds: undefined,
      side: undefined,
      number_on_side: undefined
    }
  }
})
