import Component from '@ember/component'
import { inject } from '@ember/service'
import { computed } from '@ember/object'
import { empty } from '@ember/object/computed'
import AddClaimRecordingToClaimFile from 'min-side/graphql/mutations/claims/add_recording_to_claim_file'

export default Component.extend({
  intl: inject(),
  apollo: inject(),

  'data-test-edit-claim-recording-file-upload': true,

  fileToUpload: null,
  fileDescription: '',
  selectedFileId: null,

  selectableFiles: computed('fileList', 'existingFiles', function () {
    return this.get('fileList').filter(file => {
      return !this.get('existingFiles').find(existingFile => existingFile.id === file.id)
    })
  }),

  addNewFileButtonDisabled: empty('fileToUpload'),
  addExistingFileButtonDisabled: empty('selectedFileId'),

  actions: {
    async changeFile(event) {
      this.set('fileToUpload', event.target.files[0])
    },
    closeDialog() {
      this.get('close')()
    },
    selectClaimFile(event) {
      this.set('selectedFileId', event.target.value)
    },
    selectFile(id) {
      this.set('selectedFileId', id)
      this.get('close')()
    },
    async addClaimRecordingToClaimFile(id) {
      if (!id) {
        return
      }
      const variables = {
        input: {
          claim_file_id: id,
          claim_recording_id: this.get('claim_recording_id')
        }
      }
      try {
        const response = await this.apollo.mutate({
          variables,
          mutation: AddClaimRecordingToClaimFile
        })

        this.get('addFileToClaimRecording')(response.add_claim_recording_to_claim_file.claim_file)
        this.get('close')()
      } catch (_e) {
        this.get('flashMessages').error(this.get('intl').t('flash.save.failed'))
      }
    },
    async addFileToClaimRecording() {
      const file = this.get('fileToUpload')
      const fileDescription = this.get('fileDescription')
      if (!file) {
        return
      }
      // Since we are making a multipart/form request and not using the default
      // apollo method, we can not use imported graphql files, so we define the
      // query as a plain string here instead.
      const query = `
      mutation AddFileToClaim($input: AddFileToClaimInput!) {
        add_file_to_claim(input: $input) {
          claim_file {
            id
            file {
              id
              name
              description
            }
          }
          errors {
            attribute: key
            message
          }
        }
      }`

      const formData = new FormData()
      formData.append('variables[input][claim_id]', this.get('claim_id'))
      formData.append('variables[input][claim_recording_id]', this.get('claim_recording_id'))

      try {
        const response = await this.apollo.fileUpload(query, formData, file, fileDescription)

        const claim_file = response.data.add_file_to_claim.claim_file
        this.get('addFileToClaimRecording')(claim_file)
        this.get('addFileToClaim')(claim_file)
        this.get('close')()
      } catch (_e) {
        this.get('flashMessages').error(this.get('intl').t('flash.save.failed'))
      }
    }
  }
})
