import Mixin from '@ember/object/mixin'
import { computed, getWithDefault, get } from '@ember/object'
import moment from 'moment'

const DEFAULT_VALUE = '-'

export default Mixin.create({
  title: computed('formData.releaseInfo.title', 'intl.locale', function () {
    return getWithDefault(
      this,
      'formData.releaseInfo.title',
      this.intl.t('album.header.default-title')
    )
  }),

  performer: computed('formData.releaseInfo.main_artist', 'intl.locale', function () {
    return getWithDefault(
      this,
      'formData.releaseInfo.main_artist',
      this.intl.t('album.header.default-artist')
    )
  }),

  label: computed('formData.releaseInfo.recording_label.name', 'intl.locale', function () {
    return getWithDefault(
      this,
      'formData.releaseInfo.recording_label.name',
      this.intl.t('album.header.default-label')
    )
  }),

  releaseYear: computed('formData.releaseInfo.release_date', function () {
    const releaseDate = get(this, 'formData.releaseInfo.release_date')
    if (releaseDate !== null) {
      return moment(releaseDate).format('YYYY')
    }
    return DEFAULT_VALUE
  }),

  performersCount: computed('formData.performers.[]', function () {
    return this.get('formData.performers').length || DEFAULT_VALUE
  }),

  tracksCount: computed('formData.recordings.[]', function () {
    return this.get('formData.recordings').length || DEFAULT_VALUE
  })
})
