import Controller from '@ember/controller'
import { queryManager } from 'ember-apollo-client'
import { inject as service } from '@ember/service'
import { action, computed } from '@ember/object'
import { tracked } from '@glimmer/tracking'
import { extractProblems } from 'min-side/utils/error-helpers'
import UpdatePlaybackOnClaim from 'min-side/graphql/mutations/claims/update_playback_on_claim'
import RemoveRecordingFromClaimFile from 'min-side/graphql/mutations/claims/remove_recording_from_claim_file'
import { set } from '@ember/object'

const RECORDINGS_PER_PAGE = 100

export default class ClaimsItemController extends Controller.extend() {
  @service intl
  @service intercom
  @queryManager apollo

  @tracked
  active = false

  after = null
  first = RECORDINGS_PER_PAGE

  @computed('model')
  get enableSubmit() {
    if (this.model.claim.claim_recordings.edges.length === 0) {
      return false
    }

    switch (this.model.claim.state) {
      case 'CREATED': {
        const notIgnored = this.model.claim.claim_recordings.edges.filter(
          recording => recording.state !== 'IGNORED'
        )
        if (notIgnored.length === 0) {
          return false
        }

        return notIgnored.every(recording => recording.state === 'SUBMITTED')
      }
      case 'REQUESTED_CHANGES':
        return this.model.claim.claim_recordings.edges.some(recording => {
          return recording.state === 'SUBMITTED'
        })
    }
  }

  @computed('enableSubmit')
  get disableSubmit() {
    return !this.get('enableSubmit')
  }

  @computed('model')
  get showSubmitButton() {
    return ['CREATED', 'REQUESTED_CHANGES'].includes(this.model.claim.state)
  }

  @computed('model.active')
  get isActive() {
    return this.get('model.active')
  }

  @computed('selectedRecording')
  get selectedItem() {
    return this.get('editableRecordings').indexOf(this.selectedRecording) + 1
  }

  @computed('model')
  get editableRecordings() {
    if (['SUBMITTED', 'RESUBMITTED', 'COMPLETED'].includes(this.model.claim.state)) return []

    return this.model.claim.claim_recordings.edges.filter(recording => {
      return ['CREATED', 'REJECTED', 'SUBMITTED', 'IGNORED'].includes(recording.state)
    })
  }

  @computed('editableRecordings')
  get hasEditableRecordings() {
    return this.get('editableRecordings').length > 0
  }

  @computed('model')
  get hasIgnoredRecordings() {
    return this.model.claim.claim_recordings.edges.some(recording => {
      return ['IGNORED'].includes(recording.state)
    })
  }

  @action
  async editRecording(recording) {
    if (this.get('editableRecordings').includes(recording)) {
      this.set('selectedRecording', recording)
      const numberOfYearsBefore = 5 // First available claim date is five years before from the current year.
      const defaultYear = (new Date().getFullYear() - numberOfYearsBefore).toString()
      const defaultDate = `${defaultYear}-01-01`
      this.set('selectedRecording.ownership.start_date', defaultDate)
    }
  }

  @action
  async closeRecording() {
    this.set('selectedRecording', null)
    this.send('reload')
  }

  @action
  async saveRecording(recording, cont) {
    this.set('selectedRecording.errors', null)
    const variables = {
      input: {
        id: recording.id,
        title: recording.title,
        main_artist: recording.main_artist,
        label_name: recording.label_name,
        p_line: recording.p_line,
        isrc: recording.isrc,
        comment: recording.comment
      }
    }

    if (recording.typename === 'ProducerClaimRecording') {
      variables.input.ownerships = [
        {
          share: parseInt(recording.ownership.share, 10),
          start_date: recording.ownership.start_date,
          producer_name: recording.ownership.producer_name
        }
      ]

      if (recording.ownership.producer_id) {
        variables.input.ownerships[0].producer_id = recording.ownership.producer_id
      }
    }

    if (recording.typename === 'PerformerClaimRecording') {
      variables.input.performances = recording.performances.map(performance => {
        const input = {
          instrument: performance.instrument?.id,
          role_code: performance.role_code,
          performer_name: performance.performer_name
        }
        if (performance.performer_id) {
          input.performer_id = performance.performer_id
        }
        return input
      })
    }

    try {
      const result = await this.apollo.mutate({
        mutation: UpdatePlaybackOnClaim,
        variables
      })
      if (result.update_playback_on_claim.errors) {
        this.get('flashMessages').error(this.get('intl').t('flash.save.invalid'))
        this.set('selectedRecording.errors', result.update_playback_on_claim.errors)
        return
      }
      this.flashMessages.success(this.intl.t('flash.save.success'))
      if (cont) {
        // move onto next recording
        this.send('nextRecording')
      } else {
        this.set('selectedRecording', null)
        this.send('reload')
      }
    } catch (e) {
      this.get('flashMessages').error(this.get('intl').t('flash.save.invalid'))
      this.set('selectedRecording.errors', this._parseAndMapApolloErrors(e.errors))
    }
  }

  @action
  async copyToAll(field, value) {
    this.get('editableRecordings').forEach(claim_recording => set(claim_recording, field, value))
    this.get('flashMessages').success(this.get('intl').t('claims.ownership-copied'))
  }

  @action
  async addPerformanceToAll(performance) {
    this.get('editableRecordings').forEach(claim_recording => {
      // check that the performance isn't already present
      if (
        !claim_recording.performances.find(
          p =>
            p.performer_id === performance.performer_id &&
            p.performer_name === performance.performer_name
        )
      ) {
        // if a null performance is remove that first and then add the new performance
        const nullPerformance = claim_recording.performances.find(
          p => !p.performer_name && !p.performer_id
        )
        if (nullPerformance) {
          claim_recording.performances.removeObject(nullPerformance)
        }
        claim_recording.performances.pushObject({ ...performance })
      }
    })
    this.get('flashMessages').success(this.get('intl').t('claims.performance-copied'))
  }

  @action
  async removeRecordingFromFile(claim_file_id, claim_recording_id) {
    const variables = {
      input: {
        claim_file_id,
        claim_recording_id
      }
    }
    await this.apollo.mutate({
      variables,
      mutation: RemoveRecordingFromClaimFile
    })
  }

  @action
  async nextRecording() {
    const currentIndex = this.get('editableRecordings').indexOf(this.selectedRecording)
    this.set('selectedRecording', this.get('editableRecordings')[currentIndex + 1])
    if (!this.get('selectedRecording')) {
      this.send('reload')
    }
  }

  @action
  async previousRecording() {
    const currentIndex = this.get('editableRecordings').indexOf(this.selectedRecording)
    this.set('selectedRecording', this.get('editableRecordings')[currentIndex - 1])
    if (!this.get('selectedRecording')) {
      this.send('reload')
    }
  }

  @action
  async recordingSelect(value, event) {
    value.checked = !value.checked
    event.stopPropagation()
    return false
  }

  @action
  async remove() {
    const recordings = []
    this.get('editableRecordings').forEach(recording => {
      if (recording.checked) {
        recordings.push(recording.id)
        recording.checked = false
      }
    })
    this.send('removeRecordings', recordings)
  }

  @action
  onPageChange(_, after) {
    this.set('after', after)
  }

  _parseAndMapApolloErrors(errors) {
    const mappedErrors = errors.map(error => {
      const problems = extractProblems(error)
      if (!problems) return false
      return problems.map(problem => {
        return {
          message: this.intl.t('validation.empty'),
          attribute: problem.path.join('/')
        }
      })
    })
    return [].concat(...mappedErrors)
  }
}
