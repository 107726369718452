import Service from '@ember/service'

export default Service.extend({
  add(queryParam) {
    const paramKey = Object.keys(queryParam)[0]
    this.set(paramKey, queryParam[paramKey])
  },

  empty() {
    const paramsKeys = Object.keys(this)
    paramsKeys.forEach(key => {
      delete this[key]
    })
  }
})
