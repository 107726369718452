import Route from '@ember/routing/route'
import { inject as service } from '@ember/service'
import { queryManager } from 'ember-apollo-client'
import query from 'min-side/graphql/queries/ledger/ledger-for-organization'
import { sectorForUser } from 'min-side/helpers/user-types'
import { getDebitorsForLedgerEntry, getLedgerEntry } from 'min-side/extractors/agency'

export default class UserLedgerEntryRoute extends Route {
  @service profile
  @queryManager apollo

  async model({ id, sector }) {
    const selectedSector = sector === 'performer' ? 'PERFORMER' : 'PRODUCER'

    const variables = {
      owner_id: this.get('profile.activeProfile.id'),
      entry_id: id,
      include_offset_entries: true,
      sector: selectedSector || sectorForUser(this.get('profile.activeProfile'))
    }

    this.set('entryId', id)
    return await this.apollo.watchQuery({ query, variables })
  }

  setupController(controller, model) {
    const debitors = getDebitorsForLedgerEntry(model, this.entryId)
    const entry = getLedgerEntry(model, this.entryId)

    controller.setProperties({
      debitors,
      entry
    })
  }
}
