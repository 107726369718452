import Controller from '@ember/controller'
import { inject as service } from '@ember/service'
import { action } from '@ember/object'
import { tracked } from '@glimmer/tracking'

export default class ForgotPasswordController extends Controller.extend() {
  @service intl
  @service intercom
  @tracked invalidEmail = false
  @tracked unknownError = false
  @action
  openIntercom() {
    this.intercom.show()
  }
}
