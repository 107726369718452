import Component from '@ember/component'
import { inject } from '@ember/service'
import { reads, empty, not, and, filterBy } from '@ember/object/computed'
import { task } from 'ember-concurrency'
import { A } from '@ember/array'
import EmberObject from '@ember/object'
import Changeset from 'ember-changeset'
import lookupValidator from 'ember-changeset-validations'
import OwnershipDraftValidations from 'min-side/validations/edit-recording/ownership-draft'
import { queryManager } from 'ember-apollo-client'
import { prepareOwnershipInput } from 'min-side/extractors/edit-recording/ownership'
import { getOwnershipChangeRequest } from 'min-side/extractors/edit-recording/ownership'
import { urlFor } from 'min-side/utils/url-helpers'
import { Headers } from 'fetch'

export default Component.extend({
  apollo: queryManager(),
  profile: inject(),
  router: inject(),
  queryParams: inject(),
  intl: inject(),
  session: inject(),
  ajaxRequests: inject(),

  'data-test-edit-recording-ownership': true,

  hideForm: true,
  afterSave: false,

  activeOwnership: reads('activeOwnerships.firstObject'),
  activeOwnerships: filterBy('ownerships', 'active', true),
  emptyChangeRequest: empty('changeRequest'),
  isProducer: reads('profile.isProducer'),
  notProducer: not('isProducer'),
  noPendingChangeRequest: not('hasPendingChangeRequest'),
  notEmptyChangeRequest: not('emptyChangeRequest'),
  showAddButton: and('isProducer', 'hideForm', 'noPendingChangeRequest'),
  showChangeRequest: and('isProducer', 'hasPendingChangeRequest', 'notEmptyChangeRequest'),

  async init(...args) {
    this._super(...args)

    this.setProperties({
      ownershipDraft: this._initOwnershipDraft()
    })

    this.setProperties({
      hasPendingChangeRequest: this.get('ownershipsChangeRequests.has_pending'),
      changeRequest: getOwnershipChangeRequest(this.ownershipsChangeRequests)
    })
  },

  tSaveOwnership: task(function* () {
    yield this.ownershipDraft.save()
    yield this.get('ownershipDraft.shares').forEach(changeset => changeset.save())

    const formData = prepareOwnershipInput(this.ownershipDraft, this.recordingId, this.profile)

    return this.createOwnershipChangeRequest(formData)
      .then(res => {
        if (res.errors || !res.ok) {
          return this.flashMessages.error(this.intl.t('flash.save.invalid'))
        }

        this.setProperties({
          disabled: true,
          afterSave: true
        })
        return this.flashMessages.success(this.intl.t('flash.save.success'))
      })
      .catch(_error => {
        this.flashMessages.error(this.intl.t('flash.save.failed'))
      })
  }),

  createOwnershipChangeRequest(data) {
    const headers = new Headers()
    const ajaxRequests = this.get('ajaxRequests')

    return ajaxRequests.postRequest(urlFor('graphql'), data, headers, true)
  },
  _initOwnershipDraft() {
    return new Changeset(
      EmberObject.create({
        ends_at: '',
        shares: A(),
        starts_at: undefined,
        mapped_errors: EmberObject.create({ errors: [] }),
        attachment: {
          file: undefined,
          name: undefined,
          uploaded_at: undefined,
          description: ''
        }
      }),
      lookupValidator(OwnershipDraftValidations),
      OwnershipDraftValidations
    )
  },

  actions: {
    save() {
      this.tSaveOwnership.perform()
    },

    rollback() {
      this.setProperties({
        ownershipDraft: this._initOwnershipDraft(),
        hideForm: true
      })
    },

    showForm() {
      this.set('hideForm', false)
    }
  }
})
