import Service, { inject as service } from '@ember/service'
import { queryManager } from 'ember-apollo-client'
import UpdateSession from 'min-side/mixins/update-session'

export default Service.extend(UpdateSession, {
  apollo: queryManager(),
  profile: service(),
  session: service(),
  onboarding: service(),

  async __invalidateAndClearSession() {
    const session = this.session
    await session.invalidate()
    await session.get('store').clear()
  },

  /**
   * Fully logout a user and reset all the internal services like session and apollo client
   */
  async logout() {
    // unsubscribe from all graphql operations
    this.apollo.unsubscribeAll()

    // reset graphql store
    await this.apollo.apollo.client.resetStore()

    // Reset onboarding state
    this.onboarding.reset()

    // invalidate and clear session plus redirect to user to login route
    await this.__invalidateAndClearSession()
  }
})
