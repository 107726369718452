import Component from '@glimmer/component'
import { inject as service } from '@ember/service'
import { reads } from '@ember/object/computed'
import { tracked } from '@glimmer/tracking'
import { action } from '@ember/object'

export default class GlobalHeader extends Component {
  @service features
  @service('profile') profile

  @reads('profile.current') _cp
  @reads('_cp.displayName') displayName

  @reads('_cp.meta.isImpersonating') isImpersonating
  @reads('_cp.meta.profileName') profileName
  @reads('_cp.meta.profileNameFull') profileNameFull
  @reads('_cp.meta.hasDiscography') hasDiscography
  @reads('_cp.meta.hasClientsList') hasClientsList
  @reads('_cp.meta.hasTestators') hasTestators
  @reads('_cp.meta.hasClaims') hasClaims

  @reads('_cp.profileObjectsFromLegacyService') profileObjectsFromLegacyService
  @reads('_cp.profileObjectFromLegacyService') activeProfileObjectFromLegacyService

  @reads('_cp.person.name') personName
  @reads('_cp.person.isDead') isDead
  @reads('_cp.person') person

  @tracked navExpanded = false

  @action
  toggleNav(event) {
    // Can be called when clicking on menu, or via other action
    // Only click on menu will give us an event, and we are are
    // stopping propagation as otherwise it will bubble and call
    // our on-click-outside modifier which closes the menu again
    // right away.
    if (event) {
      event.stopPropagation()
      event.preventDefault()
    }

    this.navExpanded = !this.navExpanded
  }
}
