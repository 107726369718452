import { inject as service } from '@ember/service'
import Route from '@ember/routing/route'
import claims_query from 'min-side/graphql/queries/active-claim'
import mutation from 'min-side/graphql/mutations/claims/add_claim_recording_to_active_claim'
import Removemutation from 'min-side/graphql/mutations/claims/remove_playback_from_claim'
import { set } from '@ember/object'
import { PROFILE_TYPES, PROFILE_TYPES_MAP } from 'min-side/constants/profile'
import isEqual from 'lodash/isEqual'
import PlaybacksRouteMixin from 'min-side/mixins/playbacks/route'
import { getActiveOrganization } from 'min-side/helpers/organization'
import { sectorForUser } from 'min-side/helpers/user-types'

export default Route.extend(PlaybacksRouteMixin, {
  profile: service(),
  features: service(),
  router: service(),
  authenticated: true,

  beforeModel() {
    if (this.profile.activeProfile.impersonating) {
      this.router.transitionTo('')
    }
    if (this.get('profile.activeProfile.profile') === 'performer') {
      if (!this.features.get('claims-performer')) {
        this.router.transitionTo('')
      }
    }
  },

  async model({ term, filters, after, sort, direction, year, sector }) {
    const profile_name = this.get('profile.activeProfile.profile')
    const profile_node = PROFILE_TYPES_MAP[profile_name]
    const owner_id = this.get('profile.activeProfile.id')
    const claim_variables = {
      include_producer: false,
      include_performer: false,
      include_contact: false,
      include_agency: false,
      sector: null
    }

    let claim_recording_owner_id = null

    if (profile_name === PROFILE_TYPES.PERSONAL_PRODUCER) {
      claim_variables.include_producer = true
      claim_recording_owner_id = owner_id
    } else if (profile_name === PROFILE_TYPES.PERFORMER) {
      claim_variables.include_performer = true
      claim_recording_owner_id = owner_id
    } else if (profile_name === PROFILE_TYPES.CONTACT) {
      if (sector) {
        claim_variables.sector = sector
      } else {
        claim_variables.sector = 'PRODUCER'
      }
      claim_variables.include_contact = true
    } else {
      throw new Error('Profile type not supported')
    }
    const active_claim = await this.apollo.query({
      query: claims_query,
      variables: claim_variables,
      fetchPolicy: 'network-only'
    })

    let entity = null
    // for contact profiles we need to find the correct organization to read the claim info
    if (profile_name === PROFILE_TYPES.CONTACT) {
      const activeOrg = getActiveOrganization(active_claim.user.person.contact.positions, owner_id)
      if (activeOrg.agency) {
        entity = activeOrg.agency
      } else {
        entity = activeOrg.producer_company
      }
    } else {
      entity = active_claim.user.person[profile_node]
    }

    const results = await this._getPlaybacks(
      term,
      filters,
      after,
      sort,
      direction,
      year,
      claim_recording_owner_id,
      sectorForUser(this.get('profile.activeProfile'))
    )

    if (term || filters.length) {
      // go through each listed recording and see if it is part of the active claim
      const updated = results.edges.map(playback_aggregate => {
        if (entity.active_claim) {
          const exists = entity.active_claim.claim_recordings.edges.find(recording => {
            const recording_lb_ids = recording.node.playbacks.map(playback => {
              return { local_id: playback.local_id, broadcaster_id: playback.broadcaster_id }
            })

            const playback_lb_ids = playback_aggregate.node.playbacks.map(playback => {
              return { local_id: playback.local_id, broadcaster_id: playback.broadcaster_id }
            })

            if (isEqual(recording_lb_ids, playback_lb_ids)) {
              return recording.node.id
            }
            return false
          })
          if (exists) {
            set(playback_aggregate, 'node.exists', exists)
          } else {
            set(playback_aggregate, 'node.exists', null)
          }
        } else {
          // apollo appears to cache the changes to the graphql response so need to invalidate the exists field
          set(playback_aggregate, 'node.exists', null)
        }
        return playback_aggregate
      })

      return {
        active: entity.active_claim,
        sector,
        edges: updated,
        pageInfo: results.pageInfo,
        total: results.total
      }
    }
    return {
      active: entity.active_claim,
      sector,
      edges: [],
      pageInfo: {},
      total: 0
    }
  },

  actions: {
    async addToClaim(recordings, forcedSector) {
      const profile_name = this.get('profile.activeProfile.profile')
      let sector = 'PRODUCER'
      if (profile_name === 'performer') {
        sector = 'PERFORMER'
      }
      if (forcedSector) {
        sector = forcedSector
      }
      for (let i = 0; i < recordings.length; i++) {
        const recording = recordings[i]

        const variables = {
          input: {
            claim: {
              sector,
              owner_id: this.get('profile.activeProfile.id'),
              playbacks: recording.playbacks,
              title: recording.title,
              main_artist: recording.main_artist,
              isrc: recording.isrc,
              album: recording.album,
              label_name: recording.label_name,
              catalog_number: recording.catalog_number,
              release_year: recording.release_year,
              production_year: recording.production_year,
              duration_in_seconds: recording.duration_in_seconds,
              producer_names: recording.producer_names
            }
          }
        }
        await this.apollo.mutate({
          mutation,
          variables
        })
      }
      this.refresh()
    },
    async removeFromClaim(id) {
      const variables = {
        input: {
          id
        }
      }
      await this.apollo.mutate({
        mutation: Removemutation,
        variables
      })
    },
    async viewClaim(id) {
      this.transitionTo('user.claims.item', id, {
        queryParams: { sector: this.currentModel.sector }
      })
    },
    reload() {
      this.refresh()
    }
  }
})
