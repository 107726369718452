import Component from '@glimmer/component'
import { action } from '@ember/object'
import { tracked } from '@glimmer/tracking'
import { inject } from '@ember/service'

export default class SiteHeader extends Component {
  @tracked searchExpanded = false
  @inject features

  @action
  toggleSearch(event) {
    event.stopPropagation()
    event.preventDefault()
    if (this.args.navExpanded) {
      this.args.toggleNav()
    }
    this.searchExpanded = !this.searchExpanded
  }
  @action
  clearSearch() {
    this.searchExpanded = false
  }
}
