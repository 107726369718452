import Route from '@ember/routing/route'
import { get } from '@ember/object'
import { reads } from '@ember/object/computed'
import { inject as service } from '@ember/service'
import producerCompanyQuery from 'min-side/graphql/queries/profile/producer-company'
import agencyQuery from 'min-side/graphql/queries/profile/agency'
import { queryManager } from 'ember-apollo-client'
import { getActiveOrganization } from 'min-side/helpers/organization'

export default class ProfileInfoOrgInfoRoute extends Route {
  @queryManager apollo
  @service profileInfo

  @reads('profileInfo.currentProfileType')
  currentProfileType

  async model() {
    if (this.currentProfileType === 'agency') {
      return await this.modelForAgency()
    }
    const id = get(this, 'profileInfo.activeProfile.id')
    const variables = { id }

    const response = await this.apollo.watchQuery({
      query: producerCompanyQuery,
      variables,
      fetchPolicy: 'network-only'
    })

    const organization = getActiveOrganization(response.user.person.contact.positions, id)
    return {
      ...organization,
      producer_company: {
        ...organization.producer_company,
        errors: []
      }
    }
  }

  async modelForAgency() {
    const id = get(this, 'profileInfo.activeProfile.id')
    const variables = { id }
    const response = await this.apollo.watchQuery({
      query: agencyQuery,
      variables,
      fetchPolicy: 'network-only'
    })
    return getActiveOrganization(response.user.person.contact.positions, id)
  }

  setupController(controller, model) {
    super.setupController(controller, model)

    let company = model.producer_company

    if (this.currentProfileType === 'agency') {
      company = model.agency
    }

    controller.setupChangeset(company)
  }
}
