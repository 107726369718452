import Component from '@ember/component'
import { inject } from '@ember/service'
import { computed } from '@ember/object'
import { reads } from '@ember/object/computed'
import moment from 'moment'

export default Component.extend({
  intl: inject(),

  tagName: '',
  supportsDataTestProperties: true,
  classNames: ['form__input form__input--date'],
  altInput: true,
  altInputClass: ['form__input'],
  disableMobile: false,
  dateFormat: 'Y-m-d',
  placeholder: reads('altFormat'),
  altFormat: computed('intl.locale', function () {
    if (this.get('intl.locale') === 'en') {
      return 'd/m/Y'
    }
    return 'd.m.Y'
  }),
  mode: 'single',
  static: true,
  locale: computed('intl.locale', function () {
    const localeData = moment.localeData(this.get('intl.locale'))
    return {
      ordinal: localeData.ordinal,
      weekdays: {
        longhand: localeData.weekdays(),
        shorthand: localeData.weekdaysShort()
      },
      months: {
        longhand: localeData.months(),
        shorthand: localeData.monthsShort()
      }
    }
  }),
  shouldShowClearIcon: computed('disabled', 'date', function () {
    const isDateEmpty = !this.date
    const isDisabled = !!this.disabled

    return !isDisabled && !isDateEmpty
  }),
  dateValue: computed('date', function () {
    // date needs to be null if not defined, so make sure it is here
    return this.get('date') || null
  }),
  init(...args) {
    this._super(...args)

    // ember-flatpickr module requires that we supply at least null for date property
    if (!this.date) {
      this.set('date', null)
    }
  },
  actions: {
    onChange(value) {
      const dateString = value && value[0] ? moment(value[0]).format('YYYY-MM-DD') : null
      this.set('date', dateString)

      if (dateString === null && this.onClearDateSelection) {
        this.onClearDateSelection(null)
        return
      }

      if (this.onChange) {
        this.onChange(dateString)
      }
    },
    clearDateSelection() {
      this.set('date', null)
      if (this.onClearDateSelection) {
        this.onClearDateSelection(null)
      }
    }
  }
})
