import Route from '@ember/routing/route'
import { inject as service } from '@ember/service'
import { queryManager } from 'ember-apollo-client'
import query from 'min-side/graphql/queries/claims'
import { PROFILE_TYPES, PROFILE_TYPES_MAP } from 'min-side/constants/profile'
import { getActiveOrganization } from 'min-side/helpers/organization'
import { set } from '@ember/object'

export default Route.extend({
  apollo: queryManager(),
  profile: service(),
  features: service(),
  router: service(),

  queryParams: {
    sector: { refreshModel: true },
    state: { refreshModel: true },
    term: { refreshModel: true }
  },

  beforeModel() {
    if (
      this.get('profile.activeProfile.profile') === 'performer' ||
      this.profile.activeProfile.impersonating
    ) {
      this.router.transitionTo('')
    }
  },

  async model({ sector, state, term }) {
    const profile_name = this.get('profile.activeProfile.profile')
    const profile_node = PROFILE_TYPES_MAP[profile_name]
    const id = this.get('profile.activeProfile.id')

    const variables = {
      include_producer: false,
      include_performer: false,
      include_contact: false,
      sector: null,
      states: state ? [state] : null,
      query: term
    }

    if (profile_name === PROFILE_TYPES.PERSONAL_PRODUCER) {
      variables.include_producer = true
    } else if (profile_name === PROFILE_TYPES.PERFORMER) {
      variables.include_performer = true
    } else if (profile_name === PROFILE_TYPES.CONTACT) {
      if (sector) {
        variables.sector = sector
      } else {
        variables.sector = 'PRODUCER'
      }
      variables.include_contact = true
    } else {
      throw new Error('Profile type not supported')
    }

    const results = await this.apollo.query({ query, variables, fetchPolicy: 'network-only' })
    let entity = null
    // for contact profiles we need to find the correct organization to read the claim info
    if (profile_name === PROFILE_TYPES.CONTACT) {
      const activeOrg = getActiveOrganization(results.user.person.contact.positions, id)
      if (activeOrg.agency) {
        entity = activeOrg.agency
      } else {
        entity = activeOrg.producer_company
      }
    } else {
      entity = results.user.person[profile_node]
    }
    const claims = entity.claims.edges.map(claim => {
      const approved_total = claim.node.claim_recordings.edges.filter(
        claim_recording => claim_recording.node.state === 'APPROVED'
      ).length
      set(claim.node, 'approved_total', approved_total)
      const rejected_total = claim.node.claim_recordings.edges.filter(
        claim_recording => claim_recording.node.state === 'REJECTED'
      ).length
      set(claim.node, 'rejected_total', rejected_total)
      const ignored_total = claim.node.claim_recordings.edges.filter(
        claim_recording => claim_recording.node.state === 'IGNORED'
      ).length
      set(claim.node, 'ignored_total', ignored_total)
      return claim
    })
    const active_claim = entity.active_claim
    return {
      existing: claims.filter(claim => (active_claim ? claim.node.id !== active_claim.id : 1)),
      active: active_claim,
      sector
    }
  }
})
