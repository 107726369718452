import Component from '@glimmer/component'
import { isChangeset } from 'validated-changeset'
import { action, computed } from '@ember/object'
import { hasAlternativeMobile } from 'min-side/helpers/contact-information'

export default class ProfileSectionContactInfoComponent extends Component {
  get data() {
    return this.args.changeset
  }

  set hasForeignAddress(value) {
    this.data.set('hasForeignAddress', value)
  }

  get hasForeignAddress() {
    return this.data.get('hasForeignAddress')
  }

  get hasAlternativeMobile() {
    if (this.isChangeset) {
      return hasAlternativeMobile(this.data.data, '')
    }
    return hasAlternativeMobile(this.data, '')
  }

  get errorPath() {
    return this.args.errorPath || 'contact_information.'
  }

  @computed('args.errors.length')
  get nestedErrorsObject() {
    return {
      errors: this.args.errors
    }
  }

  @computed('data')
  get isChangeset() {
    return isChangeset(this.data)
  }

  @action
  onHasForeignAddressChange() {
    this.hasForeignAddress = !this.hasForeignAddress
  }

  @action
  updatePhoneNumberField(attribute, value, extra) {
    this.data.set(attribute, {
      value,
      countryCodeIso2: extra.selectedCountryData.iso2
    })
  }
}
