import Component from '@ember/component'
import { inject } from '@ember/service'
import { queryManager } from 'ember-apollo-client'
import { computed } from '@ember/object'
import CreateAndSubmitClaim from 'min-side/graphql/mutations/claims/create_and_submit_claim'
import UpdateAndResubmitClaim from 'min-side/graphql/mutations/claims/update_and_resubmit_claim'

export default Component.extend({
  intl: inject(),
  profile: inject(),
  tagName: '',
  apollo: queryManager(),

  showPerformance: computed('recording.__typename', function () {
    return this.get('recording.__typename') === 'PerformerClaimRecording'
  }),

  actions: {
    async save() {
      this.set('recording.errors', [])

      const recording = this.get('recording')
      if (!recording.isrc) {
        recording.errors.pushObject({
          attribute: 'isrc',
          message: this.intl.t('discography.register.errors.isrc')
        })
        return
      } else if (!recording.isrc.trim().match(/^[A-Z]{2}[A-Z0-9]{3}[0-9]{7}$/i)) {
        recording.errors.pushObject({
          attribute: 'isrc',
          message: this.intl.t('discography.register.errors.isrc-invalid')
        })
        return
      }
      if (!recording.title) {
        recording.errors.pushObject({
          attribute: 'title',
          message: this.intl.t('discography.register.errors.title')
        })
        return
      }
      if (!recording.main_artist) {
        recording.errors.pushObject({
          attribute: 'main_artist',
          message: this.intl.t('discography.register.errors.main-artist')
        })
        return
      }

      const performances = recording.performances.map(performance => {
        return {
          performer_name: performance.performer_name,
          performer_id: performance.performer_id,
          role_code: performance.role_code
        }
      })

      try {
        let response = null
        let errors = null

        // if we have an id, then we are updating an existing claim
        if (recording.id) {
          response = await this.apollo.mutate({
            variables: {
              input: {
                id: recording.claim.id,
                title: recording.title,
                main_artist: recording.main_artist,
                label_name: recording.label_name,
                isrc: recording.isrc,
                performances,
                comment: recording.comment
              }
            },
            mutation: UpdateAndResubmitClaim
          })

          errors = response.update_and_resubmit_claim.errors
        } else {
          let playbacks = recording.playbacks.map(playback => {
            return {
              broadcaster_id: playback.broadcaster_id,
              local_id: playback.local_id
            }
          })

          // if we have related playbacks, then use those playbacks instead
          if (this.get('relatedPlaybackGroups')) {
            playbacks = this.get('relatedPlaybackGroups').map(playback => {
              return {
                broadcaster_id: playback.broadcaster_id,
                local_id: playback.local_id
              }
            })
          }

          // send the claim to the server
          response = await this.get('apollo').mutate({
            mutation: CreateAndSubmitClaim,
            variables: {
              input: {
                claim: {
                  owner_id: this.get('profile.activeProfile.id'),
                  sector: 'PERFORMER',
                  playbacks,
                  title: recording.title,
                  main_artist: recording.main_artist,
                  label_name: recording.label_name,
                  isrc: recording.isrc,
                  performances,
                  duration_in_seconds: recording.duration_in_seconds,
                  comment: recording.comment
                }
              }
            }
          })
          errors = response.create_and_submit_claim.errors
        }

        // if there are no errors reload the list
        if (errors) {
          this.get('flashMessages').error(this.get('intl').t('flash.save.failed'))
        } else {
          this.get('flashMessages').success(this.get('intl').t('flash.save.success'))
          if (this.get('closeRecording')) {
            this.get('closeRecording')()
          }
        }
      } catch (_e) {
        this.get('flashMessages').error(this.get('intl').t('flash.save.failed'))
      }
    },
    updateSearchableField(fieldName, { name, id }) {
      this.set(`recording.ownership.${fieldName}`, name)
      if (id) {
        this.set(`recording.ownership.producer_id`, id)
      } else {
        this.set(`recording.ownership.producer_id`, null)
      }
    },
    addPerformance() {
      this.get('recording.performances').pushObject({})
    },
    removePerformance(performnace) {
      this.get('recording.performances').removeObject(performnace)
    }
  }
})
