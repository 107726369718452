import Route from '@ember/routing/route'
import { inject as service } from '@ember/service'

export default class ProfileInfoContactRoute extends Route {
  @service phoneInput

  /**
   * This is needed for lazy loading `ember-phone-input` assets
   * more details can be found on the docs.
   * @link https://qonto.github.io/ember-phone-input/versions/master/docs/lazy-loading
   * @param transition
   * @returns {*}
   */
  async beforeModel(transition) {
    super.beforeModel(transition)
    await this.phoneInput.load()
  }

  setupController(controller, model) {
    super.setupController(controller, model)
    controller.setupChangeset(model)
  }
}
