import Component from '@ember/component'
import { alias } from '@ember/object/computed'
import { computed } from '@ember/object'
import { inject } from '@ember/service'

export default Component.extend({
  tagName: '',
  profile: inject(),

  // The claims are sorted most recent first, which is the only one we care about
  claimRecording: alias('recording.claim_recordings.firstObject'),

  pendingClaim: computed('claimRecording', function () {
    if (!this.get('claimRecording.claim')) {
      return null
    }
    if (this.get('claimRecording.state') === 'APPROVED') {
      return null
    }
    return this.get('claimRecording')
  }),

  isRegistration: computed('recording.recording', 'claimRecording.claim', function () {
    if (this.get('recording.recording')) {
      return false
    }
    return !this.get('claimRecording.claim')
  }),

  myPerformance: computed('recording.recording.performances.[]', function () {
    if (this.get('recording.recording.performances')) {
      return this.get('recording.recording.performances').find(
        p => p.public_performer.id === this.get('profile.activeProfile.id')
      )
    }
  }),

  myOwnership: computed('recording.recording.ownerships.[]', function () {
    if (this.get('recording.recording.ownerships')) {
      return this.get('recording.recording.ownerships').find(
        o =>
          o.shares.find(s => s.producer.id === this.get('profile.activeProfile.id')) &&
          o.active === true
      )
    }
  }),

  unconfirmed: computed('state', 'recording.status', function () {
    if (this.get('state') === 'registered' || this.get('state') === 'new') {
      return true
    }
    return false
  })
})
