import moment from 'moment'
import Route from '@ember/routing/route'
import { computed } from '@ember/object'
import { inject as service } from '@ember/service'
import { queryManager } from 'ember-apollo-client'
import query from 'min-side/graphql/queries/ledger/distribution-areas'
import { sectorForUser } from 'min-side/helpers/user-types'
import { createYearsList } from 'min-side/utils/date-ranges'

const YEARS_TO_SHOW = 1
// The first distribution is in March, so it only makes sense to show the
// previous year after March.
const OFFSET = moment().month() > 2 ? 1 : 2 // eslint-disable-line no-magic-numbers

export default class UserLedgerEntryRoute extends Route {
  @service profile
  @queryManager apollo

  @computed
  get defaultYear() {
    return createYearsList(YEARS_TO_SHOW, OFFSET)[0]
  }

  queryParams = {
    year: { refreshModel: true }
  }

  async model({ year }) {
    let selectedYear = year
    if (!selectedYear) {
      selectedYear = this.get('defaultYear')
    }
    const variables = {
      owner_id: this.get('profile.activeProfile.id'),
      sector: sectorForUser(this.get('profile.activeProfile')),
      years: [parseInt(selectedYear, 10)]
    }
    return await this.apollo.query({ query, variables })
  }

  setupController(controller, model) {
    super.setupController(controller, model)
    controller.set('offset', OFFSET)
  }

  resetController(controller, isExiting, _transition) {
    if (isExiting) {
      controller.set('year', null)
    }
  }
}
