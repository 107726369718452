import ChangeRequestApproveRejectRouteAbstract from 'min-side/abstracts/routes/change-request-approve-reject-route-abstract'
import { dropTask } from 'ember-concurrency-decorators'
import { urlFor } from 'min-side/utils/url-helpers'

export default class ApproveChangeRequestRoute extends ChangeRequestApproveRejectRouteAbstract {
  async beforeModel(transition) {
    const id = transition.to.queryParams.id
    const token = transition.to.queryParams.token

    return (this.taskResult = await this.tApproveChangeRequest.perform(id, token))
  }

  @dropTask
  *tApproveChangeRequest(id, token) {
    const ajaxRequests = this.ajaxRequests
    const rawFetchResponse = yield ajaxRequests.postRequest(urlFor('change_request/approve'), {
      data: { id, token }
    })

    return yield rawFetchResponse.json()
  }

  setupController(controller) {
    controller.set('taskResult', this.taskResult)
  }
}
