import Component from '@ember/component'
import { inject } from '@ember/service'
import { computed, get, getWithDefault } from '@ember/object'

export default Component.extend({
  'data-test-dashboard-common-agency-content': true,
  profile: inject(),

  agency: computed('positions', 'profile.activeProfile.id', function () {
    const organization = getWithDefault(this, 'positions', []).find(
      agency => get(agency, 'organization.id') === get(this, 'profile.activeProfile.id')
    )
    if (!organization) return {}
    return get(organization, 'organization.agency')
  })
})
