import DS from 'ember-data'

export default DS.JSONSerializer.extend({
  serialize(snapshot) {
    const options = {
      includeId: true
    }

    return this._super(snapshot, options)
  },
  extractErrors(store, typeClass, payload) {
    const errors = {}
    payload.errors.forEach(error => {
      errors[error.source.pointer] = error.detail
    })
    return errors
  }
})
