import { inject as service } from '@ember/service'
import { computed } from '@ember/object'
import ENV from 'min-side/config/environment'
import ApolloService from 'ember-apollo-client/services/apollo'
import { setContext } from 'apollo-link-context'
import { createHttpLink } from 'apollo-link-http'
import { onError } from 'apollo-link-error'
import echoFragmentMatcher from './fragment-matcher'
import { InMemoryCache } from 'apollo-cache-inmemory'
import fetch from 'fetch'

import { SESSION_DATA_KEY } from 'min-side/mixins/update-session'

const HTTP_UNAUTHORIZED_CODE = 401

export default ApolloService.extend({
  session: service(),
  logout: service(),
  intl: service(),
  flashMessages: service(),
  fragmentMatcher: echoFragmentMatcher,

  cache: computed('fragmentMatcher', function () {
    const fragmentMatcher = this.fragmentMatcher
    return new InMemoryCache({ fragmentMatcher })
  }),

  link: computed(function () {
    const apiURL = ENV.apollo.apiURL
    const httpLink = createHttpLink({ uri: apiURL, fetch })
    const authMiddleware = setContext(() => ({
      headers: {
        authorization: `Bearer ${this._token()}`
      }
    }))

    // Afterware
    const resetToken = onError(({ networkError }) => {
      if (networkError) {
        if (networkError.statusCode === HTTP_UNAUTHORIZED_CODE) {
          // invalidate session
          this.logout.logout()
        } else if (networkError.message === 'Network request failed') {
          /**
           * For some reason all we get from fetch is TypeError with
           * "Network request failed" as message.
           *
           * See https://github.com/github/fetch/blob/7232090c04e1ddefb806910bbd0a756bc8aac2f0/fetch.js#L466-L472
           */
          this.flashMessages.error(this.intl.t('flash.http_codes.service_unavailable'))
        }
      }
    })

    const authFlow = authMiddleware.concat(resetToken)

    return authFlow.concat(httpLink)
  }),

  clientOptions() {
    return {
      cache: this.cache,
      link: this.link
    }
  },

  _token() {
    const tokenPath = [
      `${SESSION_DATA_KEY}.authenticated`,
      ENV['ember-simple-auth-token'].tokenPropertyName
    ].join('.')

    return this.get(tokenPath)
  },

  fileUpload(query, formData, file, fileDescription) {
    formData.append('query', query)
    formData.append('variables[input][file][file]', file)
    formData.append('variables[input][file][description]', fileDescription)
    return fetch(`${ENV.apollo.apiURL}`, {
      headers: {
        authorization: `Bearer ${this._token()}`
      },
      body: formData,
      method: 'POST'
    }).then(result => result.json())
  }
})
