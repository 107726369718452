import Controller from '@ember/controller'
import { inject as service } from '@ember/service'
import { action } from '@ember/object'
import { queryManager } from 'ember-apollo-client'

import fileDownloadQuery from 'min-side/graphql/queries/file-download-url'

export default class ProfileInfoDocumentsController extends Controller {
  @service profileInfo
  @service router
  @service intl
  @service flashMessages
  @queryManager apollo
  @service documentSigning

  queryParams = ['documentId']

  @action
  async sign(party, person) {
    this.loading = true
    await this.documentSigning.sign(party, person.id)
    this.loading = false
  }

  @action
  async download(id) {
    const variables = { id }
    const response = await this.apollo.watchQuery({
      query: fileDownloadQuery,
      variables
    })
    if (!response.file_download_url) {
      this.flashMessages.error(this.intl.t('flash.http_codes.other'))
      return
    }
    window.open(response.file_download_url)
  }
}
