import Route from '@ember/routing/route'
import { inject as service } from '@ember/service'

export default class ChangeRequestApproveRejectRouteAbstract extends Route {
  @service session

  @service queryParams

  @service ajaxRequests
}
