import Component from '@glimmer/component'
import { inject } from '@ember/service'
import { reads } from '@ember/object/computed'

export default class CounterComponent extends Component {
  @inject spinnerScreen

  tagName = ''

  @reads('spinnerScreen.isVisible') showSpinner
}
