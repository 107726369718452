import Component from '@ember/component'
import { getWithDefault } from '@ember/object'
import { computed } from '@ember/object'
import { inject } from '@ember/service'

const DEFAULT_VALUE = '-'

export default Component.extend({
  'data-test-edit-recording-active-ownership': true,
  disabled: true,
  intl: inject(),

  shares: computed('activeOwnership.shares', 'intl.locale', function () {
    return getWithDefault(this, 'activeOwnership.shares', []).map(share => ({
      producer_name: getWithDefault(share, 'producer.name', this.intl.t('unknown-s')),
      producer_country: getWithDefault(share, 'producer.country.name', DEFAULT_VALUE),
      value: share.size
    }))
  })
})
