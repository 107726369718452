import Helper from '@ember/component/helper'
import moment from 'moment'
import { TIME_VALUES } from 'min-side/constants/time'

const { SECONDS_IN_HOUR } = TIME_VALUES

export function durationInHours(seconds) {
  return moment(SECONDS_IN_HOUR * seconds).format('hh:mm:ss')
}

export default Helper.helper(durationInHours)
