import Component from '@ember/component'
import { computed } from '@ember/object'

export default Component.extend({
  tagName: '',

  performerName: computed(
    'performance.public_performer.person.{first_name,last_name}',
    'performance.performer_name',
    function () {
      if (this.performance.public_performer) {
        return `${this.performance.public_performer.person.first_name} ${this.performance.public_performer.person.last_name}`
      }
      return this.performance.performer_name
    }
  ),

  active: computed(
    'performance.public_performer.person.id',
    'performance.performer_id',
    'performerId',
    function () {
      return (
        this.get('performance.public_performer.person.id') === this.get('performerId') ||
        this.get('performance.performer_id') === this.get('performerId')
      )
    }
  )
})
