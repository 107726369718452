import EmberObject, { getWithDefault } from '@ember/object'

const _mapAlternativeCatalogueNumbers = releaseInfo => {
  return releaseInfo.alternative_catalog_numbers.map(altCatNum => {
    return {
      catalog_number: altCatNum.get('catalog_number'),
      description: altCatNum.get('description')
    }
  })
}

const getPerformancesForMutation = performers => {
  return performers.map(performer => {
    return {
      performer_id: performer.get('id'),
      role_code: performer.get('role_code'),
      instrument_code: performer.get('instrument_code')
    }
  })
}

const getOwnershipsForMutation = shares => {
  if (shares.length) {
    const starts_at = shares[0].starts_at || null
    const ends_at = shares[0].ends_at || null
    const newShares = shares.map(share => {
      return {
        producer_id: share.producer_id,
        size: share.value
      }
    })

    return [{ starts_at, ends_at, shares: newShares }]
  }

  return []
}

const getRecordingsForMutation = recordings => {
  return recordings.map(recording => {
    const parsedRecording = EmberObject.create({
      isrc: recording.get('isrc'),
      title: recording.get('title'),
      main_artist: recording.get('main_artist'),
      release_tracks_data: {
        side: parseInt(recording.get('side'), 10),
        number_on_side: parseInt(recording.get('number_on_side'), 10)
      },
      duration_in_seconds: parseInt(recording.get('duration_in_seconds'), 10)
    })

    return parsedRecording
  })
}

const getReleaseDataForMutation = releaseInfo => {
  const { title, main_artist, catalog_number, release_date } = releaseInfo

  const record_label = getWithDefault(releaseInfo, 'recording_label.name', undefined)
  const recorded_in = getWithDefault(releaseInfo, 'recorded_in_country.id', undefined)
  const genre = getWithDefault(releaseInfo, 'genre.id', undefined)

  const format = releaseInfo.format || undefined

  return {
    title,
    main_artist,
    record_label,
    catalog_number,
    alternative_catalog_numbers: _mapAlternativeCatalogueNumbers(releaseInfo),
    release_date,
    recorded_in,
    genre,
    format
  }
}

export {
  getPerformancesForMutation,
  getOwnershipsForMutation,
  getRecordingsForMutation,
  getReleaseDataForMutation
}
