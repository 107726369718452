export default {
  "title": "_title_1e9fip",
  "artist": "_artist_1e9fip",
  "content": "_content_1e9fip",
  "controls": "_controls_1e9fip",
  "close": "_close_1e9fip",
  "navigation": "_navigation_1e9fip",
  "file-controls": "_file-controls_1e9fip",
  "recording": "_recording_1e9fip",
  "recording-form": "_recording-form_1e9fip",
  "ownership": "_ownership_1e9fip",
  "file-list": "_file-list_1e9fip",
  "file-list--row": "_file-list--row_1e9fip",
  "new-performance": "_new-performance_1e9fip",
  "new-performance-controls": "_new-performance-controls_1e9fip",
  "active": "_active_1e9fip"
};
